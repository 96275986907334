import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PopoverController, IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {NFC, NFCData} from 'src/app/utils/nfc/nfc';
import {NFCHandler} from 'src/app/utils/nfc/nfc-handler';
import {Environment} from '../../../../../environments/environment';
import {UnoIconComponent} from '../../../../components/uno/uno-icon/uno-icon.component';

/**
 * NFC input component is used to read NFC tags.
 *
 * A callback function must be provided to execute some component external processes on read data
 * 
 * Also a enabled variable may be provided to enable/disable scan.
 */
@Component({
	selector: 'nfc-reader',
	templateUrl: 'nfc-reader.component.html',
	styleUrls: ['nfc-reader.component.css'],
	standalone: true,
	imports: [IonicModule, TranslateModule, UnoIconComponent]
})
export class NFCReaderComponent implements OnInit, OnDestroy {
	public constructor(public controller: PopoverController) {}

	/**
	 * Callback for the external method to run when a NFC tag is detected.
	 *
	 * Received the NFC tag id as a parameter.
	 */
	@Input()
	public onRead: (data: string)=> void = null;
	
	/**
	 * Enables/Disables NFC scan.
	 */
	@Input()
	public enabled: boolean = true;

	/**
	 * Handler to read NFC data.
	 */
	public nfc: NFCHandler = null;

	public async ngOnInit(): Promise<void> {
		this.nfc = await NFC.read((data: NFCData) => {
			const id = data.serialNumber;
			if (this.onRead && this.enabled) {
				this.onRead(id);
			}
		});
	}

	public ngOnDestroy(): void {
		this.nfc.destroy();
	}

	/**
	 * Dismiss the modal controlled. Should close the modal screen instantly (if used in a modal).
	 */
	public dismiss(): void {
		try {
			this.nfc.destroy();
			this.controller.dismiss();
		} catch (e) {
			if (!Environment.PRODUCTION) {
				console.error('EQS: Error dismissing modal.', e);
			}
		}
	}
}
