/**
 * Method that receives data checks its validity.
 *
 * Can check for data format and content.
 */
export type Validator = (input: any)=> boolean;

/**
 * Input validator defines how input data should be checked for error.
 */
export class InputValidator {
	/**
	 * Validation method, return true if the data is valid, false otherwise.
	 */
	public validator: Validator = null;

	/**
	 * Message to present if the validation fails.
	 */
	public errorMessage: string = null;

	/**
	 * Message to present if the validation is successful.
	 */
	public successMessage: string = null;

	public constructor(validator: Validator, errorMessage?: string, successMessage?: string) {
		this.validator = validator;
		this.errorMessage = errorMessage;
		this.successMessage = successMessage;
	}
}
