import {NgClass} from '@angular/common';
import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation, WritableSignal, forwardRef, signal} from '@angular/core';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {AsyncUtils} from 'src/app/utils/async-utils';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {App} from 'src/app/app';
import {Modal} from 'src/app/modal';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {UnoOptionIconMobileModal} from './uno-options-icon-modal/uno-options-icon-modal.component';


export class UnoOptionIconData {
	/**
	 * Value of the option
	 */
	public value: string;

	/**
	 * Icon of the option
	 */
	public icon: string;

	/**
	 * Label displayed in the options
	 */
	public label: string;

	/**
	 * If applicable, sublabel displayed in the options
	 */
	public sublabel?: string;
}

/**
 * Uno Options Icon Component
 * 
 * @param disabled - Allows the input to be disabled.
 * @param options - Available options in dropdown.
 * @param width - Width of the dropdown
 * @param selectedOption - Currently selected option
 */
@Component({
	selector: 'uno-options-icon',
	templateUrl: './uno-options-icon.component.html',
	styleUrls: ['./uno-options-icon.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoOptionsIcon; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CdkConnectedOverlay, CdkOverlayOrigin, NgClass, UnoIconComponent, CdkVirtualScrollViewport, CdkVirtualForOf, CdkFixedSizeVirtualScroll]
})
export class UnoOptionsIcon {
	public app: any = App;

	/**
	 * Instance of the scrollable Viewport
	 */
	@ViewChild('viewport', {static: false})
	private viewport: CdkVirtualScrollViewport;

	/**
	 * Template for the mobile modal
	 */
	@ViewChild('mobileModal') 
	private mobileModal: TemplateRef<unknown>;


	/**
	 * Indicates if the input is disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Available options in the dropdown
	 */
	@Input()
	public options: UnoOptionIconData[];

	/**
	 * Width of the dropdown
	 */
	@Input()
	public width: number;

	/**
	 * Heigh of the input
	 */
	@Input()
	public height: number = 42;

	/**
	 * Heigh of the dropdown itself
	 */
	@Input()
	public dropdownHeight: number = 140;

	/**
	 * Indicates if input has border
	 */
	@Input()
	public border: boolean = true;

	/**
	 * Currently selected dropdown option
	 */
	@Input()
	public selectedOption: UnoOptionIconData;

	/**
	 * Emits event when  option value is changed.
	 */
	@Output() 
	public valueChange = new EventEmitter<UnoOptionIconData>();

	/**
	 * Flag to indicate if the options selection is open.
	 */
	public expanded: WritableSignal<boolean> = signal(false);
    
	/**
	 * Flag to indicate if the options selection is closing.
	 */
	public closing: WritableSignal<boolean> = signal(false);

	/**
	 * Flag to indicate if the modal is open.
	 */
	public modalOpen: WritableSignal<boolean> = signal(false);

	/**
	 * Method used to show and hide the options when clicking the selector.
	 */
	public async toggle(): Promise<void> {
		if (this.app.device.isMobile()) {
			if (this.modalOpen()) {
				this.modalOpen.set(false);
			} else {
				this.modalOpen.set(true);
				const index: number = this.options.findIndex((elem) => {return elem.value === this.selectedOption.value;});
				await Modal.component(UnoOptionIconMobileModal, {
					template: this.mobileModal,
					selectedOptionIndex: index
				});	
			}
		} else {
			if (this.closing()) {
				setTimeout(() => {
					this.expanded.set(false);
		
					this.closing.set(false);
				}, 300);
			} else {
				this.expanded.set(true);
				await AsyncUtils.await(100);
				const index: number = this.options.findIndex((elem) => {return elem.value === this.selectedOption.value;});
				this.viewport.scrollToIndex(index);
				this.closing.set(true);
			}
		}
		
	}
	
	/**
	 * Method that saves the value from options menu
	 * 
	 * @param option - Selected option from menu
	 */
	public async writeValue(option: UnoOptionIconData): Promise<void> {
		this.closing.set(true);
		await this.toggle();
			
		this.selectedOption = option;
		this.valueChange.emit(this.selectedOption);
	}

	/**
	 * Method that dismiss modal and saves the value from the options menu
	 * 
	 * @param event - Click event to get the parent modal div used for dismiss
	 * @param option - Selected option from modal
	 */
	public dismissAndWriteValue(event: any, option: UnoOptionIconData): void {
		event.target.offsetParent.dismiss();
		this.writeValue(option);
	}

}

