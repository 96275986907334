import {UUID, UUIDIdentification} from 'src/app/models/uuid';

/**
 * Object that represents a fluid family.
 */
export class AssetFluidFamily extends UUIDIdentification {
	/**
	 * Label shown to the user.
	 */
	public label: string = null;

	/**
	 * Fluid Types key(uuid) and value(Label) for this family.
	 */
	public fluidTypes: {key: UUID, value: string}[] = [];

	/**
	 * Parse JSON data received from the API into an object.
	 *
	 * @param data - Object received from API.
	 * @returns Asset fluid family object instance built from the data received.
	 */
	public static parse(data: any): AssetFluidFamily {
		const family = new AssetFluidFamily();

		family.createdAt = new Date(data.createdAt);
		family.updatedAt = new Date(data.updatedAt);

		family.uuid = data.uuid;
		family.label = data.label;

		return family;
	}
}
