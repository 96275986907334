import {Pipe, PipeTransform} from '@angular/core';
import {UnoFormUtils} from '../uno-form-utils';
import {UnoFormField} from '../uno-form-field';

/**
 * Pipe to retrieve the value of an object based on its form field attribute.
 */
@Pipe({
	name: 'formValue',
	standalone: true,
	pure: false
})
export class UnoFormAttributePipe implements PipeTransform {
	public transform(row: UnoFormField, object: any, subAttribute?: string): any {
		return UnoFormUtils.getAttribute(object, row, subAttribute);
	}
}
