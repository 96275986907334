import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {RepairNDT} from '../../../master-data/repair-ndt';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {RepairNDTLayout} from './repair-ndt-layout';

@Component({
	selector: 'masterdata-repair-ndt-page',
	templateUrl: 'repair-ndt.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})
export class RepairNdtPage extends ScreenComponent implements OnInit {
	public layout: any = RepairNDTLayout;


	public permissions = [UserPermissions.REPAIR_NDT_EDIT];

	/**
	 * List of repair NDT available in database.
	 */
	public ndts: RepairNDT[] = [];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('nonDestructiveTests');

		RepairNDT.load().then((ndts: RepairNDT[]) => {
			this.ndts = ndts;
		});
	}

	/**
	 * Add a NDT test to the list of NDT.
	 */
	public add(): void {
		this.ndts.push(new RepairNDT());
	}

	/**
	 * Delete the NDT from the list.
	 *
	 * @param ndt - Element to be deleted from list.
	 */
	public delete(ndt: RepairNDT): void {
		Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.repairs.ndt.delete, null, null, {id: ndt.id}, Session.session, (response, xhr) => {
					const index = this.ndts.indexOf(ndt);
					if (index !== -1) {
						this.ndts.splice(index, 1);
					}
				});
			}
		});
	}

	/**
	 * Update the checklist value in the server.
	 */
	public save(): void {
		const data = structuredClone(this.ndts);

		Modal.confirm(Locale.get('confirm'), Locale.get('masterdataConfirm')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.repairs.ndt.updateBulk, null, null, data, Session.session, (response, xhr) => {
					Modal.toast(Locale.get('updatedSuccessfully'));
					App.navigator.pop();
				});
			}
		});
	}

}
