import {LocaleList} from 'src/app/locale/locale';
import {EnumUtils} from 'src/app/utils/enum-utils';
import {MapStyles, MapStylesLabel} from 'src/app/theme/map-styles';
import {Themes} from 'src/app/theme/themes';
import {UnoFormFieldTypes} from '../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../components/uno-forms/uno-form/uno-form-field';
import {PictureFormat} from './data/picture-format';
import {DateFormat, DateFormatLabel} from './data/date-format';
import {TimeFormat, TimeFormatLabel} from './data/time-format';
import {UIScale} from './data/ui-scale';

export class SettingsLayout {
	/**
	 * Base settings layout.
	 */
	public static settings: UnoFormField[] = [
		{
			attribute: 'locale',
			label: 'language',
			type: UnoFormFieldTypes.OPTIONS,
			isActive: true,
			options: LocaleList.map((locale) => {return {label: locale.name, value: locale.code};})
		},
		{
			attribute: 'dateFormat',
			label: 'dateFormat',
			type: UnoFormFieldTypes.OPTIONS,
			isActive: true,
			options: EnumUtils.values(DateFormat).map((format: any) => {
				return {
					label: DateFormatLabel.get(format),
					value: format
				};
			})
		},
		{
			attribute: 'timeFormat',
			label: 'timeFormat',
			type: UnoFormFieldTypes.OPTIONS,
			isActive: true,
			options: EnumUtils.values(TimeFormat).map((format: any) => {
				return {
					label: TimeFormatLabel.get(format),
					value: format
				};
			})
		},
		{
			attribute: 'keepFilters',
			label: 'keepFilters',
			type: UnoFormFieldTypes.CHECKBOX,
			isActive: true
		},

		{
			attribute: 'keepExternalWindow',
			label: 'keepExternalWindow',
			type: UnoFormFieldTypes.CHECKBOX,
			isActive: true
		},

		{
			attribute: 'showListPictures',
			label: 'showListPictures',
			type: UnoFormFieldTypes.CHECKBOX,
			isActive: true
		},
		{
			attribute: 'pictureMaxSize',
			label: 'pictureMaxSize',
			type: UnoFormFieldTypes.NUMBER,
			isActive: true
		},
		{
			attribute: 'pictureFormat',
			label: 'pictureFormat',
			type: UnoFormFieldTypes.OPTIONS,
			isActive: true,
			options: EnumUtils.values(PictureFormat).map((format: string) => {
				return {
					label: format.toUpperCase(),
					value: format
				};
			})
		},
		{
			attribute: 'scale',
			label: 'scale',
			type: UnoFormFieldTypes.OPTIONS,
			isActive: true,
			options: EnumUtils.values(UIScale).map(function(scale: any) {
				return {
					label: scale * 100 + '%',
					value: scale
				};
			})
		},
		{
			attribute: 'theme',
			label: 'theme',
			type: UnoFormFieldTypes.OPTIONS,
			isActive: true,
			options: Object.values(Themes).map(function(theme: any) {
				return {
					label: theme.label,
					value: theme.id
				};
			})
		},
		{
			attribute: 'mapStyle',
			label: 'mapStyle',
			type: UnoFormFieldTypes.OPTIONS,
			isActive: true,
			options: Object.values(MapStyles).map(function(style: any) {
				return {
					label: MapStylesLabel.get(style),
					value: style
				};
			})
		}
	];

	/**
	 * Layout with information about the application build.
	 */
	public static buildInfo: UnoFormField[] = [
		{
			attribute: 'VERSION',
			label: 'version',
			type: UnoFormFieldTypes.TEXT,
			isActive: true,
			editable: false
		},
		{
			attribute: 'TIMESTAMP',
			label: 'timestamp',
			type: UnoFormFieldTypes.TEXT,
			isActive: true,
			editable: false
		},
		{
			attribute: 'COMMIT',
			label: 'commit',
			type: UnoFormFieldTypes.TEXT,
			isActive: true,
			editable: false
		},
		{
			attribute: 'BRANCH',
			label: 'branch',
			type: UnoFormFieldTypes.TEXT,
			isActive: true,
			editable: false
		},
		{
			attribute: 'PRODUCTION',
			label: 'production',
			type: UnoFormFieldTypes.CHECKBOX,
			isActive: true,
			editable: false
		},
		{
			attribute: 'CLIENT_ID',
			label: 'client',
			type: UnoFormFieldTypes.TEXT,
			isActive: true,
			editable: false
		}
	];
}
