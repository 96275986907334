import {Component, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'uno-title',
	templateUrl: './uno-title.component.html',
	styleUrls: ['./uno-title.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true
})
export class UnoTitleComponent {}
