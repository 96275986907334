import {SortDirection} from 'src/app/utils/sort-direction';
import {ServiceList} from '../../../http/service-list';
import {Service} from '../../../http/service';
import {Session} from '../../../session';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {CompanyService} from '../services/companies.service';

/**
 * Form used to edit company entries.
 */
export const CompanyFormLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		attribute: 'name',
		required: true,
		type: UnoFormFieldTypes.TEXT
	},
	{
		attribute: 'email',
		required: true,
		type: UnoFormFieldTypes.EMAIL
	},
	{
		attribute: 'vat',
		type: UnoFormFieldTypes.TEXT
	},
	{
		attribute: 'phoneNumber',
		type: UnoFormFieldTypes.PHONE_NUMBER
	},
	{
		attribute: 'address',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		attribute: 'representative',
		type: UnoFormFieldTypes.TEXT
	},
	{
		attribute: 'notes',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		attribute: 'picture',
		type: UnoFormFieldTypes.IMAGE_RESOURCE
	},
	{
		required: false,
		attribute: 'parent',
		label: 'parentCompany',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: any, object: any): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data: any = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[company].[name]',
				sortDirection: SortDirection.ASC
			};

			if (object.uuid) {
				data.uuid = object.uuid;
			}

			const req = await Service.fetch(ServiceList.company.listAllowedParents, null, null, data, Session.session);
			return {options: req.response.companies, hasMore: req.response.hasMore, id: req.id};
		},
		fetchOptionsBatch: async function(request: any): Promise<{options: any[]}> {
			if (request.options.length === 0) {
				return {options: []};
			}

			const company = await CompanyService.get(request.options[0]);
			return {options: [company]};
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	}
];
