import {Marker} from './objects/marker';
import {PointCloud} from './objects/pointcloud';
import {Box} from './objects/box';

/**
 * Object types available in the digital twin.
 */
export const DigitalTwinObjectType = {
	MARKER: 'Marker',
	POINTCLOUD: 'PointCloud',
	BOX: 'Box'
};

/**
 * Icons to use for each object type.
 */
export const DigitalTwinObjectTypeIcon: Map<string, string> = new Map<string, string>([
	[DigitalTwinObjectType.MARKER, '/assets/icons/3d/location.svg'],
	[DigitalTwinObjectType.POINTCLOUD, '/assets/icons/3d/cloud.svg'],
	[DigitalTwinObjectType.BOX, '/assets/icons/3d/box.svg']
]);


/**
 * Map of object constructors for each object type.
 */
export const DigitalTwinObjectTypeConstructors: Map<string, Function> = new Map<string, Function>([
	[DigitalTwinObjectType.MARKER, Marker],
	[DigitalTwinObjectType.POINTCLOUD, PointCloud],
	[DigitalTwinObjectType.BOX, Box]
]);
