import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Object3D} from 'three';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../../../../utils/css-ng-style';
import {DigitalTwinEditor} from '../../editor/digital-twin-editor';
import {DigitalTwinTreeObjectComponent} from './digital-twin-object-tree-object/digital-twin-tree-object.component';

@Component({
	selector: 'digital-twin-tree',
	templateUrl: './digital-twin-tree.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./digital-twin-tree.component.css'],
	standalone: true,
	imports: [NgStyle, DigitalTwinTreeObjectComponent, IonicModule]
})
export class DigitalTwinTreeComponent implements OnInit {

	/**
	 * Style to apply to the element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Editor where the objects are being edited.
	 */
	@Input()
	public editor: DigitalTwinEditor = null;

	/**
	 * Digital twin editor object.
	 */
	@Input()
	public objects: Object3D[] = null;

	/**
	 * Indicates if the tree is collapsed or visible.
	 */
	@Input()
	public collapsed: boolean = true;

	/**
	 * Width of the tree component in px;
	 */
	public width: number = 300;

	public ngOnInit(): void {
		this.updateStyle();
	}

	/**
	 * Toggle the collapsed state of the tree.
	 */
	public toggleCollapsed(): void {
		this.collapsed = !this.collapsed;
		this.updateStyle();
	}

	/**
	 * Update style of the element.
	 */
	public updateStyle(): void {
		this.width = this.collapsed ? 0 : 300;
		this.ngStyle.display = this.collapsed ? 'none' : 'block';
		this.ngStyle.width = this.width + 'px';
	}
}
