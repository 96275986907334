import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {DoughnutChartComponent, DoughnutChartData} from 'src/app/components/charts/doughnut-chart/doughnut-chart.component';
import {ServiceList} from 'src/app/http/service-list';
import {Locale} from 'src/app/locale/locale';
import {Service} from 'src/app/http/service';
import {Session} from 'src/app/session';
import {CSSUtils} from 'src/app/utils/css-utils';
import {DL50InspectionConclusion} from 'src/app/models/dl50/dl50-inspection-conclusion';

@Component({
	selector: 'dl50-inspections-conclusion-graph',
	templateUrl: './dl50-inspections-conclusion-graph.component.html',
	standalone: true,
	imports: [IonicModule, DoughnutChartComponent, NgStyle, TranslateModule]
})
export class DL50InspectionsConclusionGraphComponent implements OnInit {
	public data: DoughnutChartData[] = [];

	public async ngOnInit(): Promise<void> {
		const request = await Service.fetch(ServiceList.dl50.inspections.countByConclusion, null, null, null, Session.session);
		
		this.data = [
			new DoughnutChartData(Locale.get('gapsOk'), request.response.results.find((c: {conclusion: number, count: number}) => { return c.conclusion === DL50InspectionConclusion.OK; })?.count, CSSUtils.parseColor('rgb(75, 192, 104)')),
			new DoughnutChartData(Locale.get('gapsLight'), request.response.results.find((c: {conclusion: number, count: number}) => { return c.conclusion === DL50InspectionConclusion.GAPS_LIGHT; })?.count, CSSUtils.parseColor('rgb(255, 206, 86)')),
			new DoughnutChartData(Locale.get('gapsSevere'), request.response.results.find((c: {conclusion: number, count: number}) => { return c.conclusion === DL50InspectionConclusion.GAPS_SEVERE; })?.count, CSSUtils.parseColor('rgb(255, 153, 0)')),
			new DoughnutChartData(Locale.get('gapsNok'), request.response.results.find((c: {conclusion: number, count: number}) => { return c.conclusion === DL50InspectionConclusion.NOK; })?.count, CSSUtils.parseColor('rgb(255, 99, 132)'))
		];
	}
}

