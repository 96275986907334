import {Pipe, PipeTransform} from '@angular/core';
import {Session} from '../session';

/**
 * Pipe to check if the user logged into the application has a set of permissions.
 */
@Pipe({
	name: 'hasPermissions',
	standalone: true,
	pure: true
})
export class PermissionsPipe implements PipeTransform {
	public transform(permissions: number|number[]): boolean {
		return Session.isValid() && Session.hasPermissions(permissions);
	}
}
