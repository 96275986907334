/**
 * Utils to read files using file reader with async events.
 *
 * Alternative to traditional FileReader object.
 */
export class FileReaderAsync {
	/**
	 * Read file as ArrayBuffer data.
	 *
	 * @param file - File to be read.
	 * @returns File content as ArrayBuffer
	 */
	public static async readAsArrayBuffer(file: File): Promise<ArrayBuffer> {
		return new Promise(function(resolve, reject) {
			const reader = new FileReader();
			reader.onload = function() {
				resolve(reader.result as ArrayBuffer);
			};
			reader.onerror = function() {
				reject(reader.error);
			};
			reader.readAsArrayBuffer(file);
		});
	}

	/**
	 * Read file as string data.
	 *
	 * @param file - File to be read.
	 * @param encoding - File encoding. By default, UTF-8 is used.
	 * @returns File content as string
	 */
	public static async readAsText(file: File, encoding: string = 'UTF-8'): Promise<string> {
		return new Promise(function(resolve, reject) {
			const reader = new FileReader();
			reader.onload = function() {
				resolve(reader.result as string);
			};
			reader.onerror = function() {
				reject(reader.error);
			};
			reader.readAsText(file);
		});
	}

	/**
	 * Read a file object as a Data URL.
	 *
	 * @param file - File object can be retrieved.
	 * @returns File data url.
	 */
	public static async readAsDataURL(file: File): Promise<any> {
		return new Promise<any>(function(resolve, reject) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function() {
				resolve(reader.result);
			};
			reader.onerror = function() {
				reject(reader.error);
			};
		});
	}
}
