import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {AssetPortfolioFiterBarFields} from 'src/app/modules/asset-portfolio/asset-portfolio-filter-bar-fields';
import {InspectionsFilterBarFields} from 'src/app/modules/inspections/inspections-filter-bar-fields';
import {UnoOptionsLazyComponent} from '../../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {GAActionPlanStatus, GAActionPlanStatusLabel} from '../../../../../models/gap-analysis/action-plans/action-plan-status';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {GAActionPlanCountParams, GAActionPlanListParams, GapAnalysisService} from '../../../services/gap-analysis.service';

@Component({
	selector: 'gap-analysis-action-plan-list-page',
	templateUrl: 'action-plan-list.page.html',
	styleUrls: ['./action-plan-list.page.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoOptionsLazyComponent,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent
	]
})
export class GAActionPlanListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public selfStatic: any = GAActionPlanListPage;

	/**
	 * Possible database filter to be used for ordering the action plan list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[ga_action_plan].[updated_at]',
			options: [
				{label: 'updatedAt', value: '[ga_action_plan].[updated_at]'},
				{label: 'createdAt', value: '[ga_action_plan].[created_at]'},
				{label: 'status', value: '[ga_action_plan].[status]'},
				{label: 'criticality', value: '[ga_action_plan].[criticality]'},
				{label: 'description', value: '[ga_action_plan].[description]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[ga_action_plan].[id]', '[ga_action_plan].[description]', '[ga_action_plan].[work_order]', '[ga_gap].[description]', '[ap_asset].[name]', '[ap_asset].[tag]', '[ap_asset].[description]', '[inspection_project].[name]'],
			multiple: true,
			options: [
				{label: 'actionPlanUuid', value: '[ga_action_plan].[id]'},
				{label: 'actionPlanDescription', value: '[ga_action_plan].[description]'},
				{label: 'workOrder', value: '[ga_action_plan].[work_order]'},
				{label: 'gapUuid', value: '[ga_gap].[id]'},
				{label: 'gapDescription', value: '[ga_gap].[description]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'assetDescription', value: '[ap_asset].[description]'},
				{label: 'fieldUuid', value: '[ga_gap].[inspection_field_path]'},
				{label: 'projectUuid', value: '[inspection_project].[id]'},
				{label: 'projectName', value: '[inspection_project].[name]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'status',
			label: 'status',
			default: GAActionPlanStatus.ALL,
			options: Array.from(GAActionPlanStatusLabel.keys()).map((value) => {return {label: GAActionPlanStatusLabel.get(value), value: value};})
		},
		UnoFilterBarOption.copy(AssetPortfolioFiterBarFields.assetField),
		UnoFilterBarOption.copy(InspectionsFilterBarFields.inspectionField)
	];

	/**
	 * The filters to apply to the list.
	 */
	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter items by.
		 */
		search: '',

		/**
		 * Search fields/attributes to be considered on search.
		 */
		searchFields: [],

		/**
		 * Attribute used to sort the items on the list.
		 */
		sortField: '',

		/**
		 * Sort direction applied to the list.
		 */
		sortDirection: '',
			
		/**
		 * The action plan status to filter the list by.
		*/
		status: GAActionPlanStatus.ALL,

		/**
		 * The UUID of the inspection project to filter the list by.
		 */
		inspectionProjectUuid: null,

		/**
		 * The UUID of the action plan asset to filter the list by.
		 */
		assetUuid: null
	}, GAActionPlanListPage.filterOptions);

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 100;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'medium', tag: ListDisplayStyle.TITLE},
		{header: 'status', type: UnoTableColumnType.TEXT, attribute: 'status', visible: true, size: 'small', sortBy: '[ga_action_plan].[status]', tag: ListDisplayStyle.TEXT},
		{header: 'projects', type: UnoTableColumnType.TEXT, attribute: 'projects', visible: true, size: 'large'},
		{header: 'assets', type: UnoTableColumnType.TEXT, attribute: 'assets', visible: true, size: 'large'}
	];

	public ngOnInit(): void {
		super.ngOnInit();

		App.navigator.setTitle('actionPlans');

		const data = App.navigator.getData();

		if (data && !GAActionPlanStatusLabel.has(data.status)) {
			App.navigator.pop();
			return;
		}

		// Set the list status filter
		GAActionPlanListPage.filters.status = data.status;
	}

	public loadTableItems = async(count: number, pageSize: number, sortField: string, sortDirection: string): Promise<any> => {
		const params: GAActionPlanListParams = {
			from: count,
			count: pageSize,
			search: GAActionPlanListPage.filters.search,
			searchFields: GAActionPlanListPage.filters.searchFields,
			sortField: sortField || GAActionPlanListPage.filters.sortField,
			sortDirection: sortDirection || GAActionPlanListPage.filters.sortDirection,
			status: GAActionPlanListPage.filters.status,
			assetUuid: GAActionPlanListPage.filters.assetUuid,
			inspectionProjectUuid: GAActionPlanListPage.filters.inspectionProjectUuid
		};

		const list = await GapAnalysisService.listActionPlans(params);
		list.actionPlans.forEach((actionPlan) => {
			actionPlan.status = GAActionPlanStatusLabel.get(actionPlan.status);
			actionPlan.projects = actionPlan.projects ? actionPlan.projects.map((project) => {return project.name;}).toString().replace(/,/g, ', ') : '';
			actionPlan.assets = actionPlan.assets ? actionPlan.assets.map((asset) => {return asset.name;}).toString().replace(/,/g, ', ') : '';
		});

		return {
			elements: list.actionPlans,
			hasMore: list.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value.
	 */
	public async onSearchChange(search: string): Promise<void> {
		GAActionPlanListPage.filters.search = search;
		await this.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		GAActionPlanListPage.filters = filters;
		await this.reset();
	}

	/**
	 * Reset the table.
	 */
	public async reset(): Promise<void> {
		const params: GAActionPlanCountParams = {
			search: GAActionPlanListPage.filters.search,
			searchFields: GAActionPlanListPage.filters.searchFields,
			status: GAActionPlanListPage.filters.status,
			assetUuid: GAActionPlanListPage.filters.assetUuid,
			inspectionProjectUuid: GAActionPlanListPage.filters.inspectionProjectUuid
		};

		this.table.sortDirection = GAActionPlanListPage.filters.sortDirection;
		this.table.sortField = GAActionPlanListPage.filters.sortField;
		this.tableTotalItemsCount = await GapAnalysisService.countActionPlans(params);

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * When the user changes the sort using the table.
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === GAActionPlanListPage.filters.sortField) {
			GAActionPlanListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			GAActionPlanListPage.filters.sortField = sortBy;
			GAActionPlanListPage.filters.sortDirection = SortDirection.ASC;
		}

		await this.reset();
	}
}
