import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {EPIPage} from './screens/menu/epi.page';
import {EPIListPage} from './screens/list/epi-list.page';
import {EPIEditPage} from './screens/edit/epi-edit.page';

const routes: Routes = [
	{
		path: '',
		component: EPIPage
	},
	{
		path: 'list',
		component: EPIListPage
	},
	{
		path: 'edit',
		component: EPIEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EPIRouterModule { }
