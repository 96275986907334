import {Component, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormComponent} from '../../../../../../components/uno-forms/uno-form/uno-form.component';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {UnoFormField} from '../../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {AtexInspectionSettings} from '../../../data/master-data/atex-inspection-settings';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {AtexInspectionSettingsService} from '../../../services/atex-inspection-settings.service';
import {AtexInspectionSettingsLayout} from './atex-inspections-settings-layout';

@Component({
	selector: 'masterdata-atex-inspections-settings-page',
	templateUrl: 'atex-inspections-settings.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})
export class AtexInspectionsSettingsPage extends ScreenComponent {

	public get settingsLayout(): UnoFormField[] { return AtexInspectionSettingsLayout.layout; }
	
	@ViewChild('settingsForm', {static: false})
	public settingsForm: UnoFormComponent = null;

	/**
	 * Atex Inspection dashboard settings settings
	 */
	public settings: AtexInspectionSettings = null;
	
	public permissions = [UserPermissions.ATEX_INSPECTION_SETTINGS_EDIT];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('settings');

		this.settings = await AtexInspectionSettingsService.get();
	}

	/**
	 * Update the information on server
	 * 
	 * @param stayOnPage - If true it stays on the page after update.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		await Service.fetch(ServiceList.atex.inspection.settingsUpdate, null, null, this.settings, Session.session);
	
		Modal.toast(Locale.get('settingsUpdated'));

		if (!stayOnPage) {
			App.navigator.pop();
		}
	}
}
