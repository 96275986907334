import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {Locale} from 'src/app/locale/locale';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {ObservationStatus, ObservationStatusLabel} from '../../../../models/observations/observation-status';
import {UnoFilterBarComponent} from '../../../../components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoListComponent} from '../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {ObservationService, ObservationServiceListResponse} from '../../services/observation.service';

@Component({
	selector: 'observations-list-page',
	templateUrl: 'observations-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		UnoFilterBarComponent,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoResponsiveTableListComponent
	]
})
export class ObservationsListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public selfStatic: any = ObservationsListPage;

	public permissions = [UserPermissions.OBSERVATIONS];

	/**
	 * Possible database filter to be used for ordering the user roles list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		// Sort direction applied to the loaded list from database.
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		// Database attribute name used to sort the values.
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[observation].[updated_at]',
			options: [
				{label: 'updatedAt', value: '[observation].[updated_at]'},
				{label: 'createdAt', value: '[observation].[created_at]'},
				{label: 'name', value: '[observation].[name]'},
				{label: 'description', value: '[observation].[description]'}
			]
		},
		// Search fields to be considered.
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[observation].[name]'],
			options: [
				{label: 'name', value: '[observation].[name]'},
				{label: 'description', value: '[observation].[description]'},
				{label: 'uuid', value: '[observation].[id]'}
			]
		}
	];

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: '[observation].[name]', tag: ListDisplayStyle.TITLE},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'medium', sortBy: '[observation].[description]', tag: ListDisplayStyle.TEXT},
		{header: 'status', type: UnoTableColumnType.TEXT, attribute: 'status', visible: true, size: 'small', tag: ListDisplayStyle.TEXT}
	];

	/**
	 * Status of the observations to show in this list, received from page data.
	 */
	public status: number = 0;

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 100;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],
		
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: ''
	}, ObservationsListPage.filterOptions);

	public static defaultFilters = structuredClone(ObservationsListPage.filters);

	public async resetFilters(): Promise<void> {
		UnoFilterBarComponent.reset(ObservationsListPage.filters, ObservationsListPage.filterOptions);

		if (this.table) {
			await this.table.reset();
		}
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.status = ObservationStatus.ALL;

		const data = App.navigator.getData();
		if (data !== null && data.status !== undefined) {
			this.status = data.status;
		}

		App.navigator.setTitle(this.status !== ObservationStatus.ALL ? ObservationStatusLabel.get(this.status) : 'list');
	}

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params = {
			status: this.status,
			from: count,
			count: pageSize,
			search: ObservationsListPage.filters.search,
			searchFields: ObservationsListPage.filters.searchFields,
			sortDirection: ObservationsListPage.filters.sortDirection,
			sortField: ObservationsListPage.filters.sortField
		};

		const list: ObservationServiceListResponse = await ObservationService.list(params);

		const observations: any[] = list.observations;

		for (const observation of observations) {
			observation.status = Locale.get(ObservationStatusLabel.get(observation.status));
		}

		return {
			elements: observations,
			hasMore: list.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public async onSearchChange(search: string): Promise<void> {
		ObservationsListPage.filters.search = search;
		const params = {
			status: this.status,
			search: ObservationsListPage.filters.search,
			searchFields: ObservationsListPage.filters.searchFields
		};

		this.tableTotalItemsCount = await ObservationService.count(params);

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param filters - Filters
	 */
	public async onFilterChange(filters: any): Promise<void> {
		ObservationsListPage.filters = filters;
		const params = {
			status: this.status,
			search: ObservationsListPage.filters.search,
			searchFields: ObservationsListPage.filters.searchFields
		};

		this.tableTotalItemsCount = await ObservationService.count(params);

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * When the table emits a value to change which column to sort by.
	 * 
	 * @param attribute - The attribute to sort by.
	 */
	public async sortChanged(attribute: string): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (attribute === ObservationsListPage.filters.sortField) {
			ObservationsListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			ObservationsListPage.filters.sortField = attribute;
			ObservationsListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}
}
