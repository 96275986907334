import {ArraybufferUtils} from './binary/arraybuffer-utils';

/**
 * File utils contains file manipulation utils.
 */
export class FileUtils {
	/**
	 * Check if a file corresponds to a local location.
	 *
	 * @returns If the file is local returns true, false otherwise.
	 */
	public static isLocalFile(url: string): boolean {
		return !(url.startsWith('http') || url.startsWith('blob') || url.startsWith('data'));
	}

	/**
	 * Read file data from URL, using XHR.
	 *
	 * @param fname - File URL.
	 * @param responseType - Type of response to be used for the XHR.
	 * @returns Data read if in sync mode.
	 */
	public static async readFile(fname: string, responseType?: XMLHttpRequestResponseType): Promise<string> {
		return new Promise<string>((resolve: Function, reject: Function) => {
			const file = new XMLHttpRequest();
			file.overrideMimeType('text/plain');
			file.open('GET', fname, true);

			if (responseType !== undefined) {
				file.responseType = responseType;
			}

			file.onload = function() {
				resolve(file.response);
			};

			file.onerror = function() {
				reject();
			};

			file.send(null);
		});
	}

	/**
	 * Read a local or remote file as arraybuffer data.
	 *
	 * @param fname - Path or URL of the file being read.
	 * @returns File data as array buffer or Nodejs buffer.
	 */
	public static async readFileArrayBuffer(fname: string): Promise<ArrayBuffer> {
		return new Promise<ArrayBuffer>((resolve: Function, reject: Function) => {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', fname, true);
			xhr.overrideMimeType('text/plain; charset=x-user-defined');
			
			xhr.onload = function() {
				resolve(ArraybufferUtils.fromBinaryString(xhr.response));
			};

			xhr.onerror = function() {
				reject();
			};

			xhr.send(null);
		});
	}
	

	/**
	 * Download file from URL into the browser
	 *
	 * Creates a DOM element and triggers the download by click action.
	 *
	 * @param file - URL to download the file, can be blob or external URL.
	 * @param fname - Name of the file to be written into disk.
	 */
	public static download(file: (string | Blob), fname?: string): void {
		const link = document.createElement('a');
		if (fname) {
			link.download = fname;
		}
		link.target = '_blank';

		if (file instanceof Blob) {
			link.href = window.URL.createObjectURL(file);
		} else {
			link.href = file;
		}
		
		link.style.display = 'none';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	/**
	 * Write a file to a blob and download it to the client.
	 *
	 * @param fname - File name.
	 * @param data - Data to be written into the file.
	 */
	public static writeFile(fname: string, data: any): void {
		if (typeof data === 'object') {
			data = JSON.stringify(data, null, '\t');
		}

		const blob = new Blob([data], {type: 'octet/stream'});

		FileUtils.download(window.URL.createObjectURL(blob), fname);
	}

	/**
	 * Write a base64 encoded file to a blob and download it to the client.
	 *
	 * @param fname - File name.
	 * @param data - Data to be written into the file.
	 */
	public static writeFileBase64(fname: string, data: string): void {
		if (typeof data === 'object') {
			data = JSON.stringify(data, null, '\t');
		}

		const array = ArraybufferUtils.fromBase64(data);
		const blob = new Blob([array]);

		FileUtils.download(window.URL.createObjectURL(blob), fname);
	}

	/**
	 * Write binary file using array buffer data.
	 *
	 * @param fname - File name
	 * @param data - Data to be written
	 */
	public static writeFileArrayBuffer(fname: string, data: ArrayBuffer): void {
		const blob = new Blob([data]);

		FileUtils.download(window.URL.createObjectURL(blob), fname);
	}

	/**
	 * Open file chooser dialog to select a file from the host system.
	 *
	 * @param filter - Filter files by their type, can be by extension, media type or IANA types (e.g. ".docx,.doc,.pdf", "audio/*").
	 * @param multiFile - If true the chooser will accept multiple files.
	 */
	public static async chooseFile(filter?: string, multiFile: boolean = false): Promise<File[]> {
		return new Promise(function(resolve, reject) {
			const chooser = document.createElement('input');
			chooser.type = 'file';
			chooser.style.display = 'none';
			document.body.appendChild(chooser);

			if (filter !== undefined) {
				chooser.accept = filter;
			}

			chooser.multiple = multiFile === true;

			chooser.onchange = function(event) {
				// @ts-ignore
				resolve(chooser.files);
				document.body.removeChild(chooser);
			};

			chooser.onerror = function(event) {
				reject();
			};

			chooser.click();
		});
	}

	/**
	 * Get file name without extension from file path string.
	 *
	 * If input is a/b/c/abc.d output is abc.
	 *
	 * @param file - File path
	 * @returns File name without path and extension
	 */
	public static getFileName(file: (string | File)): string {
		if (file) {
			if (file instanceof File) {
				file = file.name;
			}

			const a = file.lastIndexOf('\\');
			const b = file.lastIndexOf('/');

			return file.substring(a > b ? a + 1 : b + 1, file.lastIndexOf('.'));
		}

		return '';
	}

	/**
	 * Get file extension from file path string.
	 *
	 * If input is a/b/c/abc.d output is d.
	 *
	 * @param file - File path or file object to get the extension from.
	 * @returns File extension.
	 */
	public static getFileExtension(file: (string | File)): string {
		if (file) {
			if (file instanceof File) {
				file = file.name;
			}

			return file.substring(file.lastIndexOf('.') + 1, file.length);
		}

		return '';
	}
}


