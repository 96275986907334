import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {ActionPlanPriorityLabel} from 'src/app/models/atex-inspections/action-plan/action-plan-priority';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {App} from '../../../../../app';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {ActionPlanState, ActionPlanStateLabel} from '../../../../../models/atex-inspections/action-plan/action-plan-state';
import {Locale} from '../../../../../locale/locale';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {UnoListLazyLoadHandler} from '../../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoIconComponent} from '../../../../../components/uno/uno-icon/uno-icon.component';
import {ActionPlanService, AtexActionPlanCountParams, AtexActionPlanListParams} from '../../services/action-plan.service';

@Component({
	selector: 'action-plan-list-page',
	templateUrl: 'action-plan-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent,
		UnoIconComponent
	]
})
export class ActionPlanListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public selfStatic: any = ActionPlanListPage;

	public actionPlanStateLabel: any = ActionPlanStateLabel;

	public permissions = [UserPermissions.ACTION_PLAN];

	/**
	 * List of teams available for filter.
	 */
	public teamFilterValues: any = [{name: Locale.get('none'), uuid: ''}];

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any>;

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 0;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * The rows that are checked in the table
	 */
	public checkedTableRows: number[] = [];

	/**
	 * Possible database filter to be used for ordering the action plan list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[atex_action_plan].[updated_at]',
			options: [
				{label: 'workOrder', value: '[atex_action_plan].[work_order]'},
				{label: 'updatedAt', value: '[atex_action_plan].[updated_at]'},
				{label: 'createdAt', value: '[atex_action_plan].[created_at]'},
				{label: 'description', value: '[atex_action_plan].[description]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[atex_action_plan].[description]', 'STRING_AGG([ap_asset].[name], \'\')', 'STRING_AGG([ap_asset].[tag], \'\')'],
			options: [
				{label: 'description', value: '[atex_action_plan].[description]'},
				{label: 'workOrder', value: '[atex_action_plan].[work_order]'},
				{label: 'name', value: 'STRING_AGG([ap_asset].[name], \'\')'},
				{label: 'tag', value: 'STRING_AGG([ap_asset].[tag], \'\')'},
				{label: 'parentName', value: 'STRING_AGG([parent_asset].[name], \'\')'},
				{label: 'parentTag', value: 'STRING_AGG([parent_asset].[tag], \'\')'},
				{label: 'field', value: 'STRING_AGG([atex_ffp].[field], \'\')'},
				{label: 'actionPlanUuid', value: '[atex_action_plan].[id]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'tableFields',
			label: 'tableFields',
			default: ['workOrder', 'assetNames', 'assetTags', 'fields', 'priority', 'state', 'updatedAt', 'actions'],
			options: [
				{label: 'workOrder', value: 'workOrder'},
				{label: 'assetName', value: 'assetNames'},
				{label: 'assetTag', value: 'assetTags'},
				{label: 'fields', value: 'fields'},
				{label: 'description', value: 'description'},
				{label: 'priority', value: 'priority'},
				{label: 'status', value: 'state'},
				{label: 'updatedAt', value: 'updatedAt'},
				{label: 'actions', value: 'actions'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'state',
			label: 'status',
			default: ActionPlanState.ALL,
			options: [
				{label: 'all', value: ActionPlanState.ALL},
				{label: 'underWork', value: ActionPlanState.TODO},
				{label: 'waitingClient', value: ActionPlanState.WAITING_CLIENT_VALIDATION},
				{label: 'accepted', value: ActionPlanState.CLIENT_ACCEPTED},
				{label: 'rejected', value: ActionPlanState.CLIENT_REJECTED},
				{label: 'blocked', value: ActionPlanState.BLOCKED},
				{label: 'waitingReinspection', value: ActionPlanState.WAITING_REINSPECTION},
				{label: 'done', value: ActionPlanState.DONE},
				{label: 'archived', value: ActionPlanState.ARCHIVED}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter actionPlan by their name.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Team to filter action plan with.
		 */
		filterTeam: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],

		/**
	 	* State of the action plan being listed in this screen.
	 	*/
		state: ActionPlanState.ALL,

		/**
		 * Table fields to be shown.
		 */
		tableFields: []
	}, ActionPlanListPage.filterOptions);

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'workOrder', type: UnoTableColumnType.TEXT, attribute: 'workOrder', visible: this.headerActive('workOrder'), size: 'small', sortBy: '[atex_action_plan].[work_order]'},
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetNames', visible: this.headerActive('assetNames'), size: 'small'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTags', visible: this.headerActive('assetTags'), size: 'small'},
		{header: 'fields', type: UnoTableColumnType.TEXT, attribute: 'fields', visible: this.headerActive('fields'), size: 'small'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: this.headerActive('description'), size: 'small', sortBy: '[atex_action_plan].[description]'},
		{header: 'priority', type: UnoTableColumnType.TEXT, attribute: 'priority', visible: this.headerActive('priority'), size: 'small'},
		{header: 'state', type: UnoTableColumnType.TEXT, attribute: 'state', visible: this.headerActive('state'), size: 'small'},
		{header: 'updatedAt', type: UnoTableColumnType.DATE, attribute: 'updatedAt', visible: this.headerActive('updatedAt'), size: 'small', sortBy: '[atex_action_plan].[updated_at]'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons:
			[
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row): void => {
						App.openInTab('/menu/atex/action-plan/edit', {uuid: row.uuid});
					}
				}
			]
		}
	];

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params: AtexActionPlanListParams = {
			state: ActionPlanListPage.filters.state,
			from: count,
			count: pageSize,
			search: ActionPlanListPage.filters.search,
			sortDirection: ActionPlanListPage.filters.sortDirection,
			sortField: ActionPlanListPage.filters.sortField,
			searchFields: ActionPlanListPage.filters.searchFields,
			filterTeam: ActionPlanListPage.filters.filterTeam
		};

		const list = await ActionPlanService.list(params);
		const actionPlans: any[] = list.actionPlans;

		actionPlans.forEach((actionPlan) => {
			actionPlan.assetNames = actionPlan.assets.names;
			actionPlan.assetTags = actionPlan.assets.tags;
			actionPlan.parentAssetNames = actionPlan.parentAssets.names;
			actionPlan.parentAssetTags = actionPlan.parentAssets.tags;
			actionPlan.state = ActionPlanStateLabel.get(actionPlan.state);
			actionPlan.priority = ActionPlanPriorityLabel.get(actionPlan.priority);
		});

		return {
			elements: list.actionPlans,
			hasMore: list.hasMore
		};
	};

	public constructor(private cdref: ChangeDetectorRef) {
		super();
	}

	public ngOnInit(): void {
		super.ngOnInit();
		
		ActionPlanListPage.filters.state = ActionPlanState.ALL;

		// Read state from the route data
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		ActionPlanListPage.filters.state = data.state;
		App.navigator.setTitle('actionPlan');

		this.loadTeams();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public async onSearch(search: string): Promise<void> {
		ActionPlanListPage.filters.search = search;
		await this.reset();
	}
	
	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		ActionPlanListPage.filters = filters;
		await this.reset();
	}

	/**
	 * Reset the table and handler.
	 */
	public async reset(): Promise<void> {
		this.tableLayout.forEach((column) => {
			column.visible = this.headerActive(column.attribute);
		});

		this.table.sortDirection = ActionPlanListPage.filters.sortDirection;
		this.table.sortField = ActionPlanListPage.filters.sortField;

		const params: AtexActionPlanCountParams = {
			state: ActionPlanListPage.filters.state,
			search: ActionPlanListPage.filters.search,
			searchFields: ActionPlanListPage.filters.searchFields,
			filterTeam: ActionPlanListPage.filters.filterTeam
		};

		this.tableTotalItemsCount = await ActionPlanService.count(params);
		if (this.table) {
			await this.table.reset();
		}

		this.cdref.detectChanges();
	}

	/**
	 * Load list of teams from API to show on filter.
	 */
	public async loadTeams(): Promise<void> {
		const request = await Service.fetch(ServiceList.teams.listName, null, null, null, Session.session);

		this.teamFilterValues = [{name: Locale.get('none'), uuid: ''}].concat(request.response.teams);
	}

	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === ActionPlanListPage.filters.sortField) {
			ActionPlanListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			ActionPlanListPage.filters.sortField = sortBy;
			ActionPlanListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Check if a table header is active
	 * 
	 * @param attribute - The attribute to check if is active.
	 * @returns True if the header is active
	 */
	public headerActive(attribute: string): boolean {
		return ActionPlanListPage.filters.tableFields.indexOf(attribute) !== -1;
	}
}
