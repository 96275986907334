import {UUID} from 'src/app/models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {User} from '../../../models/users/user';

export type UserServiceListParams = {
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
	sortField?: string,
	sortDirection?: string,
	filterActive?: number,
};

export class UserService {
	/**
	 * Get a user by its UUID.
	 *
	 * @param uuid - The UUID of the user.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The user object.
	 */
	public static async get(uuid: string, hideLoading: boolean = false, displayError: boolean = true): Promise<User> {
		const request = await Service.fetch(ServiceList.users.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);

		return User.parse(request.response.user);
	}

	/**
	 * List all users meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The user objects that match the params.
	 */
	public static async listUsers(params: UserServiceListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<{hasMore: boolean, users: User[]}> {
		const request = await Service.fetch(ServiceList.users.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			users: request.response.users.map((user) => {
				return User.parse(user);
			})
		};
	}

	/**
	 * Count all users meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The number of users that match the params.
	 */
	public static async countUsers(params: UserServiceListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.users.count, null, null, params, Session.session, hideLoading, displayError);

		return request.response.count;
	}

	/**
	 * Gets multiple users in batch by their UUIDs.
	 *
	 * @param userUUIDs - The UUIDs of the users.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Array of Users that match the UUIDs.
	 */
	public static async getBatch(userUUIDs: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<User[]> {
		const request = await Service.fetch(ServiceList.users.getBatch, null, null, {users: userUUIDs}, Session.session, hideLoading, displayError);
		return request.response.users.map((t: User) => { return User.parse(t); });
	}
}
