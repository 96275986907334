import {Injectable, signal, WritableSignal} from '@angular/core';
import {EventManager} from '../utils/event-manager';

/**
 * Detect the type of device in usage and its capabilities.
 */
@Injectable({providedIn: 'root'})
export class DeviceDetector {
	/**
	 * Indicates if the application is running on mobile device.
	 */
	public isMobile: WritableSignal<boolean> = signal(!DeviceDetector.isDesktop());

	/**
	 * Indicates if the application is running on desktop based device.
	 *
	 * Some tablet devices (e.g. ipad pro) might report as desktop based devices.
	 */
	public isDesktop: WritableSignal<boolean> = signal(DeviceDetector.isDesktop());

	/**
	 * Check if NFC is available on the platform.
	 */
	public hasNFC: WritableSignal<boolean> = signal(false);

	/**
	 * Check if the device has a video camera available through the user media API.
	 */
	public hasCamera: WritableSignal<boolean> = signal(false);

	/**
	 * Event handler used to store all browser events required to detect change in the flags.
	 */
	public event: EventManager = new EventManager();

	public constructor() {
		this.event.add(window, 'resize', () => {
			const isDesktop = DeviceDetector.isDesktop();

			this.isDesktop.set(isDesktop);
			this.isMobile.set(!isDesktop);
		});

		this.checkCapabilities();

		this.event.create();
	}

	/**
	 * Check for additional device capabilities.
	 *
	 * Might be rerun on permission changes.
	 */
	public checkCapabilities(): void {
		// @ts-ignore
		this.hasCamera.set((navigator.getMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia) !== undefined);

		// @ts-ignore
		this.hasNFC.set(window.NDEFReader !== undefined);
	}

	/**
	 * Check if the application is running on a desktop device.
	 *
	 * Based on the userAgent provided by the browser, is the window size is too small it also switches to mobile mode.
	 */
	public static isDesktop(): boolean {
		if (window.innerWidth < 600) {
			return false;
		}

		return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) === null;
	}
}
