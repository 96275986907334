import {Pipe, PipeTransform} from '@angular/core';
import {UnoFormUtils} from '../uno-form-utils';
import {UnoFormField} from '../uno-form-field';

/**
 * Check a boolean-like value for a form field.
 */
@Pipe({
	name: 'checkbool',
	standalone: true,
	pure: false
})
export class UnoFormCheckBooleanPipe implements PipeTransform {
	public transform(value: (boolean | Function), defaultValue: boolean, object: any, row: UnoFormField): boolean {
		return UnoFormUtils.checkBool(value, defaultValue, object, row);
	}
}
