import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {ClipboardUtils} from 'src/app/utils/clipboard-utils';
import {App} from '../../../app';
import {ValidationUtils} from '../../../utils/validation-utils';
import {UnitConverter} from '../../../utils/unit-converter';
import {UnoFormFieldTypes} from '../uno-form/uno-form-field-types';
import {UnoFormField, OptionsDisplayMode} from '../uno-form/uno-form-field';
import {Environment} from '../../../../environments/environment';
import {UnoFormUtils} from '../uno-form/uno-form-utils';
import {DateFrequencyPrecision} from '../../../models/date-frequency';

/**
 * Dynamic forms are defined by an array of form fields that contain a type and attribute details.
 *
 * These forms are built from that list of fields and can be attached to any object that contain those fields.
 *
 * Dynamic forms can be used without the field specification, and it's possible to generate automatically a list of fields by traversing the object (fields are selected based on attribute types).
 */
@Component({
	selector: 'uno-form-field-selector',
	templateUrl: 'uno-form-field-selector.component.html',
	encapsulation: ViewEncapsulation.None
})
export class UnoFormFieldSelectorComponent {

	public formUtils: any = UnoFormUtils;

	public form: any = UnoFormFieldSelectorComponent;

	public validationUtils: any = ValidationUtils;

	public optionsDisplayMode: any = OptionsDisplayMode;

	public types: any = UnoFormFieldTypes;

	public app: any = App;

	public unitConverter: any = UnitConverter;

	public dateFrequencyPrecision: any = DateFrequencyPrecision;

	/**
	 * Layout of the sub-form if any.
	 */
	@Input()
	public layout: UnoFormField[] = null;

	/**
	 * Row of that is being presented on this selector.
	 */
	@Input()
	public row: UnoFormField = null;

	/**
	 * Object being shown in the form.
	 */
	@Input()
	public object: any = null;

	/**
	 * Method to be run when the value of the row changes.
	 */
	@Input()
	public onChange: (object: any, row: UnoFormField, value: any, layout: UnoFormField[])=> void = null;

	/**
	 * Indicates if the field is editable.
	 */
	@Input()
	public editable: boolean = true;

	/**
	 * Flag to control if required message should be presented for required fields when they are disabled.
	 */
	@Input()
	public showRequiredUneditable: boolean = true;

	/**
	 * Copies a certain value to the clipboard
	 * 
	 * @param row - Row of the form to copy, to get name of the attribute in the object.
	 */
	public async copy(row: UnoFormField): Promise<void> {
		const value = UnoFormUtils.getAttribute(this.object, row);
		await ClipboardUtils.copy(value);
		Modal.toast(Locale.get('copiedSuccessfully'), 3000, 'success');
	}

	/**
	 * Set the attribute of an object to a new value.
	 *
	 * Attribute names can indicate nested objects (e.g. `a:{b:{c:2}}` the c value can be accessed as "a.b.c").
	 *
	 * @param row - Row of the form to set, to get name of the attribute in the object.
	 * @param value - New value to be set.
	 * @param subAttribute - Sub attribute to access in the object.
	 * @returns True if the attribute was changed (was different from the original attribute in the object).
	 */
	public setAttribute(row: UnoFormField, value: any, subAttribute?: string): boolean {
		try {
			
			// Split the attribute name to get sub-attributes
			let attrs = row.attribute.split('.');
			if (subAttribute !== undefined) {
				attrs = attrs.concat(subAttribute.split('.'));
			}

			// Navigate to the leaf object.
			let sub = this.object;
			let i = 0;
			for (i = 0; i < attrs.length - 1; i++) {
				sub = sub[attrs[i]];
			}

			// Only change attribute if value is different.
			if (sub[attrs[i]] !== value) {
				sub[attrs[i]] = value;

				if (row.onChange) {
					row.onChange(this.object, row, value, this.layout);
				}

				if (this.onChange) {
					this.onChange(this.object, row, value, this.layout);
				}

				return true;
			}
		} catch (e) {
			if (!Environment.PRODUCTION) {
				console.error('EQS: Error form setAttribute', this.object, row, value, e);
			}

			throw new Error('Error setting attribute of object ' + e);
		}

		return false;
	}
}
