import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {DateFrequency} from '../../../models/date-frequency';
import {Session} from '../../../session';
import {CalendarEvent} from '../../../models/asset-planning/calendar-event';
import {CalendarEventPeriodicityModes} from '../../../models/asset-planning/calendar-event-periodicity-modes';


export class CalendarEventService {
	/**
	 * Service to update calendar event.
	 *
	 * @param event - Event to be updated.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async update(event: CalendarEvent, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		if (event.alarmType.length === 0) {
			event.teamUuids = [];
			event.userUuids = [];
		}

		if (event.periodic) {
			if (event.periodicityMode === CalendarEventPeriodicityModes.REPETITIONS) {
				event.periodicityEndDate = null;
			} else if (event.periodicityMode === CalendarEventPeriodicityModes.END_DATE) {
				event.periodicityRepetition = 0;
			}
		} else {
			event.periodicity = new DateFrequency();
			event.periodicityRepetition = 0;
			event.periodicityMode = 0;
			event.periodicityEndDate = null;
		}

		await Service.fetch(ServiceList.assetPlanning.calendarEvent.update, null, null, event, Session.session, hideLoading, displayError);
	}

	/**
	 * Service to create calendar event.
	 *
	 * @param event - Event to be created.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async create(event: CalendarEvent, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		if (event.triggerEventUuid) {
			event.date = null;
		}

		if (event.alarmType.length === 0) {
			event.teamUuids = [];
			event.userUuids = [];
		}

		if (event.periodic === false) {
			event.periodicity = new DateFrequency();
			event.periodicityRepetition = 0;
			event.periodicityMode = 0;
			event.periodicityEndDate = null;
		}

		await Service.fetch(ServiceList.assetPlanning.calendarEvent.create, null, null, event, Session.session, hideLoading, displayError);
	}
}
