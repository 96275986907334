<uno-tab style="width: 100%; height: 100%">
	@if (this.role) {
		<uno-tab-section [active]="true" title="{{'role' | translate}}">
			<uno-form [editable]="canEdit()" [layout]="this.layout" [object]="this.role" #form></uno-form>
			@if (canEdit()) {
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					@if (this.createMode) {
						<uno-button (click)="this.create();">{{'create' | translate}}</uno-button>
					} @else {
						<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
						<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
					}
				</div>
			}
		</uno-tab-section>
		<uno-tab-section title="{{'permissions' | translate}}">
			<ion-list>
				@for (permission of this.options; track permission) {
					<ion-item (click)="this.role.permissions = roleUtils.togglePermission(this.role.permissions, permission.value);" button="true">
						<ion-icon slot="start" (click)="permission.collapsed = !permission.collapsed; $event.stopPropagation();" name="{{permission.collapsed ? 'chevron-forward-outline' : 'chevron-down-outline'}}"></ion-icon>
						<ion-checkbox justify="start" labelPlacement="end" [checked]="this.role.permissions.indexOf(permission.value) !== -1" button="true">
							{{permission.label}}<br>
							{{permission.value}}
						</ion-checkbox>
					</ion-item>
					@if (!permission.collapsed) {
						@for (subPermission of permission.subPermissions; track subPermission) {
							<ion-item (click)="this.role.permissions = roleUtils.togglePermission(this.role.permissions, subPermission.value);">
								<div style="width: 100px"></div>
								<ion-checkbox justify="start" labelPlacement="end" [checked]="this.role.permissions.indexOf(subPermission.value) !== -1">
									{{subPermission.label}}<br>
									{{subPermission.value}}
								</ion-checkbox>
							</ion-item>
						}
					}

				}
			</ion-list>

			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				@if (this.createMode) {
					<uno-button (click)="this.create();">{{'create' | translate}}</uno-button>
				} @else {
					<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
				}
			</div>
		</uno-tab-section>
		@if (!this.createMode) {
			<uno-tab-section title="{{'assignments' | translate}}">
				@if (this.roleUsages) {
					@for (key of object.keys(this.roleUsages); track key) {
						<uno-form [editable]="false" [layout]="this.usagesLayout" [object]="this.roleUsages[key]"></uno-form>
					}
				} @else {
					<uno-no-data></uno-no-data>
				}
			</uno-tab-section>
		}
	}
</uno-tab>
