import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {DoughnutChartComponent, DoughnutChartData} from 'src/app/components/charts/doughnut-chart/doughnut-chart.component';
import {ServiceList} from 'src/app/http/service-list';
import {Locale} from 'src/app/locale/locale';
import {Service} from 'src/app/http/service';
import {Session} from 'src/app/session';
import {CSSUtils} from 'src/app/utils/css-utils';
import {DL50InspectionStatus} from 'src/app/models/dl50/dl50-inspection-status';

@Component({
	selector: 'dl50-inspections-status-graph',
	templateUrl: './dl50-inspections-status-graph.component.html',
	standalone: true,
	imports: [IonicModule, DoughnutChartComponent, NgStyle, TranslateModule]
})
export class DL50InspectionsStatusGraphComponent implements OnInit {
	public data: DoughnutChartData[] = [];

	public async ngOnInit(): Promise<void> {
		const request = await Service.fetch(ServiceList.dl50.inspections.countByStatus, null, null, null, Session.session);

		this.data = [
			new DoughnutChartData(Locale.get('inProgress'), request.response.results.find((c: {status: number, count: number}) => { return c.status === DL50InspectionStatus.IN_PROGRESS; })?.count, CSSUtils.parseColor('rgb(54, 162, 235)')),
			new DoughnutChartData(Locale.get('rejected'), request.response.results.find((c: {status: number, count: number}) => { return c.status === DL50InspectionStatus.SUPERVISION_REJECTED; })?.count, CSSUtils.parseColor('rgb(255, 99, 132)')),
			new DoughnutChartData(Locale.get('clientFeedback'), request.response.results.find((c: {status: number, count: number}) => { return c.status === DL50InspectionStatus.CLIENT_FEEDBACK; })?.count, CSSUtils.parseColor('rgb(255, 206, 86)')),
			new DoughnutChartData(Locale.get('validation'), request.response.results.find((c: {status: number, count: number}) => { return c.status === DL50InspectionStatus.VALIDATION; })?.count, CSSUtils.parseColor('rgb(128, 64, 188)')),
			new DoughnutChartData(Locale.get('finished'), request.response.results.find((c: {status: number, count: number}) => { return c.status === DL50InspectionStatus.FINISHED; })?.count, CSSUtils.parseColor('rgb(75, 192, 104)'))
		];
	}
}
