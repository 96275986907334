import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {UUID} from '../../../models/uuid';
import {APAsset} from '../../../models/asset-portfolio/asset';
import {Geolocation} from '../../../models/geolocation';

export type AssetListParams = {
	// Asset Type to filter by
	typeUuid?: UUID,
	// Asset Sub Type to filter by
	subtypeUuid?: UUID,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
};

export type AssetCountParams = {
	// Asset Type to filter by
	typeUuid?: UUID,
	// Asset Subtype to filter by
	subtypeUuid?: UUID,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
};

// Asset list API request format.
export type AssetListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Assets.
	assets: APAsset[],
	// The id of the api call.
	id: number
};

export type AssetListGeolocationParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
};

export type AssetListGeolocationAsset = {
	// Asset UUID
	uuid: UUID,
	// Asset tag
	tag: string,
	// Asset name
	name: string,
	// Position of the asset
	position: Geolocation
};

export type AssetListGeolocation = {
	// Assets with geolocation
	assets: AssetListGeolocationAsset[]
};

export type AssetGetTreeParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
};

/**
 * Asset tree structure to be displayed on the asset tree list.
 */
export type AssetTreeLevel = {
	// UUID of the asset
	uuid: UUID;
	// Create date
	createdAt: Date;
	// Update date
	updatedAt: Date;
	// Name of the asset
	name: string;
	// Tag of the asset
	tag: string;
	// Description of the asset
	description: string;
	// If the asset has children
	hasChild: boolean;
	// NFC of the asset
	nfc: any;
	// Parent UUID of the asset
	parentUuid: any;
	// Pictures of the asset
	pictures: any[];
	// QR of the asset
	qr: any;
};

type AssetTreeLevelParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
}; 


export class AssetService {

	/**
	 * Get a level of the asset tree, data is loaded one tree branch at a time.
	 * 
	 * @param parentUuid - The UUID of the parent asset.
	 * @returns The list of assets.
	 */
	public static async getTreeLevel(parentUuid: UUID = null, params: AssetTreeLevelParams = {}): Promise<AssetTreeLevel[]> {
		const data = {
			search: params.search,
			searchFields: params.searchFields,
			sortDirection: params.sortDirection,
			sortField: params.sortField,
			parentUuid: parentUuid
		};

		const request = await Service.fetch(ServiceList.assetPortfolio.asset.listTreeLevel, null, null, data, Session.session);

		return request.response.assets;
	}

	/**
	 * Get the entire asset tree.
	 * 
	 * @param params - Parameters to filter the list.
	 * @returns The list of assets.
	 */
	public static async getTree(params?: AssetGetTreeParams): Promise<APAsset[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.listTree, null, null, params, Session.session);
		return request.response.assets;
	}


	/**
	 * Get an asset from its UUID.
	 *
	 * @param uuid - The UUID of the asset to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The asset object.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<APAsset> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return APAsset.parse(request.response.asset);
	}

	/**
	 * Get a batch of assets from its UUIDs.
	 *
	 * @param uuids - The UUIDs of the assets to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The asset objects.
	 */
	public static async getBatch(uuids: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<APAsset[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: uuids}, Session.session, hideLoading, displayError);
		return request.response.assets.map((a: APAsset) => { return APAsset.parse(a); });
	}

	/**
	 * List all assets matching the params.
	 *
	 * @param params - Parameters to filter the list.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The list of assets.
	 */
	public static async list(params: AssetListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<AssetListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			assets: request.response.assets.map((data: any) => {return APAsset.parse(data);}),
			id: request.id
		};
	}

	/**
	 * Detailed List assets matching the params.
	 *
	 * @param params - Parameters to filter the list.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The list of assets.
	 */
	public static async listDetailed(params: AssetListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<AssetListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.listDetailed, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			assets: request.response.assets.map((data: any) => {return APAsset.parse(data);}),
			id: request.id
		};
	}

	/**
	 * List distinct assets by gap UUIDs.
	 *
	 * @param gapUuids - Gap UUIDs filter the list of assets by.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns A list of distinct assets.
	 */
	public static async listByGapUuids(gapUuids: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<APAsset[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.listByGAGaps, null, null, {gapUuids: gapUuids}, Session.session, hideLoading, displayError);
		return request.response.assets.map((d: any) => { return APAsset.parse(d); });
	}

	/**
	 * List all asset geolocation info.
	 *
	 * @param params - Parameters to filter the list.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The list of assets.
	 */
	public static async listGeolocation(params: AssetListGeolocationParams, hideLoading: boolean = false, displayError: boolean = true): Promise<AssetListGeolocation> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.listGeolocation, null, null, params, Session.session, hideLoading, displayError);

		return {assets: request.response.assets};
	}

	/**
	 * Count all assets matching the params.
	 *
	 * @param params - Parameters to filter the list.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Number of assets that match the params.
	 */
	public static async count(params: AssetCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.count, null, null, params, Session.session, hideLoading, displayError);
		
		return request.response.count;
	}

	/**
	 * Get asset by tag.
	 *
	 * @param tag - The tag of the asset to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The asset object.
	 */
	public static async getByTag(tag: string, hideLoading: boolean = false, displayError: boolean = true): Promise<APAsset> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.getTag, null, null, {tag: tag}, Session.session, hideLoading, displayError);
		return APAsset.parse(request.response.asset);
	}

	/**
	 * Get asset by QR.
	 *
	 * @param qr - The qr to fetch the asset by.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The asset object.
	 */
	public static async getByQR(qr: string, hideLoading: boolean = false, displayError: boolean = false): Promise<APAsset> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.getQR, null, null, {qr: qr}, Session.session, hideLoading, displayError);
		return APAsset.parse(request.response.asset);
	}

	/**
	 * Get asset by NFC.
	 *
	 * @param nfc - The nfc to fetch the asset by.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The asset object.
	 */
	public static async getByNFC(nfc: string, hideLoading: boolean = false, displayError: boolean = true): Promise<APAsset> {
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.getNFC, null, null, {nfc: nfc}, Session.session, hideLoading, displayError);
		return APAsset.parse(request.response.asset);
	}
}
