import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {InspectionForm} from '../../../models/inspections/form/inspection-form';

export class InspectionFormService {
	/**
	 * Get inspection form from API.
	 *
	 * @param uuid - UUID of the form
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns - Inspection form object.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<InspectionForm> {
		const request = await Service.fetch(ServiceList.inspection.form.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return InspectionForm.parse(request.response.form);
	}

	/**
	 * List all inspections forms that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns - The inspections forms that match the params.
	 */
	public static async list(params: {
		from?: number,
		count?: number,
		search?: string,
		searchFields?: string[],
		sortField?: string,
		sortDirection?: string
	} = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<{hasMore: boolean, id: number, forms: any[]}> {
		const request = await Service.fetch(ServiceList.inspection.form.list, null, null, params, Session.session, hideLoading, displayError);
		return {
			hasMore: request.response.hasMore,
			id: request.id,
			forms: request.response.forms.map((form) => {
				return InspectionForm.parse(form);
			})
		};
	}

	/**
	 * Count all inspections forms that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns - The nember of inspections forms that match the params.
	 */
	public static async count(params: {
		search?: string,
		searchFields?: string[]
	} = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.inspection.form.count, null, null, params, Session.session, hideLoading, displayError);
		return request.response.count;
	}

}
