import {Resource} from '../../../models/resource';
import {UUID, UUIDIdentification} from '../../../models/uuid';

/**
 * A scene is used to organize digital twin objects in the same view space.
 *
 * Scenes are independent of each other. Objects from a scene only interact with objects of the same scene.
 */
export class DigitalTwinScene extends UUIDIdentification {
	/**
	 * Name of the scene.
	 */
	public name: string = '';

	/**
	 * Description of the scene.
	 */
	public description: string = '';

	/**
	 * Picture of the scene. Generated by the client before exiting the scene / closing the app.
	 */
	public picture: Resource = null;

	/**
	 * RBAC master list of allowed roles
	 */
	public rbacMasterAllowedList: UUID[] = [];
	
	/**
	 * RBAC list of allowed roles
	 */
	public rbacAllowedList: UUID[] = [];

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 *
	 * @param data - Object received from API.
	 * @returns Scene object instance built from the data received.
	 */
	public static parse(data: any): DigitalTwinScene {
		const scene: DigitalTwinScene = new DigitalTwinScene();

		scene.uuid = data.uuid;
		scene.updatedAt = new Date(data.updatedAt);
		scene.createdAt = new Date(data.createdAt);

		scene.name = data.name;
		scene.description = data.description;
		scene.picture = Resource.parse(data.picture);

		scene.rbacMasterAllowedList = data.rbacMasterAllowedList || [];

		scene.rbacAllowedList = data.rbacAllowedList || [];


		return scene;
	}
}
