import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
	AnimationTimer,
	EventManager,
	NodeGraph,
	Object2D,
	Renderer,
	Viewport,
	ViewportControls
} from 'escher.js/build/escher.module.js';
import {TranslateModule} from '@ngx-translate/core';
import {Environment} from '../../../../environments/environment';
import {UnoButtonComponent} from '../uno-button/uno-button.component';
import {SensorResultNode} from './nodes/sensor-result-node';
import {OperationNode} from './nodes/operation-node';
import {NumberInputNode} from './nodes/number-input-node';
import {FunctionNode} from './nodes/function-node';

@Component({
	selector: 'uno-graph',
	templateUrl: './uno-graph.component.html',
	standalone: true,
	imports: [UnoButtonComponent, TranslateModule]
})
export class UnoGraphComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild('canvas', {static: true})
	public canvas: ElementRef = null;

	/**
	 * Data being edited in this component, serialized and loaded from the graph object.
	 */
	@Input()
	public data: any = null;

	/**
	 * Callback method called every time that the expression changes.
	 */
	@Input()
	public onChange: Function = null;

	/**
	 * Node graph being edited in this component.
	 */
	public graph: NodeGraph = null;

	/**
	 * Renderer responsible for drawing the graph into the canvas.
	 */
	public renderer: Renderer = null;

	/**
	 * Viewport used to control how the user views the graph.
	 */
	public viewport: Viewport = null;

	/**
	 * Viewport controls used for the user to move the viewport around the graph.
	 */
	private controls: ViewportControls = null;

	/**
	 * Event manager to create and manage the canvas resizing events.
	 */
	private event: EventManager = null;

	/**
	 * Timer used to loop the render method.
	 */
	private timer: AnimationTimer = null;

	/**
	 * Observer used to check when the component is shown or hidden to update the canvas size.
	 */
	private observer: IntersectionObserver = null;

	/**
	 * Node where the result of the expression can be obtained.
	 */
	private result: SensorResultNode = null;

	public ngOnChanges(changes: SimpleChanges): void {
		this.parseData();
	}

	public ngOnInit(): void {
		this.parseData();

		this.viewport = new Viewport(this.canvas.nativeElement);
		this.renderer = new Renderer(this.canvas.nativeElement);

		const resizeCanvas = (): void => {
			this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth;
			this.canvas.nativeElement.height = this.canvas.nativeElement.offsetHeight;
		};

		const preventDefault = function(event): boolean {
			event.preventDefault();
			return false;
		};

		this.event = new EventManager();
		this.event.add(window, 'resize', resizeCanvas);
		this.event.add(this.canvas.nativeElement, 'DOMMouseScroll', preventDefault);
		this.event.add(this.canvas.nativeElement, 'wheel', preventDefault);
		this.event.add(this.canvas.nativeElement, 'mousewheel', preventDefault);
		this.event.add(this.canvas.nativeElement, 'contextmenu', preventDefault);
		this.event.create();

		this.controls = new ViewportControls(this.viewport);

		this.timer = new AnimationTimer(() => {
			if (this.renderer.pointer.insideCanvas()) {
				this.controls.update(this.renderer.pointer);
				this.renderer.update(this.graph, this.viewport);
			}
		});
		this.timer.start();

		this.observer = new IntersectionObserver((entries) => {
			resizeCanvas();
		}, {threshold: [0]});
		this.observer.observe(this.canvas.nativeElement);
	}

	public ngOnDestroy(): void {
		this.event.destroy();
		this.timer.stop();
		this.observer.disconnect();
	}

	public parseData(): void {
		if (!this.data && !this.graph) {
			this.createGraph();
		} else {
			try {
				if (typeof this.data === 'string') {
					this.data = JSON.parse(this.data);
				}

				this.graph = Object2D.parse(this.data);
			} catch (e) {
				if (!Environment.PRODUCTION) {
					console.warn('EQS: Expression editor data parse failed.', e);
				}
				this.graph = new NodeGraph();
			}
		}
	}

	/**
	 * Get the graph data as a serialized JSON object that can be stored for later be reloaded.
	 *
	 * @returns Serialized data for storage.
	 */
	public getData(): any {
		return this.graph.serialize();
	}

	/**
	 * Add a simple operation node to the graph.
	 *
	 * @param operation - Operation char (e.g +, -, /, *)
	 * @param label - Label of the operator shown to the user.
	 */
	public addOperationNode(operation, label): void {
		this.graph.addNode(new OperationNode(operation, label));
	}

	/**
	 * Add a user controlled numeric input to the expression.
	 */
	public addNumberInput(): void {
		this.graph.addNode(new NumberInputNode());
	}

	/**
	 * Add a math function node to the graph. These functions have to be implemented in the API.
	 *
	 * @param func - Function name (e.g cos, sin, tan, abs)
	 * @param label - Label of the function shown to the user.
	 */
	public addFunctionNode(func, label): void {
		this.graph.addNode(new FunctionNode(func, label));
	}

	/**
	 * Create a new empty graph for the user to start edit.
	 */
	public createGraph(): void {
		this.result = new SensorResultNode();
		this.graph = new NodeGraph();
		this.graph.addNode(this.result);
	}
}
