import {NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {OverlayModule} from '@angular/cdk/overlay';

import {UnoCalendarComponent} from './uno-calendar.component';

registerLocaleData(localeEs);
registerLocaleData(localePt);

@NgModule({
	exports: [UnoCalendarComponent],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot(),
		OverlayModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory
		}),
		UnoCalendarComponent
	]
})
export class UnoCalendarModule { }

