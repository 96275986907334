import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DataToolsPage} from './screens/data-tools.page';

const routes: Routes = [
	{
		path: '',
		component: DataToolsPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DataToolsRouterModule { }
