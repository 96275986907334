import {Theme} from './theme';
import {ThemeOS} from './theme-os';

export const Themes: Theme[] = [
	{
		id: ThemeOS.LIGHT,
		label: 'light',
		class: 'light',
		assets: './assets/themes/light/',
		echarts: 'light'
	},
	{
		id: ThemeOS.DARK,
		label: 'dark',
		class: 'dark',
		assets: './assets/themes/dark/',
		echarts: 'dark'
	}
];
