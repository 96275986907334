import {Component, OnInit} from '@angular/core';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {GAActionPlanStatus} from '../../../../../models/gap-analysis/action-plans/action-plan-status';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'gap-analysis-action-plan-menu-page',
	templateUrl: './action-plan-menu.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent]
})
export class GAActionPlanMenuPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public session: any = Session;

	public permissions = [UserPermissions.GA_ACTION_PLAN];

	/**
	 * List of options in this menu organized by sections.
	 */
	public groups: MenuCardsGroup[] = [
		{
			label: 'global',
			permissions: [],
			options: [
				{
					label: 'list',
					route: '/menu/gap-analysis/action-plans/list',
					data: {status: GAActionPlanStatus.ALL},
					icon: 'list',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.gapAnalysis.actionPlan.countByStatus, null, null, {status: GAActionPlanStatus.ALL}, Session.session)).response.count;
					})()
				}
			]
		},
		{
			label: 'create',
			permissions: [UserPermissions.GA_ACTION_PLAN_CREATE, GAActionPlanStatus.PROPOSAL],
			options: [
				{
					label: 'create',
					route: '/menu/gap-analysis/action-plans/edit',
					data: {createMode: true},
					icon: 'add',
					permissions: [UserPermissions.GA_ACTION_PLAN_CREATE],
					badge: null
				},
				{
					label: 'proposal',
					route: '/menu/gap-analysis/action-plans/list',
					data: {status: GAActionPlanStatus.PROPOSAL},
					icon: 'create',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.gapAnalysis.actionPlan.countByStatus, null, null, {status: GAActionPlanStatus.PROPOSAL}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'client',
			permissions: [UserPermissions.ACTION_PLAN_CLIENT],
			options: [
				{
					label: 'waitingApproval',
					route: '/menu/gap-analysis/action-plans/list',
					data: {status: GAActionPlanStatus.WAITING_APPROVAL},
					icon: 'stopwatch-outline',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.gapAnalysis.actionPlan.countByStatus, null, null, {status: GAActionPlanStatus.WAITING_APPROVAL}, Session.session, true)).response.count;
					})()
				},
				{
					label: 'rejected',
					route: '/menu/gap-analysis/action-plans/list',
					data: {status: GAActionPlanStatus.REJECTED},
					icon: 'thumbs-down',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.gapAnalysis.actionPlan.countByStatus, null, null, {status: GAActionPlanStatus.REJECTED}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'company',
			permissions: [UserPermissions.ACTION_PLAN_COMPANY],
			options: [
				{
					label: 'implementation',
					route: '/menu/gap-analysis/action-plans/list',
					data: {status: GAActionPlanStatus.IMPLEMENTATION},
					icon: 'thumbs-up',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.gapAnalysis.actionPlan.countByStatus, null, null, {status: GAActionPlanStatus.IMPLEMENTATION}, Session.session, true)).response.count;
					})()
				},

				{
					label: 'blocked',
					route: '/menu/gap-analysis/action-plans/list',
					data: {status: GAActionPlanStatus.BLOCKED},
					icon: 'assets/icons/material/block/outline.svg',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.gapAnalysis.actionPlan.countByStatus, null, null, {status: GAActionPlanStatus.BLOCKED}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'reinspection',
			permissions: [UserPermissions.ACTION_PLAN_REINSPECT],
			options: [
				{
					label: 'reinspection',
					route: '/menu/gap-analysis/action-plans/list',
					data: {status: GAActionPlanStatus.REINSPECTION},
					icon: 'refresh',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.gapAnalysis.actionPlan.countByStatus, null, null, {status: GAActionPlanStatus.REINSPECTION}, Session.session, true)).response.count;
					})()
				},
				{
					label: 'done',
					route: '/menu/gap-analysis/action-plans/list',
					data: {status: GAActionPlanStatus.DONE},
					icon: 'checkbox-outline',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.gapAnalysis.actionPlan.countByStatus, null, null, {status: GAActionPlanStatus.DONE}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'archived',
			permissions: [],
			options: [
				{
					label: 'archived',
					route: '/menu/gap-analysis/action-plans/list',
					data: {status: GAActionPlanStatus.ARCHIVED},
					icon: 'archive-outline',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.gapAnalysis.actionPlan.countByStatus, null, null, {status: GAActionPlanStatus.ARCHIVED}, Session.session, true)).response.count;
					})()
				}
			]
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('actionPlan');
	}
}
