import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {AssetService} from '../../../../asset-portfolio/services/asset.service';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {App} from '../../../../../app';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {FFPLayout} from '../ffp-layout';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {FFPState} from '../../../../../models/atex-inspections/ffp/ffp-state';
import {FFP} from '../../../../../models/atex-inspections/ffp/ffp';
import {AtexInspection} from '../../../../../models/atex-inspections/inspections/atex-inspection';
import {ActionPlan} from '../../../../../models/atex-inspections/action-plan/action-plan';
import {ActionPlanState, ActionPlanStateLabel} from '../../../../../models/atex-inspections/action-plan/action-plan-state';
import {UUID} from '../../../../../models/uuid';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {AtexInspectionFieldsService} from '../../../inspections/services/atex-inspection-fields.service';
import {AtexInspectionService} from '../../../inspections/services/atex-inspection.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'ffp-edit',
	templateUrl: 'ffp-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoFormModule, UnoButtonComponent, UnoTitleComponent, IonicModule, TranslateModule, FormatDatePipe, PermissionsPipe]
})
export class FFPEditPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public ffpLayout: any = FFPLayout;

	public ffpState: any = FFPState;

	public actionPlanState: any = ActionPlanState;

	public actionPlanStateLabel: any = ActionPlanStateLabel;

	@ViewChild('ffpForm', {static: false})
	public ffpForm: UnoFormComponent = null;

	@ViewChild('assetForm', {static: false})
	public assetForm: UnoFormComponent = null;

	public permissions = [UserPermissions.FFP];

	/**
	 * FFP object being edited that will be sent to the API.
	 */
	public ffp: FFP = null;

	/**
	 * Atex Inspection attached to this FFP.
	 */
	public inspection: AtexInspection = null;

	/**
	 * Inspection field of the FFP form layout.
	 */
	public inspectionFieldLayout: any[] = [];

	/**
	 * List of action plans that contain this FFP entry.
	 */
	public actionPlans: ActionPlan[] = [];

	/**
	 * Asset attached to this FFP.
	 */
	public asset: APAsset = null;

	/**
	 * Edit history of the FFP entry.
	 */
	public history: any[] = [];

	public ngOnInit(): void {
		super.ngOnInit();
		
		this.ffp = null;
		this.inspection = null;
		this.inspectionFieldLayout = [];
		this.asset = null;
		this.history = [];
		this.actionPlans = [];

		// Read state from the route data
		let data = App.navigator.getData();
		
		if (typeof data === 'string') {
			data = JSON.parse(data);
		}

		if (!data || !data.ffp || !data.asset || !data.inspection) {
			App.navigator.pop();
			return;
		}

		App.navigator.setTitle('ffp');
		this.loadData(data.ffp, data.asset, data.inspection);
	}

	/**
	 * Load data from the API to be displayed.
	 *
	 * Gets data for the FFP and for the asset details.
	 */
	public async loadData(ffp: string, asset: string, inspection: string): Promise<void> {
		const request = await Service.fetch(ServiceList.atex.ffp.get, null, null, {uuid: ffp}, Session.session);
		this.ffp = FFP.parse(request.response.ffp);

		const fields = await AtexInspectionFieldsService.get();
		const field: UnoFormField = structuredClone(fields[this.ffp.field]);
		field.type = UnoFormFieldTypes.ATEX_INSPECTION_FIELD;

		this.inspectionFieldLayout = [field];

		this.loadActionPlans(ffp);
		this.loadHistory(ffp);
		this.loadInspection(inspection);
		this.loadAsset(asset);
	}

	/**
	 * Load Inspection data from API.
	 */
	public async loadInspection(uuid: UUID): Promise<void> {
		if (Session.hasPermissions([UserPermissions.ATEX_INSPECTION])) {
			this.inspection = await AtexInspectionService.get(uuid);
		}
	}

	/**
	 * Load asset data from API.
	 */
	public async loadAsset(uuid: UUID): Promise<void> {
		if (Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO])) {
			this.asset = await AssetService.get(uuid);
		}
	}

	/**
	 * Load the edit history from database.
	 */
	public async loadHistory(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.atex.ffp.historyListUsers, null, null, {uuid: uuid}, Session.session);
		this.history = request.response.history;
	}

	/**
	 * Load list of action plan from the API.
	 */
	public async loadActionPlans(uuid: UUID): Promise<void> {
		if (Session.hasPermissions([UserPermissions.ACTION_PLAN])) {
			const request = await Service.fetch(ServiceList.atex.actionPlan.listDetailedForFFP, null, null, {ffp: uuid}, Session.session);
			this.actionPlans = request.response.actionPlans;
		}
	}

	/**
	 * Update FFP data and create a new action plan using this FFP.
	 */
	public async createActionPlan(): Promise<void> {
		if (!this.ffpForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(ServiceList.atex.ffp.update, null, null, this.ffp, Session.session);
		App.navigator.navigate('/menu/atex/action-plan/edit', {createMode: true, ffpUuids: [this.ffp.uuid]});
	}

	/**
	 * Update the action plan data on the API.
	 */
	public async update(stayOnPage: boolean = false, state?: number): Promise<void> {
		if (!this.ffpForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const ffp = structuredClone(this.ffp);
		if (state !== undefined) {
			ffp.state = state;
		}

		await Service.fetch(ServiceList.atex.ffp.update, null, null, ffp, Session.session);
		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('ffpSaved'));

		this.loadHistory(this.ffp.uuid);
	}

	/**
	 * Delete FFP from the database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.atex.ffp.delete, null, null, {uuid: this.ffp.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
