<div class="file-selector-container">
	<!-- Active -->
	@if (!this.disabled) {
		<div class="file-selector-file-container">
			@if (this.multiple) {
				@for (resource of $any(this.value); let index = $index; track resource;) {
					<div class="file-selector-file" (mouseenter)="this.hoverFile(index)" (mouseleave)="this.onHoverFileLeave()">
						<uno-icon [src]="$any(resource).format | extensionIcon" width="36" height="36"></uno-icon>
						<div class="file-selector-file-information" [ngClass]="{'file-selector-file-information-mobile': this.app.device.isMobile()}" (click)="this.openFile(resource);">
							<!-- Document information -->
							@if (!this.local) {
								<span>{{$any(resource).description}}</span>
							} @else {
								<span>{{$any(resource).name}}</span>
							}
						</div>
						<!-- Delete button -->
						@if (this.hoverIndex === index || this.app.device.isMobile()) {
							<uno-icon class="file-selector-file-delete-icon" src="assets/icons/uno/bin.svg" (click)="this.remove(resource)" color="primary" width="28" height="28"></uno-icon>
						}
					</div>
				}
			} @else {
				@if (this.value) {
					<div class="file-selector-file" (mouseenter)="this.hoverFile(-1)" (mouseleave)="this.onHoverFileLeave()">
						<uno-icon [src]="$any(this.value).format | extensionIcon" width="36" height="36"></uno-icon>
						<div class="file-selector-file-information" [ngClass]="{'file-selector-file-information-mobile': this.app.device.isMobile()}" (click)="this.openFile($any(this.value));">
							<!-- Document information -->
							@if (!this.local) {
								<span>{{$any(this.value).description}}</span>
							} @else {
								<span>{{$any(this.value).name}}</span>
							}
						</div>
						<!-- Delete button -->
						@if (this.hoverIndex === -1 || this.app.device.isMobile()) {
							<uno-icon class="file-selector-file-delete-icon" src="assets/icons/uno/bin.svg" (click)="this.remove($any(this.value))" color="primary" width="28" height="28"></uno-icon>
						}
					</div>
				}
			}
		</div>
		@if (this.multiple || !this.value) {
			<!-- Add Documents -->
			<div #upload class="file-selector-select-container" [ngClass]="{'file-selector-container-drag': this.isDragging}" (click)="this.selectFiles()">
				@if(isDragging) {
					<div class="file-selector-select-container-text" style="margin-top: 10px; margin-bottom: 11px;">
						<uno-icon src="assets/icons/uno/add.svg" color="primary" width="32" height="32"></uno-icon>
						<div>
							<span>Drop your file to upload</span>
						</div>
					</div>
				}
				@else {
					<div class="file-selector-select-container-text">
						<uno-icon src="assets/icons/uno/upload.svg" color="primary" width="32" height="32"></uno-icon>
						<div>
							@if(!this.app.device.isMobile()) {
								<span>{{'dragAndDrop' | translate }}</span>
								<span>{{'or' | translate }}</span>
							}
							<span class="file-selector-choose-file">{{'chooseFile' | translate }}</span>
							<span>{{'toUpload' | translate }}</span>
						</div>
					</div>
					<span class="file-selector-container-sublabel">{{'files' | translate }}</span>
				}
			</div>
		}
	}
	@else {
		<div class="file-selector-file-container">
			@if (this.multiple) {
				@for (resource of $any(this.value); track resource) {
					<div class="file-selector-file">
						<uno-icon [src]="$any(resource).format | extensionIcon" width="36" height="36"></uno-icon>
						<div class="file-selector-file-information" (click)="this.openFile(resource);">
							<!-- Document information -->
							@if (!this.local) {
								<span>{{$any(resource).description}}</span>
							} @else {
								<span>{{$any(resource).name}}</span>
							}
						</div>
					</div>
				}
			}
			@else if(this.value) {
				<div class="file-selector-file">
					<uno-icon [src]="$any(this.value).format | extensionIcon" width="36" height="36"></uno-icon>
					<div class="file-selector-file-information" (click)="this.openFile($any(this.value));">
						<!-- Document information -->
						@if (!this.local) {
							<span>{{$any(this.value).description}}</span>
						} @else {
							<span>{{$any(this.value).name}}</span>
						}
					</div>
				</div>
			}
		</div>
	}
	<!-- Sample file -->
	@if (this.sampleFile) {
		<ion-button mode="ios" (click)="fileUtils.download(this.sampleFile);" expand="block" fill="outline" style="max-width: 120px;">
			<ion-label slot="end">{{'sampleFile' | translate }}</ion-label>
		</ion-button>
	}

	<div [ngClass]="{'file-selector-file-format-container': this.isPdfOpen, 'file-selector-file-format-container-mobile': this.app.device.isMobile()}" (click)="this.closePDF()">
		<ng2-pdfjs-viewer class="file-selector-file-format" [ngClass]="{'file-selector-file-format-mobile': this.app.device.isMobile()}" #pdfViewer [externalWindow]="false" [openFile]="false" [viewBookmark]="false" [download]="false"></ng2-pdfjs-viewer>
		@if (this.isPdfOpen) {
			<div class="file-selector-bottom-bar" [ngClass]="{'file-selector-bottom-bar-mobile': this.app.device.isMobile()}" (click)="$event.stopPropagation()">
				<uno-button type="border" (click)="this.closePDF()">{{'cancel' | translate}}</uno-button>
				<uno-button icon="assets/icons/uno/download.svg" (click)="this.downloadFilePDF(this.openResource)">{{'download' | translate}}</uno-button>
			</div>
		}
	</div>

	@if(this.isOfficeDocOpen) {
		<div class="file-selector-file-format-container" [ngClass]="{'file-selector-file-format-container-mobile': this.app.device.isMobile()}" (click)="this.closeOfficeDocument()">
			<office-viewer class="file-selector-file-format" [ngClass]="{'file-selector-file-format-mobile': this.app.device.isMobile()}" [url]="this.officeUrl"></office-viewer>
			<div class="file-selector-bottom-bar" [ngClass]="{'file-selector-bottom-bar-mobile': this.app.device.isMobile()}" (click)="$event.stopPropagation()">
				<uno-button type="border" (click)="this.closeOfficeDocument()">{{'cancel' | translate}}</uno-button>
				<uno-button icon="assets/icons/uno/download.svg" (click)="this.downloadFile(this.openResource)">{{'download' | translate}}</uno-button>
			</div>
		</div>
	}
</div>
