import {Component, OnInit} from '@angular/core';
import {RepairInspectionStatus, RepairInspectionStatusLabel} from '../../../../../models/repairs/inspections/repair-inspection-status';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {RepairInspectionService} from '../../services/repair-inspection.service';

@Component({
	selector: 'repair-inspection-page',
	templateUrl: 'repair-inspection.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent]
})
export class RepairInspectionPage extends ScreenComponent implements OnInit {

	public app: any = App;
	
	public userPermissions: any = UserPermissions;
	
	public session: any = Session;

	public permissions = [UserPermissions.REPAIR_INSPECTIONS];

	/**
	 * List of groups and options on repair inspections menu, organized by sections.
	 */
	public groups: MenuCardsGroup[] = [
		{
			label: 'global',
			permissions: [UserPermissions.REPAIR_INSPECTIONS_CREATE, UserPermissions.REPAIR_INSPECTIONS],
			options: [
				{
					label: 'create',
					route: '/menu/repairs/inspections/edit',
					data: {createMode: true},
					icon: 'add',
					permissions: [UserPermissions.REPAIR_INSPECTIONS_CREATE],
					badge: undefined
				},
				{
					label: 'list',
					route: '/menu/repairs/inspections/list',
					data: {status: RepairInspectionStatus.ALL},
					icon: 'list',
					permissions: [UserPermissions.REPAIR_INSPECTIONS],
					badge: (async() => {
						return RepairInspectionService.countByStatus(RepairInspectionStatus.ALL, true);
					})()
				}
			]
		},
		{
			label: 'todo',
			permissions: [UserPermissions.REPAIR_INSPECTIONS],
			options: [
				{
					label: RepairInspectionStatusLabel.get(RepairInspectionStatus.TODO),
					route: '/menu/repairs/inspections/list',
					data: {status: RepairInspectionStatus.TODO},
					icon: 'hammer',
					permissions: [UserPermissions.REPAIR_INSPECTIONS],
					badge: (async() => {
						return RepairInspectionService.countByStatus(RepairInspectionStatus.TODO, true);
					})()
				},
				{
					label: RepairInspectionStatusLabel.get(RepairInspectionStatus.WAITING_APPROVAL),
					route: '/menu/repairs/inspections/list',
					data: {status: RepairInspectionStatus.WAITING_APPROVAL},
					icon: 'assets/icons/material/supervisor_account/outline.svg',
					permissions: [UserPermissions.REPAIR_INSPECTIONS],
					badge: (async() => {
						return RepairInspectionService.countByStatus(RepairInspectionStatus.WAITING_APPROVAL, true);
					})()
				}
			]
		},
		{
			label: 'done',
			permissions: [UserPermissions.REPAIR_INSPECTIONS],
			options: [
				{
					label: RepairInspectionStatusLabel.get(RepairInspectionStatus.DONE),
					route: '/menu/repairs/inspections/list',
					data: {status: RepairInspectionStatus.DONE},
					icon: 'checkbox-outline',
					permissions: [UserPermissions.REPAIR_INSPECTIONS],
					badge: (async() => {
						return RepairInspectionService.countByStatus(RepairInspectionStatus.DONE, true);
					})()
				}
			]
		},
		{
			label: 'archived',
			permissions: [UserPermissions.REPAIR_INSPECTIONS],
			options: [
				{
					label: RepairInspectionStatusLabel.get(RepairInspectionStatus.ARCHIVED),
					route: '/menu/repairs/inspections/list',
					data: {status: RepairInspectionStatus.ARCHIVED},
					icon: 'archive-outline',
					permissions: [UserPermissions.REPAIR_INSPECTIONS],
					badge: (async() => {
						return RepairInspectionService.countByStatus(RepairInspectionStatus.ARCHIVED, true);
					})()
				}
			]
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		App.navigator.setTitle('inspections');
	}
}
