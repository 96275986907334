import {Component, OnInit} from '@angular/core';
import {App} from 'src/app/app';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {UnoListLazyLoadHandler} from 'src/app/components/uno/uno-list/uno-list-lazy-load-handler';
import {Settings} from 'src/app/modules/settings/data/settings';
import {Session} from 'src/app/session';
import {ReportUtils} from 'src/app/utils/report-utils';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {UnoIconComponent} from 'src/app/components/uno/uno-icon/uno-icon.component';
import {InspectionProjectService} from 'src/app/modules/inspections/services/inspection-project.service';
import {Environment} from 'src/environments/environment';
import {NameUtils} from 'src/app/utils/name-utils';
import {ServiceList} from '../../../../app/http/service-list';
import {Service} from '../../../../app/http/service';
import {Resource} from '../../../../app/models/resource';
import {Inspection} from '../../../../app/models/inspections/inspection/inspection';
import {CiresConst, SecilConst} from '../../dl50-client-const';
import {FormatDatePipe} from '../../../../app/pipes/format-date.pipe';
import {UnoListComponent} from '../../../../app/components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../app/components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../app/components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../app/components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../app/components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../app/components/uno/uno-searchbar/uno-searchbar.component';
import {ResourceUtils} from '../../../../app/utils/resource-utils';

@Component({
	selector: 'dl50-client-list-page',
	templateUrl: 'dl50-client-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		UnoFilterBarComponent,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		NgStyle,
		UnoContentComponent,
		UnoListComponent,
		UnoIconComponent,
		TranslateModule,
		FormatDatePipe
	]
})
export class Dl50ClientListPage extends ScreenComponent implements OnInit {

	public get app(): any {return App;}

	public get settings(): any {return Settings;}

	public get resource(): any {return Resource;}

	public get selfStatic(): any { return Dl50ClientListPage; }

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Possible database filter to be used for ordering the user roles list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		// Sort direction applied to the loaded list from database.
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		// Database attribute name used to sort the values.
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[ap_asset].[name]',
			options: [
				{label: 'name', value: '[ap_asset].[name]'},
				{label: 'tag', value: '[ap_asset].[tag]'},
				{label: 'updatedAt', value: '[ap_asset].[updated_at]'}
			]
		},
		// Searchfields to apply on the request.
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			multiple: true,
			default: ['[ap_asset].[name]', '[ap_asset].[tag]', '[ap_asset].[description]'],
			options: [
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'assetDescription', value: '[ap_asset].[description]'},
				{label: 'inspectionUuid', value: '[inspection].[id]'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter user roles by.
		 */
		search: '',

		/**
		 * Direction to sort the results by.
		 */
		sortDirection: '',

		/**
		 * Searchfields to sort the results by.
		 */
		searchFields: ''

	}, Dl50ClientListPage.filterOptions);

	/**
	 * The const to use when listing inspections
	 */
	public optionConst: any = Environment.CLIENT_ID === 'cires' ? CiresConst : SecilConst;

	public ngOnInit(): void {
		App.navigator.setTitle(NameUtils.camelToLegible(Environment.CLIENT_ID));

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const params = {
				from: count,
				count: pageSize,
				sortDirection: Dl50ClientListPage.filters.sortDirection,
				sortField: Dl50ClientListPage.filters.sortField,
				search: Dl50ClientListPage.filters.search,
				searchFields: Dl50ClientListPage.filters.searchFields,
				step: this.optionConst.STEP_UUID,
				project: this.optionConst.PROJECT_UUID
			};

			const request = await Service.fetch(ServiceList.inspection.inspectionListData, null, null, params, Session.session);
			const response = request.response;

			const inspections = [];
			for (const inspection of response.inspections) {
				for (let d = 0; d < inspection.inspectionData.length; d++) {
					if (inspection.inspectionData[d].stepUuid === this.optionConst.CONCLUSION_STEP_UUID) {
						const conclusion = inspection.inspectionData[d].data[this.optionConst.CONCLUSION_PATH[0]][this.optionConst.CONCLUSION_PATH[1]];
						inspection.color = this.optionConst.CONCLUSION_OPTIONS.has(conclusion) ? this.optionConst.CONCLUSION_OPTIONS.get(conclusion) : 'var(--gray-6)';
					}
				}

				if (!inspection.color ) {
					inspection.color = 'var(--gray-6)';
				}

				inspections.push(inspection);
			}

			return {
				elements: inspections,
				hasMore: response.hasMore
			};
		};
		this.handler.reset();
	}

	/**
	 * Export the report in pdf.
	 * 
	 * @param inspection - The selected inspection.
	 */
	public async exportReport(inspection: Inspection): Promise<void> {
		const reports = (await InspectionProjectService.get(inspection.projectUuid)).reports;
		if (reports.length > 0) {
			await ReportUtils.inspectionReport(ResourceUtils.getURL(reports[0].template), inspection.uuid, true);
		}
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - Filters
	 */
	public onFilterChange(event: any): void {
		if (typeof event === 'string') {
			Dl50ClientListPage.filters.search = event;
		} else {
			event.search = Dl50ClientListPage.filters.search;
			Dl50ClientListPage.filters = event;
		}

		this.handler.reset();
	}

	protected readonly ResourceUtils = ResourceUtils;
}
