import {Pipe, PipeTransform} from '@angular/core';
import {ResourceUtils} from '../utils/resource-utils';

/**
 * Pipe to get the URL of a resource, if the input is a string it will return the same string.
 */
@Pipe({
	name: 'resourceUrl',
	standalone: true,
	pure: true
})
export class ResourceUrlPipe implements PipeTransform {
	public transform(value: any): any {
		return ResourceUtils.getURL(value, value);
	}
}
