import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';

function isEditable(object: any, row: UnoFormField): boolean {
	return !object.locked;
}

export const DigitalTwinObjectLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT,
		editable: isEditable
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE,
		editable: isEditable
	},
	{
		required: false,
		attribute: 'locked',
		label: 'locked',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		required: false,
		attribute: 'visible',
		label: 'visible',
		type: UnoFormFieldTypes.CHECKBOX,
		editable: isEditable
	},
	{
		attribute: 'assetUuid',
		label: 'asset',
		type: UnoFormFieldTypes.ASSET_SELECTOR,
		showClear: true,
		multiple: false,
		editable: isEditable
	}
];
