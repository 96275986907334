import {SortDirection} from './sort-direction';
import {ObjectUtils} from './object-utils';
import {CompareMethod} from './compare-utils';

/**
 * Sort object handling utilities.
 */
export class SortUtils {
	/**
	 * Sort and filter array by value and compare with reference array.
	 * 
	 * If the arrays do not match the method returns false.
	 * 
	 * @param reference - The array to compare to.
	 * @param array - The array to filter and sort. It will be compared with the property 'reference'.
	 * @param sortAttr - The attribute to sort (sub-attributes can be defined as X.Y.Z).
	 * @param sortCompareMethod - The method used to compare both properties.
	 * @param sortDirection - The order to sort the array of objects (SortDirection).
	 * @param searchAttr - The attribute to search (sub-attributes can be defined as X.Y.Z).
	 * @param searchText - The text used to filter the property the array.
	 * @returns - True if the arrays are filtered and sorted correctly.
	 */
	public static sortFilterAndCompare(reference: any[], array: any[], sortAttr: string, sortCompareMethod: CompareMethod, sortDirection: string, searchAttr: string, searchText: string): boolean {
		const sorted = SortUtils.sortList(array, sortAttr, sortCompareMethod, sortDirection);

		const searchAttrs = searchAttr.split('.');
		const sortedFiltered = sorted.filter(function(elem: any) {
			// Iterate over the attribute path
			for (let i = 0; i < searchAttrs.length; i++) {
				elem = elem[searchAttrs[i]];
			}

			return elem.includes(searchText);
		});
		
		return SortUtils.compareArray(sortedFiltered, reference);
	}

	/**
	 * Order the array according a specific attribute and direction using a specific compare method
	 * 
	 * @param array - Array of objects to sort.
	 * @param sortAttr - The attribute to sort by (sub-attributes can be splited by .)
	 * @param sortCompareMethod - The method used to compare both properties.
	 * @param sortDirection - The order to sort the array of objects (SortDirection).
	 * @returns the object sorted
	 */
	public static sortList(array: any[], sortAttr: string, sortCompareMethod: CompareMethod, sortDirection: string): any[] {
		const sortAttrs = sortAttr.split('.');

		return array.sort(function(a: any, b: any) {
			// Iterate over the attribute path for both objects
			for (let i = 0; i < sortAttrs.length; i++) {
				a = a[sortAttrs[i]];
				b = b[sortAttrs[i]];
			}

			const compare: number = sortCompareMethod(a, b);
			return sortDirection === SortDirection.DESC ? -compare : compare;
		});
	}

	/**
	 * Check if an array is sorted by a specific attribute and direction using a specific compare method.
	 * 
	 * Throws an error if the array is not sorted correctly. 
	 * 
	 * @param array - Array of objects to validate.
	 * @param sortDirection - The order to sort/validate the array of objects (SortDirection).
	 * @param sortCompareMethod - The method used to compare both properties.
	 * @param sortAttr - The attribute if the array is sorted by (sub-attributes can be split by ".")
	 * @returns True if the array is sorted, otherwise return false.
	 */
	public static isSorted(array: any[], sortDirection: string, sortCompareMethod: CompareMethod, sortAttr: string): void {
		const sortAttrs = sortAttr.split('.');

		for (let i = 0; i < array.length - 1; i++) {
			let a = array[i];
			let b = array[i + 1];

			// Iterate over the attribute path for both objects
			for (let j = 0; j < sortAttrs.length; j++) {
				a = a[sortAttrs[j]];
				b = b[sortAttrs[j]];
			}
			
			const comparison = sortCompareMethod(a, b);
			if (comparison === 0) {
				continue;
			}

			const sorted = comparison < 0 ? sortDirection === SortDirection.ASC : sortDirection === SortDirection.DESC;
			if (!sorted) {
				throw new Error(`Array is not sorted position ${i} and ${i + 1} are not in ${sortDirection} order.\n ${JSON.stringify(a, null, '\t')} \n ${JSON.stringify(a, null, '\t')}`);
			}
		}
	}

	/**
	 * Check if arrays are equals
	 *
	 * @param arrayA - The first array to compare.
	 * @param arrayB - The second array to compare. 
	 * @returns True if arrays are equal, false otherwise.
	 */
	public static compareArray(arrayA: any[], arrayB: any[]): boolean {
		return ObjectUtils.equal(arrayA, arrayB);
	}
}
