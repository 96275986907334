/**
 * Digital twin editor state
 */
export enum DigitalTwinEditorState {
	/**
	 * Editor not running waiting for configuration.
	 */
	IDLE = 0,

	/**
	 * Editor running.
	 */
	RUNNING = 1,

	/**
	 * Editor instance disposed can no longer be used.
	 */
	DISPOSED = 2
}
