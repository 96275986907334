import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {AssetSettings} from '../../../models/asset-portfolio/asset-settings';

export class AssetSettingsService {
	/**
	 * Get the master data settings from the API.
	 *
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async get(hideLoading: boolean = false, displayError: boolean = true): Promise<AssetSettings> {
		const request = await Service.fetch(ServiceList.assetPortfolio.settings.get, null, null, {}, Session.session, hideLoading, displayError);
		return AssetSettings.parse(request.response.settings);
	}

	/**
	 * Update the asset settings.
	 *
	 * @param settings - Settings object
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async update(settings: AssetSettings, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.assetPortfolio.settings.update, null, null, settings, Session.session, hideLoading, displayError);
	}
}
