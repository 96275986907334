import {Component, OnInit} from '@angular/core';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {Session} from '../../../../session';
import {MenuCardsOptions, MenuCardsComponent} from '../../../../components/layout/menu-cards/menu-cards.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'repairs-page',
	templateUrl: 'repairs.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsComponent]
})
export class RepairsPage extends ScreenComponent implements OnInit {
	public app: any = App;


	public session: any = Session;

	public permissions = [UserPermissions.REPAIR, UserPermissions.REPAIR_INSPECTIONS];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('repairs');
	}

	/**
	 * List of options in this menu.
	 */
	public options: MenuCardsOptions[] = [
		{
			route: '/menu/repairs/works',
			icon: 'build',
			label: 'repairs',
			permissions: [UserPermissions.REPAIR],
			data: null,
			badge: null
		},
		{
			route: '/menu/repairs/inspections',
			icon: 'checkbox-outline',
			label: 'repairInspections',
			permissions: [UserPermissions.REPAIR_INSPECTIONS],
			data: null,
			badge: null
		}
	];
}
