import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {AtexInspectionSettings} from '../data/master-data/atex-inspection-settings';

export class AtexInspectionSettingsService {
	/**
	 * Get the master data settings from the API.
	 *
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns Master data settings.
	 */
	public static async get(hideLoading: boolean = false, displayError: boolean = true): Promise<AtexInspectionSettings> {
		const request = await Service.fetch(ServiceList.atex.inspection.settingsGet, null, null, {}, Session.session, hideLoading, displayError);

		return AtexInspectionSettings.parse(request.response.settings);
	}
}
