import {NodeSocket, Object2D, Text, Vector2} from 'escher.js/build/escher.module.js';
import {DestroyableNode} from './destroyable-node';

export class OperationNode extends DestroyableNode {
	/**
	 * Math operation performed by this node.
	 */
	public operation: string = '';

	/**
	 * Label shown to the user in th graph.
	 */
	public label: string = '';

	public text: Text = null;

	public a: NodeSocket = null;

	public b: NodeSocket = null;

	public r: NodeSocket = null;
	
	public constructor(operation: string, label: string) {
		super();

		// @ts-ignore
		this.type = 'OperationNode';

		this.operation = operation;
		this.label = label;

		// @ts-ignore
		this.box.set(new Vector2(-50, -35), new Vector2(50, 35));

		this.text = new Text();
		this.text.serializable = false;
		this.text.font = '25px Arial';
		this.text.layer = 2;
		// @ts-ignore
		this.add(this.text);
	}

	public registerSockets(): void {
		if (!this.a) {
			// @ts-ignore
			this.a = this.addInput('string', 'a');
		}

		if (!this.b) {
			// @ts-ignore
			this.b = this.addInput('string', 'b');
		}

		if (!this.r) {
			// @ts-ignore
			this.r = this.addOutput('string', 'r');
			this.r.getValue = () => {
				return '(' + this.a.getValue() + this.operation + this.b.getValue() + ')';
			};
		}
	}

	public onUpdate(): void {
		super.onUpdate();

		this.text.text = this.label;
	}

	public serialize(recursive): void {
		// @ts-ignore
		const data = super.serialize(recursive);
		data.operation = this.operation;
		data.label = this.label;
		data.a = this.a !== null ? this.a.uuid : null;
		data.b = this.b !== null ? this.b.uuid : null;
		data.r = this.r !== null ? this.r.uuid : null;
		return data;
	}

	public parse(data, root): void {
		// @ts-ignore
		super.parse(data, root);
		this.operation = data.operation;
		this.label = data.label;

		if (data.a !== null) {
			this.a = root.getChildByUUID(data.a);
		}
		if (data.b !== null) {
			this.b = root.getChildByUUID(data.b);
		}
		if (data.r !== null) {
			this.r = root.getChildByUUID(data.r);
			this.r.getValue = () => {
				return '(' + this.a.getValue() + this.operation + this.b.getValue() + ')';
			};
		}
	}
}

Object2D.register(OperationNode, 'OperationNode');

