import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {Company} from '../../../models/companies/company';

export class CompanyService {

	/**
	 * Get a company by its uuid.
	 *
	 * @param companyUUID - The UUID of the Company.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Company that has that UUID.
	 */
	public static async get(companyUUID: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<Company> {
		const request = await Service.fetch(ServiceList.company.get, null, null, {uuid: companyUUID}, Session.session, hideLoading, displayError);
		return Company.parse(request.response.company);
	}

	/**
	 * Gets multiple companies in batch by their UUIDs.
	 *
	 * @param companyUUIDs - The UUIDs of the Companies.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Array of companies that match the UUIDs.
	 */
	public static async getBatch(companyUUIDs: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<Company[]> {
		const request = await Service.fetch(ServiceList.company.getBatch, null, null, {companies: companyUUIDs}, Session.session, hideLoading, displayError);
		return request.response.companies.map((c: Company) => { return Company.parse(c); });
	}
}
