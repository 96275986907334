import {Component, OnInit} from '@angular/core';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UnoGraphComponent} from '../../../../components/uno/uno-graph/uno-graph.component';

@Component({
	selector: 'demo-expression-editor-page',
	templateUrl: 'demo-expression-editor.page.html',
	standalone: true,
	imports: [UnoGraphComponent]
})
export class DemoExpressionEditorPage extends ScreenComponent implements OnInit {
	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('expression');
	}
}
