import {Matrix4, Camera} from 'three';

/**
 * 3D renderer using DOM elements.
 *
 * Applies the threejs transformation hierarchy to the DOM element using CSS3D.
 *
 * Only renders CSS specific objects, the output of the renderer is not combined with the WebGL output. Everything is renderer of top.
 */
export class CSS3DRenderer {
	/**
	 * Width of the renderer.
	 */
	public width: number = 0;

	/**
	 * Height of the renderer.
	 */
	public height: number = 0;

	/**
	 * Temporary matrix object.
	 */
	public matrix: Matrix4;

	/**
	 * Object cache, used to store the rendered objects state.
	 */
	public cache: { objects: WeakMap<object, any>; camera: { style: string; fov: number } };

	/**
	 * Main DOM element used for the renderer.
	 */
	public domElement: HTMLElement = null;

	/**
	 * Camera projected DOM element.
	 */
	public cameraElement: HTMLDivElement = null;

	public constructor(domElement) {
		this.width = 2;
		this.height = 2;

		this.matrix = new Matrix4();

		this.cache =
		{
			camera: {fov: 0, style: ''},
			objects: new WeakMap()
		};

		this.domElement = domElement !== undefined ? domElement : document.createElement('div');
		this.domElement.style.overflow = 'hidden';
		this.domElement.style.pointerEvents = 'none';

		this.cameraElement = document.createElement('div');
		// @ts-ignore
		this.cameraElement.style.WebkitTransformStyle = 'preserve-3d';
		this.cameraElement.style.transformStyle = 'preserve-3d';
		this.domElement.appendChild(this.cameraElement);
	}

	/**
	 * Get the size of the renderer.
	 */
	public getSize(): any {
		return {width: this.width, height: this.height};
	}

	/**
	 * Set the size of the renderer.
	 *
	 * The size is also applied to the internal DOM division.
	 */
	public setSize(width, height): void {
		this.width = width;
		this.height = height;

		this.domElement.style.width = width + 'px';
		this.domElement.style.height = height + 'px';
		this.cameraElement.style.width = width + 'px';
		this.cameraElement.style.height = height + 'px';
	}

	/**
	 * Render the CSS object of a scene using a camera.
	 */
	public render(scene, camera): void {
		const matrix = this.matrix;

		// Get the camera transform as a css 3D string
		function getCameraCSSMatrix(m: Matrix4): string {
			const elements = m.elements;

			return 'matrix3d(' +
				elements[0] + ',' +
				-elements[1] + ',' +
				elements[2] + ',' +
				elements[3] + ',' +
				elements[4] + ',' +
				-elements[5] + ',' +
				elements[6] + ',' +
				elements[7] + ',' +
				elements[8] + ',' +
				-elements[9] + ',' +
				elements[10] + ',' +
				elements[11] + ',' +
				elements[12] + ',' +
				-elements[13] + ',' +
				elements[14] + ',' +
				elements[15] +
			')';
		}

		// Get the object transform as a css 3D string
		function getObjectCSSMatrix(m: Matrix4): string {
			const elements = m.elements;
			
			return 'translate(-50%,-50%)matrix3d(' +
				elements[0] + ',' +
				elements[1] + ',' +
				elements[2] + ',' +
				elements[3] + ',' +
				-elements[4] + ',' +
				-elements[5] + ',' +
				-elements[6] + ',' +
				-elements[7] + ',' +
				elements[8] + ',' +
				elements[9] + ',' +
				elements[10] + ',' +
				elements[11] + ',' +
				elements[12] + ',' +
				elements[13] + ',' +
				elements[14] + ',' +
				elements[15] +
			')';
		}

		const self = this;

		// Auxiliar method to render a single object
		function renderObject(object: any, cam: Camera, cameraCSSMatrix: string): void {
			// Render only CSS objects
			if (object.isCSS3DObject === true) {
				// Store the css transformation style value
				let style;

				// Remove rotation from the transformation matrix for Sprites
				if (object.isCSS3DSprite === true) {
					matrix.copy(cam.matrixWorldInverse);
					matrix.transpose();
					matrix.copyPosition(object.matrixWorld);
					matrix.scale(object.scale);

					matrix.elements[3] = 0;
					matrix.elements[7] = 0;
					matrix.elements[11] = 0;
					matrix.elements[15] = 1;

					style = getObjectCSSMatrix(matrix);
				} else {
					style = getObjectCSSMatrix(object.matrixWorl);
				}

				const element = object.element;
				const cachedObject = self.cache.objects.get(object);

				// Add the DOM element to the cache
				if (cachedObject === undefined || cachedObject.style !== style) {
					element.style.WebkitTransform = style;
					element.style.transform = style;
					self.cache.objects.set(object, {style: style});
				}

				// If the DOM element does not have a parent add to the cameraElement division
				if (element.parentNode !== self.cameraElement) {
					self.cameraElement.appendChild(element);
				}
			}

			// Render children object
			for (let i = 0, l = object.children.length; i < l; i++) {
				renderObject(object.children[i], cam, cameraCSSMatrix);
			}
		}

		// Get the effective camera fov from the projection matrix
		const fov = camera.projectionMatrix.elements[5] * (this.height / 2);

		// If the camera fov is different from the cached one adjust values.
		if (this.cache.camera.fov !== fov) {
			if (camera.isPerspectiveCamera) {
				// @ts-ignore
				this.domElement.style.WebkitPerspective = fov + 'px';
				this.domElement.style.perspective = fov + 'px';
			}

			this.cache.camera.fov = fov;
		}

		// Update the scene world matrix
		scene.updateMatrixWorld();

		// Update the camera world matrix
		if (camera.parent === null) {
			camera.updateMatrixWorld();
		}

		let cameraCSSMatrix;

		// Orthographic camera matrix
		if (camera.isOrthographicCamera) {
			const tx = -(camera.right + camera.left) / 2;
			const ty = (camera.top + camera.bottom) / 2;

			cameraCSSMatrix = 'scale(' + fov + ')' + 'translate(' + tx + 'px,' + ty + 'px)' + getCameraCSSMatrix(camera.matrixWorldInverse);
			// Perspective camera matrix
		} else {
			cameraCSSMatrix = 'translateZ(' + fov + 'px)' + getCameraCSSMatrix(camera.matrixWorldInverse);
		}

		const style = cameraCSSMatrix + 'translate(' + this.width / 2 + 'px,' + this.height / 2 + 'px)';

		// If the style is different from cache adjust style
		if (this.cache.camera.style !== style) {
			// @ts-ignore
			this.cameraElement.style.WebkitTransform = style;
			this.cameraElement.style.transform = style;
			this.cache.camera.style = style;
		}

		// Render scene recursively
		renderObject(scene, camera, cameraCSSMatrix);
	}
}

