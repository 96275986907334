import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {TeamListPage} from './screens/list/team-list.page';
import {TeamEditPage} from './screens/edit/team-edit.page';

const routes: Routes = [
	{
		path: '',
		component: TeamListPage
	},
	{
		path: 'edit',
		component: TeamEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TeamsRouterModule { }
