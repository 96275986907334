import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Locale} from '../../../../../../locale/locale';
import {Modal} from '../../../../../../modal';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {FFPRecommendation} from '../../../../../../models/atex-inspections/ffp/ffp-recommendation';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {AtexFfpService} from '../../../services/atex-ffp.service';
import {FFPRecommendationLayout} from './ffp-recommendations-layout';

@Component({
	selector: 'masterdata-ffp-recommendations-page',
	templateUrl: 'ffp-recommendations.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})
export class FFPRecommendationsPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public session: any = Session;

	public layout: any = FFPRecommendationLayout;

	public permissions = [UserPermissions.FFP_RECOMMENDATIONS_EDIT];

	public data: FFPRecommendation[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.data = [];

		App.navigator.setTitle('ffpRecommendations');

		this.data = await AtexFfpService.listRecommendations();
	}

	public add(): void {
		const recommendation = new FFPRecommendation();
		recommendation.id = this.data.reduce((id: number, ffp: FFPRecommendation) => {return id > ffp.id ? id : ffp.id;}, 0) + 1;
		this.data.push(recommendation);
	}

	public delete(recommendation: FFPRecommendation): void {
		const index = this.data.indexOf(recommendation);
		if (index !== -1) {
			this.data.splice(index, 1);
		}
	}

	/**
	 * Update the checklist value in the server.
	 */
	public save(): void {
		const data = structuredClone(this.data);

		Modal.confirm(Locale.get('confirm'), Locale.get('masterdataConfirm')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.atex.ffp.recommendationsUpdate, null, null, data, Session.session, (response, xhr) => {
					Modal.toast(Locale.get('updatedSuccessfully'));
					App.navigator.pop();
				});
			}
		});
	}
}
