import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {UnoListLazyLoadHandler} from '../../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {ServiceList} from '../../../../../http/service-list';
import {Resource} from '../../../../../models/resource';
import {ServiceSync} from '../../../../../http/service-sync';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Session} from '../../../../../session';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';

@Component({
	selector: 'digital-twin-scene-list',
	templateUrl: './digital-twin-scene-list.page.html',
	standalone: true,
	imports: [UnoButtonComponent, UnoSearchbarComponent, IonicModule, FormsModule, UnoListItemComponent, UnoListItemIconComponent, UnoListItemLabelComponent, UnoContentComponent, NgStyle, UnoListComponent, TranslateModule]
})
export class DigitalTwinSceneListPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public selfStatic: any = DigitalTwinSceneListPage;

	public settings = Session.settings;

	public resource: any = Resource;

	public permissions = [UserPermissions.DIGITAL_TWIN];

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	/**
	 * Possible sort direction values.
	 */
	public static sortDirectionValues: any = [SortDirection.ASC, SortDirection.DESC];

	/**
	 * Possible database filters to be used for sorting the list.
	 */
	public static sortFieldValues: any = [
		{label: 'name', attribute: 'name'},
		{label: 'description', attribute: 'description'},
		{label: 'updatedAt', attribute: 'updated_at'}
	];

	/**
	 * List of possible search fields.
	 */
	public static searchFieldValues: any = [
		{label: 'name', attribute: 'name'},
		{label: 'description', attribute: 'description'}
	];

	public static filters = {
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: DigitalTwinSceneListPage.sortDirectionValues[1],

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: DigitalTwinSceneListPage.sortFieldValues[0].attribute,

		/**
		 * Text used to filter scenes.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [DigitalTwinSceneListPage.searchFieldValues[0].attribute, DigitalTwinSceneListPage.searchFieldValues[1].attribute]
	};

	public static defaultFilters = structuredClone(DigitalTwinSceneListPage.filters);

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('digitalTwin');

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const params = {
				from: count,
				count: pageSize,
				search: DigitalTwinSceneListPage.filters.search,
				sortDirection: DigitalTwinSceneListPage.filters.sortDirection,
				sortField: DigitalTwinSceneListPage.filters.sortField,
				searchFields: DigitalTwinSceneListPage.filters.searchFields
			};

			const request = await this.serviceSync.fetch(ServiceList.digitalTwin.scene.list, null, null, params, Session.session);
			const response = request.response;

			return {
				elements: response.scenes,
				hasMore: response.hasMore
			};
		};
		this.handler.reset();
	}

	public resetFilters(): void {
		this.serviceSync.reset();

		Object.assign(DigitalTwinSceneListPage.filters, DigitalTwinSceneListPage.defaultFilters);

		this.handler.reset();
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public onFilterChange(event: any): void {
		if (event.target === undefined) {
			DigitalTwinSceneListPage.filters.search = event;
		}

		this.serviceSync.reset();
		this.handler.reset();
	}

	protected readonly ResourceUtils = ResourceUtils;
}
