/**
 * File Extension handling utilities.
 */
export class FileExtensionUtils {

	/**
     * Check if format corresponds to an image.
     * 
     * @param format - string of the file extension
     * @returns true if is an image
     */
	public static isImage(format: string): boolean {
		return format === 'jpeg' || format === 'png' || format === 'gif' || format === 'bmp' || format === 'webp' || format === 'jpg';
	}

	/**
     * Check if format corresponds to a pdf.
     * 
     * @param format - string of the file extension
     * @returns true if is a pdf
     */
	public static isPDF(format: string): boolean {
		return format === 'pdf';
	}

	/**
     * Check if format corresponds to an office format.
     * 
     * @param format - string of the file extension
     * @returns true if it's an office format
     */
	public static isOffice(format: string): boolean {
		return format === 'doc' || format === 'docx' || format === 'xls' || format === 'xlsx' || format === 'ppt' || format === 'pptx';
	}
}
