import {Component, OnInit} from '@angular/core';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'dl50-master-data-menu-page',
	templateUrl: 'dl50-master-data-menu.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent]
})
export class DL50MasterDataMenuPage extends ScreenComponent implements OnInit {
	/**
	 * The permissions to access this screen.
	 */
	public permissions = [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_CREATE, UserPermissions.DL50_MASTER_DATA_QUESTIONS_EDIT, UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_CREATE, UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_EDIT];

	/**
	 * List of options in this menu.
	 */
	public groups: MenuCardsGroup[] = [
		{
			label: '',
			permissions: [],
			options: [
				{
					label: 'questions',
					data: {},
					permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_CREATE, UserPermissions.DL50_MASTER_DATA_QUESTIONS_EDIT, UserPermissions.DL50_MASTER_DATA_QUESTIONS_DELETE],
					icon: './assets/icons/material/list_alt/outline.svg',
					route: '/menu/dl50/master-data/questions/list',
					badge: null
				},
				{
					label: 'standards',
					data: {},
					permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_CREATE, UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_EDIT, UserPermissions.DL50_MASTER_DATA_REGULATORY_STANDARDS_DELETE],
					icon: './assets/icons/material/developer_guide/outline.svg',
					route: '/menu/dl50/master-data/regulatory-standards/list',
					badge: null
				},
				{
					label: 'masterSettings',
					data: {},
					permissions: [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_SETTINGS],
					icon: 'settings',
					route: '/menu/dl50/master-data/configuration',
					badge: null
				}
			]
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();

		App.navigator.setTitle('masterdata');
	}
}
