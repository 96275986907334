import * as XLSX from 'xlsx';
import {StringUtils} from './string-utils';
import {FileUtils} from './file-utils';

export type XlsxSheetData = {
	/**
	 * Sheet name
	 */
	name: string,

	/**
	 * Data related to this sheet, organized by row. The first row can be used as the file header.
	 * 
	 * E.g.:
	 * [
	 *  ['collumn1', 'collumn 2', 'collumn 3', '...'],
	 *  [1, false, 'row 1', '...'],
	 *  [2, true, 'row 2', '...']
	 * ]
	 */
	data: any[][]
};

/**
 * Utils to read, write and manipulate XLSX data.
 */
export class XlsxUtils {
	/**
	 * Configuration used to write XLSX files.
	 */
	public static writeConfig: XLSX.WritingOptions = {
		compression: true,
		bookSST: false,
		ignoreEC: true
	};

	/**
	 * Write data as a XLSX file from data stored as bi-dimensional array ([row][column]).
	 *
	 * The file is created with a single sheet.
	 * 
	 * Data is formated by row. The first row can be used as the sheet header.
	 * 
	 * E.g.:
	 * [
	 *  ['collumn1', 'collumn 2', 'collumn 3', '...'],
	 *  [1, false, 'row 1', '...'],
	 *  [2, true, 'row 2', '...']
	 * ]
	 *
	 * @param data - Data stored as a bi-dimensional array.
	 * @param fname - Output file name.
	 * @param name - Name of the sheet.
	 */
	public static writeFile(data: any[][], fname: string = 'file.xlsx', name: string = 'Sheet'): void {
		const sheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
		const book: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(book, sheet, name);
		XLSX.writeFile(book, fname, XlsxUtils.writeConfig);
	}

	/**
	 * Write data as a XLSX file from data stored on sheets with bi-dimensional data arrays ([row][column]).
	 *
	 * The file is created with multiple sheets.
	 * 
	 * The first row of data on each sheet can be used as the sheet header.
	 *
	 * @param sheets - All the xlsx sheets and its data stored as a bi-dimensional array ([row][column]) to insert on document.
	 * @param fname - Output file name.
	 */
	public static writeMultiSheetFile(sheets: XlsxSheetData[], fname: string = 'file.xlsx'): void {
		const book: XLSX.WorkBook = XLSX.utils.book_new();

		for (let i = 0; i < sheets.length; i++) {
			const sheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheets[i].data);

			const sheetName: string = sheets[i].name.replace(/[\\\/\\*\?[\]]/g, '').substring(0, 30);

			XLSX.utils.book_append_sheet(book, sheet, sheetName);
		}

		XLSX.writeFile(book, fname, XlsxUtils.writeConfig);
	}

	/**
	 * Write a table data as a XLSX file from data stored in an array of json entries.
	 *
	 * Each column of the table will be named as the json attributes present in the object.
	 *
	 * The file is created with a single sheet.
	 *
	 * @param data - Data stored as an array of objects.
	 * @param fname - Output file name.
	 * @param name - Name of the sheet.
	 */
	public static writeFileObject(data: any[], fname: string = 'file.xlsx', name: string = 'Sheet'): void {
		const sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
		const book: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(book, sheet, name);
		XLSX.writeFile(book, fname, XlsxUtils.writeConfig);
	}

	/**
	 * Choose a file from the host system and read it as XLSX.
	 *
	 * @returns Content as an array of objects (attribute by column).
	 */
	public static async chooseFileXLSX(): Promise<any[]> {
		const files = await FileUtils.chooseFile('.xlsx', false);

		if (files.length > 0) {
			return new Promise(function(resolve, reject) {
				const file = files[0];
				const reader = new FileReader();
				reader.onload = function() {
					const xlsx = XLSX.read(reader.result, {type: 'binary', cellDates: true});
					if (xlsx.SheetNames.length > 0) {
						resolve(XLSX.utils.sheet_to_json(xlsx.Sheets[xlsx.SheetNames[0]]));
					}
				};
				reader.onerror = function() {
					reject();
				};

				reader.readAsBinaryString(file);
			});
		}

		return Promise.reject();
	}

	/**
	 * Read attributes from a row into a object.
	 *
	 * Uses a map of values that indicates possible row names for each one of the object attributes.
	 *
	 * @param object - Object to store the result of the row in.
	 * @param row - Row object obtained from the XLSX file read as JSON data.
	 * @param attributes - List of attributes to be read alongside with the list of possible names for each one.
	 * @returns Object with values read from the row.
	 */
	public static readRowToObject(object: any, row: any, attributes: { attribute: string, names: string[] }[]): void {
		for (let i = 0; i < attributes.length; i++) {
			const value = XlsxUtils.readRow(row, attributes[i].names);
			if (value !== null) {
				object[attributes[i].attribute] = value;
			}
		}
	}

	/**
	 * Read attributes from a row into a map.
	 *
	 * Using a list of values that indicates possible row names for each one of the object attributes.
	 *
	 * @param row - Row object obtained from the XLSX file read as JSON data.
	 * @param attributes - List of attributes to be read alongside with the list of possible names for each one.
	 * @returns Map with values read from the row.
	 */
	public static readRowAsMap(row: any, attributes: { attribute: string, names: string[] }[]): Map<string, any> {
		const map = new Map();

		for (let i = 0; i < attributes.length; i++) {
			const value = XlsxUtils.readRow(row, attributes[i].names);
			if (value !== null) {
				map.set(attributes[i].attribute, value);
			}
		}

		return map;
	}

	// Checks the value and length of a row cell to fully discard empty cells.
	//
	// @params rowMap - The entire row' cells map to check the cell from.
	// @params header - The cell header to check.
	// @returns a true boolean if cell has a valid value, false otherwise.
	public static hasCell(rowMap: Map<string, any>, header: string): boolean {
		return rowMap.has(header) && String(rowMap.get(header)).length > 0;
	}

	/**
	 * Read a set of repeated attribute names from a XLSX files. The names and column names are normalized before comparison.
	 *
	 * The column names are retrieved by the XLSX library with the index appended (e.g ABC, ABC_1, ABC_2)
	 *
	 * @param row - Row object obtained from the XLSX file read as JSON data.
	 * @param index - Index to search for in the XLSX value.
	 * @param names - List of possible names for the columns without the index.
	 * @returns Object with values read from the row.
	 */
	public static readRowArrayValue(row: any, index: number, names: string[]): any {
		for (let j = 0; j < names.length; j++) {
			let name = names[j];
			if (index > 0) {
				name += '_' + index;
			}
			name = StringUtils.normalize(name);

			for (const i in row) {
				if (StringUtils.normalize(i) === name) {
					return row[i];
				}
			}
		}

		return null;
	}

	/**
	 * Get a value from a row from a list of possibilities. The names and column names are normalized before comparison.
	 *
	 * Checks the row object of the existence of one of the attribute name options provided.
	 *
	 * @param row - Row object obtained from the XLSX file read as JSON data.
	 * @param names - List of possible names for the column.
	 * @returns The value obtained from the row object if found, null otherwise.
	 */
	public static readRow(row: any, names: string[]): any {
		for (let i = 0; i < names.length; i++) {
			const name = StringUtils.normalize(names[i]);

			for (const k in row) {
				if (StringUtils.normalize(k) === name) {
					return row[k];
				}
			}
		}

		return null;
	}
}
