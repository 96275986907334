export const LocaleEN = {
	// App
	app: 'UNO Asset Management',
	uno: 'UNO',
	eqs: 'EQS',

	// Locales
	portuguese: 'Portuguese',
	english: 'English',
	spanish: 'Spanish',

	// Months
	january: 'January',
	february: 'February',
	march: 'March',
	april: 'April',
	may: 'May',
	june: 'June',
	july: 'July',
	august: 'August',
	september: 'September',
	october: 'October',
	november: 'November',
	december: 'December',

	// Week days
	monday: 'Monday',
	tuesday: 'Tuesday',
	wednesday: 'Wednesday',
	thursday: 'Thursday',
	friday: 'Friday',
	saturday: 'Saturday',
	sunday: 'Sunday',

	// Forms
	text: 'Text',
	checkbox: 'Checkbox',
	document: 'Document',

	// Login
	welcome: 'Welcome!',
	enterCredentials: 'Enter your account data to login.',
	login: 'Login',
	logout: 'Logout',
	forgotPassword: 'Recover account password.',
	confirmPassword: 'Confirm Password',
	returnLogin: 'Return to login.',
	twoFactorAuth: 'Two-Factor Authentication',
	email: 'E-Mail',
	sms: 'SMS',
	password: 'Password',
	reset: 'Reset',
	recover: 'Recover',
	code: 'Code',
	recoverEmail: 'An email with instructions to recover access to the platform was sent.',
	enterNewPasswordReset: 'Enter your new password and click on reset to change it.',
	enterEmailToRecover: 'Enter your user email to recover access to your account.',
	passwordsDontMatch: 'Passwords do not match. Check inserted values.',
	passwordResetSuccess: 'Password was reset successfully.',
	passwordResetError: 'There was an error while attempting to reset your password. Please request a new reset email.',
	passwordSizeError: 'The password must have, at least, 8 characters.',
	loginSuccess: 'Login was successful.',
	invalidLogin: 'Invalid login credentials. Check your email and password.',
	invalidPhoneNumber: 'Phone number is not valid.',
	invalidEmail: 'Email is not valid. Insert a valid email format.',
	twoFactorCode: 'Two Factor authentication is enabled for this account. A code has been sent to your email/phone. Please provide the code to continue.',

	// Master data
	masterdata: 'Master Data',
	ffpRecommendations: 'FFP Recommendations',
	inspectionChecklists: 'Inspection Checklists',
	inspectionFields: 'Inspection Fields',
	masterdataConfirm: 'Are you sure that you want to update these values? May cause irrecoverable failure on database.',
	errorRepeatedID: 'ID {id} used multiple times for different entries.',
	errorFieldInspectionMissing: 'Field {field} of inspection {inspection} does not exist.',
	errorFieldInspectionRemoved: 'Field {field} used in inspection {inspection} was removed.',
	errorAttributeShouldHaveKeyValue: 'Attribute {attribute} should have the same value as the object key.',
	errorOnlyBackofficeInspector: 'Inspection field {field} can only belong to backoffice or to inspection.',

	// File formats
	docx: 'DOCX',
	pdf: 'PDF',
	zip: 'ZIP',

	// Import/Export
	global: 'Global',
	import: 'Import',
	export: 'Export',
	exportJSON: 'Export JSON',
	dataGenerators: 'Data Generators',
	tools: 'Tools',
	howMany: 'How Many',
	generate: 'Generate',
	restore: 'Restore',
	database: 'Database',
	size: 'Size',
	runGapAnalysis: 'Run Gap Analysis',
	runGapAnalysisSub: 'Run gap analysis for all inspections. Recreated deleted gaps and restores archieved gaps.',
	generateQRTags: 'QR Code Tags',
	generateQRTagsSub: 'Generate multiple QR codes with random value to be attached to equipments. All QR are exported as PDF or ZIP file.',
	checkAtexInspectionsEmptyFields: 'Check ATEX Inspections Empty Fields ',
	checkAtexInspectionsEmptyFieldsSub: 'Check if every ATEX inspection has all fields filled. Returns a XLSX file with a list of all ATEX inspections missing fields.',
	checkAtexInspectionNoForms: 'Check ATEX Inspections Form selection',
	checkAtexInspectionNoFormsSub: 'Check if all inspections have forms selected. If not, select a default form and set the result, based on the existence of a tag (if it has a tag, inspections are approved, otherwise they are not).',
	defaultAtexInspectionForm: 'Default ATEX Inspection form',
	defaultAtexInspectionFormError: 'Default inspection form does not exist, check the master data checklist section.',
	updateReports: 'Update Reports',
	updateReportsSub: 'Update reports for all inspection projects available.',
	exportXlsx: 'Export XLSX',
	exportGapsXlsx: 'Gaps XLSX',
	exportGapsXlsxSub: 'Export a XLSX file containing all the gaps and gap recommendations inserted in the platform.',
	epiJson: 'CSI JSON',
	exportEPIJsonSub: 'Export a JSON file containing all CSI (Intrinsic Security Circuit) data available in the platform.',
	ffpJson: 'FFP JSON',
	exportFFPJsonSub: 'Export a JSON file containing all FFP (Fit For Purpose) data available in the platform.',
	assetsJson: 'Assets JSON',
	exportAssetsJsonSub: 'Export a JSON file containing the data of the assets inserted in the platform.',
	assetsXlsx: 'Assets XLSX',
	exportAssetsXlsxSub: 'Export a XLSX file containing only the data of the assets inserted in the platform.',
	exportAssetsDetailedXlsx: 'Assets Detailed XLSX',
	exportAssetsDetailedXlsxSub: 'Export a XLSX file containing the detailed data of the assets inserted in the platform.',
	keyQuestionCodesXlsx: 'Key question codes XLSX',
	atexInspectionsXlsx: 'ATEX Inspections XLSX',
	exportAtexInspectionsXlsxSub: 'Export a XLSX file containing the ATEX inspections inserted in the platform.',
	inspectionsXlsx: 'Inspections XLSX',
	exportInspectionsXlsxSub: 'Export a XLSX file containing all the inspections of all the dynamic inspection projects inserted in the platform.',
	inspectionsAverageXlsx: 'Inspections Average XLSX',
	exportInspectionsAverageXlsxSub: 'Export a xlsx file containing the inspections of all the dynamic inspection projects inserted in the platform with numeric values for the checklist answers. A file with key question codes can be uploaded. Every project sheet has an extra column with error details.',
	inspectionsJson: 'Inspections JSON',
	exportInspectionsJsonSub: 'Export all dynamic inspection data as JSON file. Contains forms, workflows, projects and inspection data.',
	importInspectionWorkflowJSON: 'Inspections Workflow JSON',
	importInspectionWorkflowJSONSub: 'Import an inspection workflow from a JSON file. Imports both forms and workflows to the inspection module.',
	atexInspectionsAuditXlsx: 'ATEX Inspections Audit XLSX',
	exportAtexInspectionsAuditXlsxSub: 'Export a XLSX file containing full information of the existing ATEX inspections, as well as the entire change history.',
	atexInspectionsXlsxResults: 'ATEX Inspections Results XLSX',
	exportAtexInspectionsXlsxResultsSub: 'Export a XLSX file containing the results of all completed ATEX inspections.',
	atexInspectionsReports: 'ATEX Inspections Reports',
	atexInspectionsReportsSub: 'Export reports for all ATEX inspections available in the platform as Word or pdf documents.',
	atexInspectionsJson: 'ATEX Inspections JSON',
	exportAtexInspectionsJson: 'Export a JSON file containing all ATEX inspections inserted in the platform with their associated details.',
	ffpXlsx: 'FFP XLSX',
	exportFFPXlsxSub: 'Export a XLSX file with all the FFP (Fit For Purpose) entries, alongside with their details, associated Inspection and Asset data.',
	actionPlanXlsx: 'Action Plan XLSX',
	exportActionPlanXlsxSub: 'Export all Action Plan entries as XLSX file alongside with their details associated FFP, Inspection and Asset data.',
	atexInspectionsBulkFields: 'Change ATEX Inspection Fields Bulk by Asset Tag',
	atexInspectionsBulkFieldsSub: 'Change the field response of a list of ATEX inspections to a defined value. The list of ATEX inspections to be changed is read from a XLSX containing the list of asset tags for those inspections.',
	bulkActionPlansFFP: 'Create Action Plans from FFP entries by Asset Tag',
	bulkActionPlansFFPSub: 'Fill FFP data for equipment tags and a list fields (R1, R2, etc). Create an Action Plan with these FFP and set their state to "Waiting Client" with a document file attached. Input is a XLSX file with a "Tag" column.',
	bulkDeleteFFPUnused: 'Delete Unused FFP',
	bulkDeleteFFPUnusedSub: 'Delete all FFP associated with inspection fields that have the response "Not Applicable" or that contain fields that have been removed. Use carefully, this action cannot be reversed.',
	repairsXlsx: 'Repairs XLSX',
	exportRepairsXlsxSub: 'Export a XLSX file, containing the full data of the repairs inserted in the platform.',
	importRepairsXlsxSub: 'Import a list of repairs from XLSX file. New repairs are created automatically and already existing (by UUID) repairs are updated.',
	importcalendarXlsxSub: 'Import a list of calendar events from XLSX file',
	calendarXlsx: 'Asset Planning XLSX',
	epiXlsx: 'CSI XLSX',
	importEpiXlsx: 'Import a list of CSI data to the application from XLSX file.',
	exportEPIXlsxSub: 'Export a list of the CSI entries in XLSX format.',
	importRENXlsxDev: 'REN Assets XLSX (DEV ONLY)',
	importRENXlsxSubDev: 'Tool to import REN assets and create asset portfolio hierarchy from a XLSX file.',
	importAssetsXlsx: 'Assets XLSX',
	importAssetsXlsxSub: 'Tool to import assets with base information, from a XLSX file.',
	exportInspectionsReportsBulk: 'Export Inspections Reports Bulk',
	exportInspectionsReportsBulkSub: 'Exports all the inspections from all the projects for a specific list of assets UUIDs, provided on a single column in a XLSx file with "assets" header.',
	exportRepairInspectionsReportsBulk: 'Inspections Reports Bulk',
	exportRepairInspectionsReportsBulkSub: 'Exports repair inspections reports in bulk.',
	exportFormBlocksXlsx: 'Asset Form Blocks (XLSX)',
	exportFormBlocksXlsxSub: 'Exports the asset portfolio form blocks and all of their form fields in XLSX format.',
	importFormBlocksXlsx: 'Asset Form Blocks (XLSX)',
	importFormBlocksXlsxSub: 'Imports form blocks and their form fields',
	importFormBlocksXlsxAdditionalInformation: 'This tool may cause permanent data loss.\n\nItems with UUID will be updated, otherwise they will be created. Form block fields must reference a form block on the form blocks sheet, either by UUID or name. Form block fields without indexing will be indexed by their order in the file. If the options to "eliminate elements not present in the document" are selected, these elements will be permanently deleted.',
	deleteMissingItemsWarning: 'You selected options to permanently delete elements not present in the document.',
	exportHasEnded: 'Export has ended.',
	exportAssetPortfolioStructureXlsx: 'Asset Portfolio Structure XLSX',
	exportAssetPortfolioStructureXlsxSub: 'Exports the entire structure of asset portfolio types, sub-types and form blocks in XLSX format.',
	exportAssetPlanningXlsxSub: 'Export the currently shown calendar events, applying the active filters.',
	userXlsx: 'User Excel',
	exportInspectionsUserSub: 'Exports all users in XLSX format.',
	errorMissingFormOnImport: 'There are references to a form with the UUID "{formUuid}" but it is missing from the imported file.',

	// Inspections
	noFormFields: 'No form fields available. Press "add" button to create new form fields.',
	noWorkflowSteps: 'No workflow steps available. Press "add" button to create workflow steps.',
	noWorkflowAdjacencies: 'No workflow adjacencies available. Press "add" button to create workflow step transitions.',
	createAtexInspection: 'Create ATEX Inspection',
	atexInspectionReportIEC60079: 'Inspections performed, comply with IEC 60079 -17 - Explosive atmospheres - Part 17: Electrical installations inspection and maintenance.',
	atexInspectionReportFormLabel: 'OK - Approved, the equipment complies with the requirements.\nOK EQS - Approved. Changes to the equipment were necessary.\nNOK - Not approved, equipment does not met the requirements.\nNot Applicable - Requirement not applicable to this equipment.',
	atexInspectionReportDisclaimer: 'The results only concern the tested items. This document can only be reproduced in its entirety.',
	atexInspectionReport: 'ATEX Equipment Inspection Report',
	inspectionPhotos: 'Inspection Photos',
	equipmentData: 'Equipment Data',
	inspectionDetails: 'Inspection Details',
	inspectionDate: 'Inspection Date',
	documentDate: 'Document Date',
	myTeamOnly: 'My team only',
	withDocuments: 'With Documents',
	waitingDocuments: 'Waiting Documents',
	inspectionReport: 'Inspection Report',
	details: 'Details',
	resultInspection: 'Inspection Result',
	resultFinal: 'Final Result',
	nok: 'Not Ok',
	okRevised: 'Ok EQS',
	todo: 'To Do',
	review: 'Review',
	approved: 'Approved',
	disapproved: 'Rejected',
	pending: 'Pending',
	conditionallyApproved: 'Conditionally Approved',
	completed: 'Completed',
	implemented: 'Implemented',
	underSupervision: 'Supervision',
	failed: 'Failed',
	inspection: 'Inspection',
	inspectionNumber: 'Inspection Number',
	atexInspection: 'ATEX Inspection',
	inspectorsCount: 'Inspectors Count',
	inspectionForms: 'Inspection Forms',
	inspectionProject: 'Inspection Project',
	inspectionProjects: 'Inspection Projects',
	inspectionProjectUuid: 'Inspection Project UUID',
	inspectorForm: 'Inspector Form',
	backofficeForm: 'Backoffice Form',
	approve: 'Approve',
	reject: 'Reject',
	clientFeedback: 'Feedback from client',
	messageSupervisor: 'Message to supervisor.',
	messageRejection: 'Rejection Justification',
	inspectionRejected: 'Inspection Rejected',
	exportReport: 'Export Report',
	exportReportPDF: 'Export Report (PDF)',
	exportReportDOCX: 'Export Report (Word)',
	editAsset: 'Edit Asset',
	confirmReject: 'Are you sure you want to reject the inspection?',
	confirmRedirect: 'If you continue with this action, all unsaved data will be lost. Confirm to proceed or cancel this action and save the data before proceeding.',
	sendToSupervision: 'Send to supervisor',
	returnToTodo: 'Return To Do',
	returnToRevision: 'Return for Revision',
	returnToBackoffice: 'Return to Backoffice',
	successInspectionSave: 'Inspection data was saved successfully.',
	successInspection: 'Inspection data was submitted successfully.',
	errorInspectionResult: 'Must select approved or failed for the inspection result.',
	errorSelectForm: 'It is required to select at least one inspection form.',
	errorInspectionState: 'Invalid inspection state found.',
	errorInspectionNotFound: 'Inspection was not found in the database.',
	warningSelectForm: 'You have not selected any inspection form. Are you sure that you want to continue?',
	warningApproveNok: 'Inspection result is set as approved but one or more fields were marked as Not Ok. Are you sure you want to continue?',
	exportAllXlsx: 'Export all (XLSX)',
	projectName: 'Project Name',
	projectCode: 'Project Code',
	createInspection: 'Create Inspection',
	optionsDisplayMode: 'Options display mode',
	checklistAnswersAvg: 'Checklist Answers Average',
	checklistWightedAnswersAvg: 'Checklist Weighted Answers Average',
	projectChecklistWeight: 'Checklist Weight',
	bypassRequiredCheck: 'Bypass required check',
	confirmRequiredCheckBypass: 'Some form fields are required and are empty. Do you want to save anyway?',
	lockStepData: 'Lock step data',
	true: 'True',
	false: 'False',
	keyQuestionCodesFile: 'Key question codes file',
	overallAssessment: 'Overall Assessment',
	assetStatus: 'Asset Status',
	inspectionQR: 'Inspection QR',
	createNewInspectionConfirmation: 'No inspections exist for this event. Do you wish to create a new one?',
	article: 'Article',
	navigateNextStep: 'Do you wish to navigate to this inspection\'s next step?',
	allowExportReportFromList: 'Allow report export from inspections list',

	// Gap Analysis
	gapAnalysis: 'Gap Analysis',
	gap: 'Gap',
	gaps: 'Gaps',
	gapUuid: 'Gap UUID',
	analysesGaps: 'Analyses Gaps',
	generatesGaps: 'Generates Gaps',
	generatesGapsOptions: 'Option that generates gaps',
	generatesGapsMultipleOptions: 'Options that generates gaps',
	gapName: 'Gap Name',
	gapDescription: 'Gap Description',
	gapDocuments: 'Gap Documents',
	inspectionDataUuid: 'Inspection data UUID',
	inspectionFieldUuid: 'Inspection field UUID',
	financialImpact: 'Financial Impact',
	gapRecommendations: 'Gap Recommendations',
	recommendationUuid: 'Recommendation UUID',
	recommendationDescription: 'Recommendation Description',
	recommendationDocuments: 'Recommendation Documents',

	// Expression Editor
	expressionEditor: 'Expression Editor',

	// Observation
	createObservation: 'Create Observation',
	observationCreated: 'Observation was created successfully',
	observationUpdated: 'Observation was updated successfully',

	// Atex
	unclassified: 'Unclassified',
	unclassifiedZone: 'Unclassified Zone',
	zone0: 'Zone 0',
	zone1: 'Zone 1',
	zone2: 'Zone 2',
	zone20: 'Zone 20',
	zone21: 'Zone 21',
	zone22: 'Zone 22',
	mine: 'Mine',
	errorAtexTags: 'ATEX property needs to have at least one tag.',
	resultAtexInspection: 'ATEX Inspection Result',
	resultAtexFinal: 'ATEX Inspection Final Result',
	liftingEquipmentRequired: 'Lifting Equipment Required',
	confirmAtexCreate: 'Are you sure you want to create an ATEX inspection?',

	// Project
	inspectionTypeRequired: 'Inspection Type Required',

	// Assets
	locations: 'Locations',
	location: 'Location',
	plants: 'Plants',
	plant: 'Plant',
	operationalUnits: 'Operational Units',
	operationalUnit: 'Operational Unit',
	operationalUnitTag: 'Operational Unit Tag',
	equipments: 'Equipments',
	equipment: 'Equipment',
	equipmentTag: 'Equipment Tag',
	equipmentSubtype: 'Equipment Sub-Type',
	equipmentOwner: 'Equipment Owner',
	components: 'Components',
	singlePhase16: 'Monophase 16A',
	triplePhase16: 'Threephase 16A',
	triplePhase63: 'Threephase 63A',
	hsx: 'HS X',
	codeLightStruct: 'Code w/ Lighting Design Structure',
	modelList: 'Models List',
	assetAlreadySelected: 'Asset already selected.',

	// QR
	qr: 'QR',
	qrReader: 'QR Reader',
	qrCode: 'QR Code',
	generateQR: 'Generate QR',
	attachQR: 'Attach QR',
	errorCameraNotFound: 'Camera not found/available in the device.',
	qrUnknown: 'QR code is not associated with any entry.',
	qrSuccess: 'QR code successfully read.',

	// NFC
	nfc: 'NFC',
	nfcReader: 'NFC Reader',
	readNFC: 'Approximate NFC tag',
	attachNFC: 'Attach NFC',
	noNFCReader: 'Device incompatible with NFC reading functionality.',
	nfcUnknown: 'NFC tag is not associated with any asset.',
	nfcErrorStarting: 'Error starting NFC Tag listener, check if NFC is enabled.',
	nfcSuccessStarting: 'Successfully started NFC Tag listener.',
	nfcSuccess: 'NFC Tag successfully read.',

	// FFP
	ffpShort: 'FFP',
	ffp: 'Fit For Purpose',
	ffpSaved: 'FFP data was saved successfully.',
	low: 'Low',
	medium: 'Medium',
	high: 'High',
	cost: 'Cost',
	priority: 'Priority',
	fieldName: 'Field Name',
	lastFFPSByAsset: 'Last FFPs by asset',

	// EPI
	epiShort: 'CSI',
	epi: 'Intrinsic Security Circuit',
	zener: 'Zener',
	galvanic: 'Galvanic',
	barrier: 'Barrier',
	barrierId: 'Barrier ID',
	barrierType: 'Barrier Type',
	cable: 'Cable',
	cables: 'Cables',
	resultingCable: 'Resulting Cable',
	comments: 'Comments',
	resistance: 'Resistance',
	resistanceSection: 'Section Resistance',
	maxOutputVoltage: 'Max. Output Voltage (Vo)',
	maxOutputCurrent: 'Max. Output Current (Io)',
	maxOutputPower: 'Max. Output Power (Po)',
	maxExternalCapacity: 'Max. External Capacity (Co)',
	maxExternalInductance: 'Max. External Inductance (Lo)',
	groundResistance: 'Ground groundResistance (Rt)',
	maxInputVoltage: 'Max. Input Voltage (Vi)',
	maxInputCurrent: 'Max. Input Current (Ii)',
	maxInputPower: 'Max. Input Power (Pi)',
	maxInternalCapacity: 'Max. Internal Capacity (Ci)',
	maxInternalInductance: 'Max. Internal Inductance (Li)',
	length: 'Length',
	inductance: 'Inductance',
	cableCapacity: 'Capacity (Cc)',
	capacitySection: 'Section Capacity',
	cableInductance: 'Inductance (Lc)',
	inductanceSection: 'Section Inductance',
	intrinsicSecurityTest: 'Intrinsic Security Test',
	acceptedResult: 'Accepted/Not Accepted',
	epiSaved: 'Intrinsic Security Circuit was saved successfully.',
	epiSubmitted: 'Intrinsic Security Circuit submitted for review.',
	epiApproved: 'Intrinsic Security Circuit approved successfully.',
	loopEquipments: 'Loop Equipments',

	// Digital Twin
	locked: 'Locked',
	fullscreen: 'Fullscreen',
	visible: 'Visible',
	top: 'Top',
	bottom: 'Bottom',
	left: 'Left',
	right: 'Right',
	front: 'Front',
	pointcloud: 'Pointcloud',
	move: 'Move',
	rotate: 'Rotate',
	scale: 'Scale',
	screenshot: 'Screenshot',
	navigate: 'Navigate',
	transform: 'Transform',
	focus: 'Focus',
	selectObjectFirst: 'To perform this operation select an object.',
	lockedEditError: 'Locked objects cannot be edited. Unlock object before applying changes.',

	// Misc
	displayMode: 'Display Mode',
	sections: 'Sections',
	tabs: 'Tabs',
	inspectionResult: 'Inspection Result',
	flowMode: 'Flow Mode',
	sendNotification: 'Send Notification',
	executed: 'Executed',
	preview: 'Preview',
	manufacturingYear: 'Manufacturing Year',
	installationDate: 'Installation Date',
	level: 'Level',
	itemsOf: '{n} of {total} items',
	pagesOf: '{n} of {total} pages',
	alarmConfiguration: 'Alarm Configuration',
	configuration: 'Configuration',
	inProgress: 'In Progress',
	noAction: 'No Action',
	filters: 'Filters',
	customized: 'Customized',
	activities: 'Activities',
	copy: 'Copy',
	clone: 'Clone',
	deepClone: 'Deep Clone',
	cloneSubForms: 'Clone Sub-forms',
	cloneSuccessfully: 'Successfully Cloned',
	spacing: 'Spacing',
	chemical: 'Chemical',
	audit: 'Audit',
	stopMaintenance: 'Maintenance Stoppage',
	actionType: 'Action Type',
	actionSubtype: 'Action SubType',
	duration: 'Duration',
	alarmType: 'Alarm Type',
	alarmOffset: 'Alarm Offset',
	fileName: 'File Name',
	upload: 'Upload',
	uploadXlsx: 'Upload XLSX',
	row: 'Row',
	accept: 'Accept',
	deleteNotApplicable: 'Delete Not Applicable',
	deleteNonExistent: 'Delete Non-Existent',
	deleteEvent: 'Delete Event',
	nonExistentField: 'Non-Existent Field',
	notApplicableField: 'Not Applicable Field',
	additionalInformations: 'Additional Informations',
	user: 'User',
	formats: 'Formats',
	signature: 'Signature',
	composedField: 'Composed Field',
	multipleOptions: 'Multiple Options',
	multipleOptionsLegacy: 'Multiple Options Legacy',
	requireAsset: 'Require Asset',
	subForm: 'Sub-Form',
	multipleForm: 'Multiple Form',
	required: 'Required',
	sampleFile: 'Sample File',
	anomalies: 'Anomalies',
	forecast: 'Forecast',
	fillGaps: 'Fill Gaps',
	basic: 'Basic',
	confidenceInterval: 'Confidence Interval',
	average: 'Average',
	derivative: 'Derivative',
	derivativeAbsolute: 'Derivative (Absolute)',
	timeseries: 'Time Series',
	last: 'Last',
	first: 'First',
	areaZoom: 'Select area to zoom',
	restoreZoom: 'Restore zoom level.',
	saveAsImage: 'Save as Image',
	forAll: 'For All',
	onlyForNew: 'Only for new',
	format: 'Format',
	separator: 'Separator',
	showInMenu: 'Show in Menu',
	reports: 'Reports',
	manual: 'Manual',
	template: 'Template',
	templates: 'Templates',
	reportTemplate: 'Report Template',
	proposalTemplate: 'Proposal Template',
	origin: 'Origin',
	destination: 'Destination',
	label: 'Label',
	forms: 'Forms',
	steps: 'Steps',
	step: 'Step',
	defaultStep: 'Default Step',
	adjacency: 'Adjacency',
	adjacencies: 'Adjacencies',
	workflow: 'Workflow',
	workflows: 'Workflows',
	projects: 'Projects',
	open: 'Open',
	view: 'View',
	nextMonth: 'Next Month',
	previousMonth: 'Previous Month',
	startDate: 'Start Date',
	endDate: 'End Date',
	repetitions: 'Repetitions',
	mode: 'Mode',
	effectiveDate: 'Effective Date',
	change: 'Change',
	register: 'Register',
	clear: 'Clear',
	number: 'Number',
	silent: 'Silent',
	expression: 'Expression',
	color: 'Color',
	virtual: 'Virtual',
	condition: 'Condition',
	inside: 'Inside',
	outside: 'Outside',
	greater: 'Greater',
	less: 'Less',
	inBetween: 'In Between',
	response: 'Response',
	noDocuments: 'No Documents',
	overrideResult: 'Override Result',
	overrideFinalResult: 'Override Final Result',
	includeArchived: 'Include Archived',
	includeBackoffice: 'Include Backoffice Fields',
	includeInspector: 'Include Inspector Fields',
	includeCompleted: 'Include Completed',
	includeWithActionPlan: 'Include w/ Action Plan',
	includeWithRecommendations: 'Include w/ Recommendations',
	skipHasRecommendations: 'Dont update FFP if has Recommendations',
	dontChange: 'Do not Change',
	equipmentProtection: 'Equipment Protection',
	recenter: 'Recenter',
	permissions: 'Permissions',
	copyDocuments: 'Copy Documents',
	copiedSuccessfully: 'Copied Successfully',
	archived: 'Archived',
	archive: 'Archive',
	unarchived: 'Unarchived',
	unarchive: 'Unarchive',
	waiting: 'Waiting',
	resolved: 'Resolved',
	direction: 'Direction',
	sortField: 'Sort Fields',
	date: 'Date',
	time: 'Time',
	asset: 'Asset',
	noAsset: 'No Asset',
	graph: 'Graph',
	events: 'Events',
	event: 'Event',
	alarms: 'Alarms',
	alarm: 'Alarm',
	sensor: 'Sensor',
	sensors: 'Sensors',
	showAll: 'Show All',
	treeView: 'Tree View',
	tableFields: 'Table Fields',
	searchFields: 'Search Fields',
	noRecommendation: 'No Recommendation',
	hasRecommendation: 'Has Recommendations',
	withActionPlan: 'With Action Plan',
	withoutActionPlan: 'Without Action Plan',
	list: 'List',
	listMode: 'List mode',
	calendar: 'Calendar',
	file: 'File',
	download: 'Download',
	documents: 'Documents',
	images: 'Images',
	minute: 'Minute',
	hour: 'Hour',
	day: 'Day',
	week: 'Week',
	month: 'Month',
	year: 'Year',
	minutes: 'Minutes',
	hours: 'Hours',
	days: 'Days',
	weeks: 'Weeks',
	months: 'Months',
	years: 'Years',
	hourly: 'Hourly',
	daily: 'Daily',
	weekly: 'Weekly',
	monthly: 'Monthly',
	yearly: 'Yearly',
	selectionMode: 'Selection Mode',
	accepted: 'Accepted',
	run: 'Run',
	theme: 'Theme',
	development: 'Development',
	related: 'Related',
	latitude: 'Latitude',
	longitude: 'Longitude',
	altitude: 'Elevation',
	asc: 'Ascending',
	desc: 'Descending',
	updatedAt: 'Update Date',
	update: 'Update',
	updateAll: 'Update All',
	createdAt: 'Creation Date',
	created: 'Created',
	updated: 'Updated',
	yes: 'Yes',
	no: 'No',
	edited: 'Edited',
	nd: 'N/d',
	compliant: 'Compliant',
	nonCompliant: 'Non Compliant',
	nonConformities: 'Nonconformities',
	done: 'Done',
	message: 'Message',
	unknown: 'Unknown',
	error: 'Error',
	errors: 'Errors',
	errorLogs: 'Error Logs',
	errorLogsTasks: 'Tasks Error Logs',
	errorLogsClient: 'Client Error Logs',
	success: 'Success',
	ok: 'Ok',
	cancel: 'Cancel',
	confirm: 'Confirm',
	all: 'All',
	create: 'Create',
	delete: 'Delete',
	remove: 'Remove',
	edit: 'Edit',
	add: 'Add',
	back: 'Back',
	dismiss: 'Dismiss',
	close: 'Close',
	options: 'Options',
	optionsLegacy: 'Options Legacy',
	form: 'Form',
	select: 'Select',
	map: 'Map',
	demo: 'Demo',
	next: 'Next',
	previous: 'Previous',
	none: 'None',
	save: 'Save',
	field: 'Field',
	fields: 'Fields',
	question: 'Question',
	questions: 'Questions',
	requiredField: 'Required Field.',
	notApplicable: 'Not Applicable',
	submit: 'Submit',
	warning: 'Warning',
	selectFile: 'Select File',
	preserveUuids: 'Preserve UUIDs',
	notFinished: 'Not Finished',
	finished: 'Finished',
	finish: 'Finish',
	selectTime: 'Select Time',
	selectDate: 'Select Date',
	version: 'Version',
	timestamp: 'Timestamp',
	commit: 'Commit',
	branch: 'Branch',
	production: 'Production',
	dateFormat: 'Date Format',
	timeFormat: 'Time Format',
	history: 'History',
	actions: 'Actions',
	action: 'Action',
	unlock: 'Unlock',
	triggerEvent: 'Triggered Event',
	triggerEventOffset: 'Event Offset',
	triggerEventUuid: 'Triggered Event UUID',
	triggerEventDirection: 'Triggered Event Direction',
	offsetDirection: 'Offset Direction',
	offset: 'Offset',
	angularOffset: 'Angular Offset',
	numberTimesRepeat: 'Number of times to repeat',
	simultaneous: 'Simultaneous',
	before: 'Before',
	after: 'After',
	accessLogs: 'Access Logs',
	createNewAsset: 'New Asset Creation',
	today: 'Today',
	percentage: 'Percentage',
	tab: 'Tab',
	addTab: 'Add Tab',
	card: 'Card',
	addCard: 'Add Card',
	modal: 'Modal',
	popover: 'Popover',
	search: 'Search',
	data: 'Data',
	index: 'Index',
	indexes: 'Indexing',
	help: 'Help',
	empty: 'Empty',
	default: 'Default',
	defaultOption: 'Default Option',
	createEvent: 'Create Event',
	eventCreated: 'Successfully created the event.',
	eventEdited: 'Successfully edited the event.',
	eventError: 'Error creating/editing the event.',
	planning: 'Planning',
	scheduledEvents: 'Scheduled Events',
	assetEvents: 'Asset Events',
	repairEvents: 'Repair Events',
	confirmEditSelection: 'Do you want to edit this event or all events?',
	confirmDeleteSelection: 'Do you want to delete this event or all events?',
	allEvents: 'All Events',
	thisEvent: 'This Event',
	myEvents: 'My Events',
	sorting: 'Sorting',
	wizard: 'Wizard',
	singleStep: 'Single Step',
	replace: 'Replace',
	read: 'Read',
	chooseFile: 'Choose File',
	toUpload: 'to upload',
	files: '(doc, PDF, xlsx and others)',
	imageFiles: '(PNG or JPG)',
	videoFiles: '(MP4, MKV, MPEG or AVI)',
	dragAndDrop: 'Drag and Drop',
	or: 'or',
	openCamera: 'open camera',
	requiredAssetFields: 'Required asset fields',
	newAsset: 'New Asset',
	assignments: 'Assignments',
	dropFile: 'Drop your file to upload',

	// Components
	repetitiveForm: 'Repetitive Form',
	assetSelector: 'Asset Selector',
	companySelector: 'Company Selector',
	teamSelector: 'Team Selector',
	userSelector: 'User Selector',
	textMultiline: 'Multiline Text',
	toggle: 'Toggle',
	image: 'Image',
	radio: 'Radio',
	numberSlider: 'Number (slider)',
	numberRange: 'Number (range)',
	atexField: 'ATEX Field',
	stringArray: 'Array of values',
	dateFrequency: 'Date Frequency',
	checkboxGroup: 'Checkbox Group',
	noActionAvailable: 'No action available',

	// Roles
	userRoles: 'Roles',
	userRole: 'Role',
	userRoleCreated: 'A new user role was created successfully.',
	userRoleDeleted: 'User role was deleted successfully.',
	userRoleDeleteError: 'An error occurred while deleting a user role.',
	administrator: 'Administrator',
	inspector: 'Inspector',
	backoffice: 'Back Office',
	supervisor: 'Supervisor',
	client: 'Client',

	// Attributes
	onlyApplicable: 'Only Applicable',
	activationTime: 'Activation Time',
	min: 'Minimum',
	max: 'Maximum',
	kpi: 'KPI',
	units: 'Units',
	ffpCount: 'FFP Count',
	projectUuid: 'Project UUID',
	ffpUuid: 'FFP UUID',
	inspectionUuid: 'Inspection UUID',
	assetUuid: 'Asset UUID',
	parentUuid: 'Parent UUID',
	parentName: 'Parent Name',
	parentTag: 'Parent Tag',
	operationalUnitUuid: 'Operational Unit UUID',
	actionPlanUuid: 'Action Plan UUID',
	actionPlanDescription: 'Action Plan Description',
	teamUuid: 'Team UUID',
	teamUuids: 'Team UUIDs',
	companyUuid: 'Company UUID',
	userUuid: 'User UUID',
	userUuids: 'User UUIDs',
	fieldUuid: 'Field UUID',
	fieldText: 'Field Text',
	fieldLabel: 'Field Label',
	recommendation: 'Recommendation',
	recommendations: 'Recommendations',
	hasRecommendations: 'Has Recommendations',
	alertEmail: 'Email Alert',
	actionPlans: 'Action Plans',
	actionPlansProgress: 'Action Plans Progress',
	resolution: 'Resolution',
	workOrder: 'Work Order',
	inspectionFrequency: 'Inspection Frequency',
	company: 'Company',
	state: 'State',
	limitDate: 'Limit Date',
	address: 'Address',
	vat: 'VAT Number',
	observations: 'Observations',
	observation: 'Observation',
	uuid: 'Unique Identifier',
	id: 'Identifier',
	attribute: 'Attribute',
	value: 'Value',
	name: 'Name',
	phoneNumber: 'Phone Number',
	notes: 'Notes',
	active: 'Active',
	inactive: 'Inactive',
	role: 'Role',
	picture: 'Picture',
	pictures: 'Pictures',
	description: 'Description',
	quantity: 'Quantity',
	tag: 'Tag',
	position: 'Position',
	manufacturer: 'Manufacturer',
	model: 'Model',
	modelIdentification: 'Model Identification',
	serialNumber: 'Serial Number',
	atex: 'ATEX',
	atexTags: 'ATEX Tags',
	atexInfo: 'ATEX Information',
	atexCompliant: 'ATEX Compliant',
	zoneClassification: 'Zone Classification',
	temperatureClass: 'Temperature Class',
	explosionGroup: 'Explosion Group',
	category: 'Category',
	type: 'Type',
	typeUuid: 'Type UUID',
	subtype: 'Sub-type',
	subtypeUuid: 'Sub-type UUID',
	current: 'Current',
	voltage: 'Voltage',
	nominalVoltage: 'Nominal Voltage',
	power: 'Power',
	rpm: 'RPM',
	weight: 'Weight',
	insulationClass: 'Insulation Class',
	energeticClass: 'Energetic Class',
	output: 'Output',
	output1: 'Output 1',
	output2: 'Output 2',
	output3: 'Output 3',
	signalRange: 'Signal Range',
	workRange: 'Operation Range',
	flangeSize: 'Flange Dimension',
	photos: 'Photos',
	photo: 'Photo',
	video: 'Video',
	audio: 'Audio',
	result: 'Result',
	status: 'Status',
	overall: 'Overall',
	tagPicture: 'Tag Picture',
	nameplate: 'Characteristics Nameplate',
	justifications: 'Justifications',
	loopDiagram: 'Loop Diagram',
	diagram: 'Diagram',
	bearingShaft: 'Bearing (Shaft Side)',
	bearingOppositeShaft: 'Bearing (Opposite Shaft Side)',
	supplyVoltage: 'Supply Voltage',
	designTemperature: 'Design Temperature',
	workTemperature: 'Work Temperature',
	designPressure: 'Design Pressure',
	workPressure: 'Work Pressure',
	corrosionThickness: 'Corrosion Thickness',
	thickness: 'Thickness',
	diameter: 'Diameter',
	material: 'Material',
	summary: 'Summary',
	scheduled: 'Scheduled',
	selectValidAsset: 'Select a valid Asset',
	selectValidRepair: 'Select a valid Repair',
	inspectionQrCode: 'Inspection QR Code',

	// Equipment subtypes
	base: 'Base',
	motor: 'Motors',
	motorHandSwitch: 'Motors Hand Switch',
	valve: 'Valves Actuators',
	jb: 'J.B (Junction Boxes)',
	transmittersPressure: 'Pressure Transmitters',
	transmittersLevel: 'Level Transmitter',
	transmittersFlow: 'Flow Transmitter',
	transmittersTemperature: 'Temperature Transmitter',
	illumination: 'Illumination and Derivation boxes',
	emergency: 'Emergency',
	socketsDerivationBoxes: 'Sockets and Derivation boxes',
	security: 'Detector, Push Buttons, Emergency shutdown button.',
	analysers: 'Analysers',
	limitSwitch: 'Positioners and Limit Switch',
	transducer: 'Transducer (I/P)',
	pipeline: 'Pipeline',
	safetyEquipment: 'Safety Equipment',

	// Dashboard
	fromDate: 'From',
	toDate: 'To',
	dailyHours: 'Daily Hours',
	dashActionPlanRejected: 'Action Plan Rejected',
	dashActionPlanRejectedSub: 'Indicates the amount of action plan entries that were rejected by the client.',
	dashActionPlanBlocked: 'Action Plan Blocked',
	dashActionPlanBlockedSub: 'Indicates the amount of action plan entries that are blocked.',
	dashActionExecuted: 'Action Plan Executed',
	dashActionExecutedSub: 'Indicates the amount of action plan entries that were executed.',
	dashActionPlanSub: 'Indicates the amount of action plan entries in each state of execution.',
	dashProgress: 'Inspections Progress',
	dashProgressSub: 'Shows how many inspections are completed and how many are in progress on each state.',
	dashResult: 'Inspections Result',
	dashResultSub: 'Inspections by their result, indicates the amount of equipments that need repair.',
	dashInspectionUpdates: 'Inspections Updated',
	dashInspectionUpdatesSub: 'Indicates how many inspections the teams has been updating on a daily basis, including performed inspections, approved and corrections.',
	dashInspectionPerformed: 'Inspections Performed',
	dashInspectionPerformedSub: 'Indicates how many inspections where performed by the field inspectors on a daily basis.',
	dashInspectionReviewed: 'Inspections Reviewed',
	dashInspectionReviewedSub: 'Indicates how many inspections where reviewed by the backoffice team on a daily basis.',
	dashTimeInspection: 'Time per inspection',
	dashTimeInspectionSub: 'Indicates how many time each inspection takes on average. Can be calculated on a daily, week or monthly basis.',

	// Action plan
	createActionPlan: 'Create Action Plan',
	underWork: 'Under Work',
	waitingClient: 'Waiting Client',
	rejected: 'Rejected',
	notRejected: 'Not Rejected',
	blocked: 'Blocked',
	waitingReinspection: 'Waiting Reinspection',
	actionPlanActions: 'Action Plan Actions',
	reinspection: 'Reinspection',
	reinspections: 'Reinspections',
	viewAsset: 'View Asset',
	viewInspection: 'View Inspection',
	implementation: 'Implementation',

	// Asset Portfolio
	structure: 'Structure',
	editable: 'Editable',
	formTab: 'Form Tab',
	formTabUuid: 'Form Tab UUID',
	formTabs: 'Form Tabs',
	formTabCards: 'Form Tab Cards',
	formBlock: 'Form Block',
	formBlockUuid: 'Form Block UUID',
	formBlocks: 'Form Blocks',
	formBlockFields: 'Form Block Fields',
	noFieldsSet: 'No Fields Set',
	addField: 'Add Field',
	types: 'Types',
	subTypes: 'Sub-Types',
	modelsList: 'Models List',
	noTabsSet: 'No Tabs Set',
	errorCreatingFormBlock: 'Error on form block creation.',
	fieldType: 'Field Type',
	assetType: 'Asset Type',
	assetTypeUuid: 'Asset type UUID',
	assetTypes: 'Asset Types',
	assetSubType: 'Asset sub-type',
	assetSubTypeUuid: 'Asset sub-type UUID',
	assetSubTypes: 'Asset sub-types',
	allowedOnRoot: 'Allowed on root',
	allowedParentTypes: 'Allowed parent types',
	allowTagRepeatedOnSameType: 'Allow tag repetition on same type',
	allowTagRepeatedOnChild: 'Allow tag repetition on child types',
	parentSubType: 'Sub-type parent',
	parentSubTypeUuid: 'Sub-type parent UUID',
	itself: 'Itself',
	errorSelectAssetTypeFirst: 'Select an asset type first.',
	errorCreatingAssetType: 'Error creating asset type.',
	errorUpdatingAssetType: 'Error updating asset type.',
	errorCreatingAsset: 'Error creating asset.',
	errorUpdatingAsset: 'Error updating asset.',
	errorCreatingSubAssetType: 'Error creating asset sub-type.',
	errorUpdatingSubAssetType: 'Error updating asset sub-type.',
	updateWillCauseDataLoss: 'Changes made to this attribute will cause unsaved changes to be lost. To avoid its loss, please close the selector and save the changes made before proceeding.',
	useFormBlock: 'Use Form Block',
	privateBlock: 'Private Block',
	parentAsset: 'Parent Asset',
	assetInfo: 'Asset Info',
	tagRules: 'Tag Rules',
	defaultAssetType: 'Default asset type',
	componentCode: 'Component Code',
	componentName: 'Component Name',
	deleteMissingFormBlocks: 'Delete form blocks not present in the file.',
	deleteMissingFormFields: 'Delete form block fields not present in the file.',
	errorMissingDocumentSheets: 'Missing document sheets. Check document template.',
	errorUpdatingFormBlockFieldsIndexesMixed: 'Error updating form block fields indexes. Check fields indexes for empty or repeated values.',
	errorNameRequired: 'Name is required.',
	errorFormBlockNotPresentOnDocument: 'Form block not present on document.',
	errorCannotUpdateFieldOfNewFormBlock: 'Cannot import fields with UUID for a new form block.',
	errorInvalidFieldComponent: 'Invalid field component.',

	// Repairs
	repairReport: 'Repair Report',
	management: 'Management',
	exportProposal: 'Export Proposal (Word)',
	exportProposalPDF: 'Export Proposal (PDF)',
	createRepair: 'Create Repair',
	newRepair: 'New Repair',
	repair: 'Repair',
	author: 'Author',
	assetList: 'Asset List',
	criticality: 'Criticality',
	temporaryRepair: 'Temporary Repair',
	definitiveRepair: 'Definitive Repair',
	repairCreated: 'Repair has been created.',
	repairSubmitted: 'Repair has been submitted for approval.',
	repairUpdated: 'Repair data has been updated.',
	technicalDocuments: 'Technical Documents',
	waitingTechnicalInfo: 'Waiting Technical Info',
	proposal: 'Proposal',
	proposals: 'Proposals',
	waitingValidation: 'Waiting Validation',
	qualityAssurance: 'Quality Assurance',
	validated: 'Validated',
	validation: 'Validation',
	finishedRepairs: 'Finished Repairs',
	repairProposal: 'Repair Proposal',
	proposalDocuments: 'Proposal Documents',
	longevity: 'Longevity',
	onGoing: 'On Going',
	repairJob: 'Repair Job',
	repairOrder: 'Repair Order',
	proposalApproval: 'Proposal Approval',
	waitingProposal: 'Waiting for Proposal',
	waitingProposalApproval: 'Waiting for Proposal Approval',
	waitingRiskAssessment: 'Waiting for Risk Assessment',
	veryLow: 'Very Low',
	normal: 'Normal',
	veryHigh: 'Very High',
	documentCode: 'Document Code',
	welder: 'Welder',
	welding: 'Welding',
	weldingJob: 'Welding Job',
	weldings: 'Weldings',
	needsWelding: 'Needs Welding',
	weldingCount: 'Welding Count',
	weldingDiameter: 'Welding Diameter',
	weldingSchedule: 'Welding Schedule',
	weldingDocuments: 'Welding Documents',
	weldingNotes: 'Welding Notes',
	procedure: 'Procedure',
	weldingProcedures: 'Welding Procedures',
	weldingTypes: 'Welding Types',
	weldingStandards: 'Welding Standards',
	standards: 'Standards',
	procedures: 'Procedures',
	licensed: 'Licensed',
	consumables: 'Consumables',
	weldNumber: 'Weld Number',
	handleFluids: 'Handle Fluids (Class 1,2 or 3)',
	riskAssessmentDocuments: 'Risk Assessment Documents',
	riskAssessmentNotes: 'Risk Assessment Notes',
	billOfMaterials: 'Bill of Materials',
	repairDeleted: 'Repair has been deleted successfully.',
	repairDeleteError: 'Error occurred while trying to delete the repair from database. Please try again later. ({error})',
	repairsProgressDashTitle: 'Repairs Progress',
	repairsProgressDashSubTitle: 'Presents how many repairs exists for each execution status.',
	criticalityGraphTitle: 'Repairs Criticality',
	criticalityGraphSubTitle: 'Presents how many repairs exist for each criticality level.',
	waitingApproval: 'Waiting Approval',
	repairExecution: 'Execution',
	block: 'Block',
	blockedJustification: 'Block Justification',
	periodicInspections: 'Periodic Inspections',
	recurrentInspections: 'Recurrent Inspections',
	periodic: 'Periodic',
	periodicity: 'Periodicity',
	periodicityRepetition: 'Periodicity Repetition',
	alertTime: 'Alert Time',
	requiresRecurrentInspections: 'Requires Recurrent Inspections',
	temporary: 'Temporary',
	definitive: 'Definitive',
	nextInspection: 'Next Inspection',
	assetName: 'Asset Name',
	assetTag: 'Asset Tag',
	assetQR: 'Asset QR',
	assetNFC: 'Asset NFC',
	assetDescription: 'Asset Description',
	repairRejected: 'Repair Rejected',
	jobPhotos: 'Job Photos',
	proposalCompany: 'Proposal Company',
	repairUuid: 'Repair UUID',
	proposalNotes: 'Proposal Notes',
	proposalApprovalNotes: 'Proposal Approval Notes',
	jobPictures: 'Job Pictures',
	jobDocuments: 'Job Documents',
	jobNotes: 'Job Notes',
	blockedDocuments: 'Blocked Documents',
	blockedNotes: 'Blocked Notes',
	rejectionMessage: 'Rejection Message',
	errorImportRepairs: 'Error occurred while importing repairs data. It may be caused by a data inconsistency. Please, check your file.',
	importedRepairsXlsxSuccessfully: 'File was imported successfully to the application. Created {imported} new repairs, updated data from {updated} repairs and {failed} repairs failed.',
	exportedReportsBulk: 'Export process finished, {success} reports were exported successfully. It was not possible to export {failed} reports.',
	removed: 'Removed',
	firstPeriodicInspectionError: 'First periodic inspection will happen after repair expiration date.',
	nonDestructiveTest: 'Non Destructive Test',
	nonDestructiveTests: 'Non Destructive Tests',
	repairType: 'Repair Type',
	fluids: 'Fluids',
	fluidFamily: 'Fluid Family',
	fluidType: 'Fluid Type',
	fluidTypes: 'Fluid Types',

	// Repair Inspections
	nonCompliantRepair: 'Non Compliant Repair',
	repairInspectorAppreciation: 'Inspector Appreciation',
	finalAppreciation: 'Final Appreciation',
	repairInspection: 'Repair Inspection',
	repairInspectionPhotos: 'Repair Inspection Photos',
	repairInspectionReport: 'Repair Inspection Report',
	repairInspectionCreated: 'Repair Inspection Created',
	repairInspectionSubmitted: 'Repair Inspection has been submitted for approval.',
	repairInspectionUpdated: 'Repair Inspection data has been updated.',
	repairInspectionDeleted: 'Repair Inspection has been deleted successfully.',
	repairInspectionDeleteError: 'Error occurred while trying to delete the repair inspection from database. Please try again later. ({error})',
	noInspectionsToShow: 'There are no inspections available to display.',
	createNewInspection: 'Create Inspection',
	dueDate: 'Due Date',

	// User
	editUser: 'Edit User',
	userName: 'User Name',
	changePassword: 'Change Password',
	createUser: 'Create User',
	userCreated: 'Created new user account successfully. Details were sent to the user email.',
	profile: 'Profile',

	// Teams
	team: 'Team',
	teamName: 'Team Name',
	teamMembers: 'Team Members',
	teamCreated: 'Team created successfully',
	teamUpdate: 'Team data was updated successfully',
	parentTeam: 'Parent Team',

	// Companies
	parentCompany: 'Parent Company',
	companyCreated: 'Company created successfully',
	companyUpdate: 'Company updated successfully',
	defaultCompany: 'Default Company',

	// Sensors
	undefined: 'Undefined',
	flow: 'Flow',
	velocity: 'Velocity',
	distance: 'Distance',
	impedance: 'Impedance',
	force: 'Force',
	mass: 'Mass',
	acceleration: 'Acceleration',
	energy: 'Energy',
	capacity: 'Capacity',
	efficiency: 'Efficiency',
	temperature: 'Temperature',
	pressure: 'Pressure',

	// Settings
	locale: 'Locale',
	language: 'Language',
	showListPictures: 'Show picture lists',
	lazyLoading: 'Lazy load list',
	pictureMaxSize: 'Photo size (px)',
	pictureFormat: 'Photo Format',
	mapStyle: 'Map Style',
	vector: 'Vector',
	satellite: 'Satellite',
	mixed: 'Mixed',
	dark: 'Dark',
	light: 'Light',
	keepExternalWindow: 'Reuse Tabs',
	keepFilters: 'Keep Search Filters',
	appearance: 'Appearance',
	preferences: 'Preferences',
	title: 'Title',
	icon: 'Icon',
	loginBackgroundImage: 'Login Background Image',
	logo: 'Logo',
	smallLogo: 'Small Logo',
	topLogo: 'Top Logo',
	bottomLogo: 'Bottom Logo',
	accentColor: 'Accent Color',
	settingsUpdated: 'Settings Updated',

	// Menu
	assetPlanning: 'Asset Planning',
	privacyPolicy: 'Privacy Policy',
	dashboard: 'Dashboard',
	functionalAnalysis: 'Functional Analysis',
	project: 'Project',
	companies: 'Companies',
	users: 'Users',
	usersEmail: 'Users (Email)',
	usersSms: 'Users (SMS)',
	tasks: 'Tasks',
	teams: 'Teams',
	assets: 'Assets',
	assetPortfolio: 'Asset Portfolio',
	actionPlan: 'Action Plan',
	atexActionPlan: 'ATEX Action Plan',
	repairs: 'Repairs',
	repairInspections: 'Repair Inspections',
	inspections: 'Inspections',
	atexInspections: 'ATEX Inspections',
	settings: 'Settings',
	masterSettings: 'Master Settings',
	importExport: 'Import/Export',
	logs: 'Logs',
	intelligentDocuments: 'Intelligent Documents',
	digitalTwin: 'Digital Twin',

	// Success messages
	createdActionPlanTag: 'Created action plan for tag {tag} with success.',
	addedSuccessfully: 'Data added successfully.',
	createdSuccessfully: 'New entry was created successfully.',
	updatedSuccessfully: 'Data was updated successfully.',
	updatedSuccessfullyName: 'Data from {name} was updated successfully.',
	importSuccessful: 'Import was finished with success.',
	importedSuccessfullyCounters: 'File was imported successfully into the application. Created {imported} new assets, updated data from {updated} assets and {failed} assets failed.',
	deleteSuccessfully: 'Data was removed successfully from the server.',
	deleteSuccessfullyName: '{name} was removed from database with success.',
	deleteSuccessfullyCount: 'Removed {count} entries from database with success.',
	reportGeneratedSuccessfully: 'Report generated successfully. Available in your download folder.',
	cameraSelected: 'Camera "{name}" selected.',
	restoreSuccessful: 'Data was restored with success.',

	// Confirm Messages
	confirmDelete: 'Are you sure to delete this data? Deleted data cannot be recovered.',
	updatedVersionFound: 'A new version of the application is available. The application will be reloaded to update to the new version.',
	confirmDeletePipeline: 'If you draw a new pipeline the one that exists will be erased. Do you wish to continue?',
	confirmDeleteEvent: 'Are you sure to delete this event? Deleted events cannot be recovered.',
	confirmInspectionValidation: 'Are you sure you want to validate all the selected inspections?',

	// Warning messages.
	deviceOffline: 'Connection to the internet lost. Device is offline.',
	deviceOnline: 'Connection to the internet restored. Device is online.',
	loadingAssetsData: 'Loading asset data',
	uploadingData: 'Uploading Data',
	processingData: 'Processing Data',
	importingData: 'Imported {imported} out of {total}. {failed} have failed.',
	importingDataLines: 'Imported data from {importedLines} rows out of {totalLines}.',
	deletingData: 'Deleting Data',
	loadingData: 'Loading data.',
	loadingMoreData: 'Loading more data...',
	nothingWasDeleted: 'Nothing was deleted.',

	// Error messages
	nothingToExport: 'Nothing to export.',
	nothingUpdatedFFPNotFound: 'Nothing was changed, not possible to find FFP that match the configuration. Check input data and files.',
	requiredFieldsError: 'Required fields must be filled.',
	assetRequiredFieldsError: 'Asset required fields must be filled.',
	warningNoSensorSelected: 'No sensor selected, you must select at least one sensor to proceed.',
	errorAssetNotFound: 'Asset was not found in the database.',
	errorFileColumn: 'The file does not contain any column with the name "{attribute}". Please, check the file for errors.',
	errorNoFile: 'No file was selected.',
	errorDeletingSomeFailed: 'It was not possible to delete {failed} entries from the database.',
	errorProcessingSomeFailed: 'It was not possible to process all entries. Check exported file for errors.',
	errorExportingQR: 'Error exporting QR code to file.',
	errorImport: 'Error occurred importing file into the application. Check your file for errors.',
	errorProcessingData: 'Error occurred while processing data.',
	errorImportDetails: 'Error occurred importing file into the application. Check your file for errors. ({details})',
	errorImportAsset: 'Error occurred while importing asset data. It might be caused by replicated Tag or invalid asset associations. Please, check your file.',
	errorExport: 'Error exporting data to file.',
	errorExportPDFConfirmTryDOCX: 'Error exporting data to PDF file. Do you want to try in DOCX instead?',
	errorGeneratingQR: 'Error while trying to generate QR code.',
	errorGeneratingReportDetails: 'Report generation has failed. A problem with data was found while generating the report. ({details})',
	errorConvertingReport: 'Report convertion has failed. ({details})',
	errorEncodingData: 'Error trying to encode data.',
	dataMalformed: 'Data received from the server is malformed. This may be caused by data model updates.',
	dataUpdateError: 'Data was not updated successfully.',
	noPermission: 'User has no permissions to execute this action.',
	noPermissionDetails: 'User has no permissions to execute this action. ({details})',
	internalServerError: 'Internal server error. ({details})',
	badRequest: 'Error in the data sent to the server. ({details})',
	cannotReachServer: 'Cannot reach the server. Check your internet connection and try again.',
	userSessionExpired: 'The user login session has expired. You will need to login into the platform again.',
	noDataOnServer: 'No content found on the server!',
	errorGettingProjectFormHeaders: 'Error getting form headers for project {projectName} ({projectUuid}) on step {stepName} ({stepUuid})',
	errorGettingInspectionsForProject: 'Error getting inspections for project {projectName} ({projectUuid})',
	errorExportingInspectionForProject: 'Error exporting inspection {inspectionName} ({inspectionUuid}) for project {projectName} ({projectUuid})',
	errorsEncounteredDuringExport: 'The following errors were found during export: \n{errors}',
	errorDefaultWorkflowStepMissing: 'A default step must be selected for the workflow.',
	errorMultipleDefaultWorkflowSteps: 'Multiple default steps selected. Please, select only one.',
	errorMissingPipeline: 'No pipeline drawn. Points must be added to a pipeline!',
	errorSnapTolerance: 'Element drawn is not close to a pipeline!',
	errorImportAssetsInvalidType: 'An invalid asset type was provided.',
	errorImportAssetsMissingDefaultType: 'A default asset type or a valid asset type name/UUID must be provided.',
	errorImportAssetsInvalidSubType: 'An invalid asset sub-type was be provided.',
	errorImportAssetsInvalidSubtypeType: 'Provided asset sub-type doesn\'t belong to the selected asset type.',
	errorImportAssetsCreationUpdateFailed: 'The import of this asset failed on the server.',
	errorImportAssetsAtexCreationFailed: 'The creation of the ATEX inspection failed.',
	errorComputingInspectionAverageDetails: 'Error computing inspection average. ({details})',
	errorImportAssetCreateFailed: 'Failed to create the asset.',
	errorImportAssetUpdateFailed: 'Failed to update the asset.',
	errorMultipleDefaultReports: 'Multiple default report templates selected. Please, select only one.',

	// MOT
	mot: 'MOT',
	sensorManagement: 'Sensor Management',
	pipePlant: 'Pipe Plant',
	frequency: 'Frequency',
	repetition: 'Repetition',
	waveChannels: 'Wave Channels',
	channels: 'Channels',
	propagationSpeed: 'Propagation Speed',
	locationPoint: 'Location',
	segment: 'Segment',
	cmp: 'CMP',
	lds: 'LDS',
	leakDetection: 'Leak Detection',
	pipelines: 'Pipelines',
	acquisition: 'Acquisition',
	experiment: 'Experiment',
	experiments: 'Experiments',
	nanoFileImportedSuccessfully: '.nano file imported successfully.',
	feature: 'Feature',
	chooseElementModal: 'Choose the element you want to add.',
	weld: 'Weld',
	support: 'Support',
	flange: 'Flange',
	teeBranch: 'Tee Branch',
	elbow: 'Elbow',
	brace: 'Brace',
	defect: 'Defect',
	indication: 'Indication',
	valves: 'Valves',
	featureGeneric: 'Feature Generic',
	compare: 'Compare',
	acquisitions: 'Acquisitions',
	pickTwoAcquisitions: 'Select two acquisitions to compare',
	pipelineIntegrity: 'Pipeline Integrity',
	alarmDeleted: 'This alarm was deleted',

	// DL50
	dl50: 'DL50',
	dl50Inspection: 'DL50 Inspection',
	validate: 'Validate',
	report: 'Report',
	representative: 'Representative',
	operator: 'Operator',
	authorizedOperator: 'Authorized Operator',
	equipmentOperator: 'Equipment Operator',
	owner: 'Owner',
	serviceHours: 'Service Hours',
	equipmentServiceHours: 'Equipment service hours',
	certification: 'Certification',
	certificationLocation: 'Certification Location',
	tests: 'Tests',
	hasElectricalTests: 'Has electrical tests',
	electricalTests: 'Electrical Tests',
	hasLoadTests: 'Has load tests',
	loadTests: 'Load Tests',
	finalNotes: 'Final Notes',
	conclusion: 'Conclusion',
	safetyRequirements: 'Safety Requirements',
	evaluation: 'Evaluation',
	maximumProvisions: 'Maximum Provisions',
	load: 'Load',
	range: 'Range',
	spearLength: 'Spear Length',
	height: 'Height',
	testData: 'Test Data',
	dynamicTest: 'Dynamic Test',
	loadTestOverload: 'Overload of:',
	loadTestStaticTest: 'Static Test',
	loadTestFunctional: 'Functional Test',
	loadTestNoLoad: '0% Overload / No Load',
	loadTestMomentLimiter: 'Moment limiter test',
	loadTestTenPercentOverloadAssessment: 'Maximum overload10% ',
	loadTestMaxLoadLimiter: 'Maximum load limiter test',
	groundCircuit: 'Ground Circuit',
	groundCircuitContinuity: 'Continuity of the protective ground circuit',
	electricCurrent: 'Current test electrical intensity',
	differentialSwitches: 'Differential Switches',
	differentialSwitchesOpenCircuitTimeHalf: 'Trigger time for ½ x IΔn',
	differentialSwitchesOpenCircuitTimeOne: 'Trigger time for 1 x IΔn',
	differentialSwitchesOpenCircuitTimeTwo: 'Trigger time for 2 x IΔn',
	differentialSwitchesOpenCircuitTimeFive: 'Trigger time for 5 x IΔn',
	insulationResistance: 'Insulation Resistance',
	insulationResistanceR: 'R - PE',
	insulationResistanceS: 'S - PE',
	insulationResistanceT: 'T - PE',
	insulationResistanceN: 'N - PE',
	emission: 'Emission',
	revision: 'Revision',
	reviewer: 'Reviewer',
	clientReview: 'Client Review',
	supervisionRejected: 'Rejected by supervision',
	errorCreatingInspection: 'Error creating inspection',
	errorUpdatingInspection: 'Error updating inspection',
	errorUpdatingInspectionPermission: 'User does not have permissions to create or edit DL50 inspections.',
	gapsOk: 'If there are no nonconformities',
	gapsLight: 'If there are non-conformities - light',
	gapsSevere: 'If there are non-conformities - serious',
	gapsNok: 'Not Fit',
	possibleGaps: 'Possible Gaps',
	legislationAndStandards: 'Legislation and regulatory standards',
	dl50InspectionXlsx: 'DL50 Inspection XLSX',
	dl50InspectionXlsxSub: 'Export a XLSX file containing details of DL-50 inspections available in the platform.',
	correctionDeadline: 'Correction Deadline',
	reportNumber: 'Report Number',
	regulatoryStandard: 'Regulatory Standard',
	emissionDate: 'Emission Date',
	emissionLocation: 'Emission Location',
	counter: 'Counter',
	dl50InspectionStatusDashTitle: 'DL50 Inspections Progress',
	dl50InspectionStatusDashSubTitle: 'DL50 inspections by status.',
	dl50InspectionConclusionDashTitle: 'DL50 Inspections Results',
	dl50InspectionConclusionDashSubTitle: 'DL50 inspections by final notes conclusion.',
	overload: 'Overload',
	confirmInspectionEvaluationInconsistency: 'This inspection evaluations do not fit conclusion value. Do you wish to proceed?',
	confirmManyInspectionsInconsistentData: 'The evaluations of the following inspection UUIDs do not fit conclusion values. Do you wish to procced? {uuids}',

	// RBAC
	rbac: 'RBAC',
	allowedRoles: 'Access Roles',
	effectiveRoles: 'Effective Roles'
};
