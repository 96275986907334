import {UnoFormField} from '../../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../../components/uno-forms/uno-form/uno-form-field-types';

export const FFPRecommendationLayout: UnoFormField[] = [
	{
		required: true,
		label: 'id',
		attribute: 'id',
		type: UnoFormFieldTypes.NUMBER
	},
	{
		required: true,
		label: 'label',
		attribute: 'label',
		type: UnoFormFieldTypes.TEXT
	}
];
