import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormComponent} from '../../../../components/uno-forms/uno-form/uno-form.component';
import {App} from '../../../../app';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {UnoFormModule} from '../../../../components/uno-forms/uno-form.module';
import {TeamFormLayout} from '../team-form-layout';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {Resource} from '../../../../models/resource';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {Team} from '../../../../models/teams/team';
import {UUID} from '../../../../models/uuid';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../components/uno/uno-title/uno-title.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {PermissionsPipe} from '../../../../pipes/permissions.pipe';

@Component({
	selector: 'team-edit-page',
	templateUrl: 'team-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoTitleComponent, UnoFormModule, UnoButtonComponent, TranslateModule, PermissionsPipe]
})
export class TeamEditPage extends ScreenComponent implements OnInit {
	public layout: any = TeamFormLayout;

	public app: any = App;

	public resource: any = Resource;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public permissions = [UserPermissions.TEAM];

	/**
	 * Team being visualized/edited in this page.
	 */
	@Input()
	public team: Team = null;

	/**
	 * Team edit form component for field validation.
	 */
	@ViewChild('teamForm', {static: false})
	public teamForm: UnoFormComponent = null;

	/**
	 * Flag to indicate if the page is in create mode.
	 *
	 * If true the page is used to create a new Team.
	 */
	public createMode: boolean = false;

	public ngOnInit(): void {
		super.ngOnInit();
		
		this.createMode = false;
		this.team = null;

		const data = App.navigator.getData();
		if (!data || !data.createMode && !data.uuid) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		this.createMode = data.createMode === true;
		
		App.navigator.setTitle(this.createMode ? 'create' : 'edit');

		if (this.createMode) {
			if (!Session.hasPermissions([UserPermissions.TEAM_CREATE])) {
				Modal.alert(Locale.get('warning'), Locale.get('noPermission'));
				App.navigator.pop();
				return;
			}
			this.team = new Team();
		} else {
			if (!Session.hasPermissions([UserPermissions.TEAM_EDIT])) {
				Modal.alert(Locale.get('warning'), Locale.get('noPermission'));
				App.navigator.pop();
				return;
			}

			if (data.uuid !== undefined) {
				this.loadData(data.uuid);
			} else {
				App.navigator.pop();
				return;
			}
		}
	}

	/**
	 * Indicates if the team data can be edited.
	 */
	public canEdit(): boolean {
		return this.createMode && Session.hasPermissions([UserPermissions.TEAM_CREATE]) || !this.createMode && Session.hasPermissions([UserPermissions.TEAM_EDIT]);
	}

	/**
	 * Load team data from the API.
	 */
	public async loadData(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.teams.get, null, null, {uuid: uuid}, Session.session);
		this.team = Team.parse(request.response.team);
	}

	/**
	 * Update the team data on the API.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.teamForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(this.createMode ? ServiceList.teams.create : ServiceList.teams.update, null, null, this.team, Session.session);

		Modal.toast(this.createMode ? Locale.get('teamCreated') : Locale.get('teamUpdate'));

		if (!stayOnPage) {
			App.navigator.pop();
		}
	}

	/**
	 * Delete team from API.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.teams.delete, null, null, {uuid: this.team.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
