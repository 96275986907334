import {Component, OnInit} from '@angular/core';
import {IonicModule} from '@ionic/angular';

import {Environment} from 'src/environments/environment';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {RepairStatus} from '../../../../../models/repairs/repairs/repair-status';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {RepairsCriticalityGraphComponent} from '../../components/repair-criticality-graph/repair-criticality-graph.component';
import {RepairStatesGraphComponent} from '../../components/repair-states-graph/repair-states-graph.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'repairs-menu-page',
	templateUrl: 'repairs-menu.page.html',
	standalone: true,
	imports: [UnoContentComponent, IonicModule, RepairStatesGraphComponent, RepairsCriticalityGraphComponent, MenuCardsGroupsComponent, PermissionsPipe]
})
export class RepairsMenuPage extends ScreenComponent implements OnInit {

	public app: any = App;
	
	public userPermissions: any = UserPermissions;
	
	public session: any = Session;

	public environment: any = Environment;

	public permissions = [UserPermissions.REPAIR];

	/**
	 * List of groups and options on repairs' menu, organized by sections.
	 */
	public groups: MenuCardsGroup[] = [
		{
			label: 'management',
			permissions: [UserPermissions.REPAIR_CREATE, UserPermissions.REPAIR_LIST_ALL],
			options: [
				{
					label: 'create',
					route: '/menu/repairs/works/edit',
					data: {createMode: true},
					icon: 'add',
					badge: null,
					permissions: [UserPermissions.REPAIR_CREATE]
				},
				{
					label: 'list',
					route: '/menu/repairs/works/list',
					data: {status: RepairStatus.ALL},
					icon: 'list',
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairs.countStatus, null, null, {status: RepairStatus.ALL}, Session.session, true)).response.count;
					})(),
					permissions: [UserPermissions.REPAIR_LIST_ALL]
				}
			]
		},
		{
			label: 'proposals',
			permissions: [UserPermissions.REPAIR_ADD_PROPOSAL, UserPermissions.REPAIR_APPROVE_PROPOSAL],
			options: [
				{
					label: 'repairProposal',
					route: '/menu/repairs/works/list',
					data: {status: RepairStatus.PROPOSAL},
					icon: 'newspaper-outline',
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairs.countStatus, null, null, {status: RepairStatus.PROPOSAL}, Session.session, true)).response.count;
					})(),
					permissions: [UserPermissions.REPAIR_ADD_PROPOSAL]
				},
				{
					label: 'waitingApproval',
					route: '/menu/repairs/works/list',
					data: {status: RepairStatus.WAITING_PROPOSAL_APPROVAL},
					icon: 'stopwatch-outline',
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairs.countStatus, null, null, {status: RepairStatus.WAITING_PROPOSAL_APPROVAL}, Session.session, true)).response.count;
					})(),
					permissions: [UserPermissions.REPAIR_APPROVE_PROPOSAL]
				}
			]
		},
		{
			label: 'repairExecution',
			permissions: [UserPermissions.REPAIR_LIST_ON_GOING, UserPermissions.REPAIR_LIST_BLOCKED],
			options: [
				{
					label: 'onGoing',
					route: '/menu/repairs/works/list',
					data: {status: RepairStatus.ON_GOING},
					icon: 'hammer',
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairs.countStatus, null, null, {status: RepairStatus.ON_GOING}, Session.session, true)).response.count;
					})(),
					permissions: [UserPermissions.REPAIR_LIST_ON_GOING]
				},
				{
					label: 'blocked',
					route: '/menu/repairs/works/list',
					data: {status: RepairStatus.BLOCKED},
					icon: 'stop-circle-outline',
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairs.countStatus, null, null, {status: RepairStatus.BLOCKED}, Session.session, true)).response.count;
					})(),
					permissions: [UserPermissions.REPAIR_LIST_BLOCKED]
				}
			]
		},
		{
			label: 'validation',
			permissions: [UserPermissions.REPAIR_APPROVE_REPAIR, UserPermissions.REPAIR_LIST_IMPLEMENTED],
			options: [
				{
					label: 'qualityAssurance',
					route: '/menu/repairs/works/list',
					data: {status: RepairStatus.QUALITY_ASSURANCE},
					icon: 'stopwatch-outline',
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairs.countStatus, null, null, {status: RepairStatus.QUALITY_ASSURANCE}, Session.session, true)).response.count;
					})(),
					permissions: [UserPermissions.REPAIR_APPROVE_REPAIR]
				},
				{
					label: 'implemented',
					route: '/menu/repairs/works/list',
					data: {status: RepairStatus.IMPLEMENTED},
					icon: 'checkbox-outline',
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairs.countStatus, null, null, {status: RepairStatus.IMPLEMENTED}, Session.session, true)).response.count;
					})(),
					permissions: [UserPermissions.REPAIR_LIST_IMPLEMENTED]
				}
			]
		},
		{
			label: 'archived',
			permissions: [UserPermissions.REPAIR_LIST_ARCHIVED],
			options: [
				{
					label: 'archived',
					route: '/menu/repairs/works/list',
					data: {status: RepairStatus.ARCHIVED},
					icon: 'archive-outline',
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairs.countStatus, null, null, {status: RepairStatus.ARCHIVED}, Session.session, true)).response.count;
					})(),
					permissions: [UserPermissions.REPAIR_LIST_ARCHIVED]
				}
			]
		}

	];

	public ngOnInit(): void {
		super.ngOnInit();
		App.navigator.setTitle('repairs');
	}
}
