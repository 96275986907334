import {UnoFormComponent} from '../../../../components/uno-forms/uno-form/uno-form.component';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {Environment} from '../../../../../environments/environment';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {AssetService} from '../../../asset-portfolio/services/asset.service';
import {AtexInspectionChecklistService} from '../services/atex-inspection-checklist.service';
import {AtexInspectionField} from '../../../../models/atex-inspections/inspections/atex-inspection-field';
import {AtexInspectionStatus, AtexInspectionStatusLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-status';
import {AtexInspectionResult, AtexInspectionResultLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-result';
import {AtexInspectionData} from '../../../../models/atex-inspections/inspections/atex-inspection-data';
import {AtexInspectionFieldResult} from '../../../../models/atex-inspections/inspections/atex-inspection-field-result';
import {AtexInspection} from '../../../../models/atex-inspections/inspections/atex-inspection';
import {AtexInspectionChecklist} from './master-data/atex-inspection-checklist';
import {AtexInspectionFormFieldType} from './master-data/atex-inspection-fields';

export class AtexInspectionTools {
	/**
	 * Check if the inspections are missing some required fields, this situation may be caused by changed to the master data schemas.
	 *
	 * Export the results into a XLSX file, does not apply any changes to the database.
	 */
	public static async checkMissingFields(): Promise<void> {
		const data: any[][] = [[Locale.get('uuid'), Locale.get('name'), Locale.get('tag'), Locale.get('result'), Locale.get('resultFinal'), Locale.get('status')]];
		const hasMore = true;
		let from = 0;
		const count = 200;

		while (hasMore) {
			const result = await Service.fetch(ServiceList.atex.inspection.listDetailed, null, null, {from: from, count: count, status: AtexInspectionStatus.ALL}, Session.session);

			for (let i = 0; i < result.response.inspections.length; i++) {
				const inspection = AtexInspection.parse(result.response.inspections[i]);

				const asset = await AssetService.get(inspection.assetUuid);

				// Check if the inspection has a form selected
				if (inspection.hasForm()) {
					const inspectorForm = await AtexInspectionChecklist.buildForm(inspection, true, false);
					const backofficeForm = await AtexInspectionChecklist.buildForm(inspection, false, true);

					// Check if the inspection is missing required fields
					if (!UnoFormComponent.requiredFilled(inspectorForm, inspection.data.responses.inspector) || !UnoFormComponent.requiredFilled(backofficeForm, inspection.data.responses.backoffice)) {
						data.push([inspection.uuid, asset.name, asset.tag, Locale.get(AtexInspectionResultLabel.get(inspection.result)), Locale.get(AtexInspectionResultLabel.get(inspection.resultFinal)), Locale.get(AtexInspectionStatusLabel.get(inspection.status))]);
					}
				}
			}

			if (result.response.hasMore) {
				from += result.response.inspections.length;
			} else {
				break;
			}
		}

		XlsxUtils.writeFile(data, 'inspections.xlsx');
	}
	
	/**
	 * Check if all inspection have forms selected, if not select a default form and set the form result based on the tagNum value.
	 *
	 * If the object has a tagNum value the inspections are approved otherwise they are not approved.
	 */
	public static async checkNoFormsSelected(): Promise<void> {
		const checklists = await AtexInspectionChecklistService.get();

		const result = await Modal.prompt(Locale.get('defaultAtexInspectionForm'), [{name: 'form', placeholder: Locale.get('defaultAtexInspectionForm')}]);
		if (result.confirm) {
			const data = result.data;
			if (checklists[data.form] === undefined) {
				Modal.alert(Locale.get('error'), Locale.get('defaultAtexInspectionFormError'));
				return;
			}

			if (!Environment.PRODUCTION) {
				console.log('EQS: Default inspection.', data, checklists);
			}

			// Process result callback function
			const processResultCallback = async(response): Promise<void> => {
				for (let i = 0; i < response.inspections.length; i++) {
					const inspection = AtexInspection.parse(response.inspections[i]);

					const asset = await AssetService.get(response.inspections[i].assetUuid);
					const hasTag: boolean = asset.tag !== null && asset.tag.length > 0;

					if (!inspection.hasForm()) {
						// Ensure that inspection has data field
						if (!inspection.data) {
							inspection.data = new AtexInspectionData();
						}

						// Select the form
						inspection.data.inspections[data.form] = true;

						// If tag is missing and inspection was approved, fail it
						if (inspection.result === AtexInspectionResult.APPROVED && !hasTag) {
							inspection.result = AtexInspectionResult.FAILED;
						}

						// Build and fill inspector form
						const inspectorForm = await AtexInspectionChecklist.buildForm(inspection, true, false);

						for (let j = 0; j < inspectorForm.length; j++) {
							if (inspectorForm[j].type === AtexInspectionFormFieldType.FIELD) {
								const attr = inspectorForm[j].attribute;
								// Create inspection field if missing
								if (!inspection.data.responses.inspector[attr]) {
									inspection.data.responses.inspector[attr] = new AtexInspectionField();
								}
								inspection.data.responses.inspector[attr].result = hasTag ? AtexInspectionFieldResult.OK : AtexInspectionFieldResult.NOK;
							}
						}

						// Build and fill backoffice form
						if (inspection.status === AtexInspectionStatus.COMPLETED) {
							// If tag is missing and inspection is approved, fail it
							if (inspection.resultFinal === AtexInspectionResult.APPROVED || inspection.resultFinal === AtexInspectionResult.CONDITIONALLY_APPROVED && !hasTag) {
								inspection.resultFinal = AtexInspectionResult.FAILED;
							}

							const backofficeForm = await AtexInspectionChecklist.buildForm(inspection, false, true);
							for (let k = 0; k < backofficeForm.length; k++) {
								if (backofficeForm[k].type === AtexInspectionFormFieldType.FIELD) {
									const attr = backofficeForm[k].attribute;
									// Create inspection field if missing
									if (!inspection.data.responses.backoffice[attr]) {
										inspection.data.responses.backoffice[attr] = new AtexInspectionField();
									}
									inspection.data.responses.backoffice[attr].result = hasTag ? AtexInspectionFieldResult.OK : AtexInspectionFieldResult.NOK;
								}
							}

							// Update in API
							await Service.fetch(ServiceList.atex.inspection.update, null, null, inspection, Session.session);
							Modal.toast(Locale.get('success') + ' ' + inspection.uuid);
						}
					}
				}
			};

			const status = [AtexInspectionStatus.COMPLETED, AtexInspectionStatus.REVIEW];
			for (let i = 0; i < status.length; i++) {
				const request = await Service.fetch(ServiceList.atex.inspection.listDetailed, null, null, {status: status[i]});
				await processResultCallback(request.response);
			}
		}
	}
}
