/**
 * Correspondence between month number and its name in locale.
 *
 * Starts in 0 (january) and ends with 11 (december).
 */
export const LocaleDateMonth: Map<number, string> = new Map([
	[0, 'january'],
	[1, 'february'],
	[2, 'march'],
	[3, 'april'],
	[4, 'may'],
	[5, 'june'],
	[6, 'july'],
	[7, 'august'],
	[8, 'september'],
	[9, 'october'],
	[10, 'november'],
	[11, 'december']
]);

/**
 * Correspondence between the week day number and the name of the week name in locale.
 *
 * Considers "monday" as the first day of the week.
 */
export const LocaleDateWeekDays: Map<number, string> = new Map([
	[1, 'monday'],
	[2, 'tuesday'],
	[3, 'wednesday'],
	[4, 'thursday'],
	[5, 'friday'],
	[6, 'saturday'],
	[7, 'sunday']
]);
