import {InspectionProjectFlowMode} from 'src/app/models/inspections/project/inspection-project-flow-mode';
import {InspectionProjectRequiredAssetFields} from 'src/app/modules/inspections/screens/project/edit/inspection-project-edit.page';
import {UUID, UUIDIdentification} from '../../uuid';
import {InspectionReportTemplate} from './inspection-report-template';
import {InspectionProjectDisplayMode} from './inspection-project-display-mode';

/**
 * A inspection project contains inspections that use the same workflow.
 *
 * Its possible to have multiple projects reusing the workflows.
 */
export class InspectionProject extends UUIDIdentification {
	/**
	 * Name of the project.
	 */
	public name: string = '';

	/**
	 * Inspection project description.
	 */
	public description: string = '';

	/**
	 * Indicate if the project should be displayed in the main menu.
	 */
	public showInMenu: boolean = false;

	/**
	 * Indicates if an asset is required when creating new entries in the project.
	 */
	public requireAsset: boolean = true;

	/**
	 * The required fields to be filled on the assets when the inspections have an asset selected
	 */
	public requiredAssetFields: (typeof InspectionProjectRequiredAssetFields)[keyof typeof InspectionProjectRequiredAssetFields][] = [];

	/**
	 * Workflow to be used in this inspection project.
	 */
	public workflowUuid: UUID = null;

	/**
	 * Team assigned to this inspection project.
	 */
	public teamUuid: UUID = null;

	/**
	 * Either to allow export of inspections from inspections list.
	 */
	public allowExportReportFromList: boolean = true;

	/**
	 * Report template used to generate reports.
	 */
	public reports: InspectionReportTemplate[] = [];

	/**
	 * Indicates if the inspection project generates gaps.
	 */
	public generatesGaps: boolean = false;

	/**
	 * RBAC master list of allowed roles
	 */
	public rbacMasterAllowedList: UUID[] = [];
	
	/**
	 * RBAC list of allowed roles
	 */
	public rbacAllowedList: UUID[] = [];

	/**
	 * Flow mode for the project.
	 */
	public flowMode: InspectionProjectFlowMode = InspectionProjectFlowMode.SINGLE_STEP;

	/**
	 * Display mode for the project.
	 */
	public displayMode: InspectionProjectDisplayMode = InspectionProjectDisplayMode.SECTIONS;

	/**
	 * Parse inspection project data to create a InspectionProject object.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): InspectionProject {
		const project = new InspectionProject();

		project.uuid = data.uuid;
		project.createdAt = new Date(data.createdAt);
		project.updatedAt = new Date(data.updatedAt);

		project.name = data.name;
		project.description = data.description;
		project.showInMenu = data.showInMenu === true;
		project.requireAsset = data.requireAsset !== false;
		project.requiredAssetFields = data.requiredAssetFields;
		project.workflowUuid = data.workflowUuid;
		project.teamUuid = data.teamUuid;
		project.allowExportReportFromList = data.allowExportReportFromList === true;
		project.generatesGaps = data.generatesGaps;
		project.flowMode = data.flowMode;
		project.displayMode = data.displayMode || InspectionProjectDisplayMode.SECTIONS;

		project.reports = [];
		if (data.reports instanceof Array) {
			for (let i = 0; i < data.reports.length; i++) {
				project.reports.push(InspectionReportTemplate.parse(data.reports[i]));
			}
		}

		project.rbacMasterAllowedList = data.rbacMasterAllowedList || [];

		project.rbacAllowedList = data.rbacAllowedList || [];

		return project;
	}
}
