import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {RepairInspectionEditPage} from './screens/edit/repair-inspection-edit.page';
import {RepairInspectionListPage} from './screens/list/repair-inspection-list.page';
import {RepairInspectionPage} from './screens/dashboard/repair-inspection.page';

const routes: Routes = [
	{
		path: '',
		component: RepairInspectionPage
	},
	{
		path: 'edit',
		component: RepairInspectionEditPage
	},
	{
		path: 'list',
		component: RepairInspectionListPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class RepairInspectionsRouterModule { }
