import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {Locale} from 'src/app/locale/locale';
import {CssNgStyle} from 'src/app/utils/css-ng-style';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {NgStyle, NgClass} from '@angular/common';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {UnoInputComponent} from '../uno-input/uno-input-component';
import {UnoIconComponent} from '../uno-icon/uno-icon.component';

/**
 * Uno search bar result contains a labels presented to the user and a value associated with the option.
 */
export type UnoSearchBarResult = {
	/**
	 * Value associated with the label.
	 */
	value: any,

	/**
	 * Label to present to the user.
	 */
	label: string
};

/**
 * UNO searchbar options is a stylized text input element used to input search filter, with the ability to choose an item from a dropdown.
 */
@Component({
	selector: 'uno-searchbar-dropdown',
	templateUrl: './uno-searchbar-dropdown.component.html',
	styleUrls: ['./uno-searchbar-dropdown.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CdkConnectedOverlay, CdkOverlayOrigin, NgStyle, NgClass, UnoInputComponent, FormsModule, UnoIconComponent, TranslateModule]
})
export class UnoSearchbarDropdownComponent {
	@ViewChild('container', {static: true})
	public container: ElementRef = null;

	/**
	 * Debounce time of the search element.
	 */
	@Input()
	public debounce: number = 250;

	/**
	 * Enable/disabled backdrop layer when dropdown is open.
	 */
	@Input()
	public backdrop: boolean = false;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Callback to retrieve options from search text.
	 *
	 * Called everytime that the input text is changed.
	 */
	@Input()
	public retrieveOptions: (searchText: string)=> Promise<UnoSearchBarResult[]>;

	/**
	 * On change method is called when the user selects a value from the dropdown.
	 */
	@Input()
	public onChange: (value: any)=> void = function(value: any) { };

	/**
	 * Specifies whether the input element should have autocomplete enabled.
	 */
	@Input()
	public autocomplete: boolean = false;

	/**
	 * Value stored in the input component.
	 */
	@Input()
	public value: any = null;

	/**
	 * Value presented in the search box.
	 */
	public searchValue: string = '';

	/**
	 * Placeholder text to display when no text is visible.
	 */
	public placeholder: string = Locale.get('search');

	/**
	 * Options currently being shown.
	 */
	public options: UnoSearchBarResult[] = [];

	/**
	 * If true the option list is shown.
	 */
	public expanded: boolean = false;

	/**
	 * Width of the dropdown container.
	 *
	 * Automatically calculated when the options are expanded.
	 */
	public optionsWidth: number = 100;

	/**
	 * Style of the text element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {
			width: '291px',
			height: '36px'
		};

	/**
	 * Clear the value and reset text box.
	 */
	public clear(): void {
		this.expanded = false;
		this.options = [];

		this.value = null;
		this.searchValue = '';
		this.onChange(null);
	}

	/**
	 * Expand the dropdown options.
	 *
	 * Only expands if there are options to be displayed.
	 */
	public expand(): void {
		this.expanded = this.options.length > 0;

		if (this.expanded) {
			this.optionsWidth = this.container.nativeElement.offsetWidth;
		}
	}

	/**
	 * Method called when the input changes, will retrieve options and fill the options list.
	 * 
	 * @param text - Text of the searchbar.
	 */
	public async inputChange(text: string): Promise<void> {
		// Only update options if value has changed and is not empty
		if (text === '' || text === this.searchValue) {
			return;
		}

		this.options = await this.retrieveOptions(text);
		this.expand();
	}

	/**
	 * Sets the value in the search input
	 * 
	 * @param value - The value to be changed into
	 */
	public async setValue(value: UnoSearchBarResult): Promise<void> {
		this.searchValue = value.label;
		this.value = value.value;
		this.onChange(value.value);
		this.expanded = false;
		this.options = await this.retrieveOptions(this.searchValue);
	}
}
