/**
 * Clipboard utils is used to read and write data into the system clipboard.
 *
 * Uses the web Clipboard API to access the operative system clipboard.
 *
 * User permission might be requested when accessing clipboard.
 */
export class ClipboardUtils {
	/**
	 * Read data from clipboard.
	 *
	 * @returns Data retrieved from the clipboard.
	 */
	public static async read(): Promise<any> {
		if (!navigator.clipboard) {
			throw new Error('Clipboard API is not available.');
		}

		return await navigator.clipboard.readText();
	}

	/**
	 * Copy text to the system clipboard.
	 *
	 * @param text - Text to be copied into the system clipboard
	 */
	public static async copy(text: string): Promise<void> {
		if (!navigator.clipboard) {
			const textArea = document.createElement('textarea');
			textArea.style.position = 'fixed';
			textArea.style.top = '0';
			textArea.style.left = '0';
			textArea.style.width = '2em';
			textArea.style.height = '2em';
			textArea.style.padding = '0';
			textArea.style.border = 'none';
			textArea.style.outline = 'none';
			textArea.style.boxShadow = 'none';
			textArea.style.background = 'transparent';
			textArea.value = text;

			document.body.appendChild(textArea);
			textArea.select();

			try {
				document.execCommand('copy');
			} catch (e) {}

			document.body.removeChild(textArea);
		} else {
			await navigator.clipboard.writeText(text);
		}
	}
}
