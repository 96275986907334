import {Pipe, PipeTransform} from '@angular/core';
import {Modules} from '../modules';
import {App} from '../app';

/**
 * Pipe to check if a module of the application is enabled.
 */
@Pipe({
	name: 'moduleCheck',
	standalone: true,
	pure: true
})
export class ModuleCheckPipe implements PipeTransform {
	public transform(module: Modules): boolean {
		return App.hasModule(module);
	}
}
