import {Vector2, WebGLRenderer} from 'three';
import {Mouse} from '../input/mouse';

/**
 * Corner of the viewport used as anchor for positioning.
 *
 * Position of the viewport is calculated relatively to this point.
 */
export const ViewportAnchor = {
	TOP_LEFT: 301,

	TOP_RIGHT: 302,

	BOTTOM_LEFT: 303,

	BOTTOM_RIGHT: 304
};

/**
 * Defines how the viewport should be sized compared to its parent container.
 */
export const ViewportSizing = {
	/**
	 * Viewport calculated relatively to the screen viewport.
	 */
	RELATIVE: 200,

	/**
	 * Viewport defined absolutely in pixels.
	 */
	ABSOLUTE: 201
};

/**
 * The viewport object is used to handle virtual visualization windows for the WebGL renderer.
 *
 * It uses normalized coordinates [0 to 1] when using RELATIVE mode or pixel based coordinated for ABSOLUTE mode.
 */
export class Viewport {
	/**
	 * Camera viewport offset.
	 *
	 * Values range from 0.0 to 1.0 in screen space when in RELATIVE mode.
	 */
	public offset: Vector2 = new Vector2(0.0, 0.0);

	/**
	 * Camera viewport size.
	 *
	 * Values range from 0.0 to 1.0 in screen space.
	 */
	public viewport: Vector2 = new Vector2(1.0, 1.0);

	/**
	 * Viewport sizing mode.
	 *
	 * Can be RELATIVE or ABSOLUTE.
	 */
	public mode: number;

	/**
	 * Positioning anchor of the viewport.
	 */
	public anchor: number = ViewportAnchor.TOP_LEFT;
	
	public constructor(mode?: number) {
		this.mode = mode ? mode : ViewportSizing.RELATIVE;
	}

	/**
	 * Get the aspect ratio of this viewport x/y.
	 */
	public getAspectRatio(canvas: HTMLCanvasElement): number {
		if (this.mode === ViewportSizing.RELATIVE) {
			return this.viewport.x * canvas.width / (this.viewport.y * canvas.height);
		}

		return this.viewport.x / this.viewport.y;
	}

	/**
	 * Check if the mouse is inside this viewport.
	 */
	public isInside(canvas: HTMLCanvasElement, mouse: Mouse): boolean {
		let viewport;
		let offset;
		if (this.mode === ViewportSizing.RELATIVE) {
			offset = new Vector2(this.offset.x * canvas.width, this.offset.y * canvas.height);
			viewport = new Vector2(this.viewport.x * canvas.width, this.viewport.y * canvas.height);
		} else if (this.mode === ViewportSizing.ABSOLUTE) {
			offset = this.offset;
			viewport = this.viewport;
		}

		if (this.anchor === ViewportAnchor.TOP_LEFT) {
			return mouse.position.x > offset.x &&
				mouse.position.x < offset.x + viewport.x &&
				mouse.position.y > offset.y &&
				mouse.position.y < offset.y + viewport.y;
		} else if (this.anchor === ViewportAnchor.TOP_RIGHT) {
			return mouse.position.x > canvas.width - viewport.x - offset.x &&
				mouse.position.x < canvas.width - offset.x &&
				mouse.position.y > offset.y &&
				mouse.position.y < offset.y + viewport.y;
		} else if (this.anchor === ViewportAnchor.BOTTOM_LEFT) {
			return mouse.position.x > offset.x &&
				mouse.position.x < offset.x + viewport.x &&
				mouse.position.y > canvas.height - offset.y - viewport.y &&
				mouse.position.y < canvas.height - offset.y;
		} else if (this.anchor === ViewportAnchor.BOTTOM_RIGHT) {
			return mouse.position.x > canvas.width - viewport.x - offset.x &&
				mouse.position.x < canvas.width - offset.x &&
				mouse.position.y > canvas.height - offset.y - viewport.y &&
				mouse.position.y < canvas.height - offset.y;
		}
	}

	/**
	 * Enable this viewport for rendering using a WebGLRenderer
	 *
	 * After rendering the WebGL renderer has to manually reset to the original values.
	 */
	public enable(renderer: WebGLRenderer): void {
		let offset;
		let viewport;
		let y;
		let x;

		if (this.mode === ViewportSizing.RELATIVE) {
			offset = new Vector2(this.offset.x * renderer.domElement.width, this.offset.y * renderer.domElement.height);
			viewport = new Vector2(this.viewport.x * renderer.domElement.width, this.viewport.y * renderer.domElement.height);
		} else if (this.mode === ViewportSizing.ABSOLUTE) {
			offset = this.offset;
			viewport = this.viewport;
		}

		if (this.anchor === ViewportAnchor.TOP_LEFT) {
			y = renderer.domElement.height - viewport.y - offset.y;
			renderer.setViewport(offset.x, y, viewport.x, viewport.y);
			renderer.setScissor(offset.x, y, viewport.x, viewport.y);
		} else if (this.anchor === ViewportAnchor.TOP_RIGHT) {
			x = renderer.domElement.width - viewport.x - offset.x;
			y = renderer.domElement.height - viewport.y - offset.y;
			renderer.setViewport(x, y, viewport.x, viewport.y);
			renderer.setScissor(x, y, viewport.x, viewport.y);
		} else if (this.anchor === ViewportAnchor.BOTTOM_LEFT) {
			renderer.setViewport(offset.x, offset.y, viewport.x, viewport.y);
			renderer.setScissor(offset.x, offset.y, viewport.x, viewport.y);
		} else if (this.anchor === ViewportAnchor.BOTTOM_RIGHT) {
			x = renderer.domElement.width - viewport.x - offset.x;
			renderer.setViewport(x, offset.y, viewport.x, viewport.y);
			renderer.setScissor(x, offset.y, viewport.x, viewport.y);
		}
	}

	/**
	 * Get normalized coordinates between [-1, 1] for a canvas size and mouse position.
	 *
	 * Useful to use ray casting for object picking in a viewport.
	 */
	public getNormalized(canvas: HTMLCanvasElement, mouse: Mouse): Vector2 {
		const normalized = new Vector2();

		let x;
		let y;
		let offset;
		let viewport;

		if (this.mode === ViewportSizing.RELATIVE) {
			offset = new Vector2(this.offset.x * canvas.width, this.offset.y * canvas.height);
			viewport = new Vector2(this.viewport.x * canvas.width, this.viewport.y * canvas.height);
		} else if (this.mode === ViewportSizing.ABSOLUTE) {
			offset = this.offset;
			viewport = this.viewport;
		}

		if (this.anchor === ViewportAnchor.TOP_LEFT) {
			x = mouse.position.x - viewport.x - offset.x;
			y = mouse.position.y - offset.y;
			normalized.set(x / viewport.x * 2 + 1, -y / viewport.y * 2 + 1);
		} else if (this.anchor === ViewportAnchor.TOP_RIGHT) {
			x = canvas.width - mouse.position.x - viewport.x - offset.x;
			y = mouse.position.y - offset.y;
			normalized.set(-x / viewport.x * 2 - 1, -y / viewport.y * 2 + 1);
		} else if (this.anchor === ViewportAnchor.BOTTOM_LEFT) {
			x = mouse.position.x - viewport.x - offset.x;
			y = canvas.height - mouse.position.y - offset.y;
			normalized.set(x / viewport.x * 2 + 1, y / viewport.y * 2 - 1);
		} else if (this.anchor === ViewportAnchor.BOTTOM_RIGHT) {
			x = canvas.width - mouse.position.x - viewport.x - offset.x;
			y = canvas.height - mouse.position.y - offset.y;
			normalized.set(-x / viewport.x * 2 - 1, y / viewport.y * 2 - 1);
		}

		return normalized;
	}
}

