<div class="uno-asset-selector" style="display: flex;">
	<ion-item class="ion-no-margin ion-no-padding" style="width: 100%;" lines="none">
		<ionic-selectable (ngModelChange)="this.writeValue($event);" (onInfiniteScroll)="this.onInfiniteLoad($event);" (onSearch)="this.onSearch($event);" [canSearch]="true" [placeholder]="'empty' | translate" [disabled]="this.disabled" [hasInfiniteScroll]="true" [isMultiple]="this.multiple" [items]="this.options" [itemValueField]="'uuid'" [ngModel]="this.value" [ngModelOptions]="{standalone: true}" [searchDebounce]="500" [shouldStoreItemValue]="true" closeButtonText="{{'close' | translate}}" confirmButtonText="{{'ok' | translate}}" itemTextField="display" style="min-width: 100%;"></ionic-selectable>
	</ion-item>

	<div style="display: flex; margin-left: 16px;">
		<!-- Qr -->
		@if (app.device.hasCamera && ([permissions.ASSET_PORTFOLIO_QR] | hasPermissions) && !this.disabled) {
			<uno-icon src="assets/components/menu/qrcode.svg" color="primary" width="32" height="32" (click)="this.selectAssetQR();"></uno-icon>
		}
		<!-- Nfc -->
		@if (app.device.hasNFC() && ([permissions.ASSET_PORTFOLIO_NFC] | hasPermissions) && !this.disabled) {
			<uno-icon src="assets/components/menu/nfc.svg" color="primary" width="32" height="32" (click)="this.selectAssetNFC();"></uno-icon>
		}
		<!-- Clear -->
		@if (this.value && !this.disabled && this.showClear) {
			<uno-icon src="assets/icons/uno/bin.svg" color="primary" width="32" height="32" (click)="this.writeValue(null);"></uno-icon>
		}
		<!-- Create -->
		@if (([permissions.ASSET_PORTFOLIO_ASSET_CREATE] | hasPermissions) && !this.value && !this.disabled) {
			<uno-icon src="assets/icons/uno/add.svg" color="primary" width="32" height="32" (click)="this.createAssetModal();"></uno-icon>
		}
	</div>

</div>
