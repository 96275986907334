
/**
 * Array utils contains tools for easier array manipulation.
 */
export class ArrayUtils {
	/**
	 * Merge an array of arrays into a single array and removed any repeated values.
	 *
	 * The output value is sorted.
	 *
	 * @param arrays - Input array of arrays.
	 * @param removeNulls - Wether or not nulls should be removed in the final array.
	 */
	public static mergeArrays(arrays: any[], removeNulls: boolean = false): any[] {
		if (arrays.length === 0) {
			return [];
		}

		const out = [];

		for (let i = 0; i < arrays.length; i++) {
			const array = arrays[i];

			for (let j = 0; j < array.length; j++) {
				if (removeNulls && array[j] === null) {
					continue;
				}
				const index = out.indexOf(array[j]);
				if (index === -1) {
					out.push(array[j]);
				}
			}
		}

		out.sort();
		return out;
	}

	/**
	 * Method to join attribute from array into single string.
	 *
	 * Access an attribute of the objects from an array by their name and concatenates all of them.
	 *
	 * @param array - Input array with objects.
	 * @param attribute - Name of the attribute to be concatenated.
	 * @param processor - Auxiliary method to pre-process the attributes.
	 * @param separator - Separator to be used.
	 */
	public static concatArrayAttribute(array: any[], attribute: string | number, processor: (any)=> any = null, separator: string = ', '): string {
		if (!array || array.length === 0) {
			return '';
		}

		let result = '';

		for (let j = 0; j < array.length; j++) {
			if (array[j][attribute] !== null) {
				// If there is a processor method concatenate the result of the processed attribute value
				result += !processor ? array[j][attribute] : processor(array[j][attribute]);

				if (j + 1 < array.length) {
					result += separator;
				}
			}
		}

		return result;
	}

	/**
	 * Move an element inside an array from the origin index to the destination index.
	 *
	 * The other elements are shifted up or down depending on the index of origin and destination.
	 */
	public static move(array: any[], ori: number, dst: number): void {
		if (ori >= 0 && ori <= array.length && dst >= 0 && dst <= array.length) {
			if (ori === dst) {
				return;
			}

			const temp = array[ori];

			// Shift elements up
			if (ori < dst) {
				for (let i = ori; i < dst; i++) {
					array[i] = array[i + 1];
				}
			// Shift elements down
			} else {
				for (let i = ori; i > dst; i--) {
					array[i] = array[i - 1];
				}
			}

			array[dst] = temp;
		} else {
			throw new Error('Indexes provided are out of range.');
		}
	}

	/**
	 * Create an array of a certain length with a certain fill;
	 *
	 * @param length - The length of the array, if not specified or zero returns an empty array.
	 * @param fill - The fill of the array, if not specified it is ''.
	 * @returns An array of the specified length filled with the specified fill.
	 */
	public static newArray(length: number = 0, fill: any = ''): any[] {
		return new Array(length).fill(fill);
	}
}
