import {UUID, UUIDIdentification} from '../uuid';
import {APAssetFormBlockFieldComponentType} from './asset-form-block-field-type';

/**
 * Form template fields are used to keep form block fields information (used under a form block).
 */
export class APAssetFormBlockField extends UUIDIdentification {
	/**
	 * Name of the form field.
	 */
	public name: string = '';

	/**
	 * Description of the form field.
	 */
	public description: string = '';

	/**
	 * Indicates if the field is required to be filled when submitting a form using this field.
	 */
	public required: boolean = false;
	
	/**
	 * The ID of the form field component that must be rendered on form block. Selected from a list of possible pre-defined components.
	 */
	public formFieldComponent: number = APAssetFormBlockFieldComponentType.NONE;
	
	/**
	 * Specific form type data.
	 */
	public data: any = null;

	/**
	 * The indexes of this field, used to sort fields relative to each other.
	 *
	 * Indexes are separated by a dot and start at "1" for asset types and "0" for sub-types (to allow sub-type fields to be placed before the type ones). Every dot on the index, represents a level deep from the types (lvl 0) to the sub-types (lvl 1 to infinity)
	 * 
	 * E.g.: Fields of a type can be "1" or "123". Fields of a sub-type can be "0.1" or "0.1 and 1.1 and 1.2 and 1.3" or "123.1". Fields of a sub-sub-sub-type can be "0.0.0.1" or "0.0.0.1 and 1.0.0.1 and 1.0.0.2 and 1.0.0.3" or "123.0.0.1"
	 */
	public indexes: string = '';
	
	/**
	 * The ID of the block that contains this form block field.
	 */
	public formBlockUuid: UUID = null;

	/**
	* Parse JSON data received from the API into an object.
	*
	* @param data - Object received from API.
	* @returns Object of the correct type, built with parsed data received.
	*/
	public static parse(data: any): APAssetFormBlockField {
		const field = new APAssetFormBlockField();

		field.uuid = data.uuid;
		field.updatedAt = new Date(data.updatedAt);
		field.createdAt = new Date(data.createdAt);

		field.name = data.name;
		field.description = data.description;
		field.required = data.required === true;
		field.formFieldComponent = data.formFieldComponent;
		field.data = data.data;

		field.indexes = data.indexes;
		field.formBlockUuid = data.formBlockUuid;

		return field;
	}
}
