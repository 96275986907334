import {PerspectiveCamera as TPerspectiveCamera, WebGLRenderer, Object3D} from 'three';
import {Viewport} from './viewport';

/**
 * Perspective camera, used for 3D scenes with objects getting smaller as they get away from the camera.
 */
export class PerspectiveCamera extends TPerspectiveCamera {
	/**
	 * Viewport indicates the portion of the screen to where this camera renders.
	 */
	public viewport: Viewport = new Viewport();

	public constructor(fov: number, aspect: number) {
		super(fov, aspect, 0.1, 8e6);
	}

	/**
	 * Render the scene from this camera.
	 *
	 * Applies the viewport attached to the camera to the renderer.
	 */
	public render(renderer: WebGLRenderer, scene: Object3D): void {
		this.viewport.enable(renderer);
		renderer.render(scene, this);
	}
}

