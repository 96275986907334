import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {GapAnalysisMenuPage} from './screens/menu/gap-analysis-menu.page';
import {GapAnalysisGapsRouterModule} from './gaps/gap-analysis-gaps-router.module';
import {GapAnalysisActionPlansRouterModule} from './action-plans/gap-analysis-action-plans-router.module';

const routes: Routes = [
	{
		path: '',
		component: GapAnalysisMenuPage
	},
	{
		path: 'gaps',
		loadChildren: () => {
			return GapAnalysisGapsRouterModule;
		}
	},
	{
		path: 'action-plans',
		loadChildren: () => {
			return GapAnalysisActionPlansRouterModule;
		}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GapAnalysisRouterModule { }
