import {DL50Inspection} from 'src/app/models/dl50/dl50-inspection';
import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {Company} from 'src/app/models/companies/company';
import {DL50Question} from 'src/app/models/dl50/masterdata/dl50-question';
import {DL50InspectionQuestionResultLabel} from 'src/app/models/dl50/dl50-inspection-question-result';
import {Locale} from 'src/app/locale/locale';
import {DL50MasterSettings} from 'src/app/models/dl50/masterdata/dl50-master-settings';
import {ResourceUtils} from 'src/app/utils/resource-utils';
import {DocxUtils} from '../../../utils/docx-utils';
import {FileUtils} from '../../../utils/file-utils';
import {Dl50MasterSettingsService} from '../services/dl50-master-settings.service';
import {DL50RegulatoryStandardService, DL50RegulatoryStandardsListResponse} from '../services/dl50-regulatory-standard.service';

/**
 * Renerates reports from DL50 inspection data.
 */
export class DL50InspectionReport {
	/**
	 * DL50 report template file.
	 */
	public static reportURL: string = '/assets/template/dl50-2005-report-template.docx';

	/**
	 * Generate asset file document using a docx template.
	 * 
	 * It uses the masterdata report template if existent. Otherwise it will use the report template hardcoded on the assets templates directory.
	 *
	 * @param inspection - DL50 inspection object to generate report from.
	 * @param previousInspectionRevision - DL50 previous revision inspection object to compare changes on report.
	 * @param questions - The list of questions to be answered on a DL50 inspection.
	 * @param company - The company object to generate the report with.
	 * @param asset - The asset object to generate the report with.
	 */
	public static async generateDocx(inspection: DL50Inspection, previousInspectionRevision: DL50Inspection, questions: DL50Question[], company: Company, asset: APAsset = null): Promise<ArrayBuffer> {
		const settings: DL50MasterSettings = await Dl50MasterSettingsService.get();

		// Set the DL50 inspection report template
		const template: ArrayBuffer = await FileUtils.readFileArrayBuffer(settings.reportTemplate ? ResourceUtils.getURL(settings.reportTemplate) : DL50InspectionReport.reportURL);
	
		const standards: DL50RegulatoryStandardsListResponse = await DL50RegulatoryStandardService.list({});

		const data = {
			inspection: inspection,
			previousInspectionRevision: previousInspectionRevision,
			asset: asset,
			company: company,
			questions: questions,
			standards: standards.standards,
			settings: settings,
			// Method get the corresponding label for a question evaluation
			evaluationLabel: (evaluation: number): string => {
				return Locale.get(DL50InspectionQuestionResultLabel.get(evaluation));
			}
		};

		return DocxUtils.generateDocxFromTemplate(template, data);
	}
}
