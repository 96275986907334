/**
 * Lazy list handler is used to retrieve pages of information from the API in pages.
 *
 * Can be configured to work with different object types.
 */
export class UnoLazyUnorderedLoaded<T> {
	/**
	 * List of elements received from the API.
	 */
	public items: T[] = null;

	/**
	 * Pages that were requested and are waiting for a response.
	 * 
	 * After the page is loaded the value is removed from the array, always check pageLoaded() first.
	 */
	public pagesRequested: number[] = [];

	/**
	 * Total Number of elements.
	 * 
	 * Provided when the handler is initialized.
	 */
	public total: number;

	/**
	 * Number of elements to fetch on each request when using lazy loading.
	 */
	public pageSize: number = 20;
	
	/**
	 * Field to filter results.
	 * 
	 * (Optional, might be null)
	 */
	public sortField: string = null;

	/**
	 * Direction to sort results.
	 * 
	 * (Optional, might be null)
	 */
	public sortDirection: string = null;

	/**
	 * Method to load more elements.
	 */
	public loadMore: (from: number, count: number, sortField: string, sortDirection: string)=> Promise<{elements: T[], hasMore: boolean}> = null;

	/**
	 * Method to refresh data after new elements are loaded.
	 */
	public onload: ()=> void = null;

	/**
	 * Transform values retrieved from API.
	 */
	public transform: (value: any)=> any = null;

	public constructor(pageSize: number = 20, total: number = 0, loadMore?: (count: number, pageSize: number)=> Promise<{ elements: T[]; hasMore: boolean; }>) {
		this.pageSize = pageSize;
		this.total = total;
		this.loadMore = loadMore;

		this.reset();
	}

	/**
	 * Check if a page was already requested
	 * 
	 * @param page - Page index
	 * @returns True if the page is loaded, false otherwise
	 */
	public pageRequested(page: number): boolean {
		return this.pagesRequested.indexOf(page) !== -1;
	}

	/**
	 * Check if a page is already loaded or not.
	 * 
	 * @param page - Page index
	 * @returns True if the page is loaded, false otherwise
	 */
	public pageLoaded(page: number): boolean {
		if (this.items[page * this.pageSize]) {
			return true;
		}
		return false;
	}

	/**
	 * Get page of an item by its index.
	 * 
	 * @param idx - Index of the item.
	 * @returns Page of the item.
	 */
	public itemPage(idx: number): number {
		return Math.floor(idx / this.pageSize);
	}

	/**
	 * Reset the handler, reset all flags and clear data associated.
	 */
	public async reset(): Promise<void> {
		this.pagesRequested = [];
		this.items = new Array(this.total).fill(null);
		if (this.loadMore) {
			await this.loadPage(0);
		}
	}

	/**
	 * Load a specific page of information.
	 * 
	 * @param page - Page index
	 */
	public async loadPage(page: number): Promise<void> {
		if (this.pageLoaded(page)) {
			return;
		}

		if (this.pageRequested(page)) {
			return;
		}

		if (!this.loadMore) {
			throw new Error('loadMore() needs to be defined.');
		}

		this.pagesRequested.push(page);

		// Load data from API
		const start = page * this.pageSize;
		const result = await this.loadMore(start, this.pageSize, this.sortField, this.sortDirection);

		if (this.transform) {
			for (let i = 0; i < result.elements.length; i++) {
				result.elements[i] = this.transform(result.elements[i]);
			}
		}

		if (result.elements.length > 0) {
			for (let i = 0; i < result.elements.length; i++) {
				this.items[start + i] = result.elements[i];
			}
		}
	
		this.items = this.items.slice(0);

		const index = this.pagesRequested.indexOf(page);
		this.pagesRequested.splice(index, 1);

		// onLoad callback
		if (this.onload) {
			this.onload();
		}
	}
}
