import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BrowserModule} from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {AssetPlanningCalendarScreen} from './screens/calendar/asset-planning-calendar-screen.component';
import {UnoCalendarModule} from './components/uno-calendar/uno-calendar.module';


@NgModule({
	exports: [AssetPlanningCalendarScreen],
	imports: [
		CommonModule,
		UnoCalendarModule,
		BrowserModule,
		OverlayModule,
		ReactiveFormsModule,
		FormsModule,
		TranslateModule.forRoot(),
		AssetPlanningCalendarScreen
	]
})
export class AssetPlanningModule { }
