import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {Session} from '../../../session';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {UserPermissions} from '../../../models/users/user-permissions';
import {UserTwoFactorAuth, UserTwoFactorAuthLabel} from '../../../models/users/user-two-factor-auth';

/**
 * Layout of the form used to create new users.
 */
export const UserFormLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		attribute: 'name',
		label: 'name',
		required: true,
		type: UnoFormFieldTypes.TEXT
	},
	{
		label: 'email',
		attribute: 'email',
		required: true,
		type: UnoFormFieldTypes.EMAIL,
		editable: (object, row) => { return Session.hasPermissions(UserPermissions.USER_EMAIL_EDIT); }
	},
	{
		attribute: 'isAdmin',
		label: 'administrator',
		type: UnoFormFieldTypes.CHECKBOX,
		isActive: function(object: any, row: UnoFormField) {
			return Session.user.isAdmin;
		}
	},
	{
		attribute: 'twoFactorAuth',
		label: 'twoFactorAuth',
		type: UnoFormFieldTypes.OPTIONS,
		options: Object.values(UserTwoFactorAuth).map(function(value) {
			return {value: value, label: UserTwoFactorAuthLabel.get(value)};
		}),
		isActive: function(object: any, row: UnoFormField) {
			return Session.user.isAdmin || object.uuid === Session.user.uuid;
		}
	},
	{
		attribute: 'teamUuids',
		label: 'teams',
		type: UnoFormFieldTypes.TEAM_SELECTOR,
		showClear: true,
		isActive: (object, row) => { return Session.hasPermissions(UserPermissions.TEAM_EDIT); },
		multiple: true
	},
	{
		attribute: 'companyUuid',
		label: 'company',
		type: UnoFormFieldTypes.COMPANY_SELECTOR,
		showClear: true
	},
	{
		attribute: 'phoneNumber',
		label: 'phoneNumber',
		type: UnoFormFieldTypes.PHONE_NUMBER
	},
	{
		attribute: 'notes',
		label: 'notes',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		attribute: 'active',
		label: 'active',
		type: UnoFormFieldTypes.CHECKBOX,
		isActive: function(object: any, row: UnoFormField) {
			return Session.user.isAdmin;
		}
	},
	{
		attribute: 'roleUuids',
		label: 'userRole',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		isActive: function(object: any, row: UnoFormField) {
			return Session.user.isAdmin && !object.isAdmin;
		},
		fetchOptions: async function(object: any, row: UnoFormField) {
			const request = await Service.fetch(ServiceList.roles.listName, null, null, null, Session.session);
			const response = request.response;
			const options = [{value: null, label: 'none'}];
			for (let i = 0; i < response.roles.length; i++) {
				options.push({
					value: response.roles[i].uuid,
					label: response.roles[i].name
				});
			}

			row.options = options;
		}
	},
	{
		attribute: 'permissions',
		label: 'permissions',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		isActive: function(object: any, row: UnoFormField) {
			return Session.user.isAdmin && !object.isAdmin;
		},
		options: function() {
			const options = [];

			for (const i in UserPermissions) {
				options.push({
					label: i,
					value: UserPermissions[i]
				});
			}
			return options;
		}(),
		showClear: true
	},
	{
		label: 'photo',
		attribute: 'picture',
		type: UnoFormFieldTypes.IMAGE_RESOURCE
	},
	{
		label: 'signature',
		attribute: 'signature',
		type: UnoFormFieldTypes.IMAGE_RESOURCE
	}
];

/**
 * Form to change and confirm the user password.
 */
export const UserPassword: UnoFormField[] = [
	{
		label: 'password',
		attribute: 'password',
		type: UnoFormFieldTypes.PASSWORD,
		editable: true
	},
	{
		label: 'confirm',
		attribute: 'confirm',
		type: UnoFormFieldTypes.PASSWORD,
		editable: true
	}
];
