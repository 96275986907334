import {Component, Input, ViewEncapsulation, OnChanges, SimpleChanges, WritableSignal, signal} from '@angular/core';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {UnoFormField} from '../uno-form/uno-form-field';
import {UnoFormUtils} from '../uno-form/uno-form-utils';

@Component({
	selector: 'uno-form-collapsable-field',
	templateUrl: './uno-form-collapsable-field.component.html',
	styleUrls: ['./uno-form-collapsable-field.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class UnoFormCollapsableFieldComponent implements OnChanges {
	public formUtils: any = UnoFormUtils;

	public app: any = App;

	public locale: any = Locale;

	/**
	 * Row of the form being edited in this field, used to fetch additional details.
	 */
	@Input()
	public row: UnoFormField = null;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public editable = false;

	/**
	 * Value stored in this input component.
	 */
	@Input()
	public object: any = null;

	/**
	 * Indicates if the collapsable sub form is expanded or hidden.
	 */
	public expanded: WritableSignal<boolean> = signal(false);

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.row) {
			this.expanded.set(this.row.expanded === true);
		}
	}
}
