import {Color, HSL, MathUtils} from 'three';

/**
 * Color palette matching the ionic three color palette scheme.
 */
export type ColorPalette = {
	primary: Color,
	secondary: Color,
	tertiary: Color
};

/**
 * Utils to generate ionic colors CSS blocks.
 */
export class IonicCSSUtils {
	/**
	 * Generate CSS color variables for each color (ionic like), given a color.
	 * 
	 * Contrast color is computed from color luminance
	 * 
	 * @param name - The color name used on color variables
	 * @param color - Accent color
	 */
	public static generateGroup(name: string, color: Color): string {
		const hsl = color.getHSL({h: 0, s: 0, l: 0});

		// Relative luminance value (different from the l component from HSL)
		const luminance = 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b;

		// Define the contrast color based off luminance.
		const contrast = luminance < 0.5 ? new Color(0xFFFFFF) : new Color(0x000000);

		const shade = new Color();
		shade.setHSL(hsl.h, hsl.s, MathUtils.clamp(hsl.l * 0.7, 0.0, 0.95));

		const tint = new Color();
		tint.setHSL(hsl.h, MathUtils.clamp(hsl.s * 1.3, 0, 1.0), MathUtils.clamp(hsl.l * 1.3, 0.0, 0.95));

		return `--ion-color-${name}: ${color.getStyle()};
			--ion-color-${name}-rgb: ${Math.round(255 * color.r)}, ${Math.round(255 * color.g)}, ${Math.round(255 * color.b)}; 
			--ion-color-${name}-contrast: ${contrast.getStyle()};
			--ion-color-${name}-contrast-rgb: ${Math.round(255 * contrast.r)}, ${Math.round(255 * contrast.g)}, ${Math.round(255 * contrast.b)};
			--ion-color-${name}-shade: ${shade.getStyle()};
			--ion-color-${name}-tint: ${tint.getStyle()};`;
	}

	/**
	 * Method that computes the three base colors for a color palette (ionic like)
	 * 
	 * @param accent - The base/accent color of type Color to generate the other two colors on palette
	 */
	public static paletteFromAccent(accent: Color): ColorPalette {
		const primary: HSL = accent.getHSL({h: 0, s: 0, l: 0});

		const secondary = new Color();
		secondary.setHSL(primary.h, MathUtils.clamp(primary.s - 0.1, 0, 0.95), MathUtils.clamp(primary.l + 0.1, 0, 0.95));

		const tertiary = new Color();
		tertiary.setHSL(primary.h, MathUtils.clamp(primary.s - 0.2, 0, 0.95), MathUtils.clamp(primary.l + 0.2, 0, 0.95));

		return {
			primary: accent,
			secondary: secondary,
			tertiary: tertiary
		};
	}

	/**
	 * Generate CSS color palette variables from base/accent color
	 * 
	 * @param accent - The color, used to generate the other two on color palette (ionic like)
	 */
	public static generate(accent: Color): string {
		const palette: ColorPalette = IonicCSSUtils.paletteFromAccent(accent);

		return IonicCSSUtils.generateGroup('primary', palette.primary) +
			IonicCSSUtils.generateGroup('secondary', palette.secondary) +
			IonicCSSUtils.generateGroup('tertiary', palette.tertiary);
	}
}
