import PdfMake from 'pdfmake/build/pdfmake';
import PdfFonts from 'pdfmake/build/vfs_fonts';
import * as QRCode from 'qrcode';
import JSZip from 'jszip';
import {ProgressBar} from 'src/app/progress-bar';
import {Modal} from '../../../modal';
import {Locale} from '../../../locale/locale';
import {FileUtils} from '../../../utils/file-utils';
import {Environment} from '../../../../environments/environment';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {generateUUID} from '../../../models/uuid';
import {ImageUtils} from '../../../utils/image-utils';
import {FileReaderAsync} from '../../../utils/file-reader-async';

// @ts-ignore
PdfMake.addVirtualFileSystem(PdfFonts);

/**
 * Possible output type for list of QR codes generated.
 */
export enum QRToolsOutput {
	PDF = 0,
	ZIP = 1
}

/**
 * Possible configuration for image personalization in QR output.
 */
export enum QRLogoOutput {
	NONE = 0,
	UNO = 1,
	EQS = 2,
	CUSTOMIZED = 3
}

/**
 * Configuration used for QR code generator.
 */
export class QRToolsConfig {
	/**
	 * Size of the QR code in mm.
	 */
	public size: number = 35;

	/**
	 * Number of QR codes to generate.
	 */
	public count: number = 10;

	/**
	 * Resolution of the QR code generated in px.
	 */
	public resolution: number = 512;
}

export class QRTools {
	/**
	 * Generate a zip file with images for each.
	 *
	 * @param images - List of images to be stored in the zip.
	 * @param config - Configuration used to generate the QR codes.
	 */
	public static async generateZip(images: any[], config: QRToolsConfig): Promise<void> {
		const zip = new JSZip();

		if (!Environment.PRODUCTION) {
			console.log('EQS: Generate images ZIP.', images);
		}

		for (let i = 0; i < images.length; i++) {
			const data = images[i].slice(images[i].search(';base64,') + 8);
			zip.file(i + '.png', data, {
				base64: true,
				binary: true
			});
		}

		const result: ArrayBuffer = await zip.generateAsync({type: 'arraybuffer'});

		FileUtils.writeFileArrayBuffer('tags.zip', result);
	}


	/**
	 * Generate PDF file from list of image.
	 *
	 * @param images - List of codes to represent in the PDF file encoded as Base64 encoded image data.
	 * @param config - Config used to generate the QR, including size in mm of each code.
	 */
	public static async generatePDF(images: any[], config: QRToolsConfig): Promise<void> {
		// 1 mm = 2.83....pts
		const density = 2.8346456692913384;
		const pageSizeFormat = 'A4';
		const size = config.size * density;
		const pageSize = 210 * density;
		const manyPerRow = Math.floor((pageSize - 100) / size);

		// Count rows to create grid properly
		let rowCount = 0;
		const rows = [];
		let row = [];

		for (let i = 0; i < images.length; i++) {
			if (rowCount >= manyPerRow) {
				rows.push(row);
				rowCount = 0;
				row = [];
			}
			rowCount++;

			row.push([{
				image: images[i],
				width: size
			}]);
		}

		// Fill the remaining columns of the row
		const remainingRow = manyPerRow - row.length;

		if (row.length > 0) {
			rows.push(row);
		}

		for (let j = 0; j < remainingRow; j++) {
			row.push([{text: ''}]);
		}

		const doc = {
			pageSize: pageSizeFormat,
			footer: function(currentPage, pageCount) {
				return {
					text: currentPage.toString(),
					alignment: 'right',
					margin: [0, 0, 30, 20]
				};
			},
			content: [{table: {body: rows}}]
		};

		const pdf = PdfMake.createPdf(doc);

		const buffer = await pdf.getBuffer();

		FileUtils.writeFileArrayBuffer('tags.pdf', buffer);
	}

	/**
	 * Prompt the user for the count of QR and size to be generated.
	 *
	 * @param output - The output format of the QR codes generated.
	 */
	public static async getConfig(output: number = QRToolsOutput.PDF): Promise<any> {
		const config = {
			output: output,
			count: 10,
			size: 35,
			resolution: 512,
			topLogo: QRLogoOutput.EQS,
			bottomLogo: QRLogoOutput.UNO
		};

		const layout: UnoFormField[] = [
			{
				label: 'howMany',
				attribute: 'count',
				type: UnoFormFieldTypes.NUMBER

			},
			{
				label: 'topLogo',
				attribute: 'topLogo',
				type: UnoFormFieldTypes.OPTIONS,
				options: [
					{label: Locale.get('none'), value: QRLogoOutput.NONE},
					{label: Locale.get('uno'), value: QRLogoOutput.UNO},
					{label: Locale.get('eqs'), value: QRLogoOutput.EQS},
					{label: Locale.get('customized'), value: QRLogoOutput.CUSTOMIZED}
				]
			},
			{
				label: 'bottomLogo',
				attribute: 'bottomLogo',
				type: UnoFormFieldTypes.OPTIONS,
				options: [
					{label: Locale.get('none'), value: QRLogoOutput.NONE},
					{label: Locale.get('uno'), value: QRLogoOutput.UNO},
					{label: Locale.get('eqs'), value: QRLogoOutput.EQS},
					{label: Locale.get('customized'), value: QRLogoOutput.CUSTOMIZED}
				]
			},
			{
				label: 'output',
				attribute: 'output',
				type: UnoFormFieldTypes.OPTIONS,
				options: [
					{label: Locale.get('zip'), value: QRToolsOutput.ZIP},
					{label: Locale.get('pdf'), value: QRToolsOutput.PDF}
				]
			},
			{
				label: 'size',
				attribute: 'size',
				type: UnoFormFieldTypes.NUMBER,
				inUnit: 'mm',
				isActive: (obj: any) => {return obj.output === QRToolsOutput.PDF;}
			},
			{
				label: 'resolution',
				attribute: 'resolution',
				type: UnoFormFieldTypes.NUMBER,
				isActive: (obj: any) => {return obj.output === QRToolsOutput.ZIP;},
				inUnit: 'px'
			}
		];

		return new Promise<any>(function(resolve, reject) {
			Modal.form(Locale.get('export'), config, layout).then((data: any) => {
				config.count = Number.parseInt(data.count, 10);

				if (output === QRToolsOutput.PDF) {
					config.size = Number.parseInt(data.size, 10);
					resolve(config);
				} else if (output === QRToolsOutput.ZIP) {
					config.resolution = Number.parseInt(data.resolution, 10);
					resolve(config);
				}
			});
		});
	}

	/**
	 * Generate a page of QR codes with two logos, one of top and one on the bottom.
	 */
	public static async generate(config: any): Promise<void> {
		// Auxiliar method to get logo images
		async function getLogo(logo: QRLogoOutput): Promise<HTMLImageElement> {
			if (logo === QRLogoOutput.CUSTOMIZED) {
				const files = await FileUtils.chooseFile('.jpg, .png', true);
				if (files.length < 1) {
					throw new Error('Image must be selected.');
				}
				const url = await FileReaderAsync.readAsDataURL(files[0]);
				return await ImageUtils.createImageAsync(url);
			} else if (logo === QRLogoOutput.EQS) {
				return await ImageUtils.createImageAsync('./assets/qr/eqs.png');
			} else if (logo === QRLogoOutput.UNO) {
				return await ImageUtils.createImageAsync('./assets/qr/uno.png');
			}

			return null;
		}

		const topImage: HTMLImageElement = await getLogo(config.topLogo);
		const bottomImage: HTMLImageElement = await getLogo(config.bottomLogo);

		const time = performance.now();
		const progress = new ProgressBar();
		progress.show();

		const options = {
			errorCorrectionLevel: 'L',
			type: 'png',
			width: config.resolution,
			scale: 1.0,
			mode: 'Alphanumeric'
		};

		// List of images generated for export, stored in base64
		const images: string[] = [];

		// How many images are ready for export
		let loaded = 0;

		try {
			for (let i = 0; i < config.count; i++) {
				progress.update(Locale.get('processingData'), loaded / config.count);
				
				const frame = document.createElement('canvas');
				frame.width = config.resolution;
				frame.height = config.resolution;
	
				const ctx = frame.getContext('2d');
	
				// Corners
				const cax = config.resolution * 0.07;
				const cay = config.resolution * 0.07;
				const cbx = config.resolution - cax;
				const cby = config.resolution - cay;
	
				// Corner sizes
				const sx = config.resolution * 0.02;
				const sy = config.resolution * 0.2;
	
				ctx.fillStyle = '#000000';
	
				// Top-left
				ctx.fillRect(cax - sx, cay - sx, sy, sx);
				ctx.fillRect(cax - sx, cay - sx, sx, sy);
	
				// Top-right
				ctx.fillRect(cbx + sx, cay - sx, -sy, sx);
				ctx.fillRect(cbx, cay - sx, sx, sy);
	
				// Bottom-left
				ctx.fillRect(cax - sx, cby, sy, sx);
				ctx.fillRect(cax - sx, cby + sx, sx, -sy);
	
				// Bottom-right
				ctx.fillRect(cbx + sx, cby, -sy, sx);
				ctx.fillRect(cbx, cby + sx, sx, -sy);
	
				// Bottom image
				if (bottomImage) {
					const bottomWidth = config.resolution * 0.3;
					const bottomRatio = topImage.width / topImage.height;
					const bottomHeight = bottomWidth / bottomRatio;
					ctx.drawImage(bottomImage, config.resolution / 2 - bottomWidth / 2, config.resolution * 0.9, bottomWidth, bottomHeight);
				}
	
				// Top image
				if (topImage) {
					const topWidth = config.resolution * 0.3;
					const topRatio = topImage.width / topImage.height;
					const topHeight = topWidth / topRatio;
					ctx.drawImage(topImage, config.resolution / 2 - topWidth / 2, config.resolution * 0.1 - topHeight, topWidth, topHeight);
				}
	
				const canvas = document.createElement('canvas');
				canvas.width = config.resolution;
				canvas.height = config.resolution;
	
				QRCode.toCanvas(canvas, generateUUID(), options, (err) => {
					const cctx = canvas.getContext('2d');
					cctx.drawImage(frame, 0, 0, config.resolution, config.resolution);
	
					images.push(canvas.toDataURL('png'));
					loaded++;

					// Generate the PDF file
					if (loaded === config.count) {
						if (config.output === QRToolsOutput.ZIP) {
							QRTools.generateZip(images, config);
						} else if (config.output === QRToolsOutput.PDF) {
							QRTools.generatePDF(images, config);
						}
	
						if (!Environment.PRODUCTION) {
							console.log('EQS: QR Generation took ' + (performance.now() - time) + 'ms.');
						}
					}
	
					progress.destroy();
				});
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorProcessingData'));
			progress.destroy();
		}
	}
}
