import {Component, ViewEncapsulation} from '@angular/core';
import {PopoverController, IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {Session} from '../../../../../session';
import {App} from '../../../../../app';
import {Modal} from '../../../../../modal';
import {PrivacyPolicyViewComponent} from '../../../../privacy-policy/components/view/privacy-policy-view.component';

@Component({
	selector: 'user-avatar-popover',
	templateUrl: './user-avatar-popover.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [IonicModule, TranslateModule]
})
export class UserAvatarPopoverComponent {

	public constructor(public controller: PopoverController) { }

	public app: any = App;

	public session: any = Session;

	/**
	 * Logout of the platform, remove user and session data.
	 */
	public async logout(): Promise<boolean> {
		Session.logout();
		return this.dismiss();
	}

	/**
	 * Hide the popover menu.
	 */
	public async dismiss(): Promise<boolean> {
		return this.controller.dismiss();
	}

	/**
	 * Display privacy policy to the user
	 */
	public async privacyPolicy(): Promise<void> {
		await Modal.component(PrivacyPolicyViewComponent, {displayOnly: true}, true);
	}
}
