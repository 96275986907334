import {AssetService} from '../../../asset-portfolio/services/asset.service';
import {Locale} from '../../../../locale/locale';
import {DocxUtils} from '../../../../utils/docx-utils';
import {FileUtils} from '../../../../utils/file-utils';
import {APAsset} from '../../../../models/asset-portfolio/asset';
import {Repair} from '../../../../models/repairs/repairs/repair';
import {AssetReport} from '../../../asset-portfolio/data/asset-report';
import {RepairReport} from '../../repair-work/data/repair-report';
import {RepairSettings} from '../../repair-work/master-data/repair-settings';
import {RepairInspection} from '../../../../models/repairs/inspections/repair-inspection';
import {RepairInspectionResultLabel} from '../../../../models/repairs/inspections/repair-inspection-result';
import {ResourceUtils} from '../../../../utils/resource-utils';
import {RepairService} from '../../repair-work/services/repair.service';

/**
 * Generate report document for repair inspections. These reports are generated using data obtained from the API and based on docx templates.
 */
export class RepairInspectionReport {
	/**
	 * Path of the default report template.
	 */
	public static defaultReportURL: string = 'assets/template/repair-inspection-report.docx';

	/**
	 * Generate repair inspection report document using a docx template.
	 *
	 * @param inspection - Repair inspection object to generate the report.
	 * @param repair - Repair associated with the inspection (optional, fetched from API if not provided)
	 * @param asset - Asset associated with the repair (optional, fetched from API if not provided)
	 * @param template - Docx template file.
	 */
	public static async generateReportDocx(inspection: RepairInspection, repair?: Repair, asset?: APAsset, template?: ArrayBuffer): Promise<ArrayBuffer> {
		const settings = await RepairSettings.load();

		if (!template) {
			const url = ResourceUtils.getURL(settings.inspectionReportTemplate, RepairInspectionReport.defaultReportURL);
			template = await FileUtils.readFileArrayBuffer(url);
		}

		// Repair
		if (!repair) {
			repair = await RepairService.get(inspection.repairUuid, true);
		}

		// Asset
		if (!asset && repair.asset !== null) {
			asset = await AssetService.get(repair.asset, true);
		}

		const data = {
			// Asset
			asset: asset,
			assetData: asset ? AssetReport.getAssetData(asset) : null,
			assetImages: asset ? AssetReport.getAssetImages(asset) : null,

			// Repair
			repair: repair,
			repairData: RepairReport.getRepairData(repair),
			repairImages: repair.pictures,

			// Proposal
			repairProposalCompany: await RepairReport.getRepairProposalCompany(repair, true),
			repairProposalData: RepairReport.getRepairProposalData(repair),
			repairProposalApprovalData: RepairReport.getRepairProposalApprovalData(repair),

			// Job
			jobData: RepairReport.getRepairJobData(repair),
			jobImages: repair.jobPictures,

			// Inspection
			repairInspection: inspection,
			repairInspectionData: await RepairInspectionReport.getRepairInspectionData(inspection),
			repairInspectionImages: inspection.pictures
		};

		return await DocxUtils.generateDocxFromTemplate(template, data);
	}

	/**
	 * Get repair inspection base data.
	 *
	 * @param inspection - Repair inspection to get the data from.
	 */
	public static async getRepairInspectionData(inspection: RepairInspection): Promise<{attr: string, value: any}[]> {
		const data: {attr: string, value: any}[] = [
			{attr: Locale.get('result'), value: Locale.get(RepairInspectionResultLabel.get(inspection.result))},
			{attr: Locale.get('inspectionNumber'), value: inspection.inspectionNumber},
			{attr: Locale.get('notes'), value: inspection.notes}
		];

		return Promise.resolve(data);
	}
}
