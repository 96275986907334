import {Locale} from '../locale/locale';
import {Session} from '../session';
import {Loading} from '../loading';
import {Modal} from '../modal';
import {FormDataUtils} from '../utils/form-data-utils';
import {Environment} from '../../environments/environment';
import {
	ServiceRequest,
	ServiceRequestErrorHandler,
	ServiceRequestHandler,
	ServiceRequestProgressHandler
} from './service-request';
import {ServiceResponseType} from './type/service-response-type';
import {ServiceBodyType} from './type/service-body-type';
import {ServiceURLType} from './type/service-url-type';
import {ServiceResponse} from './service-response';
import {ServiceStatus} from './service-status';
import {ServiceMeta} from './service-meta';

/**
 * Service contains methods to interact with REST API services.
 *
 * Services should be declared using a ServiceMeta object and can then be accessed in a standard way through the Service class.
 */
export abstract class Service {
	/**
	 * ID counter to identify individual API requests.
	 */
	public static ID: number = 0;

	/**
	 * Check if the status indicates an informational message. (HTTP 100)
	 * 
	 * @param status - Status of the request performed.
	 * @returns True if it represents an informational message.
	 */
	public static isRequestInfo(status: number): boolean {
		return status >= 100 && status < 200;
	}

	/**
	 * Check if the status indicates error code, (e.g 400 or 500);
	 *
	 *
	 * @param status - Status of the request performed.
	 * @returns True if it represents a error, false otherwise.
	 */
	public static isRequestError(status: number): boolean {
		return status >= 400 && status < 600;
	}

	/**
	 * Check if the status indicates an internal server error.
	 *
	 * An internal server error is represented by 500 codes.
	 *
	 * @param status - Status of the request performed.
	 * @returns True if it represents an error, false otherwise.
	 */
	public static isInternalServerError(status: number): boolean {
		return status >= 500 && status < 600;
	}

	/**
	 * Check is the status indicates a bad request.
	 *
	 * A internal server error is represented by 400 codes.
	 *
	 * @param status - Status of the request performed.
	 * @returns True if it represents a error, false otherwise.
	 */
	public static isBadRequest(status: number): boolean {
		return status >= 400 && status < 500;
	}

	/**
	 * Fetch a service to the server defined in the meta object.
	 *
	 * Data is automatically converted to the format used for sending (JSON, ...) if possible.
	 *
	 * @param meta - Service metadata.
	 * @param urlData - Data to be added to the request URL.
	 * @param headerData - Data to be sent in the request header.
	 * @param bodyData - Object to be posted.
	 * @param session - Session ID to be sent to the server for this specific request.
	 * @param hideLoading - If true the loading indicator will not be shown to the user.
	 * @param displayError - If true, errors returned by the API will be automatically presented to the user.
	 * @param onprogress - Callback method called on XHR progress events.
	 */
	public static fetch(meta: ServiceMeta, urlData: any, headerData: any, bodyData: any, session?: string, hideLoading: boolean = Environment.TEST, displayError: boolean = true, onprogress: ServiceRequestProgressHandler = null): Promise<ServiceResponse> {
		return new Promise<ServiceResponse>(function(resolve, reject) {
			const onload = function(response: any, xhr: XMLHttpRequest, id: number): void {
				resolve({
					response: response,
					status: xhr.status,
					id: id
				});
			};

			const onerror = function(response: any, xhr: XMLHttpRequest): void {
				if (displayError) {
					Service.defaultErrorHandler(response, xhr, urlData, headerData, bodyData, meta);
				}

				if (Environment.TEST) {
					reject({
						response: response,
						status: xhr ? xhr.status : null,
						message: `Error while calling API ${meta.server}${meta.url}, received code ${xhr?.status}.\nServer Response:\n ${JSON.stringify(response, null, '\t')}.\nClient Data:\n - Body data:\n${JSON.stringify(bodyData, null, '\t')}.\n - URL data:\n${JSON.stringify(urlData, null, '\t')}.\n - Header data:\n${JSON.stringify(headerData, null, '\t')}`
					});
				} else {
					reject({
						response: response,
						status: xhr ? xhr.status : null
					});
				}
			};

			try {
				Service.processRequest(new ServiceRequest(meta, urlData, headerData, bodyData, session, onload, onerror, onprogress, hideLoading));
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * Call a sync service to the server defined in the meta object.
	 *
	 * Data is automatically converted to the format used for sending (JSON, ...) if possible.
	 *
	 * The onLoad callback receives the response and xhr request as arguments.
	 *
	 * The onError callback is called when the Success is not true, receives the response and xhr request as arguments, if the error was caused by the xhr the first parameter is the error and the second parameter is undefined.
	 *
	 * @param meta - Service metadata.
	 * @param urlData - Data to be added to the request URL.
	 * @param headerData - Data to be sent in the request header.
	 * @param bodyData - Object to be posted.
	 * @param session - Session ID to be sent to the server for this specific request.
	 * @returns Service request object that represents the API access.
	 */
	public static fetchSync(meta: ServiceMeta, urlData: any, headerData: any, bodyData: any, session?: string): ServiceResponse {
		const request = new ServiceRequest(meta, urlData, headerData, bodyData, session, null, null, null, true);
		request.bodyData = Service.convertBodyData(request);

		const url = Service.getURL(request.meta, request.urlData);
		const xhr = Service.request(url, request.meta.type, request.headerData, request.meta.bodyType, request.bodyData, request.meta.responseType, request.session, null, null, null, true);

		let response = xhr.response;
		if (meta.responseType === ServiceResponseType.JSON_TEXT || meta.responseType === ServiceResponseType.JSON) {
			try {
				response = JSON.parse(response);
			} catch (e) {}
		}

		return new ServiceResponse(response, xhr.status, -1);
	}

	/**
	 * Call a service to the server defined in the meta object.
	 *
	 * Data is automatically converted to the format used for sending (JSON, ...) if possible.
	 *
	 * The onLoad callback receives the response and xhr request as arguments.
	 *
	 * The onError callback is called when the Success is not true, receives the response and xhr request as arguments, if the error was caused by the xhr the first parameter is the error and the second parameter is undefined.
	 *
	 * @param meta - Service metadata.
	 * @param urlData - Data to be added to the request URL.
	 * @param headerData - Data to be sent in the request header.
	 * @param bodyData - Object to be posted.
	 * @param session - Session ID to be sent to the server for this specific request.
	 * @param onload - On load callback onLoad(response, xhr, id).
	 * @param onerror - On error callback onError(response, xhr, urlData, headerData, bodyData), if undefined uses a default error handler, if it returns true the default handler is also run.
	 * @param hideLoading - If true the loading indicator will not be shown to the user.
	 * @returns Service request object that represents the API access.
	 */
	public static call(meta: ServiceMeta, urlData: any, headerData: any, bodyData: any, session?: string, onload?: ServiceRequestHandler, onerror: ServiceRequestErrorHandler = Service.defaultErrorHandler, hideLoading: boolean = Environment.TEST): ServiceRequest {
		const request = new ServiceRequest(meta, urlData, headerData, bodyData, session, onload, onerror, null, hideLoading);
		const data = Service.processRequest(request);
		request.xhr = data.xhr;
		request.id = data.id;
		return request;
	}

	/**
	 * Process body data, convert based on the service request specification.
	 * 
	 * @param request - Request to be processed.
	 * @returns The body data converted to the format specified in the request.
	 */
	public static convertBodyData(request: ServiceRequest): any {
		if (request.meta.bodyType === ServiceBodyType.FORM) {
			return Service.encodeFormURL(request.bodyData);
		} else if (request.meta.bodyType === ServiceBodyType.JSON) {
			return JSON.stringify(request.bodyData);
		} else if (request.meta.bodyType === ServiceBodyType.FILE_FORM && !(request.bodyData instanceof FormData)) {
			return FormDataUtils.createFromObject(request.bodyData);
		}

		return request.bodyData;
	}

	/**
	 * Call the next request from the request stack. Gets a request from the queue and processes it.
	 *
	 * After the request is finished the next request is called if there is any in queue.
	 *
	 * @param request - Request to be processed.
	 * @returns XHR object used to process the API request.
	 */
	public static processRequest(request: ServiceRequest): {xhr: XMLHttpRequest, id: number} {
		request.bodyData = Service.convertBodyData(request);

		const id = Service.ID;
		Service.ID++;

		const url = Service.getURL(request.meta, request.urlData);

		if (!request.hideLoading) {
			Loading.show();
		}

		const xxhr = Service.request(url, request.meta.type, request.headerData, request.meta.bodyType, request.bodyData, request.meta.responseType, request.session, function(response, xhr) {
			if (!request.hideLoading) {
				Loading.hide();
			}

			if (Service.isRequestError(xhr.status)) {
				if (request.onError(response, xhr, request.urlData, request.headerData, request.bodyData, request.meta)) {
					Service.defaultErrorHandler(response, xhr, request.urlData, request.headerData, request.bodyData, request.meta);
				}
			} else if (request.onLoad !== undefined) {
				request.onLoad(response, xhr, id);
			}
		}, function(error: Error) {
			if (!request.hideLoading) {
				Loading.hide();
			}

			if (request.onError(error, undefined, request.urlData, request.headerData, request.bodyData, request.meta)) {
				Service.defaultErrorHandler(error, undefined, request.urlData, request.headerData, request.bodyData, request.meta);
			}
		}, request.onProgress);

		return {xhr: xxhr, id: id};
	}

	/**
	 * Default error handler, use by default when the onError callbacks are passes as undefined.
	 *
	 * @param response - Response obtained from the API
	 * @param xhr - The XHR object used to perform the request.
	 * @param urlData - URL data passed to the request.
	 * @param headerData - Header data passed to the request.
	 * @param bodyData - Body data passed to the request.
	 * @param meta - Service meta information
	 */
	public static async defaultErrorHandler(response: any, xhr: XMLHttpRequest, urlData: any, headerData: any, bodyData: any, meta?: ServiceMeta): Promise<void> {
		if (Environment.TEST) {
			return;
		}

		// XHR error
		if (!xhr) {
			await Modal.alert(Locale.get('error'), Locale.get('cannotReachServer'));

		} else if (Service.isInternalServerError(xhr.status)) {
			// Server error

			if (xhr.status === ServiceStatus.gatewayTimeout) {
				await Modal.alert(Locale.get('error'), Locale.get('cannotReachServer'));
			} else {
				await Modal.alert(Locale.get('error'), Locale.get('internalServerError', {details: response}));
			}
		} else if (Service.isBadRequest(xhr.status)) {
			// Client error
			if (xhr.status === ServiceStatus.unauthorized) {
				await Modal.alert(Locale.get('error'), Locale.get('userSessionExpired'));
				Session.logout();
			} else if (xhr.status === ServiceStatus.forbidden) {
				await Modal.alert(Locale.get('error'), Locale.get('noPermissionDetails', {details: response}));
			} else {
				await Modal.alert(Locale.get('error'), Locale.get('badRequest', {details: response}));
			}
		}
	}

	/**
	 * Build URL from meta and urlData.
	 *
	 * @param meta - Service metadata.
	 * @param urlData - Data to be added to the request URL.
	 * @returns URL build from the information provided.
	 */
	public static getURL(meta: ServiceMeta, urlData: any): string {
		let url = meta.server + meta.url;

		// Insert URL data
		if (urlData !== null && urlData !== undefined) {
			if (meta.urlType === ServiceURLType.QUERY) {
				let data: string = Service.encodeFormURL(urlData);
				if (data.length > 0) {
					data = '?' + data;
				}

				url += data;
			} else if (meta.urlType === ServiceURLType.URL) {
				for (const i in urlData) {
					url = url.replace('{' + i + '}', urlData[i]);
				}
			} else if (meta.urlType === ServiceURLType.URL_ARRAY) {
				let data = '';

				// @ts-ignore
				for (let i = 0; i < urlData.length; i++) {
					data += '/' + urlData[i];
				}

				url += data;
			}
		}

		return url;
	}

	/**
	 * Generate form URL encoded data from JSON object.
	 *
	 * @param json - Object to be encoded.
	 * @returns String Form like data to be sent to the server.
	 */
	public static encodeFormURL(json: any): string {
		const data = [];

		for (const i in json) {
			data.push(encodeURIComponent(i) + '=' + encodeURIComponent(json[i]));
		}

		return data.join('&').replace(/%20/g, '+');
	}

	/**
	 * Perform a request with the specified configuration.
	 *
	 * Synchronous request should be avoided unless they are strictly necessary.
	 *
	 * @param url - Target for the request.
	 * @param requestType - Request type (POST, GET, ...)
	 * @param headerData - Object with data to be added to the request header.
	 * @param bodyType - MIME type for the request header (application/x-www-form-urlencoded, application/json, ...)
	 * @param bodyData - Data to be sent in the request.
	 * @param responseType - Type of the response data.
	 * @param session - Session authentication ID.
	 * @param onload - On load callback, receives data (String or Object) and XHR as arguments.
	 * @param onerror - XHR onError callback.
	 * @param onprogress - XHR onProgress callback.
	 * @param sync - The request should be processed syncronously, lock the execution until response is obtained.
	 * @returns XHR object used to perform the request.
	 */
	public static request(url: string, requestType: string, headerData?: any, bodyType?: number, bodyData?: any, responseType?: number, session?: string, onload?: (response: any, xhr: XMLHttpRequest)=> void, onerror?: (error: Error)=> void, onprogress?: ServiceRequestProgressHandler, sync: boolean = false): XMLHttpRequest {
		const xhr = new XMLHttpRequest();

		if (xhr.overrideMimeType) {
			xhr.overrideMimeType('text/plain');
		}

		if (!sync) {
			if (responseType === ServiceResponseType.JSON_TEXT || responseType === ServiceResponseType.TEXT) {
				xhr.responseType = 'text';
			} else if (responseType === ServiceResponseType.ARRAYBUFFER) {
				xhr.responseType = 'arraybuffer';
			} else if (responseType === ServiceResponseType.JSON) {
				xhr.responseType = 'json';
			}
		}

		xhr.open(requestType, url, !sync);

		// Fill header data from Object
		if (headerData !== null && headerData !== undefined) {
			for (const i in headerData) {
				xhr.setRequestHeader(i, headerData[i]);
			}
		}

		// Authentication token
		if (session !== undefined && session !== null) {
			xhr.setRequestHeader('Session', session);
		}

		if (onload) {
			xhr.onload = function(event) {
				if (responseType === ServiceResponseType.TEXT) {
					onload(xhr.response, xhr);
				} else if (responseType === ServiceResponseType.JSON_TEXT || responseType === ServiceResponseType.JSON) {
					try {
						onload(JSON.parse(xhr.response), xhr);
					} catch (e) {
						onload(xhr.response, xhr);
					}
				} else if (responseType === ServiceResponseType.ARRAYBUFFER) {
					onload(xhr.response, xhr);
				}
			};
		}

		if (onerror) {
			// @ts-ignore
			xhr.onerror = onerror;
			// @ts-ignore
			xhr.ontimeout = onerror;
			// @ts-ignore
			xhr.onabort = onerror;
		}

		if (onprogress) {
			xhr.upload.onprogress = onprogress;
		}

		if (bodyData !== undefined) {
			if (bodyType === ServiceBodyType.FORM) {
				xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			} else if (bodyType === ServiceBodyType.JSON) {
				xhr.setRequestHeader('Content-Type', 'application/json');
			} else if (bodyType === ServiceBodyType.TEXT) {
				xhr.setRequestHeader('Content-Type', 'text/plain');
			}

			xhr.send(bodyData);
		} else {
			xhr.send(null);
		}
		
		return xhr;
	}
}
