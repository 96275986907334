import {FormSortUtils} from '../../../utils/form-sort-utils';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {UUID} from '../../../models/uuid';
import {APAssetType} from '../../../models/asset-portfolio/asset-type';
import {APAssetFormTab} from '../../../models/asset-portfolio/asset-form-tab';
import {APAssetFormTabCard} from '../../../models/asset-portfolio/asset-form-tab-card';
import {AssetFormBlockService} from './asset-form-block.service';

export type AssetTypeListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
};

export type AssetTypeCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
};

// Asset Types list API request format.
export type AssetTypeListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Asset Types.
	types: APAssetType[],
	// The id of the api call.
	id: number
};

export type AssetTypeListNameItem = {
	// The UUID of the asset type.
	uuid: UUID,
	// The name of the asset type.
	name: string
}

// Asset Types list name API request format.
export type AssetTypeListNameResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Asset Type Names.
	types: AssetTypeListNameItem[],
	// The id of the api call.
	id: number
};

export class AssetTypeService {
	/**
	 * Get an asset type from its UUID.
	 * 
	 * @param uuid - Uuid of the Asset Type to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The Asset type that matches the UUID.
	 */
	public static async getType(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<APAssetType> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetType.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return APAssetType.parse(request.response.type);
	}

	/**
	 * Lists asset type form tabs by its UUID
	 *
	 * @param typeUuid - The asset type UUID.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns An array with asset type form tabs.
	 */
	public static async listTabs(typeUuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<APAssetFormTab[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.formTab.assetType.list, null, null, {uuid: typeUuid}, Session.session, hideLoading, displayError);
		
		return request.response.tabs.map((d: any) => {
			return APAssetFormTab.parse(d);
		});
	}

	/**
	 * Lists asset type form tab cards by tab UUID
	 *
	 * @param tabUuid - The form tab UUID.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns An array with asset type form tab cards.
	 */
	public static async listTabCards(tabUuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<APAssetFormTabCard[]> {
		// Load tab asset type cards content
		const request = await Service.fetch(ServiceList.assetPortfolio.formTabCard.assetType.list, null, null, {tabUuid: tabUuid}, Session.session, hideLoading, displayError);

		const cards: APAssetFormTabCard[] = [];

		for (let i = 0; i < request.response.cards.length; i++) {
			const card: APAssetFormTabCard = APAssetFormTabCard.parse(request.response.cards[i]);

			card.block = await AssetFormBlockService.get(card.blockUuid, hideLoading, displayError);

			cards.push(card);
		}

		return FormSortUtils.sortByIndexes(cards);
	}

	/**
	 * List all asset types matching the params.
	 *
	 * @param params - Params to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Array of asset types that match the params.
	 */
	public static async list(params: AssetTypeListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<AssetTypeListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetType.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			types: request.response.types.map((data: any) => {return APAssetType.parse(data);}),
			id: request.id
		};
	}

	/**
	 * Count all asset types matching the params.
	 * 
	 * @param params - Params to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns How many asset types match the params
	 */
	public static async count(params: AssetTypeCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetType.count, null, null, params, Session.session, hideLoading, displayError);
		return request.response.count;
	}

	/**
	 * Gets multiple asset types in batch by their UUIDs.
	 *
	 * @param typeUUIDs - The UUIDs of the Asset Types.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Array of asset types that match the UUIDs.
	 */
	public static async getBatch(typeUUIDs: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<APAssetType[]> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetType.getBatch, null, null, {types: typeUUIDs}, Session.session, hideLoading, displayError);

		return request.response.types.map((data: any) => {return APAssetType.parse(data);});
	}

	/**
	 * List name and uuids of asset types matching the params.
	 *
	 * @param params - The UUIDs of the asset ypes.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Array of asset types that match the UUIDs.
	 */
	public static async listName(params: AssetTypeListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<AssetTypeListNameResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.assetType.listName, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			types: request.response.types,
			id: request.id
		};
	}
}
