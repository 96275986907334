import {Vector2} from 'three';

/**
 * Utils to manipulate DOM elements.
 */
export class DOMUtils {
	/**
	 * Check if there is some element on fullscreen mode.
	 *
	 * Returns true even the fullscreen element is not related with the app.
	 *
	 * @returns True if there is some element in fullscreen mode.
	 */
	public static isFullscreen(): boolean {
		return document.fullscreenElement !== null;
	}

	/**
	 * Change fullscreen mode of the application.
	 *
	 * The entire document body can be set to fullscreen mode or a specific element can be specified.
	 *
	 * @param fullscreen - If true enters fullscreen, otherwise tries to leave fullscreen mode.
	 * @param element - DOM element to place in fullscreen.
	 */
	public static async setFullscreen(fullscreen: boolean, element?: HTMLElement): Promise<void> {
		if (!element) {
			element = document.body;
		}

		if (fullscreen && !DOMUtils.isFullscreen()) {
			await element.requestFullscreen();
		} else {
			await document.exitFullscreen();
		}
	}

	/**
	 * Check wait for a DOM element to be rendered.
	 * 
	 * Checks periodically every n milliseconds.
	 */
	public static waitUntilRendered(element: HTMLElement, period: number = 100): Promise<void> {
		return new Promise(function(resolve, reject) {
			function check(): void {
				if (element.offsetWidth > 0 || element.offsetHeight > 0) {
					resolve();
				} else {
					setTimeout(check, period);
				}
			}
			check();
		});
	}
	
	/**
	 * Check if a DOM element in completely visible in the viewport.
	 *
	 * @param element - DOM element to be checked.
	 */
	public static isVisible(element: HTMLElement): boolean {
		let top = element.offsetTop;
		let left = element.offsetLeft;
		const width = element.offsetWidth;
		const height = element.offsetHeight;

		while (element.offsetParent) {
			// @ts-ignore
			element = element.offsetParent;
			top += element.offsetTop;
			left += element.offsetLeft;
		}

		return top >= window.scrollY && left >= window.scrollX && top + height <= window.scrollY + window.innerHeight && left + width <= window.scrollX + window.innerWidth;
	}

	/**
	 * Get position of the dom element in the client window.
	 *
	 * This method considers the window scrolling position.
	 */
	public static getPosition(element: HTMLElement): Vector2 {
		const rect = element.getBoundingClientRect();

		return new Vector2(rect.left + window.scrollX, rect.top + window.scrollY);
	}

	/**
	 * Check if a DOM element is out of the window and how far it is, returns object with x and y values.
	 *
	 * If the value is 0 the element is inside the window on that axis.
	 */
	public static checkBorder(element: HTMLElement): Vector2 {
		let top = element.offsetTop;
		let left = element.offsetLeft;
		const width = element.offsetWidth;
		const height = element.offsetHeight;

		while (element.offsetParent) {
			// @ts-ignore
			element = element.offsetParent;
			top += element.offsetTop;
			left += element.offsetLeft;
		}

		const result = new Vector2();

		// Over the top of the window
		if (top < window.scrollY) {
			result.y = top - window.scrollY;
		// Bellow the window
		} else if (top + height > window.scrollY + window.innerHeight) {
			result.y = top + height - (window.scrollY + window.innerHeight);
		}

		// Left to the window
		if (left < window.scrollX) {
			result.x = left - window.scrollX;
		// Right to the window
		} else if (left + width > window.scrollX + window.innerWidth) {
			result.x = left + width - (window.scrollX + window.innerWidth);
		}

		return result;
	}
}
