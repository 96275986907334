import {InputOptionsMultipleLazyPageRequest, InputOptionsMultipleBatchRequest} from 'src/app/components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Session} from 'src/app/session';
import {SortDirection} from 'src/app/utils/sort-direction';
import {InspectionProjectService} from './services/inspection-project.service';

export class InspectionsFilterBarFields {
	/**
	 * Inspection field for the filter bar.
	 */
	public static inspectionField: UnoFilterBarOption = {
		type: UnoFilterBarOptionType.OPTIONS_LAZY,
		attribute: 'inspectionProjectUuid',
		label: 'inspectionProject',
		identifierAttribute: 'uuid',
		multiple: false,
		default: null,
		fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest, object: any): Promise<{options: any[], hasMore: boolean, id: number}> => {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[inspection_project].[name]',
				sortDirection: SortDirection.ASC
			};
    
			const req = await InspectionProjectService.list(data);
			return {options: req.projects, hasMore: req.hasMore, id: req.id};
		},
		fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> => {
			if (request.options.length > 0) {
				const data = {uuid: request.options[0]};
				const req = await Service.fetch(ServiceList.inspection.project.get, null, null, data, Session.session);
				return {options: req.response.project};
			}

			return {options: []};
		},
		getOptionText: (option: any): string => {
			return [option.name, option.description].filter(Boolean).join(' - ');
		}
	};
}
