import {Pipe, PipeTransform} from '@angular/core';

/**
 * Range pipe to generate a range of numbers.
 */
@Pipe({
	name: 'range',
	pure: true, 
	standalone: true
})
export class RangePipe implements PipeTransform {
	public transform(size: number = 0, start: number = 0, step: number = 1): any {
		const range: number[] = [];
		for (let length = 0; length < size; ++length) {
			range.push(start);
			start += step;
		}

		return range;
	}
}
