import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {MenuCardsOptions, MenuCardsComponent} from '../../../../components/layout/menu-cards/menu-cards.component';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {Session} from '../../../../session';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {AssetTypeCardsComponent} from './asset-type-cards/asset-type-cards.component';

@Component({
	selector: 'asset-portfolio-menu-page',
	templateUrl: 'asset-portfolio-menu.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsComponent, IonicModule, UnoSearchbarComponent, AssetTypeCardsComponent, TranslateModule]
})
export class AssetPortfolioMenuPage extends ScreenComponent implements OnInit {
	@ViewChild('cards', {static: true})
	public cards: AssetTypeCardsComponent = null;

	@ViewChild('content', {static: true})
	public content: UnoContentComponent = null;

	public app: any = App;

	public session: any = Session;

	public permissions = [UserPermissions.ASSET_PORTFOLIO];

	public userPermissions: any = UserPermissions;

	public selfStatic: any = AssetPortfolioMenuPage;

	public static filters = {
		/**
		 * Text used to filter asset types by.
		 */
		search: '',

		/**
		 * Search fields to be considered on search filter.
		 */
		searchFields: ['[ap_asset_type].name']
	};

	/**
	 * List of base cards options in this menu.
	 */
	public menuCards: MenuCardsOptions[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('assetPortfolio');

		const container = this.content.div.nativeElement;
		container.onscroll = () => {
			const bottom = container.scrollHeight - container.scrollTop;
			const margin = 100;

			if (bottom - margin < container.offsetHeight) {
				this.cards.handler.nextPage();
			}
		};

		await this.getMenuCards();
		await this.cards.reset();
	}

	/**
	 * Update the text used to filter search.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(event: any): Promise<void> {
		if (event.target === undefined) {
			AssetPortfolioMenuPage.filters.search = event;
		}

		await this.cards.reset();
	}

	/**
	 * Dynamically build base menu group.
	 * 
	 * @returns a promise with a menu card group.
	 */
	public async getMenuCards(): Promise<void> {
		this.menuCards = [
			{
				label: 'treeView',
				data: {},
				permissions: [],
				icon: './assets/icons/assets/component.svg',
				route: '/menu/asset-portfolio/asset/list/tree',
				badge: (async() => {
					return (await Service.fetch(ServiceList.assetPortfolio.asset.count, null, null, null, Session.session, true)).response.count;
				})()
			},
			{
				label: 'list',
				data: {},
				permissions: [],
				icon: './assets/icons/assets/component.svg',
				route: '/menu/asset-portfolio/asset/list',
				badge: (async() => {
					return (await Service.fetch(ServiceList.assetPortfolio.asset.count, null, null, null, Session.session, true)).response.count;
				})()
			},
			{
				label: 'modelList',
				data: {},
				permissions: [],
				icon: './assets/icons/assets/sub-op-uni.svg',
				route: '/menu/asset-portfolio/asset/list/model',
				badge: null
			},
			{
				label: 'structure',
				data: {},
				permissions: [UserPermissions.ASSET_PORTFOLIO_STRUCTURE],
				icon: './assets/icons/assets/sub-op-uni.svg',
				route: '/menu/asset-portfolio/structure',
				badge: null
			}
		];

		// Asset creation and lists card menus should only be presented if at least one asset type exists.
		const request = await Service.fetch(ServiceList.assetPortfolio.assetType.count, null, null, null, Session.session, true);
		if (request.response.count > 0) {
			this.menuCards.push({
				label: 'create',
				data: {createMode: true},
				permissions: [],
				icon: './assets/icons/assets/component.svg',
				route: '/menu/asset-portfolio/asset/edit',
				badge: null
			});
		}
	}
}
