import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {UnoFormField} from '../uno-form/uno-form-field';

/**
 * Form composed field is a field built from other form field type entries.
 */
@Component({
	selector: 'uno-form-composed-field',
	templateUrl: './uno-form-composed-field.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoFormComposedFieldComponent; }),
		multi: true
	}]
})
export class UnoFormComposedFieldComponent implements ControlValueAccessor {
	public app: any = App;

	public locale: any = Locale;

	/**
	 * Row of the inspection form being edited in this field, used to fetch additional details.
	 */
	@Input()
	public row: UnoFormField = null;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Value stored in this input component.
	 */
	public value: any = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		this.onChange(this.value);
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
