import {Component, Signal, ViewEncapsulation, WritableSignal, computed, forwardRef, signal} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {LocaleList} from 'src/app/locale/locale';
import {Session} from 'src/app/session';
import {UnoOptionIconData, UnoOptionsIcon} from '../../uno/uno-options-icon/uno-options-icon.component';

@Component({
	selector: 'uno-language-options',
	templateUrl: './uno-language-options.component.html',
	styleUrls: ['./uno-language-options.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoLanguageOptions; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoOptionsIcon]
})
export class UnoLanguageOptions {
	/**
	 * Array containing possible language options
	 */
	public languageOptions: Signal<UnoOptionIconData[]> = computed(() => {
		return LocaleList.map((locale) => {
			return {value: locale.code, icon: `/assets/flags/${locale.code}.svg`, label: locale.code.toLocaleUpperCase()};
		});
	});

	/**
	 * Currently Selected language option  
	 */
	public selectedLanguage: WritableSignal<UnoOptionIconData> = signal({value: '', icon: '', label: ''});

	/** 
	 * On init, get selected language
	 */
	public ngOnInit(): void {

		const settings = structuredClone(Session.settings);

		if (settings.locale === 'en') {
			this.selectedLanguage.set({value: settings.locale, icon: '/assets/flags/gb.svg', label: settings.locale.toLocaleUpperCase()});
		} else {
			this.selectedLanguage.set({value: settings.locale, icon: `/assets/flags/${settings.locale}.svg`, label: settings.locale.toLocaleUpperCase()});
		}
	}
    
	public updateLanguage(option?: UnoOptionIconData): void {
		const settings = structuredClone(Session.settings);
		settings.locale = option.value;
		Session.updateSettings(settings);
	}
}
