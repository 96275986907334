import {Component, Input} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {Resource} from '../../../../../models/resource';
import {App} from '../../../../../app';
import {HierarchicalTeam, TeamListPage} from '../team-list.page';

import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';

/**
 * Team list item is used to display a team (with its sub teams on a collapsable tree list)
 */
@Component({
	selector: 'team-list-item',
	templateUrl: 'team-list-item.component.html',
	standalone: true,
	imports: [UnoListItemComponent, UnoListItemIconComponent, NgStyle, IonicModule, UnoListItemLabelComponent]
})
export class TeamListItemComponent {
	public resource: any = Resource;
	
	@Input()
	public team: HierarchicalTeam = null;

	/**
	 * Level of this object in the tree.
	 */
	@Input()
	public level: number = 1;

	/**
	 * If true the tree list is expanded to show its children.
	 */
	public expanded: boolean = false;

	
	/**
	 * Method called when the node is clicked, to open the team edit page.
	 */
	public editTeam(): void {
		App.navigator.navigate('/menu/teams/edit', {uuid: this.team.uuid});
	}

	/**
	 * Toggle the value of expand var, making template expand/collapse
	 * 
	 * May require data to be loaded lazily.
	 */
	public expand(event: MouseEvent): boolean {
		// Cancel event bubble to prevent navigation
		if (event !== undefined) {
			event.preventDefault();
			event.stopPropagation();
		}
		
		// Lazy load data if method available.
		if (this.team.hasChildren && !this.team.children) {
			TeamListPage.loadData(this.team.uuid).then((children) => {
				this.team.children = children;
			});

		}

		// Toggle expanded flag
		this.expanded = !this.expanded;


		return false;
	}

	protected readonly ResourceUtils = ResourceUtils;
}
