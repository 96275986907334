import {Component, Input, ViewEncapsulation} from '@angular/core';


@Component({
	selector: 'uno-list-item-icon',
	templateUrl: './uno-list-item-icon.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: []
})
export class UnoListItemIconComponent {
	@Input()
	public src: string;
}
