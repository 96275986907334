import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {IonicSelectableComponent} from 'ionic-selectable';
import {TranslateModule} from '@ngx-translate/core';

import {IonicModule} from '@ionic/angular';
import {Session} from '../../../session';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {App} from '../../../app';
import {UserPermissions} from '../../../models/users/user-permissions';
import {User} from '../../../models/users/user';
import {UUID} from '../../../models/uuid';

/**
 * Component used to select users from a list.
 */
@Component({
	selector: 'uno-user-selector',
	templateUrl: './uno-user-selector.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoUserSelectorComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [IonicModule, IonicSelectableComponent, FormsModule, TranslateModule]
})
export class UnoUserSelectorComponent implements ControlValueAccessor {
	public permissions: any = UserPermissions;

	public session: any = Session;

	public app: any = App;
	
	/**
	 * Indicate if the user can select multiple users.
	 *
	 * When selecting multiple, the value stored is an array.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set, clear button will be shown on component to clear its value.
	 */
	@Input()
	public showClear: boolean = false;

	/**
	 * If set, sorts all the items alphabetically upon fetch.
	 */
	@Input()
	public sort: boolean = false;

	/**
	 * List of the selected user UUIDs stored in this component.
	 *
	 * This list can be changed dynamically using the writeValue() method.
	 */
	public value: (UUID[] | UUID) = [];

	/**
	 * Companies that are selected on the object and are not yet available from the API.
	 *
	 * These should be retrieved from the API.
	 */
	public baseOptions: User[] = [];

	/**
	 * Teams available based on the search value inserted.
	 */
	public options: User[] = [];

	/**
	 * Search value inserted.
	 */
	public search: string = '';

	/**
	 * Number of elements to fetch on each request when using lazy loading.
	 */
	public batchSize: number = 20;

	/**
	 * Number of element already loaded from the API, used to control lazy loading.
	 */
	public count: number = 0;

	/**
	 * Load more data from the API to display. Only loads a chunk of data at a time.
	 *
	 * @param component - Component, used to control the data flow.
	 * @param isSearch - If set true its assumed to be a search or first request, the lazy loader counter is reset and data is cleared.
	 */
	public async loadDataLazy(component?: any, isSearch: boolean = false): Promise<void> {
		// Auxiliary method to add loaded options to the list
		const addOptions = (options): void => {
			for (let i = 0; i < options.length; i++) {
				// Check the uuid already exists in the base options that were pre-loaded
				if (this.baseOptions.find(function(a) { return a.uuid === options[i].uuid; }) === undefined) {
					if (!this.options) {
						this.options = [];
					}
					
					this.options.push(options[i]);
				}
			}
		};

		const data: any = {
			from: isSearch ? 0 : this.count,
			count: this.batchSize,
			search: this.search
		};

		if (this.sort) {
			data.sortField = 'name';
			data.sortDirection = 'ASC';
		}

		const request = await Service.fetch(ServiceList.users.list, null, null, data, Session.session);

		// If is it a search result clean up old options
		if (isSearch) {
			if (component) {
				component.enableInfiniteScroll();
			}

			this.options = this.baseOptions.concat([]);
			addOptions(request.response.users);
			this.count = request.response.users.length;
		} else {
			addOptions(request.response.users);
			this.count += request.response.users.length;
		}

		if (component) {
			if (!request.response.hasMore) {
				component.disableInfiniteScroll();
			}
			if (isSearch) {
				component.endSearch();
			}
			component.endInfiniteScroll();
		}
	}

	/**
	 * Load base options from the API server, these options are the ones selected on the object.
	 *
	 * They need to be fetched to show on the list (their name is not known, if their info is not retrieved).
	 */
	public async loadBaseOptions(): Promise<void> {
		if (!this.value) {
			return;
		}

		const users: any = this.multiple ? this.value : [this.value];
		
		const request = await Service.fetch(ServiceList.users.getBatch, null, null, {users: users}, Session.session);

		this.baseOptions = request.response.users;
		this.options = this.baseOptions.concat([]);
	}

	/**
	 * Callback method called when the user inputs something into the search box.
	 */
	public onSearch(event: {component: IonicSelectableComponent, text: string}): void {
		this.search = event.text;
		this.loadDataLazy(event.component, true);
	}

	/**
	 * Callback method used to load more data from the API.
	 */
	public onInfiniteLoad(event: {component: IonicSelectableComponent, text: string}): void {
		this.loadDataLazy(event.component, false);
	}
	
	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		this.loadBaseOptions();
		this.onChange(this.value);
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}
