import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';

export class AtexInspectionChecklistService {
	/**
	 * Load inspection checklists from the API server.
	 *
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns Atex inspection checklist.
	 */
	public static async get(hideLoading: boolean = false, displayError: boolean = true): Promise<any> {
		const request = await Service.fetch(ServiceList.atex.inspection.checklistsGet, null, null, null, Session.session, hideLoading, displayError);

		return request.response;
	}

}
