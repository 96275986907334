import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {LogsPage} from './screens/menu/logs.page';
import {LogsErrorPage} from './screens/errors/logs-error.page';
import {LogsErrorTasksPage} from './screens/errors-tasks/logs-error-tasks.page';
import {LogsErrorClientPage} from './screens/errors-client/logs-error-client.page';
import {LogsAccessPage} from './screens/access/logs-access.page';

const routes: Routes = [
	{
		path: '',
		component: LogsPage
	},
	{
		path: 'error',
		component: LogsErrorPage
	},
	{
		path: 'tasks/error',
		component: LogsErrorTasksPage
	},
	{
		path: 'client/error',
		component: LogsErrorClientPage
	},
	{
		path: 'access',
		component: LogsAccessPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LogsRouterModule { }
