import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CalendarEvent} from 'angular-calendar';
import {UnoContentComponent} from 'src/app/components/uno/uno-content/uno-content.component';
import {UnoIconComponent} from 'src/app/components/uno/uno-icon/uno-icon.component';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {CalendarEventSubtypes, CalendarEventSubtypesLabel, CalendarEventTypes, CalendareventSubtypeColors} from 'src/app/models/asset-planning/calendar-event-actions';
import {CalendarEventOccurrence} from 'src/app/models/asset-planning/calendar-event-occurrence';
import {CalendarEventOccurrencePriorityIcon} from 'src/app/models/asset-planning/calendar-event-occurrence-priority';
import {CalendarEventStatus, CalendarEventStatusBackground, CalendarEventStatusLabel} from 'src/app/models/asset-planning/calendar-event-occurrence-status';
import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {Session} from 'src/app/session';
import {UUID} from 'src/app/models/uuid';
import {Inspection} from 'src/app/models/inspections/inspection/inspection';
import {ServiceResponse} from 'src/app/http/service-response';
import {InspectionWorkflowStep} from 'src/app/models/inspections/workflow/inspection-workflow-step';
import {App} from 'src/app/app';
import {AtexInspection} from 'src/app/models/atex-inspections/inspections/atex-inspection';
import {Repair} from 'src/app/models/repairs/repairs/repair';
import {RepairInspection} from 'src/app/models/repairs/inspections/repair-inspection';
import {UnoNoDataComponent} from 'src/app/components/uno/uno-no-data/uno-no-data.component';
import {DateFrequencyUtils} from 'src/app/utils/date-frequency-utils';
import {SortDirection} from 'src/app/utils/sort-direction';
import {CalendarEvent as PlanningCalendarEvent} from '../../../../models/asset-planning/calendar-event';

@Component({
	selector: 'asset-calendar-list',
	templateUrl: 'asset-calendar-list.component.html',
	styleUrls: ['asset-calendar-list.component.css'],
	standalone: true,
	imports: [
		CommonModule,
		UnoContentComponent,
		TranslateModule,
		UnoIconComponent,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		UnoNoDataComponent
	]
})

export class AssetCalendarListComponent implements OnInit {

	public app = App;

	public session = Session;

	public locale = Locale;

	public priorityIcon = CalendarEventOccurrencePriorityIcon;

	public calendarEventSubtypes = CalendarEventSubtypes;

	public calendarEventStatus = CalendarEventStatus;

	public calendarEventStatusLabel = CalendarEventStatusLabel;

	public calendarEventStatusBackground = CalendarEventStatusBackground;

	public get calendarEventSubtypesLabel(): Map<number, string> {return CalendarEventSubtypesLabel;};

	/**
	 * The Asset UUID.
	 */
	@Input()
	public asset: APAsset = null;

	/**
	 * The events.
	 */
	@Input()
	public events: CalendarEvent[] = [];

	public calendarEventSubtypeColors: Map<number, string> = new Map<number, string>([
		[CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION, CalendareventSubtypeColors.get(CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION)],
		[CalendarEventSubtypes.ASSET_ATEX_INSPECTION, CalendareventSubtypeColors.get(CalendarEventSubtypes.ASSET_ATEX_INSPECTION)],
		[CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION, CalendareventSubtypeColors.get(CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION)],
		[CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR, CalendareventSubtypeColors.get(CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR)]
	]);

	public async ngOnInit(): Promise<void> {
		const response = (await Service.fetch(ServiceList.assetPlanning.calendarEvent.listByAsset, null, null, {assetUuid: this.asset.uuid, sortDirection: SortDirection.ASC, sortField: '[calendar_event_occurrence].[date]'}, Session.session)).response;

		const calendarEvents: PlanningCalendarEvent[] = response.calendarEvents.map((d: any) => {
			return PlanningCalendarEvent.parse(d);
		});

		// Parse the request response into a correct object
		const occurrences = response.occurrences.map((d: any) => {
			return CalendarEventOccurrence.parse(d);
		});

		const findTriggerEventDate = (event: PlanningCalendarEvent): Date => {
			let triggerEvent: PlanningCalendarEvent = event;
			while (triggerEvent.triggerEventUuid) {
				triggerEvent = calendarEvents.find((evt: PlanningCalendarEvent) => {
					return evt.uuid === triggerEvent.triggerEventUuid;
				});
			}

			return triggerEvent.date;
		};

		const eventList = [];

		// Iterate through the calendar event occurrences
		for (const occurrence of occurrences) {
			// Find calendar event associated with this occurrence
			const event: PlanningCalendarEvent = calendarEvents.find((evt: PlanningCalendarEvent) => {
				return evt.uuid === occurrence.calendarEventUuid;
			});

			if (event) {
				const startDate: Date = new Date(occurrence.date !== null ? occurrence.date : findTriggerEventDate(event));
				
				let endDate: Date = new Date(startDate);
				if (event.duration !== null) {
					endDate = DateFrequencyUtils.addDate(endDate, event.duration);
				}

				eventList.push({
					title: event.name,
					color: {primary: CalendareventSubtypeColors.get(event.actionSubtype), secondary: 'var(--brand-primary)'},
					start: startDate,
					end: endDate,
					// Meta is used to store any custom data in the GUI calendar event
					meta: {...event, occurrence: occurrence} 
				});
			}
		}
		this.events = this.events.concat(eventList);
	}

	/**
	 * Method to check if an event has an action associated.
	 * 
	 * @param event - The event to check if it has a pointer
	 * @returns True if the occurrence is of subType atex inspection, inspection, repair or repair inspection and has a valid uuid.
	 */
	public hasActionAssociated(event: CalendarEvent): boolean {
		if (event.meta.actionSubtype === CalendarEventSubtypes.ASSET_ATEX_INSPECTION ||
			event.meta.actionSubtype === CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION ||
			event.meta.actionSubtype === CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR ||
			event.meta.actionSubtype === CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION) {
			return true;
		}
		return false;
	}

	/**
	 * Closes the modal and resets the page.
	 */
	public closeModal(): void {
		App.popover.dismiss();
	}

	/**
	 * When an occurrence is clicked navigate to its execution.
	 * 
	 * @param event - The occurrence that was clicked
	 */
	public occurrenceClicked(event: any): void {
		if (event.meta.actionSubtype !== CalendarEventSubtypes.SCHEDULED_AUDIT && event.meta.actionSubtype !== CalendarEventSubtypes.SCHEDULED_PLANNED_STOP_MAINTENANCE) {
			this.navigateToAction(event);
		}
	}

	/**
	 * Navigates to the action associated to the calendar occurrence.
	 *
	 * Action might be of different type based on calendar event.
	 *
	 * @param ev - Event object containing the calendar occurrence.
	 */
	public async navigateToAction(ev: CalendarEvent, showModal: boolean = true): Promise<void> {

		const event: PlanningCalendarEvent = ev.meta;
		const occurrence: CalendarEventOccurrence = ev.meta.occurrence;
		let createMode: boolean = false;

		if (showModal && !occurrence.atexInspectionUuid && !occurrence.inspectionUuid && !occurrence.repairUuid && !occurrence.repairInspectionUuid) {
			if (!await Modal.confirm(Locale.get('create'), Locale.get('createNewInspectionConfirmation'))) {
				return;
			}
			createMode = true;
		}
		this.closeModal();
		if (event.actionType === CalendarEventTypes.ASSET) {
			switch (event.actionSubtype) {
				case CalendarEventSubtypes.ASSET_ATEX_INSPECTION:
					if (createMode) {
						occurrence.atexInspectionUuid = await this.createAction(event);

						// Update the occurrence.
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, occurrence, Session.session);
					}
					this.closeModal();
					App.navigator.navigate('/menu/atex/inspections/edit', {uuid: occurrence.atexInspectionUuid});
					break;
				case CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION:
					if (createMode) {
						occurrence.inspectionUuid = await this.createAction(event);

						// Update the occurrence.
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, occurrence, Session.session);
					}
					this.closeModal();
					App.navigator.navigate('/menu/inspection/edit', {project: event.inspectionProjectUuid, uuid: occurrence.inspectionUuid});
					break;
			}
		} else if (event.actionType === CalendarEventTypes.REPAIR) {
			switch (event.actionSubtype) {
				case CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR:
					if (createMode) {
						occurrence.repairUuid = await this.createAction(event);

						// Update the occurrence.
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, occurrence, Session.session);
					}
					this.closeModal();
					App.navigator.navigate('/menu/repairs/works/edit', {uuid: occurrence.repairUuid});
					break;
				case CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION:
					if (createMode) {
						occurrence.repairInspectionUuid = await this.createAction(event);

						// Update the occurrence.
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.updateOccurrence, null, null, occurrence, Session.session);
					}
					this.closeModal();
					App.navigator.navigate('/menu/repairs/inspections/edit', {uuid: occurrence.repairInspectionUuid});
					break;
			}
		}
	}

	/**
	 * Create the action associated with the calendar event.
	 *
	 * Might be of different type (e.g. repair, inspection) depending on calendar event type.
	 *
	 * @param event - Calendar event to create action for.
	 */
	public async createAction(event: PlanningCalendarEvent): Promise<UUID> {
		let uuid: UUID;
		switch (event.actionSubtype) {
			case CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION:
				if (event.assetUuid) {
					const inspection = new Inspection();

					inspection.assetUuid = event.assetUuid;
					inspection.projectUuid = event.inspectionProjectUuid;

					const stepRequest: ServiceResponse = await Service.fetch(ServiceList.inspection.project.defaultStep, null, null, {uuid: inspection.projectUuid}, Session.session);
					const step: InspectionWorkflowStep = InspectionWorkflowStep.parse(stepRequest.response.step);
					
					inspection.stepUuid = step.uuid;

					uuid = (await Service.fetch(ServiceList.inspection.create, null, null, inspection, Session.session)).response.uuid;
				}
				break;
			case CalendarEventSubtypes.ASSET_ATEX_INSPECTION:
				if (event.assetUuid) {
					const atexInspection = new AtexInspection();
					atexInspection.assetUuid = event.assetUuid;
					uuid = (await Service.fetch(ServiceList.atex.inspection.createForAsset, null, null, atexInspection, Session.session)).response.uuid;
				}
				break;
			case CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR:
				if (event.assetUuid) {
					const definitiveRepair = new Repair();
					definitiveRepair.asset = event.assetUuid;
					uuid = (await Service.fetch(ServiceList.repairs.create, null, null, definitiveRepair, Session.session)).response.uuid;
				}
				break;
			case CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION:
				if (event.repairUuid) {
					const repair = new RepairInspection();
					repair.repairUuid = event.repairUuid;
					uuid = (await Service.fetch(ServiceList.repairInspections.create, null, null, repair, Session.session)).response.uuid;
				}
				break;
		}
		return uuid;
	}
}
