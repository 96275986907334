/**
 * ArraybufferUtils contains methods to convert from and to ArrayBuffer binary format
 */
export class ArraybufferUtils {
	/**
	 * Create arraybuffer from base64 string, the base 64 string should not contain any header. It should be removed before calling this method.
	 *
	 * @param str - Input string data bas64.
	 * @returns Array buffer data obtained from the base64 string.
	 */
	public static fromBase64(str: string): ArrayBuffer {
		const encoding = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
		const length = str.length / 4 * 3;
		const array = new ArrayBuffer(length);
		const view = new Uint8Array(array);

		let a, b, c, d;

		let i = 0, j = 0;
		for (; i < length; i += 3) {
			a = encoding.indexOf(str.charAt(j++));
			b = encoding.indexOf(str.charAt(j++));
			c = encoding.indexOf(str.charAt(j++));
			d = encoding.indexOf(str.charAt(j++));

			// Tslint:disable-next-line:no-bitwise
			view[i] = a << 2 | b >> 4;
			if (c !== 64) {
				// Tslint:disable-next-line:no-bitwise
				view[i + 1] = (b & 15) << 4 | c >> 2;
			}
			if (d !== 64) {
				// Tslint:disable-next-line:no-bitwise
				view[i + 2] = (c & 3) << 6 | d;
			}
		}

		return array;
	}

	/**
	 * Create arraybuffer from binary string. A binary string encodes binary data directly based on the system char encoding.
	 *
	 * These strings do not waste any space with encoding (like base64) but cannot be transferred/converted to other encoding formats.
	 *
	 * @param str - Binary string in which each character represents its binary data counterpart based on the encoding method in usage.
	 * @returns Array buffer data obtained from the characters binary content.
	 */
	public static fromBinaryString(str: string): ArrayBuffer {
		const length = str.length;
		const arraybuffer = new ArrayBuffer(length);
		const view = new Uint8Array(arraybuffer);

		for (let i = 0; i < length; i++) {
			view[i] = str.charCodeAt(i);
		}

		return arraybuffer;
	}

	/**
	 * Create arraybuffer from Nodejs buffer
	 *
	 * @param buffer - Nodejs buffer to be converted into array buffer.
	 * @returns Converted data in ArrayBuffer format.
	 */
	// @ts-ignore
	public static fromBuffer(buffer: Buffer): ArrayBuffer {
		const array = new ArrayBuffer(buffer.length);
		const view = new Uint8Array(array);
		
		for (let i = 0; i < buffer.length; i++) {
			view[i] = buffer[i];
		}

		return array;
	}
}

