import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {DoughnutChartData, DoughnutChartComponent} from '../../../../../../components/charts/doughnut-chart/doughnut-chart.component';
import {CSSUtils} from '../../../../../../utils/css-utils';
import {Session} from '../../../../../../session';
import {Locale} from '../../../../../../locale/locale';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {App} from '../../../../../../app';
import {AtexInspectionStatus} from '../../../../../../models/atex-inspections/inspections/atex-inspection-status';
import {AtexInspectionsDocumentFilter} from '../../../screens/list/atex-inspections-list.page';

@Component({
	selector: 'graph-atex-inspection-progress',
	templateUrl: './atex-inspection-progress.component.html',
	standalone: true,
	imports: [IonicModule, DoughnutChartComponent, NgStyle, TranslateModule]
})
export class AtexInspectionProgressComponent implements OnInit {
	public data: DoughnutChartData[] = [];

	public async ngOnInit(): Promise<void> {
		const request = await Service.fetch(ServiceList.atex.inspection.countStatusResult, null, null, null, Session.session, true);
		const count = request.response.inspections.count;

		this.data = [
			new DoughnutChartData(Locale.get('todo'), count.todo, CSSUtils.parseColor('rgb(255, 99, 132)'), () => {
				App.navigator.navigate('/menu/atex/inspections/list', {status: AtexInspectionStatus.TODO, results: [], filterWaitingDocuments: AtexInspectionsDocumentFilter.ALL});
			}),
			new DoughnutChartData(Locale.get('review'), count.review, CSSUtils.parseColor('rgb(255, 206, 86)'), () => {
				App.navigator.navigate('/menu/atex/inspections/list', {status: AtexInspectionStatus.REVIEW, results: [], filterWaitingDocuments: AtexInspectionsDocumentFilter.ALL});
			}),
			new DoughnutChartData(Locale.get('underSupervision'), count.supervision, CSSUtils.parseColor('rgb(54, 162, 235)'), () => {
				App.navigator.navigate('/menu/atex/inspections/list', {status: AtexInspectionStatus.TODO_SUPERVISION, results: [], filterWaitingDocuments: AtexInspectionsDocumentFilter.ALL});
			}),
			new DoughnutChartData(Locale.get('finished'), count.approved, CSSUtils.parseColor('rgb(75, 192, 192)'), () => {
				App.navigator.navigate('/menu/atex/inspections/list', {status: AtexInspectionStatus.COMPLETED, results: [], filterWaitingDocuments: AtexInspectionsDocumentFilter.ALL});
			})
		];
	}
}
