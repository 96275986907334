import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormComponent} from '../../../../components/uno-forms/uno-form/uno-form.component';
import {App} from '../../../../app';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {UnoFormModule} from '../../../../components/uno-forms/uno-form.module';
import {ObservationsLayout} from '../observations-layout';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {ObservationStatus} from '../../../../models/observations/observation-status';
import {ObservationService} from '../../services/observation.service';
import {Observation} from '../../../../models/observations/observation';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {PermissionsPipe} from '../../../../pipes/permissions.pipe';

@Component({
	selector: 'edit-observation',
	templateUrl: 'observations-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule, PermissionsPipe]
})
export class ObservationsEditPage extends ScreenComponent implements OnInit {

	public app: any = App;

	public layout: any = ObservationsLayout;

	public observationStatus: any = ObservationStatus;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	@ViewChild('form', {static: false})
	public form: UnoFormComponent = null;

	public permissions = [UserPermissions.OBSERVATIONS_EDIT];

	/**
	 * Observation being edited in the page.
	 */
	@Input()
	public observation: Observation = null;

	public constructor(private service: ObservationService) {
		super();
	}

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('edit');

		const data = App.navigator.getData();
		if (!data || !data.uuid) {
			App.navigator.pop();
		}

		this.observation = await this.service.get(data.uuid);
	}


	/**
	 * Create a repair from the data in this observation.
	 */
	public async createRepair(): Promise<void> {
		await this.update(true, ObservationStatus.DONE);
		App.navigator.navigate('/menu/repairs/works/edit', {createMode: true});
	}

	/**
	 * Create task in the API, send the task information to the server.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 * @param overrideStatus - Status to override the observation.
	 */
	public async update(stayOnPage: boolean = false, overrideStatus?: number): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const observation = structuredClone(this.observation);

		if (overrideStatus !== undefined) {
			observation.status = overrideStatus;
		}

		await Service.fetch(ServiceList.observation.update, null, null, observation, Session.session);

		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('observationUpdated'));
	}

	/**
	 * Delete the observation from the database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.observation.delete, null, null, {uuid: this.observation.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
