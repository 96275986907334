import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle, DatePipe} from '@angular/common';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {ExperimentListResponse, ExperimentService} from 'src/app/modules/pipeline-integrity/services/experiment.service';
import {UUID} from '../../../../../../models/uuid';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Experiment} from '../../../../../../models/pipeline-integrity/mot/experiment';
import {UnoListItemLabelComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoNoDataComponent} from '../../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';

@Component({
	selector: 'mot-experiment-list-page',
	templateUrl: 'mot-experiment-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		DatePipe,
		TranslateModule,
		UnoResponsiveTableListComponent
	]
})

export class MOTExperimentListPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public session: any = Session;

	public selfStatic: any = MOTExperimentListPage;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY];

	/**
	 * UUID of the acquisition.
	 */
	public acquisitionUuid: UUID;

	/**
	 * List of Experiments to be displayed retrieved by the API.
	 */
	public experiments: Experiment[] = [];

	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'label', type: UnoTableColumnType.TEXT, attribute: 'label', visible: true, size: 'small', tag: ListDisplayStyle.TEXT},
		{header: 'date', type: UnoTableColumnType.DATE, attribute: 'dateTime', visible: true, size: 'small', tag: ListDisplayStyle.TEXT},
		{header: 'frequency', type: UnoTableColumnType.TEXT, attribute: 'frequency', visible: true, size: 'small', tag: ListDisplayStyle.LABEL}
	];

	public loadMore = async(): Promise<any> => {
		if (this.acquisitionUuid) {
			this.ngOnInit();
		}

		const list: ExperimentListResponse = await ExperimentService.list({acquisitionUuid: this.acquisitionUuid});

		const experiments: any[] = list.experiments;

		for (const experiment of experiments) {
			experiment.frequency = experiment.frequency + 'KHZ';
		}

		return {
			elements: experiments,
			hasMore: list.hasMore
		};
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		this.acquisitionUuid = data.acquisitionUuid;

		App.navigator.setTitle('experiments');

		this.totalItems = await ExperimentService.count({acquisitionUuid: this.acquisitionUuid});
	}
}
