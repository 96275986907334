import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ObservationsPage} from './screens/menu/observations.page';
import {ObservationsListPage} from './screens/list/observations-list.page';
import {ObservationsCreatePage} from './screens/create/observations-create.page';
import {ObservationsEditPage} from './screens/edit/observations-edit.page';

const routes: Routes = [
	{
		path: '',
		component: ObservationsPage
	},
	{
		path: 'list',
		component: ObservationsListPage
	},
	{
		path: 'create',
		component: ObservationsCreatePage
	},
	{
		path: 'edit',
		component: ObservationsEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ObservationsRouterModule { }
