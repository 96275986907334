import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {ProgressBar} from '../../../../progress-bar';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {AtexInspectionChecklist} from '../../inspections/data/master-data/atex-inspection-checklist';
import {UUID} from '../../../../models/uuid';
import {AtexInspectionChecklistService} from '../../inspections/services/atex-inspection-checklist.service';
import {FFP} from '../../../../models/atex-inspections/ffp/ffp';

/**
 * Tools to manipulate FFP.
 *
 * Include bulk operation utils to changed data across the entire database.
 */
export class FFPTools {
	/**
	 * Tool to delete in bulk FFP entries that no longer have valid ATEX inspection data associated, such as:
	 * - FFP that are associated with inspection fields that have the response 'Not applicable'.
	 * - FFP that contain fields that are no longer present in the ATEX inspection.
	 *
	 * After finishing a list of all deleted FFP entries is exported into XLSX file.
	 *
	 * Also removes Action plans created for those FFP entries.
	 *
	 * Use carefully, this action cannot be reversed.
	 */
	public static async bulkDeleteUnusedFFP(): Promise<void> {
		const data = {
			deleteNotApplicable: false,
			deleteNonExistent: false
		};

		const layout: UnoFormField[] = [
			{
				label: 'deleteNotApplicable',
				attribute: 'deleteNotApplicable',
				type: UnoFormFieldTypes.CHECKBOX
			},
			{
				label: 'deleteNonExistent',
				attribute: 'deleteNonExistent',
				type: UnoFormFieldTypes.CHECKBOX
			}
		];

		await Modal.form(Locale.get('delete'), data, layout);

		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (!confirm || !data.deleteNotApplicable && !data.deleteNonExistent) {
			return;
		}

		let from: number = 0;
		const count: number = 300;
		let hasMore = true;

		// List of entries processed (for which action has been taken).
		const processed: any[] = [];

		// List of ffp to delete in this batch
		const toDelete: UUID[] = [];

		// Possible actions
		const actions = {
			// Failed to process
			failed: -1,
			// No action required
			none: 0,
			// Delete because field is not applicable
			deleteNotApplicable: 1,
			// Delete because field does not exist
			deleteNonExistent: 2
		};

		const loading = new ProgressBar();
		loading.show();

		// Total number of ffp entries in database
		loading.update(Locale.get('loadingData'), 0);
		const length = (await Service.fetch(ServiceList.atex.ffp.count, null, null, {from: from, count: count}, Session.session)).response.count;

		const checklists = await AtexInspectionChecklistService.get();

		while (hasMore) {
			loading.update(Locale.get('loadingData'), from / length);

			const request = await Service.fetch(ServiceList.atex.ffp.listData, null, null, {from: from, count: count}, Session.session);
			const response = request.response;

			for (let i = 0; i < response.ffp.length; i++) {
				try {
					const ffp: FFP = FFP.parse(response.ffp[i]);
					const inspection = response.ffp[i].inspection;

					// Check if inspection fields is not applicable
					if (data.deleteNotApplicable) {
						const field = inspection.data.responses[ffp.form][ffp.field];
						if (field && field.notApplicable === true) {
							toDelete.push(ffp.uuid);
							processed.push({ffp: response.ffp[i], action: actions.deleteNotApplicable});
						}
					}

					// Check if inspection field is still used
					if (data.deleteNonExistent) {
						const fields: string[] = AtexInspectionChecklist.getFormFields(checklists, inspection);
						if (fields.indexOf(ffp.field) === -1 || !inspection.data.responses[ffp.form][ffp.field]) {
							toDelete.push(ffp.uuid);
							processed.push({ffp: response.ffp[i], action: actions.deleteNonExistent});
						}
					}
				} catch (e) {
					processed.push({ffp: response.ffp[i], action: actions.failed});
				}
			}

			from += response.ffp.length;
			hasMore = response.hasMore;
		}

		// Delete entries
		while (toDelete.length > 0) {
			await Service.fetch(ServiceList.atex.ffp.deleteBatch, null, null, {uuid: toDelete.splice(0, 2000)}, Session.session);
		}

		loading.destroy();

		// Write deleted entries into XLSX file
		if (processed.length > 0) {
			const actionLabels: Map<number, string> = new Map([
				[actions.failed, 'failed'],
				[actions.none, 'none'],
				[actions.deleteNotApplicable, 'notApplicableField'],
				[actions.deleteNonExistent, 'nonExistentField']
			]);

			const output: any[][] = [[Locale.get('action'), Locale.get('name'), Locale.get('tag'), Locale.get('field'), Locale.get('description'), Locale.get('ffpUuid'), Locale.get('assetUuid'), Locale.get('inspectionUuid')]];

			for (let i = 0; i < processed.length; i++) {
				output.push([Locale.get(actionLabels.get(processed[i].action)), processed[i].ffp.asset.name, processed[i].ffp.asset.tag, processed[i].ffp.field, processed[i].ffp.description, processed[i].ffp.uuid, processed[i].ffp.asset.uuid, processed[i].ffp.inspection.uuid]);
			}

			XlsxUtils.writeFile(output, 'deleted-ffp.xlsx');
		}

		Modal.alert(Locale.get('success'), Locale.get('deleteSuccessfullyCount', {count: processed.length}));
	}
}
