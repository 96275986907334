import {Component, OnInit} from '@angular/core';
import {App} from '../../../../../app';
import {AtexInspectionStatus} from '../../../../../models/atex-inspections/inspections/atex-inspection-status';
import {Session} from '../../../../../session';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'atex-inspections-page',
	templateUrl: 'atex-inspections.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent]
})
export class AtexInspectionsPage extends ScreenComponent implements OnInit {
	public app: any = App;


	public session: any = Session;

	public permissions = [UserPermissions.ATEX_INSPECTION];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('atexInspections');
	}

	/**
	 * List of options in this menu.
	 */
	public groups: MenuCardsGroup[] = [
		{
			label: 'global',
			permissions: [],
			options: [
				{
					label: 'list',
					route: '/menu/atex/inspections/list',
					icon: 'list',
					data: {status: null},
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.inspection.count, null, null, {status: AtexInspectionStatus.ALL}, Session.session, true)).response.count;
					})()
				},
				{
					label: 'create',
					route: '/menu/atex/inspections/edit',
					icon: 'add',
					data: {createMode: true},
					permissions: [UserPermissions.ATEX_INSPECTION_CREATE],
					badge: null
				}
			]
		},
		{
			label: 'todo',
			permissions: [],
			options: [
				{
					label: 'todo',
					route: '/menu/atex/inspections/list',
					icon: 'hammer',
					data: {status: AtexInspectionStatus.TODO},
					permissions: [UserPermissions.ATEX_INSPECTION_INSPECT],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.inspection.count, null, null, {status: AtexInspectionStatus.TODO}, Session.session, true)).response.count;
					})()
				},
				{
					label: 'underSupervision',
					route: '/menu/atex/inspections/list',
					icon: 'assets/icons/material/supervisor_account/outline.svg',
					data: {status: AtexInspectionStatus.TODO_SUPERVISION},
					permissions: [UserPermissions.ATEX_INSPECTION_INSPECT_SUPERVISOR],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.inspection.count, null, null, {status: AtexInspectionStatus.TODO_SUPERVISION}, Session.session, true)).response.count;
					})()
				},
				{
					label: 'rejected',
					route: '/menu/atex/inspections/list',
					icon: 'thumbs-down',
					data: {status: AtexInspectionStatus.REJECTED},
					permissions: [UserPermissions.ATEX_INSPECTION_INSPECT],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.inspection.count, null, null, {status: AtexInspectionStatus.REJECTED}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'review',
			permissions: [],
			options: [
				{
					label: 'review',
					route: '/menu/atex/inspections/list',
					icon: 'assets/icons/material/rate_review/outline.svg',
					data: {status: AtexInspectionStatus.REVIEW},
					permissions: [UserPermissions.ATEX_INSPECTION_REVIEW],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.inspection.count, null, null, {status: AtexInspectionStatus.REVIEW}, Session.session, true)).response.count;
					})()
				},
				{
					label: 'underSupervision',
					route: '/menu/atex/inspections/list',
					icon: 'assets/icons/material/supervisor_account/outline.svg',
					data: {status: AtexInspectionStatus.REVIEW_SUPERVISION},
					permissions: [UserPermissions.ATEX_INSPECTION_REVIEW_SUPERVISOR],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.inspection.count, null, null, {status: AtexInspectionStatus.REVIEW_SUPERVISION}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'completed',
			permissions: [],
			options: [
				{
					label: 'completed',
					route: '/menu/atex/inspections/list',
					icon: 'checkbox-outline',
					data: {status: AtexInspectionStatus.COMPLETED},
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.inspection.count, null, null, {status: AtexInspectionStatus.COMPLETED}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'archived',
			permissions: [],
			options: [
				{
					label: 'archived',
					route: '/menu/atex/inspections/list',
					icon: 'archive-outline',
					data: {status: AtexInspectionStatus.ARCHIVED},
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.inspection.count, null, null, {status: AtexInspectionStatus.ARCHIVED}, Session.session, true)).response.count;
					})()
				}
			]
		}

	];
}
