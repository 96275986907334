import {Environment} from 'src/environments/environment';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';

export class AppearanceMasterSettingsLayout {
	public static layout: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'text'
		},
		{
			attribute: 'title',
			label: 'title',
			type: UnoFormFieldTypes.TEXT,
			isActive: true
		},
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'images'
		},
		{
			attribute: 'loginBackgroundImage',
			label: 'loginBackgroundImage',
			type: UnoFormFieldTypes.IMAGE_RESOURCE,
			compress: false,
			isActive: true
		},
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'theme',
			isActive: !Environment.PRODUCTION
		},
		{
			attribute: 'accentColor',
			label: 'accentColor',
			type: UnoFormFieldTypes.COLOR,
			isActive: !Environment.PRODUCTION
		}
	];
}
