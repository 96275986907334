import {cloneDeep} from 'lodash-es';
import {Component, ViewChild, OnInit, Input} from '@angular/core';
import {CalendarEventLayout} from 'src/app/modules/asset-planning/screens/calendar-event-layout';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {FileConverterService} from 'src/app/services/file-converter.service';
import {UnoFormUtils} from '../../../../../components/uno-forms/uno-form/uno-form-utils';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {DateFrequency} from '../../../../../models/date-frequency';
import {AssetService} from '../../../../asset-portfolio/services/asset.service';
import {CalendarEventSubtypes, CalendarEventTypes} from '../../../../../models/asset-planning/calendar-event-actions';
import {CalendarEvent} from '../../../../../models/asset-planning/calendar-event';
import {CalendarEventService} from '../../../../asset-planning/services/calendar-event.service';
import {RepairInspection} from '../../../../../models/repairs/inspections/repair-inspection';
import {ServiceResponse} from '../../../../../http/service-response';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {App} from '../../../../../app';
import {Locale} from '../../../../../locale/locale';
import {Modal} from '../../../../../modal';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {RepairFormLayout} from '../repairs-layout';
import {Repair} from '../../../../../models/repairs/repairs/repair';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {RepairStatus} from '../../../../../models/repairs/repairs/repair-status';
import {Loading} from '../../../../../loading';
import {FileUtils} from '../../../../../utils/file-utils';
import {RepairReport} from '../../data/repair-report';
import {RepairInspectionStatusLabel} from '../../../../../models/repairs/inspections/repair-inspection-status';
import {RepairInspectionResultLabel} from '../../../../../models/repairs/inspections/repair-inspection-result';
import {UUID} from '../../../../../models/uuid';
import {AssetBaseLayout} from '../../../../asset-portfolio/screens/asset/asset-layout';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoIconComponent} from '../../../../../components/uno/uno-icon/uno-icon.component';
import {UnoNoDataComponent} from '../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {RepairService} from '../../services/repair.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'repairs-edit-page',
	templateUrl: './repairs-edit.page.html',
	styleUrls: ['./repairs-edit.page.css'],
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoFormModule, UnoTitleComponent, UnoButtonComponent, IonicModule, UnoNoDataComponent, UnoIconComponent, TranslateModule, FormatDatePipe, PermissionsPipe]
})
export class RepairsEditPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public layout: any = RepairFormLayout;

	public assetsLayout: any = AssetBaseLayout;

	public status: any = RepairStatus;

	public userPermissions: any = UserPermissions;

	public inspectionStatusLabel: any = RepairInspectionStatusLabel;

	public inspectionResultLabel: any = RepairInspectionResultLabel;

	public session: any = Session;

	@ViewChild('baseForm', {static: false})
	public baseForm: UnoFormComponent = null;

	@ViewChild('proposalForm', {static: false})
	public proposalForm: UnoFormComponent = null;

	@ViewChild('proposalApprovalForm', {static: false})
	public proposalApprovalForm: UnoFormComponent = null;
	
	@ViewChild('jobForm', {static: false})
	public jobForm: UnoFormComponent = null;

	@ViewChild('weldingForm', {static: false})
	public weldingForm: UnoFormComponent = null;

	@ViewChild('ndtForm', {static: false})
	public ndtForm: UnoFormComponent = null;

	@ViewChild('handleFluidsForm', {static: false})
	public handleFluidsForm: UnoFormComponent = null;

	@ViewChild('blockedForm', {static: false})
	public blockedForm: UnoFormComponent = null;
	
	public permissions = [
		UserPermissions.REPAIR_CREATE,
		UserPermissions.REPAIR_EDIT,
		UserPermissions.REPAIR_DELETE,
		UserPermissions.REPAIR_ADD_PROPOSAL,
		UserPermissions.REPAIR_APPROVE_PROPOSAL,
		UserPermissions.REPAIR_APPROVE_REPAIR,
		UserPermissions.REPAIR_FINISH,
		UserPermissions.REPAIR_UNARCHIVE
	];

	/**
	 * Repair data retrieved from the API.
	 */
	@Input()
	public repair: Repair = null;

	/**
	 * Data of the asset related with this repair.
	 */
	public asset: APAsset = null;

	/**
	 * Inspections list obtained for repair.
	 */
	public inspections: RepairInspection[] = [];

	/**
	 * Repair edition history entries.
	 */
	public history: any[] = [];

	/**
	 * Calendar Events containing the repair's events
	 */
	public calendarEvents: CalendarEvent[] = [];

	/**
	 * Calendar event to create in new repair for repair inspections.
	 */
	public inspectionCalendarEvent: CalendarEvent = null;

	/**
	 * Flag to indicate if the page is in create mode.
	 *
	 * If true the page is used to create a new Repair.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.repair = null;
		this.asset = null;
		this.createMode = false;
		this.inspections = [];
		this.history = [];
		
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		this.createMode = data.createMode === true;
		App.navigator.setTitle(this.createMode ? 'create' : 'edit');

		if (this.createMode) {
			this.repair = new Repair();
			this.inspectionCalendarEvent = this.newCalendarEvent(CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION);
			this.repair.asset = data.asset;
		} else {
			if (data.uuid !== undefined) {
				await this.loadData(data.uuid);
				this.loadInspectionsList(data.uuid);
				this.loadHistory(data.uuid);
				this.loadCalendarEvents(data.uuid);
			} else {
				App.navigator.pop();
				return;
			}
		}
	}

	/**
	 * Load calendar events relative to the repair.
	 */
	public async loadCalendarEvents(repairUuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.assetPlanning.calendarEvent.listByRepair, null, null, {uuid: repairUuid}, Session.session);
		const events = request.response.calendarEvents;

		const calendarEvents: CalendarEvent[] = [];
		for (let i = 0; i < events.length; i++) {
			calendarEvents.push(CalendarEvent.parse(events[i]));
		}

		this.calendarEvents = calendarEvents;
	}

	/**
	 * Load the repair data from the API from UUID.
	 */
	public async loadData(uuid: UUID): Promise<void> {
		this.repair = await RepairService.get(uuid);

		if (this.repair.asset !== null) {
			this.loadAssetData(this.repair.asset);
		}
	}

	/**
	 * Load the asset data from the API from UUID.
	 */
	public async loadAssetData(uuid: UUID): Promise<void> {
		this.asset = await AssetService.get(uuid);
	}

	/**
	 * Load list of inspections available for the repair.
	 */
	public async loadInspectionsList(uuid: UUID): Promise<void> {
		if (Session.hasPermissions([UserPermissions.REPAIR_INSPECTIONS])) {
			const request = await Service.fetch(ServiceList.repairInspections.listForRepair, null, null, {uuid: uuid}, Session.session);
			this.inspections = request.response.inspections;
		}
	}

	/**
	 * Load repair edition history entries from database.
	 */
	public async loadHistory(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.repairs.historyListUsers, null, null, {uuid: uuid}, Session.session);
		this.history = request.response.history;
	}

	/**
	 * Open a history entry in a modal to display the changes performed on a selected entry.
	 *
	 * @param historyId - History id of the entry from history list to be displayed.
	 */
	public async openHistoryEntry(historyId: number): Promise<void> {
		const selectedEntryResponse: ServiceResponse = await Service.fetch(ServiceList.repairs.historyGet, null, null, {
			uuid: this.repair.uuid,
			historyId: historyId
		}, Session.session);
		const selectedRepair: Repair = Repair.parse(selectedEntryResponse.response.history);
		
		const highestStatus: number = selectedRepair.status;

		const buttons = [{success: false, label: 'close', color: 'primary'}];
		let formLayout = RepairFormLayout.base;

		if (highestStatus >= RepairStatus.PROPOSAL) {
			formLayout = formLayout.concat(RepairFormLayout.proposal).concat(RepairFormLayout.needWelding).concat(RepairFormLayout.handleFluids).concat(RepairFormLayout.nonDestructiveTests);
		}
		if (highestStatus >= RepairStatus.WAITING_PROPOSAL_APPROVAL) {
			formLayout = formLayout.concat(RepairFormLayout.proposalApproval);
		}
		if (highestStatus === RepairStatus.BLOCKED || highestStatus >= RepairStatus.ON_GOING && selectedRepair.blockedJustification !== '') {
			formLayout = formLayout.concat(RepairFormLayout.blocked);
		}
		if (highestStatus >= RepairStatus.ON_GOING) {
			formLayout = formLayout.concat(RepairFormLayout.job);
		}

		Modal.form(Locale.get('history'), selectedRepair, formLayout, buttons, false);
	}

	/**
	 * Export repair report as a Docx file, using a template file.
	 *
	 * This document provides details about the repair proposal and its target asset, as well as proposal company.
	 */
	public async exportProposalDOCX(): Promise<void> {
		Loading.show();

		try {
			const report = await RepairReport.generateProposalDocx(this.repair);
			FileUtils.writeFileArrayBuffer(this.repair.uuid + '_proposal.docx', report);
			Modal.alert(Locale.get('success'), Locale.get('reportGeneratedSuccessfully'));
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		}

		Loading.hide();
	}

	/**
	 * Generate a repair report as a Docx file, using a template file, send it to the file convertion server to export as a PDF report.
	 *
	 * This document provides details about the repair proposal and its target asset, as well as proposal company.
	 */
	public async exportProposalPDF(): Promise<void> {
		Loading.show();

		try {
			const doc = await RepairReport.generateProposalDocx(this.repair);

			const pdf = await FileConverterService.convertDocxToPdf(doc);

			FileUtils.writeFileArrayBuffer(this.repair.uuid + '_proposal.pdf', pdf);
			Modal.alert(Locale.get('success'), Locale.get('reportGeneratedSuccessfully'));
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		}

		Loading.hide();

	}

	/**
	 * Export complete repair report as a Docx file, using a template file.
	 *
	 * This document provides all details about the repair and its target asset, as well as proposal company.
	 */
	public async exportCompleteRepairDOCX(): Promise<void> {
		Loading.show();

		try {
			const report = await RepairReport.generateCompleteRepairDocx(this.repair);
			FileUtils.writeFileArrayBuffer(this.repair.uuid + '.docx', report);
			Modal.toast(Locale.get('reportGeneratedSuccessfully'));
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		}

		Loading.hide();
	}

	/**
	 * Generate a complete repair report as a Docx file, using a template file, send it to the file convertion server to export as a PDF report.
	 *
	 * This document provides all details about the repair and its target asset, as well as proposal company.
	 */
	public async exportCompleteRepairPDF(): Promise<void> {
		Loading.show();

		try {
			const doc = await RepairReport.generateCompleteRepairDocx(this.repair);
			const pdf = await FileConverterService.convertDocxToPdf(doc);

			FileUtils.writeFileArrayBuffer(this.repair.uuid + '.pdf', pdf);
			Modal.toast(Locale.get('reportGeneratedSuccessfully'));

		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		}

		Loading.hide();
	}

	/**
	 * Check if all the required forms (the visible ones) are filled. Automatically displays a GUI alert message on error.
	 *
	 * @returns True if all forms are filled, false otherwise.
	 */
	public checkRequiredForms(): boolean {
		const allRequiredFilled: boolean =
			this.baseForm && !this.baseForm.requiredFilled() ||
			this.proposalForm && !this.proposalForm.requiredFilled() ||
			this.proposalApprovalForm && !this.proposalApprovalForm.requiredFilled() ||
			this.repair.needsWelding && this.weldingForm && !this.weldingForm.requiredFilled() ||
			this.ndtForm && !this.ndtForm.requiredFilled() ||
			this.repair.handleFluids && this.handleFluidsForm && !this.handleFluidsForm.requiredFilled() ||
			this.jobForm && !this.jobForm.requiredFilled() ||
			this.blockedForm && !this.blockedForm.requiredFilled();

		if (allRequiredFilled) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return false;
		}

		return true;
	}

	/**
	 * Create a new inspection for this repair.
	 */
	public createInspection(): void {
		App.navigator.navigate('/menu/repairs/inspections/edit', {createMode: true, repair: this.repair.uuid});
	}

	/**
	 * It assumes just a info save, not a submit to the next status, unless explicitly submitted.
	 *
	 * @param submit - If true updates the status of the repair for the next step in the pipeline.
	 * @param stayOnPage - If true it stays on the page after update.
	 * @param overrideStatus - Override status value.
	 */
	public async update(submit: boolean = false, stayOnPage: boolean = false, overrideStatus?: number): Promise<void> {
		if (!(this.repair.status === RepairStatus.ARCHIVED && overrideStatus === RepairStatus.IMPLEMENTED) && !this.checkRequiredForms()) {
			return;
		}

		const repair = structuredClone(this.repair);
		
		if (submit) {
			if (repair.status === RepairStatus.PROPOSAL) {
				repair.status = RepairStatus.WAITING_PROPOSAL_APPROVAL;
			} else if (repair.status === RepairStatus.ON_GOING) {
				repair.status = RepairStatus.QUALITY_ASSURANCE;
			} else if (repair.status === RepairStatus.BLOCKED) {
				repair.status = RepairStatus.ON_GOING;
			} else if (repair.status === RepairStatus.IMPLEMENTED) {
				repair.status = RepairStatus.ARCHIVED;
			}
		}

		if (overrideStatus !== undefined) {
			repair.status = overrideStatus;
		}

		const request = await Service.fetch(this.createMode ? ServiceList.repairs.create : ServiceList.repairs.update, null, null, repair, Session.session);
		Modal.toast(this.createMode ? Locale.get('repairCreated') : Locale.get(submit ? 'repairSubmitted' : 'repairUpdated'));

		// @ts-ignore
		if (this.createMode && this.inspectionCalendarEvent.createEvent) {
			this.inspectionCalendarEvent.repairUuid = request.response.uuid;
			await CalendarEventService.create(this.inspectionCalendarEvent);
		}

		if (!stayOnPage) {
			App.navigator.pop();
		} else {
			this.repair.status = repair.status;
			this.loadHistory(this.repair.uuid);
		}
	}

	/**
	 * Approves or rejects a submission and assumes a transition to the next/previous status respectively.
	 *
	 * @param isApproved - If true the repair is approved if false the repair is reject.
	 */
	public async approve(isApproved: boolean): Promise<void> {
		if (!this.checkRequiredForms()) {
			return;
		}

		const repair = structuredClone(this.repair);

		if (repair.status === RepairStatus.WAITING_PROPOSAL_APPROVAL) {
			repair.status = isApproved ? RepairStatus.ON_GOING : RepairStatus.PROPOSAL;
		} else if (repair.status === RepairStatus.QUALITY_ASSURANCE) {
			repair.status = isApproved ? RepairStatus.IMPLEMENTED : RepairStatus.ON_GOING;
		}

		if (!isApproved) {
			const result = await Modal.prompt(Locale.get('confirm'), [{name: 'message', placeholder: Locale.get('messageRejection'), type: 'text'}]);

			if (result.confirm) {
				// Set rejectionMessage on repair rejection
				repair.rejectionMessage = result.data.message;

				// Repair update won't be made if no confirmation is made
				await Service.fetch(ServiceList.repairs.update, null, null, repair, Session.session);
				Modal.toast(Locale.get('repairUpdated'));
				App.navigator.pop();
			}
		} else {
			// Clear rejectionMessage on repair approval
			repair.rejectionMessage = '';

			await Service.fetch(ServiceList.repairs.update, null, null, repair, Session.session);
			Modal.toast(Locale.get('repairUpdated'));
			App.navigator.pop();
		}
	}

	/**
	 * Send the repair into blocked status, prompts the user for a reject message to explain why he is blocking the repair.
	 *
	 * This message is later displayed on the blocked screen.
	 */
	public async block(): Promise<void> {
		const result = await Modal.prompt(Locale.get('confirm'), [{name: 'message', placeholder: Locale.get('messageRejection'), type: 'text'}]);
		if (result.confirm) {
			const repair = structuredClone(this.repair);
			repair.status = RepairStatus.BLOCKED;
			repair.blockedJustification = result.data.message;

			await Service.fetch(ServiceList.repairs.update, null, null, repair, Session.session);
			Modal.toast(Locale.get('repairUpdated'));
			App.navigator.pop();
		}
	}

	/**
	 * Delete the repair from the application.
	 *
	 * This action cannot be reversed, the user is prompted to confirm the action.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.repairs.delete, null, null, {uuid: this.repair.uuid}, Session.session);
			Modal.toast(Locale.get('repairDeleted'));
			App.navigator.pop();
		}
	}

	/**
	 * Format date to be shown on the list.
	 * 
	 * @param date - The date to get in the correct format
	 */
	public getDate(date: any): string {
		return FormatDatePipe.formatDate(new Date(date));
	}

	/**
	 * Edit an existing calendar event
	 * 
	 * @param event - The calendar event to edit
	 */
	public async editEvent(event: CalendarEvent): Promise<void> {
		const calendarEvent = structuredClone(event);

		calendarEvent.duration = calendarEvent.duration ? calendarEvent.duration : new DateFrequency();
		calendarEvent.periodicity = calendarEvent.periodicity ? calendarEvent.periodicity : new DateFrequency();
		calendarEvent.triggerEventOffset = calendarEvent.triggerEventOffset ? calendarEvent.triggerEventOffset : new DateFrequency();

		const layout: UnoFormField[] = cloneDeep(CalendarEventLayout);

		// Disable calendar envent type field edition
		UnoFormUtils.getFormFieldByAttribute(layout, 'actionType').editable = false;

		// Disable calendar envent asset field edition
		UnoFormUtils.getFormFieldByAttribute(layout, 'assetUuid').isActive = false;

		await Modal.form(Locale.get('edit'), calendarEvent, layout);

		try {
			await CalendarEventService.update(calendarEvent);
			Modal.toast(Locale.get('eventEdited'), 3000, 'success');
			await this.loadCalendarEvents(this.repair.uuid);
		} catch {
			Modal.toast(Locale.get('eventError'), 3000, 'danger');
		}
	}

	/**
	 * Create a new calendar event with the information associated to the repair.
	 * 
	 * @returns Calendar event created.
	 */
	public newCalendarEvent(subType: number = CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR): CalendarEvent {
		const event = new CalendarEvent();
		event.actionType = CalendarEventTypes.REPAIR;
		event.actionSubtype = subType;
		event.date = new Date();
		event.repairUuid = this.repair.uuid;
		event.description = this.repair.description;
		event.periodicity = new DateFrequency();
		event.alarmOffset = new DateFrequency();
		event.triggerEventOffset = new DateFrequency();
		return event;
	}
	
	/**
	 * Create a new calendar event and send it to the API.
	 * 
	 * @param showModal - If false the modal to edit the event is not displayed.
	 */
	public async createEvent(showModal: boolean = true): Promise<void> {
		// Create new calendar event
		const event = this.newCalendarEvent();
		event.actionType = CalendarEventTypes.REPAIR;
		event.assetUuid = this.repair.asset;

		// Display the creation form
		if (showModal) {
			const layout: UnoFormField[] = cloneDeep(CalendarEventLayout);
			
			// Disable calendar envent type field edition
			UnoFormUtils.getFormFieldByAttribute(layout, 'actionType').editable = false;

			// Disable calendar envent asset field edition
			UnoFormUtils.getFormFieldByAttribute(layout, 'assetUuid').isActive = false;

			await Modal.form(Locale.get('create'), event, layout);

			delete event.uuid;

			if (event.triggerEventUuid) {
				event.date = null;
			}
		}

		try {
			await CalendarEventService.create(event);
			Modal.toast(Locale.get('eventCreated'), 3000, 'success');
		} catch {
			Modal.toast(Locale.get('eventError'), 3000, 'danger');
		}

		await this.loadCalendarEvents(this.repair.uuid);
	}

	/**
	 * Opens up a confirmation modal and on confirmation deletes the given event.
	 * 
	 * @param event - The event to be deleted.
	 */
	public async deleteEvent(event: CalendarEvent): Promise<void> {
		if (await Modal.confirm(Locale.get('deleteEvent'), Locale.get('confirmDeleteEvent'))) {
			await Service.fetch(ServiceList.assetPlanning.calendarEvent.delete, null, null, {uuid: event.uuid}, Session.session);
			await this.loadCalendarEvents(this.repair.uuid);
		}
	}
}
