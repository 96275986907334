import {InputOptionsMultipleLazyPageRequest, InputOptionsMultipleBatchRequest} from 'src/app/components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {ServiceResponse} from 'src/app/http/service-response';
import {SortDirection} from 'src/app/utils/sort-direction';
import {Session} from 'src/app/session';
import {AssetService} from './services/asset.service';

export class AssetPortfolioFiterBarFields {
	/**
	 * Asset field for the filter bar.
	 */
	public static assetField: UnoFilterBarOption = {
		type: UnoFilterBarOptionType.OPTIONS_LAZY,
		attribute: 'assetUuid',
		label: 'asset',
		identifierAttribute: 'uuid',
		multiple: false,
		default: null,
		fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> => {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};
    
	
			const req: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.asset.list, null, null, data, Session.session);
			return {options: req.response.assets, hasMore: req.response.hasMore, id: req.id};
		
		},
		fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> => {
			if (request.options.length > 0) {
				return {options: [await AssetService.get(request.options[0])]};
			}
			return {options: []};
		},
		getOptionText: (option: any): string => {
			return [option.name, option.tag].filter(Boolean).join(' - ');
		}
	};
}
