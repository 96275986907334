/**
 * Map of the possible places where a role is used, with their translation.
 */
export const RoleUsageLabel: Map<string, string> = new Map([
	['ap_asset', 'asset'],
	['inspection', 'inspection'],
	['inspection_project', 'inspectionProject'],
	['inspection_workflow_step', 'steps'],
	['atex_inspection', 'atexInspection'],
	['repair', 'repair'],
	['repair_inspection', 'repairInspection'],
	['ga_gap', 'gaps'],
	['calendar_event', 'planning'],
	['calendar_event_occurrence', 'event'],
	['dl50_inspection', 'dl50Inspection']
]);
