import {
	MeshBasicMaterial,
	Material,
	BufferGeometry,
	BoxGeometry, Mesh
} from 'three';
import {DigitalTwinObject} from '../digital-twin-object';
import {DigitalTwinObjectType} from '../digital-twin-object-type';

/**
 * Box can be used to mark 3D objects in space.
 *
 * The box can be resized to fit a specific object.
 */
export class Box extends DigitalTwinObject {
	/**
	 * Box geometry shared for all boxes.
	 */
	public static geometry: BufferGeometry = new BoxGeometry();

	/**
	 * Box material shared for all boxes.
	 */
	public static material: Material = new MeshBasicMaterial({
		opacity: 0.3,
		color: 0xFFFF00,
		transparent: true,
		depthTest: true,
		depthWrite: true
	});

	public constructor() {
		super (DigitalTwinObjectType.BOX);

		this.name = 'box ' + this.uuid.substr(0, 3);
		this.add(new Mesh(Box.geometry, Box.material));
	}
}

