import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoTreeLayout, UnoTreeLayoutType} from 'src/app/components/uno/uno-tree/uno-tree-layout';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {App} from '../../../../app';
import {Company} from '../../../../models/companies/company';
import {Service} from '../../../../http/service';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {Session} from '../../../../session';
import {ServiceList} from '../../../../http/service-list';
import {UnoNoDataComponent} from '../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {PermissionsPipe} from '../../../../pipes/permissions.pipe';
import {UnoTreeComponent} from '../../../../components/uno/uno-tree/uno-tree.component';

/**
 * The extension of a company adding its children sub-companies and the loader function
 */
export class HierarchicalCompany extends Company {
	/**
	 * List of company children elements.
	 */
	public children: HierarchicalCompany[] = [];

	/**
	 * Indicates if children have been loaded.
	 */
	public hasChildren: boolean = false;
}

@Component({
	selector: 'company-list-page',
	templateUrl: './company-list.page.html',
	standalone: true,
	imports: [UnoButtonComponent, UnoSearchbarComponent, IonicModule, FormsModule, UnoContentComponent, NgStyle, UnoNoDataComponent, TranslateModule, PermissionsPipe, UnoTreeComponent]
})
export class CompanyListPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public selfStatic: any = CompanyListPage;

	public permissions = [UserPermissions.COMPANY];

	/**
	 * Possible database filter to be used for ordering the companies list.
	 */
	public static sortFieldValues: any = [
		{label: 'name', attribute: 'name'},
		{label: 'createdAt', attribute: 'created_at'},
		{label: 'updatedAt', attribute: 'updated_at'}
	];

	/**
	 * Possible sort direction values.
	 */
	public static sortDirectionValues: any = [SortDirection.ASC, SortDirection.DESC];

	/**
	 * List of possible search fields
	 */
	public static searchFieldValues: any = [
		{label: 'name', attribute: 'name'}
	];

	/**
	 * Companies to be displayed on the page.
	 */
	public companies: HierarchicalCompany[] = [];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('companies');
	}

	public static filters = {
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: CompanyListPage.sortDirectionValues[1],

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: CompanyListPage.sortFieldValues[0].attribute
	};

	public static defaultFilters = structuredClone(CompanyListPage.filters);

	/**
	 * Tree item layout.
	 */
	public treeLayout: UnoTreeLayout[] = [
		{
			attribute: 'name',
			type: UnoTreeLayoutType.TITLE
		}, 
		{
			attribute: 'description',
			type: UnoTreeLayoutType.LABEL
		},
		{
			attribute: 'picture',
			type: UnoTreeLayoutType.PICTURE
		}
	];

	/**
	 * Load more data from the API to display. Data is loaded one tree branch at a time.
	 * 
	 * @param uuid - Company parent UUID whose child we want to load
	 */
	public loadData = async function(company: any = null): Promise<any> {
		const data = {
			uuid: company ? company.uuid : null,
			sortDirection: CompanyListPage.filters.sortDirection,
			sortField: CompanyListPage.filters.sortField,
			search: CompanyListPage.filters.search
		};

		const request = await Service.fetch(ServiceList.company.listChild, null, null, data, Session.session);

		return request.response.children;
	};

	public onClick = function(company: any): void {
		App.navigator.navigate('/menu/companies/edit', {uuid: company.uuid});
	};

	public resetFilters(): void {
		Object.assign(CompanyListPage.filters, CompanyListPage.defaultFilters);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(event: any): Promise<void> {
		if (event.type === undefined) {
			CompanyListPage.filters.search = event;
		}

		this.companies = await this.loadData(null);
	}


}
