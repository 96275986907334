import {
	Component,
	ElementRef,
	Input,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {RouterOutlet} from '@angular/router';
import {NgClass, NgStyle} from '@angular/common';
import {App} from '../../../../app';
import {Session} from '../../../../session';
import {MenuOption} from '../../data/menu-option';
import {UnoTooltipDirective} from '../../../../components/uno/uno-tooltip/uno-tooltip.directive';
import {UnoIconComponent} from '../../../../components/uno/uno-icon/uno-icon.component';
import {UserAvatarComponent} from '../user-avatar/user-avatar.component';
import {ModuleCheckPipe} from '../../../../pipes/module.pipe';
import {PermissionsPipe} from '../../../../pipes/permissions.pipe';
import {UnoLanguageOptions} from '../../../../components/uno-input/uno-language-options/uno-language-options.component';
@Component({
	selector: 'menu-desktop-page',
	templateUrl: './menu-desktop.component.html',
	styleUrls: ['./menu-desktop.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, UserAvatarComponent, RouterOutlet, UnoIconComponent, UnoTooltipDirective, NgStyle, TranslateModule, ModuleCheckPipe, PermissionsPipe, UnoLanguageOptions]
})
export class MenuDesktopComponent {
	@ViewChild('sidebar', {static: false})
	public sidebar: ElementRef;

	public menuOption: any = MenuOption;

	public app: any = App;

	public session: any = Session;

	/**
	 * Indicates if the menu sidebar is currently expanded.
	 */
	public expanded: boolean = Session.settings.sidebarExpanded;

	/**
	 * If the sidebar logo image is to be the small one or the large one
	 */
	public imgSmall: boolean = !Session.settings.sidebarExpanded;

	@Input()
	public pages: MenuOption[] = [];

	/**
	 * Method used to determine whether to expand or collapse the sidebar.
	 */
	public toggle(): void {
		if (this.expanded) {
			this.collapse();
		} else {
			this.expand();
		}
	}

	/**
	 * Method used to collapse the sidebar. 
	 * 
	 * The setTimeout() method is used in order to change the image from large to small in the middle of the collapsing animation.
	 */
	public collapse(): void {
		if (!this.expanded) {
			return;
		}

		this.sidebar.nativeElement.classList.add('collapsed');
		this.sidebar.nativeElement.classList.remove('expanded');

		Session.settings.sidebarExpanded = false;
		Session.updateSettings(Session.settings);

		this.expanded = false;

		setTimeout(() => {
			this.imgSmall = true;
		}, 350);
	}

	/**
	 * Method used to expand the sidebar.
	 */
	public expand(): void {
		if (this.expanded) {
			return;
		}

		this.sidebar.nativeElement.classList.add('expanded');
		this.sidebar.nativeElement.classList.remove('collapsed');

		Session.settings.sidebarExpanded = true;
		Session.updateSettings(Session.settings);

		this.expanded = true;
		this.imgSmall = false;
	}
}
