import {Component, OnInit} from '@angular/core';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Session} from '../../../../../session';
import {ActionPlanState} from '../../../../../models/atex-inspections/action-plan/action-plan-state';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'action-plan-page',
	templateUrl: './action-plan.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent]
})
export class ActionPlanPage extends ScreenComponent implements OnInit {
	public app: any = App;


	public session: any = Session;

	public permissions = [UserPermissions.ACTION_PLAN];

	/**
	 * List of options in this menu organized by sections.
	 */
	public groups: MenuCardsGroup[] = [
		{
			label: 'global',
			permissions: [],
			options: [
				{
					label: 'list',
					route: '/menu/atex/action-plan/list',
					data: {state: -1},
					icon: 'list',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, {state: -1}, Session.session)).response.count;
					})()
				}
			]
		},
		{
			label: 'create',
			permissions: [UserPermissions.ACTION_PLAN_CREATE, UserPermissions.ACTION_PLAN_SUBMIT],
			options: [
				{
					label: 'create',
					route: '/menu/atex/action-plan/edit',
					data: {createMode: true},
					icon: 'add',
					permissions: [UserPermissions.ACTION_PLAN_CREATE],
					badge: null
				},
				{
					label: 'underWork',
					route: '/menu/atex/action-plan/list',
					data: {state: ActionPlanState.TODO},
					icon: 'create',
					permissions: [UserPermissions.ACTION_PLAN_SUBMIT],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, {state: ActionPlanState.TODO}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'client',
			permissions: [UserPermissions.ACTION_PLAN_CLIENT],
			options: [
				{
					label: 'waitingClient',
					route: '/menu/atex/action-plan/list',
					data: {state: ActionPlanState.WAITING_CLIENT_VALIDATION},
					icon: 'stopwatch-outline',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, {state: ActionPlanState.WAITING_CLIENT_VALIDATION}, Session.session, true)).response.count;
					})()
				},
				{
					label: 'rejected',
					route: '/menu/atex/action-plan/list',
					data: {state: ActionPlanState.CLIENT_REJECTED},
					icon: 'thumbs-down',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, {state: ActionPlanState.CLIENT_REJECTED}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'company',
			permissions: [UserPermissions.ACTION_PLAN_COMPANY],
			options: [
				{
					label: 'approved',
					route: '/menu/atex/action-plan/list',
					data: {state: ActionPlanState.CLIENT_ACCEPTED},
					icon: 'thumbs-up',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, {state: ActionPlanState.CLIENT_ACCEPTED}, Session.session, true)).response.count;
					})()
				},

				{
					label: 'blocked',
					route: '/menu/atex/action-plan/list',
					data: {state: ActionPlanState.BLOCKED},
					icon: 'assets/icons/material/block/outline.svg',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, {state: ActionPlanState.BLOCKED}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'reinspection',
			permissions: [UserPermissions.ACTION_PLAN_REINSPECT],
			options: [
				{
					label: 'waitingReinspection',
					route: '/menu/atex/action-plan/list',
					data: {state: ActionPlanState.WAITING_REINSPECTION},
					icon: 'refresh',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, {state: ActionPlanState.WAITING_REINSPECTION}, Session.session, true)).response.count;
					})()
				},
				{
					label: 'finished',
					route: '/menu/atex/action-plan/list',
					data: {state: ActionPlanState.DONE},
					icon: 'checkbox-outline',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, {state: ActionPlanState.DONE}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'archived',
			permissions: [],
			options: [
				{
					label: 'archived',
					route: '/menu/atex/action-plan/list',
					data: {state: ActionPlanState.ARCHIVED},
					icon: 'archive-outline',
					permissions: [],
					badge: (async() => {
						return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, {state: ActionPlanState.ARCHIVED}, Session.session, true)).response.count;
					})()
				}
			]
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('actionPlan');
	}
}
