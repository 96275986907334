/**
 * Utils to manipulate FormData objects.
 */
export class FormDataUtils {
	/**
	 * Create form data from an object.
	 * 
	 * Object keys used as keys for the form.
	 *
	 * @param object - Object to extract data from.
	 * @returns Form data object with the same attributes as the object.
	 */
	public static createFromObject(object: any): FormData {
		const form = new FormData();

		for (const i in object) {
			if (object[i] instanceof Blob) {
				form.append(i, object[i], 'data');
			} else {
				form.append(i, object[i]);
			}
		}

		return form;
	}
}
