import {Component, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {App} from '../../../../../app';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {AssetSettings} from '../../../../../models/asset-portfolio/asset-settings';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {AssetSettingsService} from '../../../services/asset-settings.service';
import {AssetSettingsLayout} from './asset-settings-layout';

@Component({
	selector: 'masterdata-asset-settings-page',
	templateUrl: 'asset-settings.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})
export class AssetSettingsPage extends ScreenComponent {

	public get settingsLayout(): UnoFormField[] { return AssetSettingsLayout.layout; }
	
	@ViewChild('settingsForm', {static: false})
	public settingsForm: UnoFormComponent = null;

	public settings: AssetSettings = null;
	
	public permissions = [UserPermissions.ASSET_PORTFOLIO_SETTINGS_EDIT];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('settings');

		this.settings = await AssetSettingsService.get();
	}

	/**
	 * Update the information on server
	 * 
	 * @param stayOnPage - If true it stays on the page after update.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		await AssetSettingsService.update(this.settings);
		
		Modal.toast(Locale.get('settingsUpdated'));

		if (!stayOnPage) {
			App.navigator.pop();
		}
	}
}
