import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {ProgressBar} from 'src/app/progress-bar';
import {XlsxUtils} from 'src/app/utils/xlsx-utils';
import {UserPermissions} from '../../../models/users/user-permissions';
import {UserService} from '../services/users.service';
import {RoleService} from '../../roles/services/role.service';

export class UserExport {

	/**
	 * Export a user list with all the users and their information as an XLSX file.
	 */
	public static async exportUserXLSX(): Promise<void> {

		const progress = new ProgressBar();
		progress.show();

		try {

			// Create header
			const header: string[] = ['name', 'email', 'phoneNumber', 'permissions', 'role', 'administrator', 'active'];

			// Translate the header
			header.forEach(function(title, index) {
				header[index] = Locale.get(title);
			});

			// Add the translated headers to the matrix that will be turned into an xlsx file
			const fileData: any[][] = [header];

			// How many items to get
			const data = {
				from: 0,
				count: 2000
			};

			// Get all users
			while (true) {
				// Get all the users that match the filter
				const request = await UserService.listUsers(data, true);
				progress.update(Locale.get('loadingData'), data.from / (request.users.length + data.from));

				for (const user of request.users) {
					// The row
					const fileRow: any = {};

					// Create fileRowTemplate
					const fileRowTemplate: string[] = ['name', 'email', 'phoneNumber', 'permissions', 'role', 'administrator', 'active'];

					for (const key of fileRowTemplate) {
						fileRow[key] = user[key];	
					}

					fileRow.role = '';
					for (const roleUuid of user.roleUuids) {
						fileRow.role += (fileRow.role ? ', ' : '') + (await RoleService.get(roleUuid, true)).name;
					}

					fileRow.permissions.forEach(function(permission, index) {
						fileRow.permissions[index] = Object.keys(UserPermissions).find((key) => {
							return UserPermissions[key] === permission;
						});
					});

					fileRow.permissions = fileRow.permissions.toString();
					fileRow.admin = user.isAdmin;

					// Push the row to the matrix
					fileData.push([fileRow.name, fileRow.email, fileRow.phoneNumber, fileRow.permissions, fileRow.role, fileRow.admin, fileRow.active]);
				}

				data.from += request.users.length;

				if (!request.hasMore) {
					break;
				}
			}

			// Write the XLSX file
			XlsxUtils.writeFile(fileData, 'UserList.xlsx');
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}
}
