import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {RepairsMenuPage} from './screens/menu/repairs-menu.page';
import {RepairsListPage} from './screens/list/repairs-list.page';
import {RepairsEditPage} from './screens/edit/repairs-edit.page';
import {RepairConsumablesPage} from './screens/master-data/repair-consumables/repair-consumables.page';
import {RepairSettingsPage} from './screens/master-data/repair-settings/repair-settings.page';
import {RepairWeldingProceduresPage} from './screens/master-data/repair-welding-procedures/repair-welding-procedures.page';
import {RepairWeldingStandardsPage} from './screens/master-data/repair-welding-standard/repair-welding-standards.page';
import {RepairNdtPage} from './screens/master-data/repair-ndt/repair-ndt.page';
import {RepairWeldingTypesPage} from './screens/master-data/repair-welding-types/repair-welding-types.page';
import {RepairTemporaryTypesPage} from './screens/master-data/repair-temporary-types/repair-temporary-types.page';

const routes: Routes = [
	{
		path: '',
		component: RepairsMenuPage
	},
	{
		path: 'edit',
		component: RepairsEditPage
	},
	{
		path: 'list',
		component: RepairsListPage
	},
	{
		path: 'master-data',
		children: [
			{
				path: 'ndt',
				component: RepairNdtPage
			},
			{
				path: 'welding-types',
				component: RepairWeldingTypesPage
			},
			{
				path: 'temporary-repair-types',
				component: RepairTemporaryTypesPage
			},
			{
				path: 'welding-procedures',
				component: RepairWeldingProceduresPage
			},
			{
				path: 'welding-standards',
				component: RepairWeldingStandardsPage
			},
			{
				path: 'consumables',
				component: RepairConsumablesPage
			},
			{
				path: 'settings',
				component: RepairSettingsPage
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class RepairWorksRouterModule { }
