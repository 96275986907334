import {UUID, UUIDIdentification} from '../../uuid';
import {InspectionWorkflowStepAction} from './inspection-workflow-step-action';

/**
 * Inspection workflow steps are used to build workflows.
 *
 * A step represents a phase in the inspection workflow. Steps are connected in a workflow graph.
 */
export class InspectionWorkflowStepAdjacency extends UUIDIdentification {
	/**
	 * Step of origin.
	 */
	public originUuid: UUID = null;

	/**
	 * Destination step.
	 */
	public destinationUuid: UUID = null;

	/**
	 * Controls the check for required fields upon inspection step transition
	 */
	public bypassRequiredCheck: boolean = false;

	/**
	 * Workflow that this step adjacency belongs to.
	 */
	public workflowUuid: UUID = null;

	/**
	 * Action to be taken when the step changes.
	 */
	public action: number = InspectionWorkflowStepAction.NONE;

	/**
	 * The UUIDs of the users to be notified on step change.
	 */
	public userUuids: UUID[] = [];

	/**
	 * The UUIDs of the teams to be notified on step change.
	 */
	public teamUuids: UUID[] = [];


	/**
	 * Indicates if the gaps will be analysed when the inspection moves through the step adjacency.
	 */
	public analysesGaps: boolean = false;

	/**
	 * Parse data into workflow step adjacency object.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): InspectionWorkflowStepAdjacency {
		const adjacency = new InspectionWorkflowStepAdjacency();

		adjacency.uuid = data.uuid;
		adjacency.createdAt = new Date(data.createdAt);
		adjacency.updatedAt = new Date(data.updatedAt);

		adjacency.originUuid = data.originUuid;
		adjacency.destinationUuid = data.destinationUuid;
		adjacency.bypassRequiredCheck = data.bypassRequiredCheck === true;
		adjacency.workflowUuid = data.workflowUuid;
		adjacency.action = data.action;
		adjacency.analysesGaps = data.analysesGaps;
		adjacency.userUuids = data.userUuids || [];
		adjacency.teamUuids = data.teamUuids || [];

		return adjacency;
	}
}
