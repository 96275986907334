import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {IonicRouteStrategy} from '@ionic/angular';
import {ObjectUtils} from '../utils/object-utils';

/**
 * Strategy to be applied to recycle components for route reuse.
 */
@Injectable()
export class RouteStrategy extends IonicRouteStrategy {

	/**
	 * Routes should only be reused if no change in route and parameter is found.
	 * 
	 * @param future - New route 
	 * @param curr - Current route
	 * @returns True if the route should be reused, false otherwise.
	 */
	public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		// @ts-ignore
		if (future._routerState.url === curr._routerState.url && !ObjectUtils.equal(future.queryParams, curr.queryParams)) {
			return false;
		}

		return super.shouldReuseRoute(future, curr);
	}
}
