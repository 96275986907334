import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {UnoFormComponent} from '../../../../../../components/uno-forms/uno-form/uno-form.component';
import {AssetFormBlockService} from '../../../../services/asset-form-block.service';
import {ArrayUtils} from '../../../../../../utils/array-utils';
import {App} from '../../../../../../app';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Session} from '../../../../../../session';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {generateUUID} from '../../../../../../models/uuid';
import {APAssetFormBlockField} from '../../../../../../models/asset-portfolio/asset-form-block-field';
import {APAssetFormBlock} from '../../../../../../models/asset-portfolio/asset-form-block';
import {AssetFormBlockFieldLayout, APAssetFormBlockLayout} from '../../asset-template-layout';
import {FormSortUtils} from '../../../../../../utils/form-sort-utils';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../../../components/uno/uno-title/uno-title.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';

/**
 * Page used to edit and create form blocks. Should receive the UUID of the form block to edit or a createMode value set to true.
 */
@Component({
	selector: 'form-block-edit-page',
	templateUrl: './asset-form-block-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoTitleComponent, UnoButtonComponent, IonicModule, TranslateModule, PermissionsPipe]
})
export class AssetFormBlockEditPage extends ScreenComponent implements OnInit {
	@ViewChild('formTemplateBlockForm', {static: false})
	public formTemplateBlockForm: UnoFormComponent = null;

	@ViewChildren('formTemplateFieldForms') 
	public formTemplateFieldForms: QueryList<UnoFormComponent>;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public app: any = App;

	public formTemplateBlockLayout: any = APAssetFormBlockLayout;

	public formTemplateFieldLayout: any = AssetFormBlockFieldLayout;

	public permissions = [UserPermissions.ASSET_PORTFOLIO_FORM_BLOCK_CREATE, UserPermissions.ASSET_PORTFOLIO_FORM_BLOCK_EDIT];

	/**
	 * Form block being edited in this screen.
	 */
	public block: APAssetFormBlock = null;

	/**
	 * Flag to indicate if the page is in create mode.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.createMode = false;
		this.block = null;

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		if (data.createMode === true) {
			this.createMode = true;
			this.block = new APAssetFormBlock();
			this.block.uuid = generateUUID();

			App.navigator.setTitle('create');
		} else {
			this.block = await AssetFormBlockService.get(data.uuid);

			App.navigator.setTitle(this.block.name || 'edit');
		}
	}

	/**
	 * Update the form block in the DB.
	 * 
	 * @param stayOnPage - Flag to remain/leave on page after update.
	 * @returns a void promise
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		// Check required fields of form template block content
		if (!this.formTemplateBlockForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		// Check required fields of all form block fields content
		for (const field of this.formTemplateFieldForms.toArray()) {
			if (!field.requiredFilled()) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		}

		const block = structuredClone(this.block);

		try {
			const request = await Service.fetch(this.createMode ? ServiceList.assetPortfolio.formBlock.create : ServiceList.assetPortfolio.formBlock.update, null, null, this.createMode ? block : {block: block, deleteMissingFields: true}, Session.session);

			if (this.createMode) {
				Modal.toast(Locale.get('createdSuccessfully'));
			} else {
				Modal.toast(Locale.get('updatedSuccessfully'));
			}
			if (!stayOnPage) {
				App.navigator.pop();
				return;
			}
			
			this.block = request.response.block;
		} catch {}
	}

	/**
	 * Delete the object on database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.assetPortfolio.formBlock.delete, null, null, {uuid: this.block.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

	/**
	 * Add new form block field to the block
	 */
	public addField(): void {
		if (!(this.block.fields instanceof Array)) {
			this.block.fields = [];
		}

		const field: APAssetFormBlockField = new APAssetFormBlockField();
		field.uuid = generateUUID();
		field.formBlockUuid = this.block.uuid;

		this.block.fields.push(field);
		this.block.fields = FormSortUtils.updateIndexes(this.block.fields);
	}

	/**
	 * Move form block field back in the list of form block fields of this block.
	 * 
	 * @param field - Form template field to be moved back.
	 */
	public moveFieldUp(field: APAssetFormBlockField): void {
		const index = this.block.fields.indexOf(field);
		if (index > 0) {
			ArrayUtils.move(this.block.fields, index, index - 1);
			this.block.fields = FormSortUtils.updateIndexes(this.block.fields);
		}
	}

	/**
	 * Move form block field forward in the list of template form block fields of this block.
	 * 
	 * @param field - Form template field to be moved forward.
	 */
	public moveFieldDown(field: APAssetFormBlockField): void {
		const index = this.block.fields.indexOf(field);
		if (index < this.block.fields.length && index !== -1) {
			ArrayUtils.move(this.block.fields, index, index + 1);
			this.block.fields = FormSortUtils.updateIndexes(this.block.fields);
		}
	}

	/**
	 * Remove a form block field from the form block.
	 *
	 * @param field - Tab to be removed.
	 */
	public removeField(field: APAssetFormBlockField): void {
		const index = this.block.fields.indexOf(field);
		if (index !== -1) {
			this.block.fields.splice(index, 1);
			this.block.fields = FormSortUtils.updateIndexes(this.block.fields);
		}
	}
}
