import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {ObjectUtils} from 'src/app/utils/object-utils';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {UnoNoDataComponent} from '../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {AssetService, AssetTreeLevel} from '../../../services/asset.service';
import {AssetTreeListItemComponent} from './asset-tree-list-item/asset-tree-list-item.component';

/**
 * Asset tree level item to be rendered in the tree list.
 */
export type AssetTreeLevelItem = AssetTreeLevel & {
	// Level of the asset in the tree.
	level: number;

	// Whether the asset is expanded or not.
	expanded: boolean;

	// Whether its children are loaded or not.
	loaded: boolean;

	// Children assets of this asset.
	items: AssetTreeLevelItem[];
}

/**
 * Asset tree list component to display assets in a hierarchical tree.
 * 
 * Items can be filtered and sorted, operation performed on server side.
 */
@Component({
	selector: 'asset-tree-list-page',
	templateUrl: 'asset-tree-list.page.html',
	styleUrls: ['asset-tree-list.page.css'],
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		AssetTreeListItemComponent,
		TranslateModule,
		UnoFilterBarComponent,
		ScrollingModule
	]
})
export class AssetTreeListPage extends ScreenComponent implements OnInit {
	public selfStatic: any = AssetTreeListPage;

	public permissions = [UserPermissions.ASSET_PORTFOLIO];

	/**
	 * List of assets to render in the tree.
	 * 
	 * Includes assets from all levels in a flat list.
	 */
	public items: AssetTreeLevelItem[] = [];
	
	/**
	 * Flat list of assets to render in the tree.
	 */
	public flatItems: AssetTreeLevelItem[] = [];

	/**
	 * Last filters used to load data from the API.
	 */
	public lastFilters: any = null;

	/**
	 * Possible database filter to be used for ordering the Asset Tree list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'a.name',
			options: [
				{label: 'name', value: 'a.name'},
				{label: 'tag', value: 'a.tag'},
				{label: 'createdAt', value: 'a.created_at'},
				{label: 'updatedAt', value: 'a.updated_at'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['ap_asset.name', 'ap_asset.tag'],
			multiple: true,
			options: [
				{label: 'uuid', value: 'ap_asset.id'},
				{label: 'name', value: 'ap_asset.name'},
				{label: 'tag', value: 'ap_asset.tag'},
				{label: 'description', value: 'ap_asset.description'},
				{label: 'nfc', value: 'ap_asset.nfc'},
				{label: 'qr', value: 'ap_asset.qr'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Search fields to be considered to filter list entries by their content.
		 */
		searchFields: [],

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: ''
	}, AssetTreeListPage.filterOptions);

	public static defaultFilters = structuredClone(AssetTreeListPage.filters);

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('treeView');

		await this.loadData();
	}

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		await this.loadData();
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		AssetTreeListPage.filters.search = event;
		await this.loadData();
	}

	/**
	 * Prepare the list of items to be rendered in the tree.
	 */
	public flatTree(): void {
		const flat = [];

		function addItem(item: AssetTreeLevelItem): void {
			flat.push(item);
			if (item.expanded) {
				for (const child of item.items) {
					addItem(child);
				}
			}
		}

		for (const item of this.items) {
			addItem(item);
		}

		this.flatItems = flat;
	}

	/**
	 * Toggle the expansion of an asset in the tree.
	 */
	public async toggleExpansion(item: AssetTreeLevelItem): Promise<void> {
		item.expanded = !item.expanded;

		if (item.expanded && !item.loaded) {
			await this.loadData(item);
		}

		this.flatTree();
	}

	/**
	 * Load a level of the asset tree.
	 * 
	 * @param parentItem - Parent item to load children from.
	 * @returns Items loaded from the API.
	 */
	public async loadData(parentItem: AssetTreeLevelItem = null): Promise<void> {
		if (!parentItem && this.lastFilters && ObjectUtils.equal(this.lastFilters, AssetTreeListPage.filters)) {
			return;
		}

		this.lastFilters = structuredClone(AssetTreeListPage.filters);

		const items = await AssetService.getTreeLevel(parentItem ? parentItem.uuid : null, AssetTreeListPage.filters);

		const treeItems = items.map((item: AssetTreeLevel) => {
			return {
				...item,
				level: parentItem ? parentItem.level + 1 : null,
				expanded: false,
				loaded: false,
				items: []
			};
		});

		if (parentItem) {
			parentItem.loaded = true;
			parentItem.items = treeItems;
		} else {
			this.items = treeItems;
			this.flatTree();
		}
	}
}
