import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DigitalTwinSceneListPage} from './screens/scenes/list/digital-twin-scene-list.page';
import {DigitalTwinEditorPage} from './screens/editor/digital-twin-editor.page';
import {DigitalTwinSceneEditPage} from './screens/scenes/edit/digital-twin-scene-edit.page';

const routes: Routes = [
	{
		path: '',
		component: DigitalTwinSceneListPage
	},
	{
		path: 'editor',
		component: DigitalTwinEditorPage
	},
	{
		path: 'scene/edit',
		component: DigitalTwinSceneEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DigitalTwinRouterModule { }
