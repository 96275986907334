/**
 * The name utils contains methods to convert attribute names between formats.
 */
export class NameUtils {
	/**
	 * Convert PascalCase to snake_case
	 */
	public static pascalToSnake(name: string): string {
		return name.replace(/\.?([A-Z])/g, function(g) {return '_' + g[0].toLowerCase();}).replace(/^_/, '');
	}

	/**
	 * Convert camelCase to dash-case
	 */
	public static camelToDash(name: string): string {
		return name.replace(/([A-Z])/g, function(g) {return '-' + g.toLowerCase();});
	}

	/**
	 * Convert camelCase to snake_case
	 */
	public static camelToSnake(name: string): string {
		return name.replace(/([A-Z])/g, function(g) {return '_' + g.toLowerCase();});
	}

	/**
	 * Convert camelCase to Legible Case
	 */
	public static camelToLegible(name: string): string {
		return name.charAt(0).toUpperCase() + name.replace(/([A-Z])/g, function(g) {return ' ' + g;}).slice(1);
	}

	/**
	 * Convert PascalCase to camelCase
	 */
	public static pascalToCamel(name: string): string {
		return name.charAt(0).toLowerCase() + name.slice(1);
	}

	/**
	 * Convert camelCase to PascalCase
	 */
	public static camelToPascal(name: string): string {
		return name.charAt(0).toUpperCase() + name.slice(1);
	}

	/**
	 * Convert SCREAMING_CASE to Legible Case
	 */
	public static screamingToLegible(name: string): string {
		const words = name.split('_');

		for (let i = 0; i < words.length; i++) {
			words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
		}

		return words.join(' ');
	}
}

