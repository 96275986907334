import {Session} from 'src/app/session';
import {UUID} from 'src/app/models/uuid';
import {DL50RegulatoryStandard} from '../../../models/dl50/masterdata/dl50-regulatory-standard';
import {DL50Question} from '../../../models/dl50/masterdata/dl50-question';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';

// Parameters to apply on the DL50 regulatory standards count API request.
export type DL50RegulatoryStandardsCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
};

// Parameters to apply on the DL50 regulatory standards list API request.
export type DL50RegulatoryStandardsListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
};

// DL50 regulatory standards list API request format.
export type DL50RegulatoryStandardsListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched regulatory standards
	standards: DL50Question[],
	// Id of the request
	id: number
};

export class DL50RegulatoryStandardService {
	/**
	 * Create a regulatory standard with received data.
	 *
	 * @param params - The params to apply on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The UUID of the created regulatory standard.
	 */
	public static async create(data: DL50RegulatoryStandard, hideLoading: boolean = false, displayError: boolean = true): Promise<UUID> {
		return (await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.create, null, null, data, Session.session, hideLoading, displayError)).response.uuid;
	}

	/**
	 * Update a regulatory standard with received data.
	 *
	 * @param params - The params to apply on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async update(params: DL50RegulatoryStandard, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.update, null, null, params, Session.session, hideLoading, displayError);
	}

	/**
	 * Count regulatory standards matching the params.
	 *
	 * @param params - The params to apply on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The number of regulatory standards in the database that match the params.
	 */
	public static async count(params: DL50RegulatoryStandardsCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		return (await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.count, null, null, params, Session.session, hideLoading, displayError)).response.count;
	}

	/**
	 * List regulatory standards matching the params.
	 *
	 * @param params - The params to apply on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The regulatory standards in the database that match the params.
	 */
	public static async list(params: DL50RegulatoryStandardsListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<DL50RegulatoryStandardsListResponse> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			standards: request.response.standards.map((q: any) => { return DL50RegulatoryStandard.parse(q); }),
			id: request.id
		};
	}

	/**
	 * Get regulatory standards in batch by their UUIDs.
	 *
	 * @param uuids - The uuids of the regulatory standards to fetch.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Array of Regulatory Standards that match the uuids.
	 */
	public static async getBatch(uuids: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<DL50RegulatoryStandard[]> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.getBatch, null, null, {uuids: uuids}, Session.session, hideLoading, displayError);

		return request.response.standards.map((s: any) => {return DL50RegulatoryStandard.parse(s);});
	}

	/**
	 * Delete a regulatory standard by its UUID.
	 *
	 * @param uuid - UUID of the regulatory standard to delete.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async delete(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.delete, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
	}
}
