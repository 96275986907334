import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {GapEditPage} from './screens/edit/gap-edit.page';
import {GapListPage} from './screens/list/gap-list.page';

const routes: Routes = [
	{
		path: 'edit',
		component: GapEditPage
	},
	{
		path: 'list',
		component: GapListPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GapAnalysisGapsRouterModule { }
