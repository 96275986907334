import {Pipe, PipeTransform} from '@angular/core';
import {UnoFormField} from '../uno-form-field';

/**
 * Pipe to represent the label of a form field.
 */
@Pipe({
	name: 'label',
	standalone: true,
	pure: true
})
export class UnoFormLabelPipe implements PipeTransform {
	public transform(row: UnoFormField, obj: any): string {
		if (row.label === undefined) {
			return row.attribute;
		}

		if (row.label instanceof Function) {
			return row.label(obj, row);
		}

		return row.label;
	}
}
