import {
	Component,
	ElementRef,
	Input, OnChanges, OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {NgStyle, NgClass} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';
import {UnoColorMap} from '../uno-color/uno-color';

/**
 * Possible styles for the text.
 */
export const UnoTextSize = {
	REGULAR: 'regular',
	SMALL: 'small'
};

/**
 * Text alignment controls how the text is aligned relative to its container.
 */
export const UnoTextAlignment = {
	CENTER: 'center',
	LEFT: 'left',
	RIGHT: 'right',
	TOP: 'top',
	BOTTOM: 'bottom'
};

/**
 * Text overflow can be used to control how text should behave when drawn outside the div.
 */
export const UnoTextOverflow = {
	CLIP: 'clip',
	ELLIPSIS: 'ellipsis',
	SHOW: 'show'
};

@Component({
	selector: 'uno-text',
	templateUrl: './uno-text.component.html',
	styleUrls: ['./uno-text.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, NgClass]
})
export class UnoTextComponent implements OnChanges, OnInit {
	@ViewChild('span', {static: true})
	public span: ElementRef<HTMLSpanElement>;

	/**
	 * Style to apply to the text div.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Indicates if the text element should allow for multiline text.
	 */
	@Input()
	public multiline: boolean = false;

	/**
	 * How to handle text overflow.
	 */
	@Input()
	public overflow: string = UnoTextOverflow.ELLIPSIS;

	/**
	 * Set the text horizontal alignment.
	 */
	@Input()
	public alignment: string = UnoTextAlignment.LEFT;

	/**
	 * Set text vertical alignment.
	 */
	@Input()
	public verticalAlignment: string = UnoTextAlignment.TOP;

	/**
	 * Color of the text to be displayed.
	 */
	@Input()
	public color: string = null;

	/**
	 * Size of the font to present.
	 */
	@Input()
	public size: string = UnoTextSize.REGULAR;

	/**
	 * Style to apply to the text div.
	 */
	public style: CssNgStyle = {
		'pointer-events': 'none',
		display: 'flex',
		width: '100%',
		height: '100%'
	};

	/**
	 * Style to apply to the text div.
	 */
	public spanStyle: CssNgStyle = {overflow: 'hidden'};

	public ngOnChanges(changes: SimpleChanges): void {
		this.updateStyle();
	}

	public ngOnInit(): void {
		this.updateStyle();
	}

	public updateStyle(): void {
		if (this.color) {
			this.style.color = UnoColorMap.has(this.color) ? UnoColorMap.get(this.color) : this.color;
		} else {
			this.style.color = null;
		}

		// Multiline
		if (this.multiline) {
			this.spanStyle.whiteSpace = 'normal';
			this.spanStyle.wordBreak = 'break-word';

			if (this.overflow === UnoTextOverflow.CLIP) {
				this.style.overflow = 'hidden';
			} else if (this.overflow === UnoTextOverflow.ELLIPSIS) {
				this.spanStyle.textOverflow = 'ellipsis';
				this.spanStyle.display = 'block';
				this.spanStyle.width = '100%';
				this.spanStyle.height = '100%';
			}
			// Single line
		} else {
			this.spanStyle.whiteSpace = 'pre';
			this.spanStyle.wordBreak = 'normal';

			// Overflow
			if (this.overflow === UnoTextOverflow.ELLIPSIS) {
				this.spanStyle.whiteSpace = 'nowrap';
				this.spanStyle.textOverflow = 'ellipsis';
			} else if (this.overflow === UnoTextOverflow.CLIP) {
				this.spanStyle.whiteSpace = 'pre';
				this.spanStyle.textOverflow = 'clip';
			} else if (this.overflow === UnoTextOverflow.SHOW) {
				this.spanStyle.whiteSpace = 'pre';
				this.spanStyle.textOverflow = 'unset';
			}
		}


		if (this.alignment === UnoTextAlignment.CENTER) {
			this.style.justifyContent = 'center';
			this.style.textAlign = 'center';
		} else if (this.alignment === UnoTextAlignment.LEFT) {
			this.style.justifyContent = 'flex-start';
			this.style.textAlign = 'left';
		} else if (this.alignment === UnoTextAlignment.RIGHT) {
			this.style.justifyContent = 'flex-end';
			this.style.textAlign = 'right';
		}

		// Update style for vertical alignment.
		if (this.verticalAlignment === UnoTextAlignment.CENTER) {
			this.style.alignItems = 'center';
		} else if (this.verticalAlignment === UnoTextAlignment.TOP) {
			this.style.alignItems = 'flex-start';
		} else if (this.verticalAlignment === UnoTextAlignment.BOTTOM) {
			this.style.alignItems = 'flex-end';
		}

		Object.assign(this.style, this.ngStyle);
	}

	/**
	 * Get size of the text inside this component in px
	 * 
	 * @returns The size of the text in px.
	 */
	public measure(): {x: number, y: number} {
		const span = this.span.nativeElement;
		return {x: span.offsetWidth, y: span.offsetHeight};
	}

}
