import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {CompanyListPage} from './screens/list/company-list.page';
import {CompanyEditPage} from './screens/edit/company-edit.page';

const routes: Routes = [
	{
		path: '',
		component: CompanyListPage
	},
	{
		path: 'edit',
		component: CompanyEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CompaniesRouterModule { }
