import {Component, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormComponent} from '../../../../components/uno-forms/uno-form/uno-form.component';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {App} from '../../../../app';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormModule} from '../../../../components/uno-forms/uno-form.module';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {Session} from '../../../../session';
import {MasterSettingsPreferences} from '../../../../models/master-settings/master-settings-preferences';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {PreferencesMasterSettingsLayout} from './preferences-master-settings-layout';

@Component({
	selector: 'preferences-settings-edit-page',
	templateUrl: './preferences-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})
export class MasterSettingsPreferencesEditPage extends ScreenComponent {

	public get preferencesLayout(): UnoFormField[] { return PreferencesMasterSettingsLayout.layout; }
	
	@ViewChild('preferencesForm', {static: false})
	public preferencesForm: UnoFormComponent = null;

	/**
	 * Application preferences settings
	 */
	public preferences: MasterSettingsPreferences = null;
	
	public permissions = [UserPermissions.MASTER_SETTINGS_EDIT];

	public ngOnInit(): void {
		super.ngOnInit();

		this.preferences = null;

		this.loadSettings();

		App.navigator.setTitle('preferences');
	}

	public loadSettings(): void {
		Service.call(ServiceList.masterSettings.preferences.get, null, null, {}, null, (response, xhr) => {
			this.preferences = MasterSettingsPreferences.parse(response.preferences);
		});
	}

	/**
	 * Update the information on server
	 * 
	 * @param stayOnPage - If true it stays on the page after update.
	 */
	public update(stayOnPage: boolean = false): void {
		Service.call(ServiceList.masterSettings.preferences.update, null, null, this.preferences, Session.session, (response, xhr) => {
			Modal.toast(Locale.get('settingsUpdated'));

			if (!stayOnPage) {
				App.navigator.pop();
			}
		});
	}
}
