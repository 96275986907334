/**
 * Possible formats used to generate templates.
 */
export const ReportTemplateFormat = {
	PDF: 0,
	DOCX: 1
};

export const ReportTemplateFormatLabel: Map<number, string> = new Map([
	[ReportTemplateFormat.PDF, 'pdf'],
	[ReportTemplateFormat.DOCX, 'docx']
]);

