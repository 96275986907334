import {Resource} from '../resource';
import {UUID, UUIDIdentification} from '../uuid';

/**
 * DL50 assessment gap data format.
 * 
 * The inspection responses can have assessments which can be related to gaps (from gaps list of each question on master data)
 */
export class DL50InspectionQuestionResponseGap extends UUIDIdentification {
	/**
	 * Question UUID for this gap.
	 */
	public questionUuid: UUID = null;

	/**
	 * The gap UUID related that this response belongs to.
	 */
	public gapUuid: UUID = null;
	
	/**
	 * The photos related to the gap selection on the assessment of the question.
	 */
	public photos: Resource[] = [];
	
	/**
	 * The comments related to the gap selection on the assessment of the question.
	 */
	public comments: string = '';

	/**
	 * If true, this question response gap has been archived from the Gap Analysis
	 */
	public archived: boolean = false;

	/**
	 * Parse data into Inspection object.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): DL50InspectionQuestionResponseGap {
		const gap = new DL50InspectionQuestionResponseGap();

		gap.uuid = data.uuid;
		gap.createdAt = new Date(data.createdAt);
		gap.updatedAt = new Date(data.updatedAt);

		gap.questionUuid = data.questionUuid;
		gap.gapUuid = data.gapUuid;
		gap.photos = Resource.parseArray(data.photos);
		gap.comments = data.comments;
		gap.archived = data.archived === true;

		return gap;
	}
}
