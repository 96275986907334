import {UUID} from 'src/app/models/uuid';

/**
 * Object that represents a temporary repair type.
 */
export class RepairTemporaryType {
	/**
	 * UUID of the temporary repair type.
	 */
	public uuid: UUID = null;

	/**
	 * Label shown to the user.
	 */
	public label: string = null;

	/**
	 * Parse object data into a RepairTemporaryType object.
	 *
	 * @param data - Object data to parse.
	 * @returns RepairTemporaryType object instance built from the received data.
	 */
	public static parse(data: any): RepairTemporaryType {
		const type: RepairTemporaryType = new RepairTemporaryType();

		type.uuid = data.uuid;
		type.label = data.label;

		return type;
	}
}
