import {Pipe, PipeTransform} from '@angular/core';
import {UnoFormUtils} from '../uno-form-utils';
import {UnoFormField} from '../uno-form-field';

/**
 * Checks if a field is empty.
 */
@Pipe({
	name: 'fieldEmpty',
	standalone: true,
	pure: false
})
export class UnoFormFieldEmptyPipe implements PipeTransform {
	public transform(row: UnoFormField, object: any): boolean {
		return UnoFormUtils.fieldEmpty(row, object);
	}
}
