import * as QRCode from 'qrcode';
import {Modal} from '../../../modal';
import {Locale} from '../../../locale/locale';
import {Environment} from '../../../../environments/environment';
import {FileUtils} from '../../../utils/file-utils';
import {Base64Utils} from '../../../utils/binary/base64-utils';
import {ArraybufferUtils} from '../../../utils/binary/arraybuffer-utils';

/**
 * Utils to generate QR codes.
 */
export class QRGenerator {
	/**
	 * Generate a QR marker encoded as an ArrayBuffer image.
	 */
	public static generateArrayBuffer(data: string, format: string = 'png', resolution: number = 512): Promise<ArrayBuffer> {
		const options = {
			errorCorrectionLevel: 'L',
			type: format,
			width: resolution,
			margin: 2,
			scale: 1.0
		};

		return new Promise((resolve, reject) => {
			QRCode.toDataURL(data, options, function(err, url): void {
				if (err) {
					reject(err);
					return;
				}

				const base64 = Base64Utils.removeHeader(url);
				const arraybuffer = ArraybufferUtils.fromBase64(base64);
				resolve(arraybuffer);
			});
		});

	}

	/**
	 * Generate a QR code file with provided data.
	 *
	 * When generating QR codes for objects in the platform, prefer to encoded the UUID value directly.
	 */
	public static generateFile(data: string, format: string = 'png', resolution: number = 512): void {
		const options = {
			errorCorrectionLevel: 'L',
			type: format,
			width: resolution,
			margin: 2,
			scale: 1.0
		};

		QRCode.toDataURL(data, options, function(err, url): void {
			if (err) {
				if (!Environment.PRODUCTION) {
					console.error('EQS: Failed to generate QR code.', data, err);
				}
				Modal.alert(Locale.get('error'), Locale.get('errorGeneratingQR'));
				return;
			}

			FileUtils.writeFileBase64(data + '.png', Base64Utils.removeHeader(url));
		});
	}
}
