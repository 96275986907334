import {SortDirection} from 'src/app/utils/sort-direction';
import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {RBACLayout} from 'src/app/modules/roles/screens/rbac-layout';
import {EnumUtils} from 'src/app/utils/enum-utils';
import {InspectionProjectDisplayMode, InspectionProjectDisplayModeLabel} from 'src/app/models/inspections/project/inspection-project-display-mode';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {InspectionReport} from '../../data/inspection/inspection-report';
import {ReportTemplateFormatLabel, ReportTemplateFormat} from '../../../../utils/report-template-format';
import {InspectionWorkflow} from '../../../../models/inspections/workflow/inspection-workflow';
import {InspectionWorkflowService} from '../../services/inspection-workflow.service';
import {InspectionProjectFlowMode, InspectionProjectFlowModeLabel} from '../../../../models/inspections/project/inspection-project-flow-mode';
import {InspectionProjectRequiredAssetFields} from './edit/inspection-project-edit.page';

export const InspectionProjectLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'showInMenu',
		label: 'showInMenu',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		required: false,
		attribute: 'requireAsset',
		label: 'requireAsset',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		required: false,
		attribute: 'requiredAssetFields',
		label: 'requiredAssetFields',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		multiple: true,
		options: [
			{label: 'name', value: InspectionProjectRequiredAssetFields.NAME},
			{label: 'tag', value: InspectionProjectRequiredAssetFields.TAG},
			{label: 'nfc', value: InspectionProjectRequiredAssetFields.NFC},
			{label: 'qr', value: InspectionProjectRequiredAssetFields.QR},
			{label: 'position', value: InspectionProjectRequiredAssetFields.POSITION}
		]
	},
	{
		required: false,
		attribute: 'teamUuid',
		label: 'team',
		type: UnoFormFieldTypes.TEAM_SELECTOR,
		showClear: true
	},
	{
		required: true,
		attribute: 'workflowUuid',
		label: 'workflow',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[inspection_workflow].[name]',
				sortDirection: SortDirection.ASC
			};

			const req = await InspectionWorkflowService.list(data);
			return {options: req.inspectionWorkflows, hasMore: req.hasMore, id: req.id};
		
		},
		fetchOptionsBatch: async function(request): Promise<{options: any[]}> {
			const data = {workflows: request.options};

			const req = await Service.fetch(ServiceList.inspection.workflow.listBatch, null, null, data, Session.session);
			return {options: req.response.workflows};
		},
		getOptionText: function(option: InspectionWorkflow) {
			return option.name;
		}
	},
	{
		required: false,
		attribute: 'flowMode',
		label: 'flowMode',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		options: EnumUtils.values(InspectionProjectFlowMode).map((value: InspectionProjectFlowMode) => {
			return {
				label: InspectionProjectFlowModeLabel.get(value),
				value: value
			};
		})
	},
	{
		required: false,
		attribute: 'displayMode',
		label: 'displayMode',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		options: EnumUtils.values(InspectionProjectDisplayMode).map((value: InspectionProjectDisplayMode) => {
			return {
				label: InspectionProjectDisplayModeLabel.get(value),
				value: value
			};
		})
	},
	{
		required: false,
		attribute: 'generatesGaps',
		label: 'generatesGaps',
		type: UnoFormFieldTypes.CHECKBOX
	},
	...RBACLayout,
	{
		required: false,
		attribute: 'allowExportReportFromList',
		label: 'allowExportReportFromList',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		required: false,
		attribute: 'reports',
		label: 'reports',
		type: UnoFormFieldTypes.REPETITIVE_FORM,
		fields: [
			{
				required: false,
				attribute: 'name',
				label: 'name',
				type: UnoFormFieldTypes.TEXT
			},
			{
				required: false,
				attribute: 'description',
				label: 'description',
				type: UnoFormFieldTypes.TEXT_MULTILINE
			},
			{
				required: false,
				attribute: 'isDefault',
				label: 'default',
				type: UnoFormFieldTypes.CHECKBOX
			},
			{
				required: true,
				attribute: 'formats',
				label: 'formats',
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
				sort: false,
				options: Object.values(ReportTemplateFormat).map((value: number) => {
					return {
						label: ReportTemplateFormatLabel.get(value),
						value: value
					};
				})
			},
			{
				required: true,
				attribute: 'template',
				label: 'template',
				sampleData: InspectionReport.defaultReportURL,
				type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
				filter: '.docx'
			}
		]
	}
];
