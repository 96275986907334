import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {IonicSelectableComponent} from 'ionic-selectable';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {EventManager} from 'src/app/utils/event-manager';

/**
 * Input option page request.
 */
export class InputOptionsMultipleLazyPageRequest {
	/**
	 * Index offset of the items to request.
	 */
	public from: number = 0;

	/**
	 * Number of elements to fetch.
	 */
	public count: number = 0;

	/**
	 * Textual search filter.
	 */
	public search: string = '';
}

/**
 * Object to carry batch request for multiple option selector.
 *
 * Used to obtain the data of the pre-selected objects.
 */
export class InputOptionsMultipleBatchRequest {
	/**
	 * Options to be fetched from the API.
	 */
	public options: any[];
}

/**
 * Form input options box used to lazy load data from the API.
 *
 * Receives function to define the lazyLoad / search / display etc.
 */
@Component({
	selector: 'uno-options-lazy',
	templateUrl: './uno-options-lazy.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./uno-options-lazy.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoOptionsLazyComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [
		IonicModule,
		IonicSelectableComponent,
		FormsModule,
		NgStyle,
		TranslateModule
	]
})
export class UnoOptionsLazyComponent implements ControlValueAccessor, OnChanges {
	/**
	 * Object Attribute used to identify each individual entry.
	 */
	@Input()
	public identifierAttribute: string = 'uuid';

	/**
	 * Indicate if the user can select multiple assets.
	 *
	 * When selecting multiple, the value stored is as an array.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set, clear button will be shown on component to clear its value.
	 */
	@Input()
	public showClear: boolean = false;

	/**
	 * If set, clear button will be shown on modal to clear its value.
	 */
	@Input()
	public showClearButton: boolean = true;

	/**
	 * If set, the options will be automatically translated
	 */
	@Input()
	public translate: boolean = true;

	/**
	 * A placeholder to set as option when no option is selected
	 */
	@Input()
	public placeholder: string = 'empty';

	/**
	 * Method used to fetch options.
	 *
	 * The onFinish method should be called when the options are received, and the onError() should be called when something goes wrong.
	 *
	 * An optional object may also be passed to be used to filter fetch requests.
	 */
	@Input()
	public fetchOptionsLazy: (request: InputOptionsMultipleLazyPageRequest, params?: any)=> Promise<{options: any[], hasMore: boolean, id: number}> = null;

	/**
	 * Method used to get a set of options from their identifier value.
	 *
	 * An optional object may also be passed to be used to filter fetch requests.
	 */
	@Input()
	public fetchOptionsBatch: (request: {options: any[]}, params?: any)=> Promise<{options: any[]}> = null;

	/**
	 * Function that defines the text to be displayed for each option.
	 *
	 * Receives the option object obtained from the API.
	 */
	@Input()
	public getOptionText: (option: any)=> string = () => { return 'n/d'; };

	/**
	 * This object is intended to be passed to the fetchOptions method to filter data.
	 *
	 * Should contain the params passed to the API call in fetchOptions and fetchOptions batch
	 */
	@Input()
	public params: any = null;

	/**
	 * Callback method to be called when the options list is opened.
	 */
	@Input()
	public onOpen: ()=> void = null;

	/**
	 * Callback method to be called when the options list is closed.
	 */
	@Input()
	public onClose: ()=> void = null;

	/**
	 * Callback method to be called when any option is selected or unselected from the open list.
	 *
	 * This method is called even if the value selected does not change.
	 */
	@Input()
	public onSelect: (item: any, isSelected: boolean)=> void = null;

	/**
	 * Callback method to be called when the filters change.
	 */
	@Output()
	public valueChanged = new EventEmitter<any>();

	/**
	 * Event manager to handle search bar clear
	 */
	private event: EventManager = null;

	/**
	 * List of values stored in this component.
	 */
	@Input()
	public value: any = [];

	/**
	 * Indicates if the component is visible or not.
	 *
	 * Used to keep track of the component state and refresh the size of the map
	 */
	public visible: boolean = false;

	/**
	 * Options that are selected on the object and are not yet available from the API.
	 */
	public baseOptions: any[] = null;

	/**
	 * Options available based on the search value inserted.
	 */
	public options: any[] = [];

	/**
	 * Search value inserted.
	 */
	public search: string = '';

	/**
	 * Number of elements to fetch on each request when using lazy loading.
	 */
	public batchSize: number = 20;

	/**
	 * Number of element already loaded from the API, used to control lazy loading.
	 */
	public count: number = 0;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.value) {
			this.writeValue(this.value);
		}
	}

	/**
	 * Load more data from the API to display. Only loads a chunk of data at a time.
	 *
	 * @param component - Component used to control the data flow.
	 * @param reset - If set true it's assumed to be a search or first request, the lazy loader counter is reset and data is cleared.
	 */
	public async loadData(component?: IonicSelectableComponent, reset: boolean = false): Promise<void> {
		try {
			const request: InputOptionsMultipleLazyPageRequest = {
				from: reset ? 0 : this.count,
				count: this.batchSize,
				search: this.search
			};

			const result = await this.fetchOptionsLazy(request, this.params);
			const options = result.options;

			if (!(result.options instanceof Array)) {
				throw new Error('fetchOptionsLazy() options result must be an array.');
			}

			if (reset) {
				if (component !== undefined) {
					component.enableInfiniteScroll();
				}

				this.options = this.baseOptions?.length > 0 ? this.baseOptions.concat([]) : [];
				this.count = 0;
			}
		
			// Add the new options to the list
			for (let i = 0; i < options.length; i++) {
				// Check if the option is already in the base list
				const optionExists = this.baseOptions?.length > 0 && this.baseOptions.find((a) => { return a[this.identifierAttribute] === options[i][this.identifierAttribute]; }) !== undefined;
				if (!optionExists) {
					options[i]._display = this.getOptionText(options[i]);
					this.options.push(options[i]);
				}
			}

			this.count += options.length;

			if (component) {
				if (!result.hasMore) {
					component.disableInfiniteScroll();
				}
				if (reset) {
					component.endSearch();
				}
				component.endInfiniteScroll();
			}
			
		} catch (e) {
			if (component) {
				component.endInfiniteScroll();
			}
		}
	
	}

	/**
	 * Load base options from the API server, these options are the ones selected on the object.
	 *
	 * They need to be fetched to show on the list (their name is not known, if their info is not retrieved).
	 */
	public async loadBaseOptions(): Promise<void> {
		// If the value is empty, there is no need to fetch anything.
		if (!this.value || this.value.length === 0) {
			this.baseOptions = [];
			this.options = [];
			return;
		}

		const request = {options: this.multiple ? this.value : [this.value]};
		const result = await this.fetchOptionsBatch(request, this.params);

		if (!(result.options instanceof Array)) {
			throw new Error('fetchOptionsBatch() options result must be an array.');
		}

		for (let i = 0; i < result.options.length; i++) {
			result.options[i]._display = this.getOptionText(result.options[i]);
		}

		this.baseOptions = result.options;
		this.options = this.baseOptions.concat([]);

	}

	/**
	 * The callback method called internally to the event onOpen of the ion-selectable input component.
	 *
	 * @param event - The content associated to the event that triggered this call.
	 */
	public onOpenEvent(event: { component: IonicSelectableComponent }): void {
		const clearSearch = (): void => {
			this.search = '';
			this.loadData(undefined, true);
		};

		this.event = new EventManager();
		this.event.add(document.querySelector('ion-searchbar'), 'ionClear', clearSearch);
		this.event.create();

		if (this.onOpen) {
			this.onOpen();
		}
	}

	/**
	 * The callback method called internally to the event onClose of the ion-selectable input component.
	 *
	 * @param event - The content associated to the event that triggered this call.
	 */
	public onCloseEvent(event: { component: IonicSelectableComponent }): void {
		this.event.destroy();

		if (this.onClose) {
			this.onClose();
		}
	}

	/**
	 * The callback method called internally to the event onSelect of the ion-selectable input component.
	 *
	 * @param event - The content associated to the event that triggered this call.
	 */
	public onSelectEvent(event: { component: IonicSelectableComponent, item: any, isSelected: boolean }): void {
		if (this.onSelect) {
			this.onSelect(event.item, event.isSelected);
		}
	}

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {
		this.valueChanged.emit(value);
	};

	/**
	 * Callback method called when the user inputs something into the search box.
	 */
	public onSearch(event: { component: IonicSelectableComponent, text: string }): void {
		this.search = event.text;
		this.loadData(event.component, true);
	}

	/**
	 * Callback method used to load more data from the API.
	 */
	public onInfiniteLoad(event: { component: IonicSelectableComponent, text: string }): void {
		this.loadData(event.component, false);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		
		if (this.value) {
			this.loadBaseOptions();
		}
		
		this.onChange(this.value);
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}
