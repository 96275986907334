import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';

export const DL50MasterSettingsLayout: UnoFormField[] = [
	{
		label: 'configuration',
		type: UnoFormFieldTypes.TITLE
	},
	{
		required: false,
		attribute: 'companyUuid',
		label: 'defaultCompany',
		type: UnoFormFieldTypes.COMPANY_SELECTOR,
		showClear: true
	},
	{
		required: false,
		attribute: 'projectCode',
		label: 'projectCode',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'reportTemplate',
		label: 'reportTemplate',
		type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
		multiple: false
	}
];
