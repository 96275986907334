/**
 * NFC handler object used to control NFC during read and write operations.
 */
export class NFCHandler {
	/**
	 * NDEF access object.
	 */
	public ndef: any = null;

	/**
	 * Abort signal controller.
	 */
	public controller: AbortController = null;

	public constructor(ndef: any, controller: AbortController) {
		this.ndef = ndef;
		this.controller = controller;
	}

	/**
	 * Set timeout for the NFC reader.
	 *
	 * @param timeout - Time to disable the NFC handler.
	 */
	public timeout(timeout: number): void {
		setTimeout(() => {
			this.destroy();
		}, timeout);
	}

	/**
	 * Stop reading NFC tags.
	 * 
	 * Should be called when the handler is no longer needed.
	 */
	public destroy(): void {
		if (this.controller) {
			this.controller.abort();
		}
	}
}
