<div class="asset-tree-list-item" [ngClass]="settings.theme === 'dark' ? 'asset-tree-list-item-container-hover-dark':'asset-tree-list-item-container-hover-light'" (click)="this.open();">
	<div class="asset-tree-list-item-space">
		<div class="asset-tree-list-item-container">
			<!-- Level padding -->
			@for (item of (this.item.level | range); track item) {
				<div class="asset-tree-list-item-guideline-container">
					<div class="asset-tree-list-item-guideline"></div>
				</div>
			}

			<!-- Expand arrrows -->
			@if (this.canExpand()) {
				<div class="asset-tree-list-item-arrow">
					@if (this.item.expanded) {
						<uno-icon (click)="this.expand($event)" color="contrast" src="/assets/icons/uno/down-fullarrow.svg"></uno-icon>
					} @else {
						<uno-icon (click)="this.expand($event)" color="contrast" src="/assets/icons/uno/right-fullarrow.svg"></uno-icon>
					}
				</div>
			}

			<!-- Image -->
			<img class="asset-tree-list-item-img" width="34" height="34" loading="lazy" src="{{this.pictureUrl()}}" />

			<!-- Content -->
			<div class="asset-tree-list-item-content">
				@if (this.item.name) {
					<div class="uno-p2 asset-tree-list-item-text">{{this.item.name}}</div>}
				@if (this.item.tag) {
					<div class="uno-p3 asset-tree-list-item-text">{{this.item.tag}}</div>}
			</div>
		</div>

		<!-- Actions -->
		@if (app.device.isDesktop()) {
			<div class="asset-tree-list-item-buttons">
				<ion-icon (click)="$event.stopPropagation(); generateQr(this.item.uuid);" name="qr-code-outline"></ion-icon>
				<ion-icon (click)="app.openInTab('/menu/asset-portfolio/asset/edit', {uuid: this.item.uuid}, $event);" name="desktop-outline"></ion-icon>
			</div>
		}

	</div>

</div>
