import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Session} from '../../session';
import {App} from '../../app';
import {Locale} from '../../locale/locale';
import {Environment} from '../../../environments/environment';
import {Modal} from '../../modal';

/**
 * Screen component should be used as a base for all components that have a route attached.
 *
 * It provides additional control over the screen lifecycle.
 */
@Component({
	template: '',
	selector: 'screen'
})
export class ScreenComponent implements OnInit, OnDestroy {
	/**
	 * Store the screen filters managed by the user.
	 *
	 * These are reused after leaving and returning to the screen component.
	 */
	public static filters: any = {};

	/**
	 * Default values of the filter object.
	 */
	public static defaultFilters: any = {};

	/**
	 * List of permissions required to view this screen.
	 *
	 * The users need to have at least one the permissions. If empty everybody can enter the screen.
	 */
	public permissions: number[] = [];

	public ngOnInit(): void {
		// Check user permissions.
		if (!this.validatePermissions()) {
			if (!Environment.PRODUCTION) {
				console.log('EQS: Screen permissions validation failed.', this);
			}

			Modal.alert(Locale.get('warning'), Locale.get('noPermission'));
			App.navigator.pop();
			return;
		}

		// Reset screen filters if necessary
		if (!Session.settings.keepFilters) {
			this.resetFilters();
		}
	}

	@HostListener('unloaded')
	public ngOnDestroy(): void {}

	/**
	 * Method used to reset the filters of the screen.
	 *
	 * Should be called when the screen is open, the filters can be stored as static data.
	 */
	public resetFilters(): void {}

	/**
	 * Validate the permissions from user session and return the result.
	 */
	public validatePermissions(): boolean {
		return this.permissions.length === 0 || Session.hasPermissions(this.permissions);
	}
}
