import {Vector3, Vector2, Matrix4, PerspectiveCamera, Object3D, MathUtils} from 'three';
import {Easing, Tween} from '@tweenjs/tween.js';
import {Mouse} from '../input/mouse';
import {Keyboard} from '../input/keyboard';
import {Object3DUtils} from '../utils/object-3d-utils';
import {OrthographicCamera} from '../camera/orthographic-camera';
import {Keys} from '../input/keys';
import {EditorControls} from './editor-controls';
import {OrientationCubeSide} from './orientation-cube';

/**
 * Orbit controls can be used to navigate the world using a imaginary central point as reference.
 *
 * The camera orbits around that central point always looking towards it, and the distance to the point can be changes.
 */
export class OrbitControls extends EditorControls {
	/**
	 * Vector pointing up. Use for some calculations.
	 */
	public static UP = new Vector3(0, 1, 0);

	/**
	 * Distance to the center of the orbit.
	 */
	public distance: number = 10;

	/**
	 * Central point of the orbit.
	 */
	public center: Vector3 = new Vector3();

	/**
	 * Orientation of the camera.
	 *
	 * X is the horizontal orientation and Y the vertical orientation.
	 */
	public orientation: Vector2 = new Vector2();

	/**
	 * Maximum Distance allowed.
	 */
	public maxDistance: number = 1e7;

	/**
	 * Minimum distance allowed.
	 */
	public minDistance: number = 1e-1;

	/**
	 * Maximum angle allowed in the y (vertical) orientation.
	 */
	public limitUp: number = 1.57;

	/**
	 * Minimum angle allowed in the y (vertical) orientation.
	 */
	public limitDown: number = -1.57;

	/**
	 * Enables smooth orbit movement.
	 */
	public smooth: boolean = true;

	/**
	 * Orbit speed friction, higher value allow the orbit to retain more speed.
	 *
	 * Only used when smooth is set true.
	 */
	public friction: number = 0.99;

	/**
	 * Obit movement speed.
	 *
	 * Only used when smooth is set true.
	 */
	public speed: number = 0.3;

	public speedDistance: number = 0;

	public speedCenter: Vector3 = new Vector3(0, 0, 0);

	public speedOrientation: Vector2 = new Vector2(0, 0);

	/**
	 * Mouse look sensitivity.
	 */
	public mouseLookSensitivity: number = 0.0015;

	/**
	 * Mouse wheel sensitivity.
	 */
	public mouseWheelSensitivity: number = 0.001;

	/**
	 * If set true the Y orientation movement is inverted.
	 */
	public invertNavigation: boolean = false;

	/**
	 * Flag to indicate if keyboard navigation is enabled.
	 */
	public keyboardNavigation: boolean = true;

	/**
	 * Keyboard navigation speed.
	 */
	public keyboardNavigationSpeed: number = 0.01;

	/**
	 * Indicates if the orbit controls needed an update on the last update.
	 *
	 * The variable is reset on each update call.
	 */
	public needsUpdate: boolean = false;

	/**
	 * Indicates if there is a movement animation running.
	 */
	public animationRunning: boolean = false;

	public constructor() {
		super();

		this.reset();
		this.updateControls();
	}

	public reset(): void {
		this.distance = 10;
		this.center.set(0, 0, 0);
		this.orientation.set(-0.4, 0.4);
		this.updateControls();
	}

	public focusObject(object: Object3D): void {
		let distanceTarget = 0.0;
		const centerTarget = new Vector3();

		const box: any = Object3DUtils.calculateBoundingBox(object);

		if (box !== null) {
			box.getCenter(centerTarget);

			const tempVector: Vector3 = new Vector3(0, 0, 0);

			const size = box.getSize(tempVector).length();

			if (this.camera instanceof PerspectiveCamera) {
				distanceTarget = size / 2 / Math.tan(MathUtils.DEG2RAD * 0.5 * this.camera.fov);
			} else {
				distanceTarget = size;
			}

			const distanceAnimation = new Tween<any>(this);
			distanceAnimation.to({distance: distanceTarget}, 1000);
			distanceAnimation.easing(Easing.Cubic.InOut);
			distanceAnimation.start();

			const centerAnimation = new Tween<Vector3>(this.center);
			centerAnimation.to(centerTarget, 1000);
			centerAnimation.easing(Easing.Cubic.InOut);
			centerAnimation.onStart(() => {
				this.animationRunning = true;
			});
			centerAnimation.onComplete(() => {
				this.animationRunning = false;
			});
			centerAnimation.onUpdate(() => {
				this.updateControls();
			});
			centerAnimation.start();
		}
	}

	public moveTo(point: Vector3): void {
		const animation = new Tween<Vector3>(this.center);
		animation.to(point, 1000);
		animation.easing(Easing.Cubic.InOut);
		animation.onStart(() => {
			this.animationRunning = true;
		});
		animation.onComplete(() => {
			this.animationRunning = false;
		});
		animation.onUpdate(() => {
			this.updateControls();
		});
		animation.start();
	}

	public setOrientation(code: number): void {
		if (code === OrientationCubeSide.Z_POS) {
			this.orientation.set(Math.PI / 2, 0);
		} else if (code === OrientationCubeSide.Z_NEG) {
			this.orientation.set(-Math.PI / 2, 0);
		} else if (code === OrientationCubeSide.X_POS) {
			this.orientation.set(0, 0);
		} else if (code === OrientationCubeSide.X_NEG) {
			this.orientation.set(Math.PI, 0);
		} else if (code === OrientationCubeSide.Y_POS) {
			this.orientation.set(Math.PI, 1.57);
		} else if (code === OrientationCubeSide.Y_NEG) {
			this.orientation.set(Math.PI, -1.57);
		}

		this.updateControls();
	}

	public update(mouse: Mouse, keyboard: Keyboard, delta: number): void {
		if (!this.enabled) {
			return;
		}

		// Ratio relative to the 60hz update
		const ratio = delta / 16.67;

		let y;
		let x;
		this.needsUpdate = false;

		if (mouse.buttonPressed(Mouse.LEFT)) {
			if (this.smooth === true) {
				this.speedOrientation.y += ratio * this.speed * this.mouseLookSensitivity * (this.invertNavigation ? mouse.delta.y : -mouse.delta.y);
				this.speedOrientation.x -= ratio * this.speed * this.mouseLookSensitivity * mouse.delta.x;
			} else {
				this.orientation.y += ratio * this.mouseLookSensitivity * (this.invertNavigation ? mouse.delta.y : -mouse.delta.y);
				this.orientation.x -= ratio * this.mouseLookSensitivity * mouse.delta.x;
			}

			this.needsUpdate = true;
		}

		if (mouse.buttonPressed(Mouse.MIDDLE)) {
			if (this.smooth === true) {
				this.speedCenter.y += ratio * this.speed * this.mouseLookSensitivity * mouse.delta.y * this.distance;
			} else {
				this.center.y += ratio * this.mouseLookSensitivity * mouse.delta.y * this.distance;
			}

			this.needsUpdate = true;
		}

		if (mouse.buttonPressed(Mouse.RIGHT)) {

			const tempVector: Vector3 = new Vector3(0, 0, 0);

			const direction = this.getWorldDirection(tempVector);
			const up = direction.y > 0;
			direction.y = 0;
			direction.normalize();

			if (this.smooth === true) {
				y = ratio * this.speed * mouse.delta.y * this.mouseLookSensitivity * this.distance;
				this.speedCenter.x += up ? -direction.x * y : direction.x * y;
				this.speedCenter.z += up ? -direction.z * y : direction.z * y;

				direction.applyAxisAngle(OrbitControls.UP, Math.PI / 2);

				x = ratio * this.speed * mouse.delta.x * this.mouseLookSensitivity * this.distance;
				this.speedCenter.x -= direction.x * x;
				this.speedCenter.z -= direction.z * x;
			} else {
				y = ratio * mouse.delta.y * this.mouseLookSensitivity * this.distance;
				this.center.x += up ? -direction.x * y : direction.x * y;
				this.center.z += up ? -direction.z * y : direction.z * y;

				direction.applyAxisAngle(OrbitControls.UP, Math.PI / 2);

				x = ratio * mouse.delta.x * this.mouseLookSensitivity * this.distance;
				this.center.x -= direction.x * x;
				this.center.z -= direction.z * x;
			}

			this.needsUpdate = true;
		}

		if (mouse.wheel !== 0) {
			if (this.smooth === true) {
				this.speedDistance += ratio * this.speed * mouse.wheel * this.distance * this.mouseWheelSensitivity;
			} else {
				this.distance += ratio * mouse.wheel * this.distance * this.mouseWheelSensitivity;
			}

			this.needsUpdate = true;
		}

		// Keyboard movement
		if (this.keyboardNavigation && this.keyboardMovement(keyboard)) {
			this.needsUpdate = true;
		}

		if (this.smooth === true) {
			this.distance += this.speedDistance;
			this.center.add(this.speedCenter);
			this.orientation.add(this.speedOrientation);

			this.applyLimits();

			const friction = Math.pow(this.friction, delta);

			this.speedDistance *= friction;
			this.speedOrientation.multiplyScalar(friction);
			this.speedCenter.multiplyScalar(friction);

			this.updateControls();
			return;
		}

		if (this.needsUpdate === true) {
			this.updateControls();
		}
	}

	public keyboardMovement(keyboard: Keyboard): boolean {
		let direction;
		let needsUpdate = false;

		const tempVector: Vector3 = new Vector3(0, 0, 0);

		if (keyboard.keyPressed(Keys.DOWN)) {
			direction = this.getWorldDirection(tempVector);
			direction.y = 0;
			direction.normalize();

			this.center.x += direction.x * this.keyboardNavigationSpeed * this.distance;
			this.center.z += direction.z * this.keyboardNavigationSpeed * this.distance;
			needsUpdate = true;
		}
		if (keyboard.keyPressed(Keys.UP)) {
			direction = this.getWorldDirection(tempVector);
			direction.y = 0;
			direction.normalize();

			this.center.x -= direction.x * this.keyboardNavigationSpeed * this.distance;
			this.center.z -= direction.z * this.keyboardNavigationSpeed * this.distance;
			needsUpdate = true;
		}
		if (keyboard.keyPressed(Keys.LEFT)) {
			direction = this.getWorldDirection(tempVector);
			direction.y = 0;
			direction.normalize();
			direction.applyAxisAngle(OrbitControls.UP, Math.PI / 2);

			this.center.x -= direction.x * this.keyboardNavigationSpeed * this.distance;
			this.center.z -= direction.z * this.keyboardNavigationSpeed * this.distance;
			needsUpdate = true;
		}
		if (keyboard.keyPressed(Keys.RIGHT)) {
			direction = this.getWorldDirection(tempVector);
			direction.y = 0;
			direction.normalize();
			direction.applyAxisAngle(OrbitControls.UP, Math.PI / 2);

			this.center.x += direction.x * this.keyboardNavigationSpeed * this.distance;
			this.center.z += direction.z * this.keyboardNavigationSpeed * this.distance;
			needsUpdate = true;
		}

		return needsUpdate;
	}

	/**
	 * Enforce the camera movement limits.
	 *
	 * Check if the camera is out of bound and set it.
	 */
	public applyLimits(): void {
		if (this.orientation.y < this.limitDown) {
			this.orientation.y = this.limitDown;
		} else if (this.orientation.y > this.limitUp) {
			this.orientation.y = this.limitUp;
		}

		if (this.distance < this.minDistance) {
			this.distance = this.minDistance;
		} else if (this.distance > this.maxDistance) {
			this.distance = this.maxDistance;
		}
	}

	public updateControls(): void {
		this.applyLimits();

		const cos = this.distance * Math.cos(this.orientation.y);
		this.position.set(Math.cos(this.orientation.x) * cos, this.distance * Math.sin(this.orientation.y), Math.sin(this.orientation.x) * cos);
		this.position.add(this.center);

		const tempMatrix: Matrix4 = new Matrix4();
		tempMatrix.lookAt(this.position, this.center, OrbitControls.UP);
		this.quaternion.setFromRotationMatrix(tempMatrix);

		this.updateMatrix();
		this.updateMatrixWorld(true);

		if (this.camera instanceof OrthographicCamera) {
			this.camera.size = this.distance;
			this.camera.updateProjectionMatrix();
		}
	}

	public isMoving(): boolean {
		if (this.animationRunning === true || this.needsUpdate === true) {
			return true;
		}

		if (this.smooth === true) {
			const tresh = 1e-7;

			return this.speedDistance > tresh || this.speedOrientation.x > tresh || this.speedOrientation.y > tresh || this.speedCenter.x > tresh || this.speedCenter.y > tresh || this.speedCenter.z > tresh;
		}

		return this.needsUpdate;
	}
}

