import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {InspectionPage} from './screens/menu/inspection.page';
import {InspectionListPage} from './screens/inspection/list/inspection-list.page';
import {InspectionListStepsPage} from './screens/inspection/list-steps/inspection-list-steps.page';
import {InspectionEditPage} from './screens/inspection/edit/inspection-edit.page';
import {InspectionFormListPage} from './screens/form/list/inspection-form-list.page';
import {InspectionFormEditPage} from './screens/form/edit/inspection-form-edit.page';
import {InspectionWorkflowListPage} from './screens/workflow/list/inspection-workflow-list.page';
import {InspectionWorkflowEditPage} from './screens/workflow/edit/inspection-workflow-edit.page';
import {InspectionProjectListPage} from './screens/project/list/inspection-project-list.page';
import {InspectionProjectEditPage} from './screens/project/edit/inspection-project-edit.page';

const routes: Routes = [
	{
		path: '',
		component: InspectionPage
	},
	{
		path: 'list',
		component: InspectionListPage
	},
	{
		path: 'list/steps',
		component: InspectionListStepsPage
	},
	{
		path: 'edit',
		component: InspectionEditPage
	},
	{
		path: 'form/list',
		component: InspectionFormListPage
	},
	{
		path: 'form/edit',
		component: InspectionFormEditPage
	},
	{
		path: 'workflow/list',
		component: InspectionWorkflowListPage
	},
	{
		path: 'workflow/edit',
		component: InspectionWorkflowEditPage
	},
	{
		path: 'project/list',
		component: InspectionProjectListPage
	},
	{
		path: 'project/edit',
		component: InspectionProjectEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class InspectionsRouterModule { }
