import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {AtexInspectionFormField} from '../data/master-data/atex-inspection-fields';

export class AtexInspectionFieldsService {
	/**
	 * Load list of inspection fields from the API.
	 *
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns On object with all the Atex inspection fields.
	 */
	public static async get(hideLoading: boolean = false, displayError: boolean = true): Promise<{[key: string]: AtexInspectionFormField}> {
		const request = await Service.fetch(ServiceList.atex.inspection.fieldsGet, null, null, null, Session.session, hideLoading, displayError);

		const data: {[key: string]: AtexInspectionFormField} = {};
		for (const i in request.response) {
			data[i] = AtexInspectionFormField.parse(request.response[i]);
		}

		return data;
	}
}
