import {UUID} from 'crypto';
import {Session} from 'src/app/session';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {FFPRecommendation} from '../../../../models/atex-inspections/ffp/ffp-recommendation';

export type AtexFfpListParams = {
	// State of the FFP.
	state?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort direction to apply on the request
	sortDirection?: string,
	// Field to sort by
	sortField?: string,
	// The action plan filter to apply
	filterActionPlan?: number,
	// The recommendation filter to apply
	filterRecommendations?: number
	// Flag to indicate if only the last ffps by asset should be fetched
	listMode?: boolean
};

export type AtexFfpCountParams = {
	// State of the FFP.
	status?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// The action plan filter to apply
	filterActionPlan?: number,
	// The recommendation filter to apply
	filterRecommendations?: number
	// Search mode to apply
	searchMode?: number
	// Flag to indicate if only the last ffps by asset should be fetched
	listMode?: boolean
};

export type AtexFFPListItem = {
	// UUid of the FFP
	uuid: UUID,
	// The asset associated with the FFP
	asset: {name: string, tag: string, uuid: UUID},
	// The result of the FFP
	result: number,
	// The number of action plans that have this FFP
	actionPlanCount: number,
	// The cost of the FFP
	cost: number,
	// The field of the FFP
	field: string,
	// The form of the FFP
	form: string,
	// The inspection associated with the FFP
	inspection: {uuid: UUID},
	// The parent asset associated with the FFP
	parentAsset: {name: string, tag: string, uuid: UUID},
	// The priority of the FFP
	priority: number,
	// The number of recommendations that the FFP has
	recommendations: number
}

export class AtexFfpService {
	/**
	 * List all ffps meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 *
	 * @returns The FFPs that match the params.
	 */
	public static async list(params: AtexFfpListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<{hasMore: boolean, ffp: AtexFFPListItem[], id: number}> {
		const request = await Service.fetch(ServiceList.atex.ffp.list, null, null, params, Session.session, hideLoading, displayError);
		return {
			hasMore: request.response.hasMore,
			ffp: request.response.ffp,
			id: request.id
		};
	}

	/**
	 * Lists FFP recommendations.
	 *
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 *
	 * @returns The FFP recommendations that match the params.
	 */
	public static async listRecommendations(hideLoading: boolean = false, displayError: boolean = true): Promise<FFPRecommendation[]> {
		const request = await Service.fetch(ServiceList.atex.ffp.recommendationsGet, null, null, null, Session.session, hideLoading, displayError);
		return request.response.map((d: any) => { return FFPRecommendation.parse(d); });
	}

	/**
	 * Count all ffps meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 *
	 * @returns Number of FFP recommendations that match the params.
	 */
	public static async count(params: AtexFfpCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		return (await Service.fetch(ServiceList.atex.ffp.count, null, null, params, Session.session, hideLoading, displayError)).response.count;
	}
}


