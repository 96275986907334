import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {RepairWeldingType} from '../../../master-data/repair-welding-type';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {RepairWeldingTypeLayout} from './repair-welding-type-layout';

@Component({
	selector: 'masterdata-repair-welding-types-page',
	templateUrl: 'repair-welding-types.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})
export class RepairWeldingTypesPage extends ScreenComponent implements OnInit {

	public layout: any = RepairWeldingTypeLayout;

	public permissions = [UserPermissions.REPAIR_WELDING_TYPES_EDIT];

	/**
	 * List of repair welding types available in database.
	 */
	public types: RepairWeldingType[] = [];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('weldingTypes');

		RepairWeldingType.load().then((types: RepairWeldingType[]) => {
			this.types = types;
		});
	}

	/**
	 * Add a welding type to the list.
	 */
	public add(): void {
		this.types.push(new RepairWeldingType());
	}

	/**
	 * Delete the welding types from the list.
	 *
	 * @param type - Element to be deleted from list.
	 */
	public delete(type: RepairWeldingType): void {
		Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.repairs.weldingType.delete, null, null, {id: type.id}, Session.session, (response, xhr) => {
					const index = this.types.indexOf(type);
					if (index !== -1) {
						this.types.splice(index, 1);
					}
				});
			}
		});
	}

	/**
	 * Update the checklist value in the server.
	 */
	public save(): void {
		const data = structuredClone(this.types);

		Modal.confirm(Locale.get('confirm'), Locale.get('masterdataConfirm')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.repairs.weldingType.updateBulk, null, null, data, Session.session, (response, xhr) => {
					Modal.toast(Locale.get('updatedSuccessfully'));
					App.navigator.pop();
				});
			}
		});
	}

}
